import { makeStyles } from '@material-ui/core/styles';
import {
  FundraisingStandardAsset,
  FundraisingStandardAssetModel,
  Slider,
  Sliders,
  Texts,
  Video,
  VideoSliders,
} from 'src/models/FundraisingModel';
import { TABS } from 'src/components/Pages/AddFundraisingPage';
import { MouseEvent, useState } from 'react';
import { Store } from 'src/redux/Store';
import { FundraisingLanguages } from 'src/constants/FundraisingLanguages';
import ImageSliderManager from 'src/components/Elements/ImageSliderManager';
import { Paper, Tab, Tabs } from '@material-ui/core';
import TextSliderManager from 'src/components/Elements/TextSliderManager';
import FormUploadImage from 'src/components/Control/FormControls/FormUploadImage';
import VideoChooser from 'src/components/Elements/VideoChooser';
import VideoSliderManager from 'src/components/Elements/VideoSliderManager';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { t } from 'src/lib/language';

const useStyles = makeStyles(() => ({
  container: {
    padding: '16px 8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  header: {
    marginTop: '8px',
    border: 'solid 1px #eee',
    backgroundColor: 'rgba(174, 46, 111, 0.1)',
    fontSize: '16px',
    minHeight: '48px',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
    marginTop: '8px',
  },
}));

interface Props {
  onChange: (eventInfo: FundraisingStandardAssetModel) => void;
  onNextStep: (step:number) => void;
  setErrors: (tab:TABS, hasError: boolean) => void;
  languages: FundraisingLanguages[];
  slug: string,
  initialState: FundraisingStandardAssetModel;
  setHasChanged: (hasChanged: boolean) => void;
}

const FundraisingStandardAssetsForm = (props: Props) => {
  const classes = useStyles();
  const {
    onChange,
    onNextStep,
    setErrors,
    slug,
    languages,
    initialState,
    setHasChanged,
  } = props;

  const organisation = useSelector((state: Store) => state.currentOrganisation.id);
  const lang = useSelector((state: Store) => state.language.language);

  const getInitialAssets = () => {
    if (Object.keys(initialState).length) return initialState;
    const initAssets = {} as FundraisingStandardAssetModel;
    languages.forEach((language) => {
      initAssets[language] = {
        poster_portrait: '',
        poster_landscape: '',
        poster_portrait_slider: [[] as Slider] as Sliders,
        poster_landscape_slider: [[] as Slider] as Sliders,
        texts: [] as Texts,
        video_slider: [] as VideoSliders,
        video: {} as Video,
      } as FundraisingStandardAsset;
    });
    return initAssets;
  };

  const [currentLanguage, setCurrentLanguage] = useState(languages[0]);
  const [fundraisingAsset, setFundraisingAsset] = useState(getInitialAssets());

  const getVideo = () => {
    if (!!fundraisingAsset[currentLanguage]) {
      return fundraisingAsset[currentLanguage].video as Video;
    }
    return {} as Video;
  };

  const onSlidersChange = (
    language: FundraisingLanguages,
    name: string,
    sliders: Sliders,
  ) => {
    setHasChanged(true);
    const newAssets = { ...fundraisingAsset };
    newAssets[language][name] = sliders;
    setFundraisingAsset(newAssets);
  };

  const onTextsChange = (language: FundraisingLanguages, texts: Texts) => {
    setHasChanged(true);
    const newAssets = { ...fundraisingAsset };
    if (!newAssets[language]) {
      newAssets[language] = {} as FundraisingStandardAsset;
    }
    newAssets[language].texts = texts;
    setFundraisingAsset(newAssets);
  };

  const onVideoChange = (video: Video) => {
    setHasChanged(true);
    const newFundraisingAsset = { ...fundraisingAsset };
    newFundraisingAsset[currentLanguage].video = video;
    setFundraisingAsset(newFundraisingAsset);
  };

  const onVideoSliderChange = (language: FundraisingLanguages, videoSlider: VideoSliders) => {
    setHasChanged(true);
    const newFundraisingAsset = { ...fundraisingAsset };
    newFundraisingAsset[currentLanguage].video_slider = videoSlider;
    setFundraisingAsset(newFundraisingAsset);
  };

  const displayTabs = () => languages.map((language) => (
    <Tab key={language} value={language} label={language} />
  ));

  const getPath = (name: string) => {
    if (fundraisingAsset[currentLanguage][name]) {
      return fundraisingAsset[currentLanguage][name];
    }
    const filename = `${name}_${currentLanguage}.png`;
    return `${organisation}/${slug}/images/${filename}`;
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setErrors(TABS.event_assets, false);
    onNextStep(6);
    onChange(fundraisingAsset);
  };

  const getHeader = (field: string, header: string) => <>{header}</>;

  return (
    <>
      <Paper square>
        <Tabs
          value={t(lang, `form.fundraising.${currentLanguage}`)}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, value) => setCurrentLanguage(value)}
          aria-label="disabled tabs example"
        >
          {displayTabs()}
        </Tabs>
      </Paper>
      <Paper square className={classes.container}>
        <TextSliderManager
          name="texts"
          title={t(lang, 'forms.fundraising.texts')}
          language={currentLanguage || FundraisingLanguages.en}
          onChange={onTextsChange}
          value={fundraisingAsset[currentLanguage].texts || [] as Texts}
        />
        <div className={classes.header}>
          {getHeader('poster_portrait', t(lang, 'forms.fundraising.poster_portrait'))}
        </div>
        <FormUploadImage
          name={`poster_portrait_${currentLanguage}.png`}
          height={745}
          width={1150}
          path={getPath('poster_portrait')}
        />
        <ImageSliderManager
          name="poster_portrait_slider"
          title={t(lang, 'forms.fundraising.poster_portrait_slider')}
          onChange={onSlidersChange}
          slug={slug}
          language={currentLanguage || FundraisingLanguages.en}
          height={745}
          width={1150}
          value={fundraisingAsset[currentLanguage].poster_portrait_slider}
        />
        <div className={classes.header}>
          {getHeader('poster_landscape', t(lang, 'forms.fundraising.poster_landscape'))}
        </div>
        <FormUploadImage
          name={`poster_landscape_${currentLanguage}.png`}
          height={1600}
          width={1150}
          path={getPath('poster_landscape')}
        />
        <ImageSliderManager
          name="poster_landscape_slider"
          title={t(lang, 'forms.fundraising.poster_landscape_slider')}
          onChange={onSlidersChange}
          slug={slug}
          language={currentLanguage || FundraisingLanguages.en}
          height={1600}
          width={1150}
          value={fundraisingAsset[currentLanguage].poster_landscape_slider}
        />
        <div className={classes.header}>
          {getHeader('video', 'Video')}
        </div>
        <div className={classes.row}>
          <VideoChooser
            onChange={onVideoChange}
            value={getVideo()}
            name="video"
            language={currentLanguage}
            slug={slug}
          />
        </div>
        <div className={classes.header}>
          {getHeader('video_slider', t(lang, 'forms.fundraising.video_slider'))}
        </div>
        <VideoSliderManager
          name="video_slider"
          label={t(lang, 'forms.fundraising.video_slider')}
          onChange={onVideoSliderChange}
          language={currentLanguage}
          slug={slug}
          value={fundraisingAsset[currentLanguage].video_slider}
        />
      </Paper>
      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          key="submit-button"
        >
          {t(lang, 'forms.fundraising.next_step')}
        </Button>
      </div>
    </>
  );
};

export default FundraisingStandardAssetsForm;
