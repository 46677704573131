import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ButtonProps } from '@material-ui/core/Button/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
// import { t } from 'src/lib/language';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import {
  postDownloadSelectedEntitesAction,
} from 'src/redux/actions/querybuilderActions';
import { DELIVERY_TYPE, FORMAT, ORIENTATION } from 'src/models/DownloadModel';
import { PAYMENT_STATUS, PaymentModel } from 'src/models/PaymentModel';

const UserDownloadPaymentButton = (props: ButtonProps) => {
  const dispatch = useAppDispatch();
  const { disabled } = props;

  // const lang = useSelector((state: Store) => state.language.language);

  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.payments] || [],
  );

  const isPaidStatus = (payment: PaymentModel) => payment.payment_status === PAYMENT_STATUS.paid;

  const isPaid = useSelector(
    (state: Store) => {
      if (!selections.length) { return false; }
      return selections.reduce(
        (result: boolean, id: number) => result || isPaidStatus(state.payments[id]),
      );
    },
  );

  const handleOpen = () => {
    dispatch(postDownloadSelectedEntitesAction(
      ENTITIES.payments,
      selections,
      {
        file: 'payment_receipt.pdf',
        orientation: ORIENTATION.portrait,
        format: FORMAT.pdf,
        delivery: DELIVERY_TYPE.download,
        paymentReceipt: 1,
      },
    ));
  };

  return (
    <>
      <Tooltip
        title="Download receipt"
        placement="top-start"
      >
        <span>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.download}
            onClick={handleOpen}
            disabled={disabled || !isPaid}
          >
            <GetAppIcon />
          </ActionButton>
        </span>
      </Tooltip>
    </>
  );
};

export default UserDownloadPaymentButton;
