import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import { useState } from 'react';
import Line from 'src/components/Elements/Line';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { t } from 'src/lib/language';

const useStyles = makeStyles((theme) => ({
  instructions: {
    fontSize: '0.8rem',
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1),
    color: 'rgb(13, 60, 97)',
    backgroundColor: 'rgb(232, 244, 253)',
    textAlign: 'center',
    width: '100%',
  },
  line: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: 'fit-content',
  },
  outline: {
    borderTop: '1px solid',
    borderTopColor: 'rgb(0,0,0,0.5)',
  },
  hideLink: {
    fontSize: '0.7rem',
    color: theme.palette.primary.main,
    display: 'inline-block',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  fullWidth: {
    width: '100%',
  },
  form: {
    maxWidth: '640px',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    fontSize: '0.8rem',
  },
  hide: {
    display: 'none',
  },
  show: {
    display: 'block',
  },
}));
//
interface Props {
  children: any;
  outline?: boolean;
  fullWidth?: boolean;
}

const HelpBox = (props: Props) => {
  const classes = useStyles();

  const { children, outline, fullWidth } = props;

  const [show, setShow] = useState(false);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  return (
    <div className={`
    ${classes.instructions}
    ${outline ? classes.outline : ''}
    ${fullWidth ? classes.fullWidth : classes.form}
    `}
    >
      <div className={`${show ? classes.hide : classes.show}`}>
        <Line centered>
          <HelpIcon className={classes.icon} />
          <Typography
            className={classes.hideLink}
            onClick={() => setShow(true)}
          >
            {t(lang, 'misc.help')}
          </Typography>
        </Line>
      </div>
      <div className={show ? classes.show : classes.hide}>
        {children}
        <Typography
          className={classes.hideLink}
          onClick={() => setShow(false)}
        >
          {t(lang, 'misc.hide_help')}
        </Typography>
      </div>
    </div>
  );
};

export default HelpBox;
