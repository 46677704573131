import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { DIALOGS } from 'src/models/DialogModel';
import RefundPaymentForm from 'src/components/Forms/RefundPaymentForm';

const RefundPaymentDialog = () => {
  const opened =
    useSelector((state: Store) => state.dialog[DIALOGS.refundPayments]?.opened || false);

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="refund-payment"
      open={opened}
    >
      <DialogTitle id="refund-payment">{t(lang, 'forms.payments.refund_payment')}</DialogTitle>
      <DialogContent dividers>
        <RefundPaymentForm />
      </DialogContent>
    </Dialog>
  );
};

export default RefundPaymentDialog;
