import { MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import {
  getOrganisationAction,
  setCurrentOrganisation,
} from 'src/redux/actions/organisationActions';
import {
  Button, Menu, MenuItem, TextField, Theme,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { getUserDataAction } from 'src/redux/actions/userActions';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles((theme: Theme) => createStyles({
  menu: {
    backgroundColor: theme.palette.primary.light,
  },
}));

const OrganisationSelector = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const [currentFilter, setCurrentFilter] = useState('');
  const currentOrganisationData = useSelector((state: Store) => state.organisation);

  const organisationList = useSelector((state: Store) => state.organisationsUser);
  const organisations = useSelector((state: Store) => state.organisationList);

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    if (Object.keys(organisationList).length < 1) {
      return;
    }
    setAnchorElement(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorElement(null);
  };

  const handleItemClick = (organisation: string) => {
    dispatch(setCurrentOrganisation(organisationList[organisation]));
    dispatch(getUserDataAction());
    dispatch(getOrganisationAction());
    closeMenu();
  };

  const filterName = (id: string, term: string) => {
    const fr = organisations[id].nameFr.toLowerCase().indexOf(term.toLowerCase()) > 0;
    const en = organisations[id].nameEn.toLowerCase().indexOf(term.toLowerCase()) > 0;
    return fr || en;
  };

  const displayItems = () => {
    const elements = [] as Array<JSX.Element>;
    Object.keys(organisationList)
      .filter((id) => !!organisations[id] && !organisations[id].hide)
      .filter((id) => {
        if (currentFilter) {
          return filterName(id, currentFilter);
        }
        return true;
      })
      .forEach((id: string) => {
        if (!!organisations[id] && !organisations[id].hide) {
          elements.push(
            <MenuItem
              key={uuid()}
              onClick={() => handleItemClick(id)}
            >{ organisations[id].nameEn }
            </MenuItem>,
          );
        }
      });
    return elements;
  };

  return (
    <div>
      <Button
        aria-haspopup="true"
        aria-controls="customized-menu"
        className={classes.menu}
        color="primary"
        onClick={openMenu}
        variant="contained"
        title="Current organisation"
      >
        { currentOrganisationData.nameEn || 'Perfect Deed' }
      </Button>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        id="customized-menu"
        getContentAnchorEl={null}
        keepMounted
        onClose={() => closeMenu()}
        open={!!anchorElement}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <div style={{ margin: '0 16px' }}>
          <TextField
            margin="dense"
            variant="outlined"
            label="Filter"
            onChange={(e) => setCurrentFilter(e.target.value)}
            value={currentFilter}
          />
          { displayItems() }
        </div>
      </Menu>
    </div>
  );
};

export default OrganisationSelector;
