import EditInvoiceForm from 'src/components/Forms/EditInvoiceForm';
import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';

const EditInvoiceModal = () => (
  <ModalBase
    name={MODALS.editInvoice}
    size={MODALS_SIZE.large}
  >
    <EditInvoiceForm />
  </ModalBase>
);

export default EditInvoiceModal;
