import {
  ENTITIES,
  GridColumDefinition,
  GridColumns,
  QuerbuilderPayloadColumns,
} from 'src/models/QuerybuilderModel';
import { ListItem, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { f } from 'src/lib/language';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { findIndex } from 'lodash';
import { setColumns } from 'src/redux/actions/querybuilderActions';

const useStyles = makeStyles(() => ({
  menu: {
    marginTop: '30px',
  },
  item: {
    width: '256px',
  },
  icon: {
    marginRight: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  scroller: {
    height: '512px',
    overflowY: 'auto',
  },
}));

interface Props {
  entity: ENTITIES,
  anchor: null | HTMLElement,
  setAnchor: (anchor: null | HTMLElement) => void
}

const ColumnSelectionMenu = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { entity, anchor, setAnchor } = props;

  const lang = useSelector((state: Store) => state.language.language || 'en');
  const customfields = useSelector((state: Store) => state.customfields);
  const columns = useSelector(
    (state: Store) => (
      state.querybuilder[entity] && state.querybuilder[entity].columns
    ) || [] as GridColumns,
  );

  const handleClose = () => {
    setAnchor(null);
  };

  const toggleColumnVisibility = (field: string) => {
    const newColumns = [...columns];
    const columnIndex = findIndex(newColumns, (column) => column.field === field);
    const newColumn = {
      ...columns[columnIndex],
      hide: !columns[columnIndex].hide,
    };
    newColumns.splice(columnIndex, 1, newColumn);

    dispatch(setColumns({
      entity,
      columns: newColumns,
    } as QuerbuilderPayloadColumns));
  };

  const displayIcon = (column: GridColumDefinition) => {
    if (column.hide) {
      return (
        <VisibilityOffIcon
          className={classes.icon}
          color="action"
          fontSize="small"
          onClick={() => toggleColumnVisibility(column.field)}
        />
      );
    }
    return (
      <VisibilityIcon
        className={classes.icon}
        color="primary"
        fontSize="small"
        onClick={() => toggleColumnVisibility(column.field)}
      />
    );
  };

  const getCaption = (column: GridColumDefinition) => {
    if (column.display) {
      return column.display;
    }
    const [table, property] = column.field.split('.');
    return (table === 'custom_fields' && !!customfields[property])
      ? customfields[property][`display_${lang}`]
      : f(lang, column.field);
  };

  const getItems = () => columns.map((column) => (
    <ListItem className={classes.item} key={`column-item-${column.field}`}>
      { displayIcon(column) }
      <span style={column.hide ? { color: 'rgba(0, 0, 0, 0.5)' } : {}}>
        { getCaption(column) }
      </span>
    </ListItem>
  ));

  return (
    <Menu
      className={classes.menu}
      id="customized-menu"
      anchorEl={anchor}
      keepMounted
      open={Boolean(anchor)}
      onClose={handleClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className={classes.scroller}>
        {getItems()}
      </div>
    </Menu>
  );
};

export default ColumnSelectionMenu;
//
