import { useMediaQuery } from 'react-responsive';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  widescreen: {
    padding: theme.spacing(4),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  mobile: {
    padding: theme.spacing(4),
    backgroundColor: '#FFF',
  },
}));

interface Props {
  children: any,
}

const PageBackground = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const classes = useStyles();
  const { children } = props;

  if (isTabletOrMobile) {
    return (
      <div className={classes.mobile}>
        { children }
      </div>
    );
  }
  return (
    <Paper className={classes.widescreen} style={{ width: '100%', minHeight: '825px' }}>
      { children }
    </Paper>
  );
};

export default PageBackground;
