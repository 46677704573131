import { FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { setError } from 'src/redux/actions/errorsActions';
import {
  getUserContactAction,
  postUserContactAction,
  putUserContactAction,
} from 'src/redux/actions/contactActions';
import ContactModel from 'src/models/ContactModel';
import { FORM } from 'src/constants/Form';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import Subtitle from 'src/components/Elements/Subtitle';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import FullContactValidator from 'src/validations/FullContactValidator';
import FullContactShippingValidator from 'src/validations/FullContactShippingValidator';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import { t } from 'src/lib/language';
import SalutationSelector from 'src/components/Control/SalutationSelector';
import CountriesSelector from 'src/components/Control/CountriesSelector';
import AddressSearch from 'src/components/Control/AddressSearch';
import { AddressModel, AddressType } from 'src/models/AddressModel';
import Debug from 'src/lib/Debug';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: '640px',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectElement: {
    width: '100%',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.50)',
  },
  selectLabelError: {
    color: 'rgba(255, 0, 0, 0.50)',
  },
  spacer: {
    height: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
}));

const UserContactForm = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const user = useSelector((state: Store) => state.user);
  const primaryEmail = useSelector((state: Store) => state.user.email);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const contact: ContactModel = useSelector((state: Store) => state.userContact);
  const hasContact = useSelector((state: Store) => state.user.has_contact);

  const [state, setState] = useState({
    ...contact,
    email: primaryEmail,
    sameAsBilling: true,
  });

  useEffect(() => {
    dispatch(getUserContactAction());
    dispatch(setError({ [FORM.contact]: {} }));
  }, []);

  useEffect(() => {
    setState({
      ...contact,
      ...state,
    });
  }, [contact]);

  const onFieldChange = (value: Value, field: string) => {
    switch (field) {
      case 'phone_home':
      case 'phone_office':
      case 'phone_mobile':
        if (value) {
          if (!value.toString().match(/^\d+$/)) {
            return;
          }
          if (value.toString().length >= 20) {
            return;
          }
        }
        break;
      default:
    }
    setState({
      ...state,
      [field]: value,
    });
  };

  const setShippingFromBilling = () => ({
    ...state,
    shipping_address: state.billing_address,
    shipping_suite: state.billing_suite,
    shipping_city: state.billing_city,
    shipping_state: state.billing_state,
    shipping_country_id: state.billing_country_id,
    shipping_zip_code: state.billing_zip_code,
  });

  const setAddressFromGoogle = (address: AddressModel, type: AddressType) => {
    setState({
      ...state,
      [`${type}_address`]: address.address,
      [`${type}_city`]: address.city,
      [`${type}_state`]: address.state,
      [`${type}_country_id`]: address.country_id,
      [`${type}_zip_code`]: (!!address.zip_code) ? address.zip_code.replaceAll(' ', '') : '',
    });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(setError({ [FORM.contact]: {} }));
    try {
      if (!state.sameAsBilling) {
        const schema = FullContactValidator(lang).concat(FullContactShippingValidator(lang));
        schema.validateSync(state, { abortEarly: false });
      } else {
        const schema = FullContactValidator(lang);
        schema.validateSync(state, { abortEarly: false });
      }
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.contact.validation_errors',
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      Debug.log(validationErrors.inner);
      dispatch(setError({ [FORM.contact]: errorBag }));
      return false;
    }
    let data = state;
    if (state.sameAsBilling) {
      data = setShippingFromBilling();
    }
    data.locale_id = user.localeId;
    if (!data.salutation_id) {
      data.salutation_id = '';
    }

    if (hasContact) {
      dispatch(putUserContactAction(data as ContactModel));
      return true;
    }
    dispatch(postUserContactAction(data as ContactModel));
    dispatch(getUserContactAction());
    return true;
  };

  const displayName = !hasContact || (hasContact && !!state.first_name);
  const displayCompany = !hasContact || (hasContact && !!state.company_name);

  return (
    <form className={classes.form} noValidate onSubmit={(event) => handleSubmit(event)}>
      <Subtitle>{t(lang, 'forms.contact.header_name')}</Subtitle>
      <div className={displayName ? '' : classes.hide}>
        <SalutationSelector
          form={FORM.contact}
          name="salutation_id"
          value={state.salutation_id || ''}
          onChange={(id) => onFieldChange(id, 'salutation_id')}
        />
        <FormTextField
          disabled={hasContact}
          form={FORM.contact}
          label={t(lang, 'forms.contact.first_name')}
          name="first_name"
          onChange={onFieldChange}
          required
          value={state.first_name}
        />
        <FormTextField
          disabled={hasContact}
          form={FORM.contact}
          label={t(lang, 'forms.contact.last_name')}
          name="last_name"
          onChange={onFieldChange}
          required
          value={state.last_name}
        />
      </div>
      <div className={displayCompany ? '' : classes.hide}>
        <FormTextField
          disabled={hasContact}
          form={FORM.contact}
          label={t(lang, 'forms.contact.company_name')}
          name="company_name"
          onChange={onFieldChange}
          value={state.company_name}
        />
      </div>
      <Subtitle>{t(lang, 'forms.contact.header_emails')}</Subtitle>
      <FormTextField
        disabled
        form={FORM.contact}
        label={t(lang, 'forms.contact.primary_email')}
        name="email"
        onChange={onFieldChange}
        required
        value={primaryEmail || ''}
      />
      <FormCheckbox
        name="email_allow_notification"
        label={t(lang, 'forms.contact.email_notification')}
        checked={!!state.email_allow_notification}
        onChange={onFieldChange}
      />
      <FormTextField
        form={FORM.contact}
        label={t(lang, 'forms.contact.secondary_email')}
        name="secondary_email"
        onChange={onFieldChange}
        required
        value={state.secondary_email || ''}
      />
      <FormCheckbox
        name="secondary_email_allow_notification"
        label={t(lang, 'forms.contact.email_notification')}
        checked={!!state.secondary_email_allow_notification}
        onChange={onFieldChange}
      />
      <Subtitle>{t(lang, 'forms.contact.header_billing_address')}</Subtitle>
      <AddressSearch
        onChange={
          onFieldChange
        }
        onAddressChange={
          (addresse: AddressModel) => { setAddressFromGoogle(addresse, AddressType.billing); }
        }
        value={{ description: state.billing_address || '' }}
        name="billing_address"
        form={FORM.contact}
      />
      <FormTextField
        form={FORM.contact}
        label={t(lang, 'forms.contact.suite')}
        name="billing_suite"
        onChange={onFieldChange}
        value={state.billing_suite || ''}
      />
      <FormTextField
        form={FORM.contact}
        label={t(lang, 'forms.contact.city')}
        name="billing_city"
        onChange={onFieldChange}
        required
        value={state.billing_city || ''}
      />
      <FormTextField
        form={FORM.contact}
        label={t(lang, 'forms.contact.state')}
        name="billing_state"
        onChange={onFieldChange}
        required
        value={state.billing_state || ''}
      />
      <CountriesSelector
        form={FORM.contact}
        name="billing_country_id"
        onChange={(id) => onFieldChange(id, 'billing_country_id')}
        value={state.billing_country_id || 0}
      />
      <FormTextField
        form={FORM.contact}
        label={t(lang, 'forms.contact.zip_code')}
        name="billing_zip_code"
        onChange={onFieldChange}
        required
        value={state.billing_zip_code || ''}
      />
      <Subtitle>{t(lang, 'forms.contact.header_shipping_address')}</Subtitle>
      <div className={classes.spacer} />
      <FormCheckbox
        checked={!!state.sameAsBilling}
        label={t(lang, 'forms.contact.same_as_billing')}
        name="sameAsBilling"
        onChange={onFieldChange}
      />
      { !state.sameAsBilling && (
        <>
          <AddressSearch
            onChange={
              onFieldChange
            }
            onAddressChange={
              (addresse: AddressModel) => { setAddressFromGoogle(addresse, AddressType.shipping); }
            }
            value={{ description: state.shipping_address || '' }}
            name="shipping_address"
            form={FORM.contact}
          />
          <FormTextField
            disabled={state.sameAsBilling}
            form={FORM.contact}
            label={t(lang, 'forms.contact.suite')}
            name="shipping_suite"
            onChange={onFieldChange}
            value={state.shipping_suite || ''}
          />
          <FormTextField
            disabled={state.sameAsBilling}
            form={FORM.contact}
            label={t(lang, 'forms.contact.city')}
            name="shipping_city"
            onChange={onFieldChange}
            required
            value={state.shipping_city || ''}
          />
          <FormTextField
            disabled={state.sameAsBilling}
            form={FORM.contact}
            label={t(lang, 'forms.contact.state')}
            name="shipping_state"
            onChange={onFieldChange}
            required
            value={state.shipping_state || ''}
          />
          <CountriesSelector
            form={FORM.contact}
            name="shipping_country_id"
            onChange={(id) => onFieldChange(id, 'shipping_country_id')}
            value={state.shipping_country_id || 0}
          />
          <FormTextField
            disabled={state.sameAsBilling}
            form={FORM.contact}
            label={t(lang, 'forms.contact.zip_code')}
            name="shipping_zip_code"
            onChange={onFieldChange}
            required
            value={state.shipping_zip_code || ''}
          />
        </>
      ) }
      <Subtitle>{t(lang, 'forms.contact.header_phones')}</Subtitle>
      <FormTextField
        form={FORM.contact}
        label={t(lang, 'forms.contact.phone_home')}
        name="phone_home"
        onChange={onFieldChange}
        required
        value={state.phone_home || ''}
      />
      <FormCheckbox
        checked={!!state.phone_home_allow_notification}
        label={t(lang, 'forms.contact.phone_notification')}
        name="phone_home_allow_notification"
        onChange={onFieldChange}
      />
      <FormTextField
        form={FORM.contact}
        label={t(lang, 'forms.contact.phone_mobile')}
        name="phone_mobile"
        onChange={onFieldChange}
        required
        value={state.phone_mobile || ''}
      />
      <FormCheckbox
        checked={!!state.phone_mobile_allow_notification}
        label={t(lang, 'forms.contact.phone_notification')}
        name="phone_mobile_allow_notification"
        onChange={onFieldChange}
      />
      <FormTextField
        form={FORM.contact}
        label={t(lang, 'forms.contact.phone_office')}
        name="phone_office"
        onChange={onFieldChange}
        required
        value={state.phone_office || ''}
      />
      <FormCheckbox
        checked={!!state.phone_office_allow_notification}
        label={t(lang, 'forms.contact.phone_notification')}
        name="phone_office_allow_notification"
        onChange={onFieldChange}
      />
      <br />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        {t(lang, 'misc.save')}
      </Button>
    </form>
  );
};

export default UserContactForm;
//
