import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { EmptyFilter } from 'src/models/AdvanceSearchModel';
import { getOperatorAndFilters } from 'src/lib/QueryBuilderHelper';
import { FIELD_TYPES } from 'src/constants/Fields';
import { OPERATOR_EXTRA_TYPE, SEARCH_OPERATORS } from 'src/constants/SearchOperators';
import { ENTITIES, GridColumDefinition, GridColumns } from 'src/models/QuerybuilderModel';
import { FILTER_POSITION } from 'src/constants/FilterPosition';
import { AUTOCOMPLETE_ID } from 'src/constants/Autocomplete';
import { CustomFieldType } from 'src/models/CustomfieldModel';
import OperandPaymentType from 'src/components/Control/AdvancedSearch/Operands/OperandPaymentType';
import OperandInvoiceState
  from 'src/components/Control/AdvancedSearch/Operands/OperandInvoiceState';
import OperandBetweenText from 'src/components/Control/AdvancedSearch/Operands/OperandBetweenText';
import OperandBetweenDate from 'src/components/Control/AdvancedSearch/Operands/OperandBetweenDate';
import OperandText from 'src/components/Control/AdvancedSearch/Operands/OperandText';
import OperandDate from 'src/components/Control/AdvancedSearch/Operands/OperandDate';
import OperandListSingle from 'src/components/Control/AdvancedSearch/Operands/OperandListSingle';
import OperandGroup from 'src/components/Control/AdvancedSearch/Operands/OperandGroup';
import OperandCountry from 'src/components/Control/AdvancedSearch/Operands/OperandCountry';
import OperandSalutation from 'src/components/Control/AdvancedSearch/Operands/OperandSalutation';
import OperandDepartment from 'src/components/Control/AdvancedSearch/Operands/OperandDepartment';
import OperandAccount from 'src/components/Control/AdvancedSearch/Operands/OperandAccount';
import OperandProgram from 'src/components/Control/AdvancedSearch/Operands/OperandProgram';
import OperandBankAccount from 'src/components/Control/AdvancedSearch/Operands/OperandBankAccount';
import OperandContact from 'src/components/Control/AdvancedSearch/Operands/OperandContact';
import OperandEmailStatus from 'src/components/Control/AdvancedSearch/Operands/OperandEmailStatus';
import OperandPaymentStatus
  from 'src/components/Control/AdvancedSearch/Operands/OperandPaymentStatus';
import OperandInvoicePaymentStatus
  from 'src/components/Control/AdvancedSearch/Operands/OperandInvoicePaymentStatus';

interface Props {
  entity: ENTITIES;
  index: number;
}

const OperandSelector = (props: Props) => {
  const {
    index, entity,
  } = props;

  const columns = useSelector(
    (state: Store) => (state.querybuilder[entity] && state.querybuilder[entity].columns)
      || [] as GridColumns,
  );

  const customfields = useSelector(
    (state: Store) => state.customfields,
  );

  const filters = useSelector(
    (state: Store) => state.advancedSearch[entity] || EmptyFilter,
  );

  const filterList = getOperatorAndFilters(filters)[1];

  const field = filterList[index][FILTER_POSITION.field];
  const operator = filterList[index][FILTER_POSITION.operator];
  const extra = filterList[index][FILTER_POSITION.extra];

  const getColumType = () => {
    if (!field) return '';
    const [table, property] = field.split('.');
    if (table === 'custom_fields') {
      if (!customfields[property]) return 'inexistent';
      return customfields[property].type_id as CustomFieldType;
    }
    let column = {} as GridColumDefinition;
    columns.some((item) => {
      if (item.field === field) {
        column = item;
        return true;
      }
      return false;
    });
    return column.type as FIELD_TYPES;
  };

  const displayOperandField = () => {
    switch (getColumType()) {
      case FIELD_TYPES.listMultiple:
      case FIELD_TYPES.listSingle:
        return (<OperandListSingle index={index} entity={entity} />);
      case FIELD_TYPES.date:
      case FIELD_TYPES.datetime:
        return (<OperandDate index={index} entity={entity} />);
      case FIELD_TYPES.group: {
        const multiple =
          operator === SEARCH_OPERATORS.is_in || operator === SEARCH_OPERATORS.is_not_in;
        return (<OperandGroup index={index} entity={entity} multiple={multiple} />);
      }
      case FIELD_TYPES.country: {
        const multiple =
          operator === SEARCH_OPERATORS.is_in || operator === SEARCH_OPERATORS.is_not_in;
        return (<OperandCountry index={index} entity={entity} multiple={multiple} />);
      }
      case FIELD_TYPES.invoiceStatus: {
        const multiple =
          operator === SEARCH_OPERATORS.is_in || operator === SEARCH_OPERATORS.is_not_in;
        return (
          <OperandInvoiceState index={index} entity={entity} multiple={multiple} />
        );
      }
      case FIELD_TYPES.invoicePaymentStatus: {
        const multiple =
          operator === SEARCH_OPERATORS.is_in || operator === SEARCH_OPERATORS.is_not_in;
        return (
          <OperandInvoicePaymentStatus index={index} entity={entity} multiple={multiple} />
        );
      }
      case FIELD_TYPES.paymentType: {
        const multiple =
          operator === SEARCH_OPERATORS.is_in || operator === SEARCH_OPERATORS.is_not_in;
        return (
          <OperandPaymentType index={index} entity={entity} multiple={multiple} />
        );
      }
      case FIELD_TYPES.paymentStatus: {
        const multiple =
          operator === SEARCH_OPERATORS.is_in || operator === SEARCH_OPERATORS.is_not_in;
        return (<OperandPaymentStatus index={index} entity={entity} multiple={multiple} />);
      }
      case FIELD_TYPES.salutation:
        return (<OperandSalutation index={index} entity={entity} />);
      case FIELD_TYPES.emailStatus: {
        const multiple =
          operator === SEARCH_OPERATORS.is_in || operator === SEARCH_OPERATORS.is_not_in;
        return (<OperandEmailStatus index={index} entity={entity} multiple={multiple} />);
      }
      case FIELD_TYPES.department: {
        const multiple =
          operator === SEARCH_OPERATORS.is_in || operator === SEARCH_OPERATORS.is_not_in;
        return (<OperandDepartment index={index} entity={entity} multiple={multiple} />);
      }
      case FIELD_TYPES.account: {
        const multiple =
          operator === SEARCH_OPERATORS.is_in || operator === SEARCH_OPERATORS.is_not_in;
        return (<OperandAccount index={index} entity={entity} multiple={multiple} />);
      }
      case FIELD_TYPES.organisationBankAccount:
        return (<OperandBankAccount index={index} entity={entity} />);
      case FIELD_TYPES.program: {
        const multiple =
          operator === SEARCH_OPERATORS.is_in || operator === SEARCH_OPERATORS.is_not_in;
        return (<OperandProgram index={index} entity={entity} multiple={multiple} />);
      }
      case FIELD_TYPES.contact: {
        const property = field.split('.')[1];
        let autocompleteId = AUTOCOMPLETE_ID.search_contact;
        switch (property) {
          case 'seller_id':
            autocompleteId = AUTOCOMPLETE_ID.search_seller;
            break;
          case 'contact_payer_id':
            autocompleteId = AUTOCOMPLETE_ID.search_payer;
            break;
          default:
            autocompleteId = AUTOCOMPLETE_ID.search_contact;
        }
        return (
          <OperandContact
            autocompleteId={autocompleteId}
            index={index}
            entity={entity}
          />
        );
      }
      case 'inexistent':
        return (<OperandText index={index} entity={entity} isEmpty />);
      default:
        return (<OperandText index={index} entity={entity} />);
    }
  };

  switch (operator) {
    case SEARCH_OPERATORS.is_between:
    case SEARCH_OPERATORS.is_not_between: {
      return (getColumType() === FIELD_TYPES.date || getColumType() === FIELD_TYPES.datetime)
        ? (<OperandBetweenDate index={index} entity={entity} />)
        : (<OperandBetweenText index={index} entity={entity} />);
    }
    case '':
    case SEARCH_OPERATORS.is_empty:
    case SEARCH_OPERATORS.is_not_empty:
    case SEARCH_OPERATORS.is_not_define:
    case SEARCH_OPERATORS.is_true:
    case SEARCH_OPERATORS.is_false:
      return (<></>);
    default:
      if (extra === OPERATOR_EXTRA_TYPE.boolean) {
        return (<></>);
      }
      return displayOperandField();
  }
};

export default OperandSelector;
