import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { setError } from 'src/redux/actions/errorsActions';
import { EditCustomfieldFormType, FORM } from 'src/constants/Form';
import { t } from 'src/lib/language';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { Customfield, CustomFieldsPayload, CustomFieldType } from 'src/models/CustomfieldModel';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import CustomfieldTypeSelector from 'src/components/Control/CustomfieldTypeSelector';
import { putCustomFieldsAction } from 'src/redux/actions/customfieldActions';
import CustomfieldCreationValidator from 'src/validations/CustomfieldCreationValidator';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';

interface Props {
  customfield: Customfield;
  onClose?: () => void;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: '640px',
  },
  submit: {
    marginTop: '12px',
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectElement: {
    width: '100%',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.50)',
  },
  selectLabelError: {
    color: 'rgba(255, 0, 0, 0.50)',
  },
  spacer: {
    height: theme.spacing(3),
  },
  hide: {
    display: 'none',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '10px',
  },
}));

const EditCustomfieldForm = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const { customfield, onClose } = props;

  const [state, setState] = useState(customfield);
  const form = `edit-customfield-${customfield.id}` as EditCustomfieldFormType;

  useEffect(() => {
    dispatch(setError({ [form]: {} }));
  }, []);

  const onChange = (value: any, field: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setError({ [form]: {} }));
    try {
      CustomfieldCreationValidator(lang).validateSync(state, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.customfields.validation_errors',
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [form]: errorBag }));
      return false;
    }
    const payload = {
      name: customfield.name,
      display: {
        en_CA: state.display_en,
        fr_CA: state.display_fr,
      },
      list: state.list,
      list_type_id: CustomFieldType.string,
      type_id: state.type_id,
      required: state.required,
    } as unknown as CustomFieldsPayload;
    dispatch(putCustomFieldsAction(customfield.id, payload));
    return true;
  };

  const displayListValueField = () => {
    if (
      state.type_id === CustomFieldType.multipleList
      || state.type_id === CustomFieldType.singleList
    ) {
      return (
        <FormTextField
          form={FORM.add_customfield}
          label={t(lang, 'forms.customfields.list')}
          name="list"
          onChange={onChange}
          required
          value={state.list}
        />
      );
    }
    return (<></>);
  };

  return (
    <form className={classes.form} noValidate>
      <FormTextField
        form={form}
        label={t(lang, 'forms.customfields.display_en')}
        name="display_en"
        onChange={onChange}
        required
        value={state.display_en}
      />
      <FormTextField
        form={form}
        label={t(lang, 'forms.customfields.display_fr')}
        name="display_fr"
        onChange={onChange}
        required
        value={state.display_fr}
      />
      <CustomfieldTypeSelector
        form={form}
        name="type_id"
        value={state.type_id || ''}
        onChange={(type_id) => onChange(type_id, 'type_id')}
      />
      { displayListValueField() }
      <div className={classes.spacer} />
      <FormCheckbox
        checked={state.required || false}
        label={t(lang, 'forms.customfields.required')}
        name="required"
        onChange={onChange}
      />
      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
          key="submit-button"
        >
          {t(lang, 'misc.save')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleClose}
          key={`submit-button-custom-field-${customfield.id}`}
        >
          {t(lang, 'misc.back_to_list')}
        </Button>
      </div>
    </form>
  );
};

export default EditCustomfieldForm;
