import { makeStyles } from '@material-ui/core/styles';
import { SEARCH_TABS } from 'src/constants/SearchTabs';

const useStyles = (selected:boolean, first?: boolean) => makeStyles((theme) => ({
  datagridSearchTab: {
    height: '30px',
    lineHeight: '30px',
    verticalAlign: 'middle',
    padding: theme.spacing(0, 2),
    borderRight: '1px solid #eee',
    borderTop: '1px solid #eee',
    borderLeft: first ? '1px solid #eee' : '',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#FFF',
      cursor: 'pointer',
      borderRight: '1px solid #FFF',
    },
    backgroundColor: selected ? theme.palette.primary.main : '',
    color: selected ? '#fff' : theme.palette.text.primary,
  },
}));
//
interface Props {
  display: string;
  first?: boolean;
  setSelected: (selected: SEARCH_TABS) => void;
  selected: boolean;
  tab: SEARCH_TABS;
}

const DatagridSearchTab = (props: Props) => {
  const {
    display, first, selected, setSelected, tab,
  } = props;

  const classes = useStyles(selected, first)();

  return (
    <div
      className={classes.datagridSearchTab}
      onClick={() => setSelected(tab)}
    >
      {display}
    </div>
  );
};

export default DatagridSearchTab;
