import { makeStyles } from '@material-ui/core/styles';
import SubmenuLink from 'src/components/Elements/SubmenuLink';
import { useEffect, useState } from 'react';
import { getTaxesAction } from 'src/redux/actions/taxActions';
import OrganisationAddressForm from 'src/components/Forms/OrganisationAddressForm';
import OrganisationImagesForm from 'src/components/Forms/OrganisationImagesForm';
import OrganisationTaxesForm from 'src/components/Forms/OrganisationTaxesForm';
import OrganisationInfo from 'src/components/Forms/OrganisationInfo';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import ManageBankAccounts from 'src/components/Elements/ManageBankAccounts';
import { getSettingsAction } from 'src/redux/actions/settingActions';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'bottom',
    height: '4vh',
    gap: '10px',
  },
  header: {
    paddingLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
}));

export const enum TABS {
  general = 'general',
  payment = 'payment',
  address = 'address',
  images = 'images',
  taxes = 'taxes',
}

const SettingsPage = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [currentTab, setCurrentTab] = useState(TABS.general);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  useEffect(() => {
    dispatch(getTaxesAction());
    dispatch(getSettingsAction());
  }, []);

  const displayForm = () => {
    switch (currentTab) {
      case TABS.payment:
        return (<ManageBankAccounts />);
      case TABS.address:
        return (<OrganisationAddressForm />);
      case TABS.images:
        return (<OrganisationImagesForm />);
      case TABS.taxes:
        return (<OrganisationTaxesForm />);
      default:
        return (<OrganisationInfo />);
    }
  };

  return (
    <div>
      <div className={`${classes.row} ${classes.header}`}>
        <SubmenuLink
          onClick={() => setCurrentTab(TABS.general)}
          selected={currentTab === TABS.general}
          noMargin
        >
          <span>{t(lang, 'misc.general_information')}</span>
        </SubmenuLink>
        <SubmenuLink
          onClick={() => setCurrentTab(TABS.address)}
          selected={currentTab === TABS.address}
          noMargin
        >
          <span>{t(lang, 'misc.address_information')}</span>
        </SubmenuLink>
        <SubmenuLink
          onClick={() => setCurrentTab(TABS.payment)}
          selected={currentTab === TABS.payment}
          noMargin
        >
          <span>{t(lang, 'misc.payment_information')}</span>
        </SubmenuLink>
        <SubmenuLink
          onClick={() => setCurrentTab(TABS.taxes)}
          selected={currentTab === TABS.taxes}
          noMargin
        >
          <span>Taxes</span>
        </SubmenuLink>
        <SubmenuLink
          onClick={() => setCurrentTab(TABS.images)}
          selected={currentTab === TABS.images}
          noMargin
        >
          <span>Images</span>
        </SubmenuLink>
      </div>
      <div>
        { displayForm() }
      </div>
    </div>
  );
};

export default SettingsPage;
