import GuessPaymentForm from 'src/components/Forms/GuessPaymentForm';
import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';

const GuessPaymentModal = () => (
  <ModalBase
    name={MODALS.guessPayment}
    size={MODALS_SIZE.medium}
  >
    <GuessPaymentForm />
  </ModalBase>
);

export default GuessPaymentModal;
