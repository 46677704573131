import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import ManageAccounts from 'src/components/Elements/ManageAccounts';

const AccountManagementModal = () => (
  <ModalBase
    name={MODALS.manageAccount}
    size={MODALS_SIZE.small}
  >
    <ManageAccounts />
  </ModalBase>
);

export default AccountManagementModal;
