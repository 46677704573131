import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';

import Button from '@material-ui/core/Button';
import { Box, Checkbox, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  deleteOrganisationsUserAction,
  shareInfoOrganisationsUserAction,
} from 'src/redux/actions/organisationActions';
import Line from 'src/components/Elements/Line';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import DeleteOrganisationDialog from 'src/components/Dialogs/DeleteOrganisationDialog';
import { setFormStatus } from 'src/redux/actions/formActions';
import { t } from 'src/lib/language';
import { v4 as uuid } from 'uuid';
import { openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  userOrganisationsBox: {
    margin: 0,
    padding: 0,
    fontSize: '1.0rem',
  },
  disabled: {
    color: 'rgba(0,0,0,0.5)',
  },
  deleteIcon: {
    marginRight: theme.spacing(0.5),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const OrganisationsForm = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const organisationsUserList = useSelector((state: Store) => state.organisationsUser);
  const organisations = useSelector((state: Store) => state.organisationList);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const sharingStatus = useSelector(
    (state: Store) => state.formStatus.user_organisation_share,
  );

  const deleteStatus = useSelector(
    (state: Store) => state.formStatus.user_organisation_delete,
  );

  const processing =
    () => (sharingStatus === FORM_STATUS.processing) || (deleteStatus === FORM_STATUS.processing);

  const [confirm, setConfirm] = useState(false);
  const [organisationToDelete, setOrganisationToDelete] = useState('');

  useEffect(() => {
    dispatch(setFormStatus({ [FORM.user_organisation_share]: null }));
    dispatch(setFormStatus({ [FORM.user_organisation_delete]: null }));
  }, []);

  const handleOpen = () => {
    dispatch(openModal({ modal: MODALS.chooseOrganisation }));
  };

  const shareInfoWithOrganisation = (organisation: string, share: boolean) => {
    dispatch(shareInfoOrganisationsUserAction(organisation, share));
  };

  const confirmDeleteOrganisation = (organisation: string) => {
    setOrganisationToDelete(organisation);
    setConfirm(true);
  };

  const deleteOrganisation = () => {
    dispatch(deleteOrganisationsUserAction(organisationToDelete));
  };

  const displayUserOrganisation = () => {
    const elements = [] as Array<JSX.Element>;
    Object.keys(organisationsUserList).forEach((id: string) => {
      elements.push(
        <Box key={uuid()}>
          <Line>
            <Tooltip
              title={t(lang, 'forms.organisations.remove_organisation')}
              placement="top-start"
            >
              <DeleteIcon
                fontSize="small"
                className={`
                    ${classes.deleteIcon}
                    ${processing() ? classes.disabled : ''}
                `}
                onClick={() => confirmDeleteOrganisation(id)}

              />
            </Tooltip>
            <Tooltip
              title={t(lang, 'forms.organisations.share_contact_info')}
              placement="top-start"
            >
              <Checkbox
                disabled={processing()}
                checked={!!organisationsUserList[id].shareInfo}
                color="primary"
                onChange={
                  (e) => shareInfoWithOrganisation(id, e.target.checked as boolean)
                }
              />
            </Tooltip>
            {organisations[id]?.nameEn}
          </Line>
        </Box>,
      );
    });
    return elements;
  };

  return (
    <div>
      <div className={classes.container}>
        <Box
          className={classes.userOrganisationsBox}
        >
          {displayUserOrganisation()}
        </Box>
      </div>
      <Button variant="contained" color="primary" onClick={() => handleOpen()}>
        {t(lang, 'forms.organisations.add_organisation')}
      </Button>
      <DeleteOrganisationDialog
        open={confirm}
        toggleOpen={() => setConfirm(false)}
        onDelete={() => deleteOrganisation()}
      >
        {t(lang, 'forms.organisations.delete_organisation_confirm')}
      </DeleteOrganisationDialog>
    </div>
  );
};
export default OrganisationsForm;
