import { makeStyles } from '@material-ui/core/styles';
import { OrderColumn } from 'src/components/Elements/OrderDisplayer';
import ProductsSelector from 'src/components/Control/ProductsSelector';
import { INVOICE_ORDER_FIELDS, INVOICE_STATE } from 'src/constants/Invoices';
import { OrderTaxModel, ProductOrderList, ProductOrderModel } from 'src/models/InvoiceModel';
import { t } from 'src/lib/language';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import ButtonLink from 'src/components/Elements/ButtonLink';
import { ProductList, ProductModel } from 'src/models/ProductModel';
import { useEffect, useState } from 'react';
import TaxSelector from 'src/components/Control/TaxSelector';
import { FORM_ID } from 'src/models/FormModel';
import { keyBy } from 'lodash';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import { Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  items: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: '60px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'end',
    flexDirection: 'row',
    gap: '10px',
  },
  actions: {
    marginRight: '4px',
    marginTop: '-8px',
    display: 'flex',
    gap: '8px',
  },
  odd: {
    backgroundColor: '#f8f8f8',
  },
  even: {
    backgroundColor: '#f0f0f0',
  },
  item: {
    padding: '0 4px',
  },
  delete: {
    minWidth: OrderColumn.actions,
    width: OrderColumn.actions,
    padding: '0 4px',
  },
  quantity: {
    minWidth: OrderColumn.quantity,
    width: OrderColumn.quantity,
    padding: '0 4px',
  },
  product: {
    width: OrderColumn.product,
    padding: '0 4px',
  },
  price: {
    minWidth: OrderColumn.price,
    width: OrderColumn.price,
    padding: '0 4px',
  },
  taxes: {
    minWidth: OrderColumn.taxes,
    width: OrderColumn.taxes,
    padding: '0 4px',
    paddingTop: '4px',
  },
  discount: {
    minWidth: OrderColumn.discount,
    width: OrderColumn.discount,
  },
  deductible: {
    minWidth: OrderColumn.deductible,
    width: OrderColumn.deductible,
  },
  amount: {
    minWidth: OrderColumn.amount,
    width: OrderColumn.amount,
    textAlign: 'right',
  },
  divider: {
    width: '1px',
    height: '20px',
  },
  noteContainer: {
    width: '100%',
  },
}));

interface Props {
  canDeleteProduct: boolean,
  form: FORM_ID,
  index: number,
  onDeleteProduct: (index: number) => void,
  onUpdateProduct: (value: Value, field: string, index: number) => void,
  onProductChange: (product?: ProductModel, index?: number) => void,
  order: ProductOrderModel,
  orders: ProductOrderList,
  invoiceState?: INVOICE_STATE,
  disabled?: boolean,
}

const OrderProductItem = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {
    canDeleteProduct,
    form,
    index,
    onDeleteProduct,
    onUpdateProduct,
    onProductChange,
    order,
    orders,
    invoiceState,
    disabled,
  } = props;

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'CAD',
  });

  const lang = useSelector((state: Store) => state.language.language);
  const products = useSelector((state: Store) => state.products || [] as ProductList);
  const taxes = useSelector((state: Store) => {
    if (!state.taxes) return {};
    const result = {};
    state.taxes.forEach((tax) => {
      if (tax.id) result[tax.id] = tax;
    });
    return result;
  });

  const getProduct = (id:number) => (
    products && products.find((product) => product.id === id)
  );

  const getSelectedTax = () => Object.keys(order.taxes ?? {}).map(Number) as number[];

  const [displayNote, setDisplayNote] = useState(false);
  const [orderItemSubtotal, setOrderItemSubtotal] = useState(0);

  useEffect(() => {
    setOrderItemSubtotal((order.quantity ?? 0) * ((order.paid ?? 0) - (order.discount ?? 0)));
  }, [order.paid, order.quantity, order.discount]);

  const handleProductChange = (productId: number) => {
    const product = getProduct(productId);
    if (!productId && !product) return;

    if (
      Object.keys(keyBy(orders, 'product_id')).length
      !== Object.values(orders).length
    ) {
      dispatch(setAlert({
        type: ALERT_TYPE.warning,
        code: 'Product already present in order list',
      }));
      return;
    }
    onProductChange(product, index);
  };

  const onDeleteNotes = () => {
    onUpdateProduct('', INVOICE_ORDER_FIELDS.note, index);
    setDisplayNote(false);
  };

  const displayNoteActions = () => {
    if (!displayNote) {
      return (
        <ButtonLink onClick={() => setDisplayNote(true)}>{t(lang, 'forms.invoices.view_note')}</ButtonLink>
      );
    }
    return (
      <ButtonLink onClick={() => onDeleteNotes()}>{t(lang, 'forms.invoices.hide_note')}</ButtonLink>
    );
  };

  const formatTaxes = (taxesValue: number[]) => {
    const taxesObject = {} as OrderTaxModel;
    taxesValue.forEach((taxId: number) => {
      const subtotal = (order.quantity ?? 0) * ((order.paid ?? 0) - (order.discount ?? 0));
      taxesObject[taxId] = subtotal * taxes[taxId].rate;
    });
    return taxesObject;
  };

  const displayDeleteButton = (indexRow:number) => {
    if (!canDeleteProduct || disabled) return (<></>);
    return (
      <div className={`${classes.item} ${classes.delete}`}>
        <Tooltip
          title="Delete that note"
          placement="top-start"
        >
          <span>
            <ActionButton
              variant="outlined"
              category={MENU_BUTTON_CATEGORY.action}
              onClick={() => onDeleteProduct(indexRow)}
              disabled={!canDeleteProduct || disabled}
              style={{
                marginLeft: '4px',
                marginRight: '8px',
              }}
            >
              <DeleteIcon />
            </ActionButton>
          </span>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={`${classes.container} ${index % 2 ? classes.odd : classes.even}`}>
      <div className={classes.items}>
        {displayDeleteButton(index)}
        <div className={`${classes.item} ${classes.quantity}`}>
          <FormTextField
            form={form}
            required
            name={`${INVOICE_ORDER_FIELDS.quantity}_${index}`}
            onChange={(value) => onUpdateProduct(
              value, INVOICE_ORDER_FIELDS.quantity, index,
            )}
            value={order.quantity ?? 1}
            margin="dense"
            size="small"
            disabled={invoiceState === INVOICE_STATE.final || disabled}
          />
        </div>
        <div className={classes.divider} />
        <div className={`${classes.item} ${classes.product}`}>
          <ProductsSelector
            size="small"
            form={form}
            name={`${INVOICE_ORDER_FIELDS.product_id}_${index}`}
            onChange={(id) => handleProductChange(id)}
            value={order.product_id}
            disabled={invoiceState === INVOICE_STATE.final || disabled}
          />
        </div>
        <div className={classes.divider} />
        <div className={`${classes.item} ${classes.price}`}>
          <FormTextField
            form={form}
            label={t(lang, 'forms.invoices.price')}
            name={`${INVOICE_ORDER_FIELDS.paid}_${index}`}
            onChange={(value) => onUpdateProduct(value, INVOICE_ORDER_FIELDS.paid, index)}
            value={order.paid}
            margin="dense"
            size="small"
            disabled={invoiceState === INVOICE_STATE.final || disabled}
          />
        </div>
        <div className={classes.divider} />
        <div className={`${classes.item} ${classes.taxes}`}>
          <TaxSelector
            form={form}
            name={`${INVOICE_ORDER_FIELDS.taxes}_${index}`}
            onChange={(value: any) => onUpdateProduct(
              formatTaxes(value), INVOICE_ORDER_FIELDS.taxes, index,
            )}
            values={getSelectedTax()}
            disabled={invoiceState === INVOICE_STATE.final || disabled}
          />
        </div>
        <div className={classes.divider} />
        <div className={`${classes.item} ${classes.discount}`}>
          <FormTextField
            form={form}
            label={t(lang, 'forms.invoices.discount')}
            name={`${INVOICE_ORDER_FIELDS.discount}_${index}`}
            onChange={(value) => onUpdateProduct(value, INVOICE_ORDER_FIELDS.discount, index)}
            value={order.discount}
            margin="dense"
            size="small"
            disabled={invoiceState === INVOICE_STATE.final || disabled}
          />
        </div>
        <div className={classes.divider} />
        <div className={`${classes.item} ${classes.deductible}`}>
          <FormTextField
            form={form}
            label={t(lang, 'forms.invoices.deductible')}
            name={`${INVOICE_ORDER_FIELDS.deductible}_${index}`}
            onChange={(value) => onUpdateProduct(value, INVOICE_ORDER_FIELDS.deductible, index)}
            value={order.deductible}
            margin="dense"
            size="small"
            disabled={invoiceState === INVOICE_STATE.final || disabled}
          />
        </div>
        <div className={classes.divider} />
        <div className={`${classes.item} ${classes.amount}`}>
          {formatter.format(orderItemSubtotal)}
        </div>
      </div>
      <div className={classes.row}>
        <span className={classes.actions}>
          {displayNoteActions()}
        </span>
      </div>
      { displayNote && (
        <div className={classes.row}>
          <FormTextField
            form={form}
            label={`${INVOICE_ORDER_FIELDS.note}`}
            multiline
            minRows="4"
            name={`${INVOICE_ORDER_FIELDS.note}_${index}`}
            onChange={(value) => onUpdateProduct(value, INVOICE_ORDER_FIELDS.note, index)}
            value={order.note}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};

export default OrderProductItem;
