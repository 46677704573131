import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { setError } from 'src/redux/actions/errorsActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { t } from 'src/lib/language';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import { postProductAction } from 'src/redux/actions/productAction';
import ProductValidator from 'src/validations/ProductValidator';
import { INVOICE_FIELDS } from 'src/constants/Invoices';
import { ProductModel, ProductType } from 'src/models/ProductModel';
import MetadataSelector, { MetadataType } from 'src/components/Control/MetadataSelector';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import { Tooltip } from '@material-ui/core';
import TaxSelector from 'src/components/Control/TaxSelector';
import OrganisationBankAccountSelector
  from 'src/components/Control/OrganisationBankAccountSelector';
import { setFormStatus } from 'src/redux/actions/formActions';
import GatewaySelector from 'src/components/Control/GatewaySelector';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import BackspaceIcon from '@material-ui/icons/Backspace';
import SaveIcon from '@material-ui/icons/Save';
import ProductTypeSelector from 'src/components/Control/ProductTypeSelector';
import { openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import ButtonLink from 'src/components/Elements/ButtonLink';

interface Props {
  onClose?: () => void;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: '640px',
  },
  submit: {
    marginTop: '12px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  subtitle: {
    marginTop: '8px',
    borderBottom: 'solid #888 1px',
  },
  selectElement: {
    width: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    gap: '10px',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.50)',
  },
  selectLabelError: {
    color: 'rgba(255, 0, 0, 0.50)',
  },
  spacer: {
    height: theme.spacing(3),
  },
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '82px',
    marginBottom: '15px',
  },
  hide: {
    display: 'none',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '10px',
  },
  formControl: {
    width: '100%',
    minWidth: '120px',
    marginBottom: theme.spacing(1),
  },
}));

const AddProductForm = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const formStatus = useSelector((state: Store) => state.formStatus[FORM.add_product] ?? null);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const { onClose } = props;

  const [state, setState] = useState({
    allow_installment: 1,
    number_allowed_installments: 0,
    last_name_required: false,
    first_name_required: false,
    email_required: false,
    phone_required: false,
  } as ProductModel);
  const form = FORM.manage_product;

  useEffect(() => {
    dispatch(setError({ [form]: {} }));
  }, []);

  useEffect(() => {
    if (formStatus === FORM_STATUS.success) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onClose && onClose();
      dispatch(setFormStatus({ [FORM.add_product]: null }));
    }
  }, [formStatus]);

  const onChange = (value: any, field: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setError({ [form]: {} }));

    try {
      ProductValidator(lang).validateSync(state, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.group.validation_errors',
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [form]: errorBag }));
      return false;
    }

    dispatch(postProductAction({
      ...state,
      allow_installment: state.allow_installment ? 1 : 0,
      deductible: (state.deductible ?? 0) * 0.01,
    } as ProductModel));
    return true;
  };

  const onIdentifiersChange = (value: string) => {
    setState({
      ...state,
      identifiers: value,
    });
  };

  const manageIdentifiers = () => {
    dispatch(
      openModal({ modal: MODALS.ticketIdentifiers, payload: [onIdentifiersChange, null] }),
    );
  };

  return (
    <form className={classes.form} noValidate>
      <div className={classes.header}>
        <div className={classes.row}>
          <div className={classes.title}><b>{ t(lang, 'menus.add_product') }</b></div>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}
          >
            { t(lang, 'misc.close') }
          </Button>
        </div>
        <div className={classes.row}>
          <Tooltip
            title={t(lang, 'menus.save')}
            placement="top-start"
          >
            <span>
              <ActionButton
                category={MENU_BUTTON_CATEGORY.action}
                onClick={handleSubmit}
              >
                <SaveIcon />
              </ActionButton>
            </span>
          </Tooltip>
          <Tooltip
            title={t(lang, 'misc.back_to_list')}
            placement="top-start"
          >
            <span>
              <ActionButton
                category={MENU_BUTTON_CATEGORY.action}
                onClick={handleClose}
              >
                <BackspaceIcon />
              </ActionButton>
            </span>
          </Tooltip>
        </div>
      </div>
      <FormCheckbox
        checked={state.is_managed}
        label={t(lang, 'forms.products.is_managed')}
        name="is_managed"
        onChange={onChange}
      />
      <FormCheckbox
        checked={state.is_modifiable}
        label={t(lang, 'forms.products.is_modifiable')}
        name="is_modifiable"
        onChange={onChange}
      />
      <FormCheckbox
        checked={state.is_public}
        label={t(lang, 'forms.products.is_public')}
        name="is_public"
        onChange={onChange}
      />
      <div className={classes.subtitle}><b>General Information</b></div>
      <FormTextField
        form={form}
        label={t(lang, 'forms.groups.name_en')}
        name="name_en"
        onChange={onChange}
        required
        value={state.name_en}
      />
      <FormTextField
        form={form}
        label={t(lang, 'forms.groups.name_fr')}
        name="name_fr"
        onChange={onChange}
        required
        value={state.name_fr}
      />
      <FormTextField
        form={form}
        label={t(lang, 'forms.groups.description_en')}
        name="description_en"
        onChange={onChange}
        required
        value={state.description_en}
      />
      <FormTextField
        form={form}
        label={t(lang, 'forms.groups.description_fr')}
        name="description_fr"
        onChange={onChange}
        required
        value={state.description_fr}
      />
      <ProductTypeSelector
        form={form}
        name="type"
        onChange={onChange}
        value={state.type}
      />
      { state.type === ProductType.ticket && (
        <>
          <FormCheckbox
            checked={state.last_name_required}
            label={t(lang, 'forms.products.last_name_required')}
            name="last_name_required"
            onChange={onChange}
          /><br />
          <FormCheckbox
            checked={state.first_name_required}
            label={t(lang, 'forms.products.first_name_required')}
            name="first_name_required"
            onChange={onChange}
          /><br />
          <FormCheckbox
            checked={state.email_required}
            label={t(lang, 'forms.products.email_required')}
            name="email_required"
            onChange={onChange}
          /><br />
          <FormCheckbox
            checked={state.phone_required}
            label={t(lang, 'forms.products.phone_required')}
            name="phone_required"
            onChange={onChange}
          />
        </>
      )}
      <div className={classes.subtitle}><b>Pricing and Payments</b></div>
      <FormTextField
        form={form}
        label={t(lang, 'forms.products.price')}
        name="price"
        onChange={onChange}
        required
        value={state.price}
      />
      <FormTextField
        form={form}
        label={`${t(lang, 'forms.products.deductible')} (%)`}
        name="deductible"
        onChange={onChange}
        value={state.deductible}
        required
      />
      <FormTextField
        form={form}
        label={t(lang, 'forms.products.discount')}
        name="discount"
        onChange={onChange}
        value={state.discount}
      />
      <div style={{ marginTop: '8px' }}>
        <FormCheckbox
          checked={!!state.allow_installment}
          label={t(lang, 'forms.products.allow_installment')}
          name="allow_installment"
          onChange={onChange}
        />
      </div>
      { (!!state.allow_installment) && (
        <FormTextField
          form={form}
          label={t(lang, 'forms.groups.number_of_payments')}
          name="number_allowed_installments"
          onChange={onChange}
          value={state.number_allowed_installments}
          required
        />
      )}
      <TaxSelector
        form={form}
        name="taxes"
        onChange={(taxId: Number) => onChange(taxId, 'taxes')}
        size="medium"
        marginTop="8px"
      />
      <OrganisationBankAccountSelector
        value={state.bank_account_id || undefined}
        onChange={(id:number) => onChange(id, 'bank_account_id')}
        form={form}
        name="bank_account_id"
      />
      <GatewaySelector
        form={form}
        name="gateway_id"
        onChange={(value) => onChange(value, 'payment_gateway_id')}
        value={state.payment_gateway_id}
        noMarginTop
      />
      <div className={classes.subtitle}><b>Meta Information</b></div>
      <MetadataSelector
        type={MetadataType.program}
        onChange={(id) => onChange(id, 'program_id')}
        form={form}
        name="program_id"
        value={state.program_id || undefined}
        noMarginTop
      />
      <MetadataSelector
        type={MetadataType.department}
        onChange={(id) => onChange(id, 'department_id')}
        form={form}
        name="department_id"
        value={state.department_id || undefined}
        noMarginTop
      />
      <MetadataSelector
        type={MetadataType.account}
        onChange={(id) => onChange(id, 'account_id')}
        form={form}
        name="account_id"
        value={state.account_id || undefined}
        noMarginTop
      />
      <div className={classes.subtitle}><b>Store Information</b></div>
      { (state.type === ProductType.ticket) && (
        <div style={{ marginTop: '8px' }}>
          <FormCheckbox
            checked={state.identifier_required}
            label={t(lang, 'forms.products.identifier_required')}
            name="identifier_required"
            onChange={onChange}
          />
          <ButtonLink onClick={manageIdentifiers}>Manage Identifiers</ButtonLink>
        </div>
      )}
      <FormTextField
        form={form}
        label={t(lang, 'forms.products.quantity')}
        name="quantity"
        onChange={onChange}
        value={state.quantity}
      />
      <FormTextField
        form={form}
        label={t(lang, 'forms.products.cost')}
        name="cost"
        onChange={onChange}
        value={state.cost}
      />
      <FormTextField
        form={form}
        label={t(lang, 'forms.products.back_order')}
        name="back_order"
        onChange={onChange}
        value={state.back_order}
      />
      <FormTextField
        form={form}
        label="SKU"
        name="sku"
        onChange={onChange}
        value={state.sku}
      />
      <FormTextField
        form={form}
        label={t(lang, 'forms.products.picture')}
        name="picture"
        onChange={onChange}
        value={state.picture}
      />
      <FormTextField
        form={form}
        label={t(lang, 'forms.invoices.note')}
        multiline
        minRows="4"
        name={INVOICE_FIELDS.note}
        onChange={onChange}
        value={state.note || ''}
      />
      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
          key="submit-button"
        >
          {t(lang, 'misc.save')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleClose}
          key={`submit-button-group-${state.id}`}
        >
          {t(lang, 'misc.back_to_list')}
        </Button>
      </div>
    </form>
  );
};

export default AddProductForm;
