import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { t } from 'src/lib/language';
import {
  InvoiceModel, OrderList, OrderModel, ProductOrderModel,
} from 'src/models/InvoiceModel';
import { getInvoiceAction, putInvoiceAction } from 'src/redux/actions/invoiceActions';
import { INVOICE_STATE } from 'src/constants/Invoices';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { MODALS_SIZE } from 'src/models/modal';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: MODALS_SIZE.large,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
  },
  column: {
    maxWidth: '640px',
  },
  col2: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  control: {
    minHeight: '56px',
  },
  amount: {
    width: '105px',
    display: 'inline-block',
    textAlign: 'right',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: 120,
    margin: theme.spacing(3, 2, 0),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectElement: {
    width: '100%',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.50)',
  },
  selectLabelError: {
    color: 'rgba(255, 0, 0, 0.50)',
  },
  spacer: {
    height: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
    marginTop: '10px',
  },
  clickable: {
    cursor: 'pointer',
  },
  pagination: {
    margin: '8px 0',
    height: '24px',
    '& > ul': {
      justifyContent: 'center',
    },
  },
  close: {
    padding: 5,
    minHeight: 0,
    minWidth: 0,
    float: 'right',
    '&> span': {
      lineHeight: 0.75,
    },
  },
  totals: {
    float: 'right',
  },
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '50px',
  },
  headerRow: {
    height: '32px',
  },
  scroller: {
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: theme.spacing(2),
    height: '66vh',
    padding: '8px',
    marginTop: '8px',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  warning: {
    color: '#d41d1d',
    marginRight: 0,
    marginLeft: 'auto',
    fontSize: '16px',
  },
}));

const DeleteInvoiceForm = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.invoices] || [],
  );
  const invoice = useSelector(
    (state: Store) => {
      if (!!state.invoices[selections[0] as number]) {
        return state.invoices[selections[0] as number];
      }
      return {} as InvoiceModel;
    },
  );

  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  useEffect(() => {
    if (selections[0]) {
      dispatch(getInvoiceAction(selections[0] as number));
    }
  }, []);

  const handleCancel = () => {
    dispatch(closeModal({ modal: MODALS.deleteInvoice }));
  };

  const getTaxesForOrder = (order: ProductOrderModel):string => JSON.stringify(order.taxes);

  const denormalizeOrders = ():OrderList => {
    if (!invoice.order) return [] as OrderList;
    return {
      ...invoice.order.map((order: ProductOrderModel) => ({
        ...order,
        taxes: getTaxesForOrder(order),
      } as OrderModel)),
    };
  };

  const handleOk = () => {
    const payload = {
      invoice: {
        ...invoice,
        state: INVOICE_STATE.canceled,
      },
      order: denormalizeOrders(),
    };
    dispatch(putInvoiceAction(selections[0] as number, payload));
    dispatch(closeModal({ modal: MODALS.deleteInvoice }));
  };

  return (
    <>
      <div className={classes.header}>
        <div className={`${classes.row} ${classes.headerRow}`}>
          <div className={classes.title}>
            {t(lang, 'forms.invoices.delete_invoice')}&nbsp;
          </div>
        </div>
      </div>
      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleOk}
          key="submit-button"
        >
          {t(lang, 'misc.delete')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleCancel}
          key="submit-button-group-"
        >
          {t(lang, 'misc.cancel')}
        </Button>
      </div>
    </>
  );
};

export default DeleteInvoiceForm;
