import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import AdminDonationForm from 'src/components/Forms/AdminDonationForm';

const AdminDonationModal = () => (
  <ModalBase
    name={MODALS.adminDonation}
    size={MODALS_SIZE.small}
  >
    <AdminDonationForm />
  </ModalBase>
);

export default AdminDonationModal;
