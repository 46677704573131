import * as Yup from 'yup';
import { t } from 'src/lib/language';

type BaseSchema = {
  contact_id: Yup.NumberSchema<number | undefined>;
  first_name: Yup.StringSchema<string | undefined>;
  last_name: Yup.StringSchema<string | undefined>;
  card_number: Yup.StringSchema<string | undefined>;
  expiration_year: Yup.NumberSchema<number | undefined>;
  expiration_month: Yup.NumberSchema<number | undefined>;
  cvv: Yup.NumberSchema<number | undefined>;
};

const CreditCardValidator = (lang: string, isAdmin = false) => {
  const baseSchema = {
    first_name: Yup.string().max(128).required(),
    last_name: Yup.string().max(128).required(),
    card_number: Yup.string()
      .matches(/^[0-9]+$/, t(lang, 'validations.creditcard.only_digit'))
      .min(16, t(lang, 'validations.creditcard.exact_digit_12'))
      .max(16, t(lang, 'validations.creditcard.exact_digit_12'))
      .required(),
    expiration_year: Yup.number()
      .max(99)
      .min(Number(new Date().getFullYear().toString().substr(-2)))
      .required(),
    expiration_month: Yup.number()
      .max(12)
      .required(),
    cvv: Yup.number()
      .max(9999)
      .required(),
  } as BaseSchema;

  if (isAdmin) {
    baseSchema.contact_id = Yup.number()
      .min(1, t(lang, 'validations.creditcard.field_required'))
      .required();
  }

  return Yup.object().shape(baseSchema);
};

export default CreditCardValidator;
