import { SELECTED_CONTACT } from 'src/components/Forms/MergeContactForm';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  label: string;
  value: string;
  property: string;
  onChoose: (field: SELECTED_CONTACT, property: string) => void;
  order: SELECTED_CONTACT;
  selected: boolean;
  disabled: boolean;
}

const useStyles = makeStyles((theme) => ({
  selected: {
    border: '1px solid #4e7ff8',
    background: 'rgba(78, 127, 248, 0.25)',
    color: '#000',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  normal: {
    border: '1px solid #666',
    background: '#fff',
    color: '#999',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  disabled: {
    border: '1px solid #666',
    background: '#fff',
    color: '#999',
    opacity: 0.25,
    '&:hover': {
      cursor: 'default',
    },
  },
  cell: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    height: '48px',
    lineHeight: '48px',
    padding: theme.spacing(0, 2),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

  },
}));

const MergeContactFieldChooser = (props: Props) => {
  const classes = useStyles();
  const {
    disabled, label, value, order, property, onChoose, selected,
  } = props;
  const selectedClass = selected ? classes.selected : classes.normal;
  const currentClasse = disabled ? classes.disabled : selectedClass;

  const handleOnClick = () => {
    if (!disabled) {
      onChoose(order, property);
    }
  };
  return (
    // eslint-disable-next-line max-len
    <div
      onClick={() => handleOnClick()}
      className={`${classes.cell} ${currentClasse}`}
    >
      <b>{label}</b> : {value}
    </div>
  );
};

export default MergeContactFieldChooser;
