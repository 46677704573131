import { makeStyles } from '@material-ui/core/styles';
import { FundraisingEventInfo } from 'src/models/FundraisingModel';
import { TABS } from 'src/components/Pages/AddFundraisingPage';
import { MouseEvent, useState } from 'react';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { FORM } from 'src/constants/Form';
import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import FormUploadImage from 'src/components/Control/FormControls/FormUploadImage';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { ErrorBag } from 'src/models/ErrorModel';
import { setError } from 'src/redux/actions/errorsActions';
import FundraisingEventValidator from 'src/validations/FundraisingEventInfoValidator';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { subscribeEvent } from 'src/models/EventHelper';
import { EventType } from 'src/models/EventType';
import { t } from 'src/lib/language';

const useStyles = makeStyles(() => ({
  subtitle: {
    marginTop: '8px',
    borderBottom: 'solid #888 1px',
    width: '480px',
  },
  list: {
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '0px',
  },
  item: {
    border: '1px solid #c8c8c8',
    borderRadius: '8px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px',
    width: '480px',
    backgroundColor: 'white',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
  },
}));

interface Props {
  onChange: (eventInfo: FundraisingEventInfo) => void;
  onNextStep: (step:number) => void;
  setErrors: (tab:TABS, hasError: boolean) => void;
  slug: string,
  initialState: FundraisingEventInfo;
  setHasChanged: (hasChanged: boolean) => void;
}

const FundraisingEventInfoForm = (props: Props) => {
  const classes = useStyles();
  const {
    onChange, onNextStep, setErrors, slug, initialState, setHasChanged,
  } = props;

  const dispatch = useAppDispatch();

  const organisation = useSelector((state: Store) => state.currentOrganisation.id);
  const lang = useSelector((state: Store) => state.language.language);

  const [eventInfoState, setEventInfoState] = useState(initialState);
  const [order, setOrder] = useState(initialState.order);
  const [upload, setUpload] = useState(0);
  const forceReload = () => setUpload(upload + 1);

  subscribeEvent(EventType.uploadComplete, () => forceReload());

  const onValueChange = (value: any, field: string) => {
    setHasChanged(true);
    setEventInfoState({
      ...eventInfoState,
      [field]: value,
    });
  };

  const onDragEnd = (dropResult: any) => {
    const { destination, source } = dropResult;
    // dropped outside the list
    if (!destination) return;

    const newOrder = Array.from(order);
    const [removed] = newOrder.splice(source.index, 1);
    newOrder.splice(destination.index, 0, removed);
    setOrder(newOrder);
    onValueChange(order, 'order');
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setErrors(TABS.event_info, false);
    const errorBag = {} as ErrorBag;
    dispatch(setError({ [FORM.fundraising_info]: {} }));
    try {
      FundraisingEventValidator().validateSync(eventInfoState, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.group.validation_errors',
      }));
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      // eslint-disable-next-line prefer-destructuring
      dispatch(setError({ [FORM.fundraising_notification]: errorBag }));
      setErrors(TABS.info, true);
    }
    onNextStep(5);
    onChange(eventInfoState);
  };

  return (
    <>
      <div className={classes.subtitle}>
        {t(lang, 'forms.fundraising.order')}
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {
            (provided: any) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={classes.list}
              >
                {order.map((item, index) => (
                  <Draggable
                    key={`item-${index}`}
                    draggableId={`${index}`}
                    index={index}
                  >
                    {(providedDraggable: any) => (
                      <li
                        className={classes.item}
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                      >
                        {item}
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )
          }
        </Droppable>
      </DragDropContext>
      <div className={classes.subtitle}>
        {t(lang, 'forms.fundraising.event_information')}
      </div>
      <div style={{
        width: '480px',
        marginBottom: '8px',
      }}
      >
        <FormDatePicker
          form={FORM.edit_payment}
          label={t(lang, 'forms.fundraising.event_information')}
          name="event_date"
          onChange={
            (date: MaterialUiPickersDate) => onValueChange(date, 'event_date')
          }
          value={eventInfoState.event_date}
        />
        <FormTextField
          form={FORM.fundraising_info}
          label={t(lang, 'forms.fundraising.title')}
          name="title"
          onChange={onValueChange}
          value={eventInfoState.title}
        />
        <FormTextField
          form={FORM.fundraising_info}
          label={t(lang, 'forms.fundraising.venue_name')}
          name="location_name"
          onChange={onValueChange}
          value={eventInfoState.location_name}
        />
        <FormTextField
          form={FORM.fundraising_info}
          label={t(lang, 'forms.fundraising.venue_address')}
          name="location_address"
          onChange={onValueChange}
          value={eventInfoState.location_address}
        />
        <FormTextField
          form={FORM.fundraising_info}
          label={t(lang, 'forms.fundraising.contact_email')}
          name="contact_email"
          onChange={onValueChange}
          value={eventInfoState.contact_email}
        />
        <FormTextField
          form={FORM.fundraising_info}
          name="footer_message"
          label={t(lang, 'forms.fundraising.footer_message')}
          onChange={onValueChange}
          value={eventInfoState.footer_message}
        />
        <div className={classes.subtitle} style={{ marginBottom: '8px', width: '576px' }}>
          Logo
        </div>
        <FormUploadImage
          name="event_info_logo.png"
          height={110}
          width={576}
          path={`${organisation}/${slug}/images/media_image_preview.png`}
        />
      </div>
      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          key="submit-button"
        >
          {t(lang, 'forms.fundraising.next_step')}
        </Button>
      </div>
    </>
  );
};

export default FundraisingEventInfoForm;
