import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { EmptyFilter } from 'src/models/AdvanceSearchModel';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { t } from 'src/lib/language';
import { FILTER_POSITION } from 'src/constants/FilterPosition';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { getOperatorAndFilters } from 'src/lib/QueryBuilderHelper';
import { setAdvancedSearch } from 'src/redux/actions/advancedSearchAction';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FORM } from 'src/constants/Form';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import MomentUtils from '@date-io/moment';
import { Moment } from 'moment';

interface Props {
  entity: ENTITIES;
  index: number;
}

const OperandBetweenDate = (props: Props) => {
  const {
    index, entity,
  } = props;

  const dispatch = useAppDispatch();

  const filters = useSelector(
    (state: Store) => state.advancedSearch[entity] || EmptyFilter,
  );
  const [operator, filterList] = getOperatorAndFilters(filters);

  const getStartDate = () => {
    if (!filterList[index][FILTER_POSITION.operands][0]) {
      return null;
    }
    return moment(filterList[index][FILTER_POSITION.operands][0]).startOf('day');
  };

  const getEndDate = () => {
    if (!filterList[index][FILTER_POSITION.operands][1]) {
      return null;
    }
    return moment(filterList[index][FILTER_POSITION.operands][1]).endOf('day');
  };

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const error: string =
    useSelector(
      (state: Store) => {
        if (state.errors[FORM.query_builder]) {
          if (state.errors[FORM.query_builder][index]) {
            return state.errors[FORM.query_builder][index][FILTER_POSITION.operands];
          }
          return '';
        }
        return '';
      },
    );

  const handleDateChange = (position: number, value: Moment) => {
    if (!value) { return; }
    const dateValue = value;
    let newStartDate: Moment;
    let newEndDate: Moment;

    if (position === 0) {
      newStartDate = dateValue;
      newEndDate = getEndDate();
    } else {
      newStartDate = getStartDate();
      newEndDate = dateValue;
    }

    if (newStartDate && newEndDate) {
      // @ts-ignore
      if (newStartDate > newEndDate) {
        newEndDate = newStartDate;
      }
    }
    if (newStartDate && !newEndDate) {
      // eslint-disable-next-line prefer-destructuring
      newEndDate = newStartDate;
    }
    if (!newStartDate && newEndDate) {
      // eslint-disable-next-line prefer-destructuring
      newStartDate = newEndDate;
    }

    const startDateValue = moment(newStartDate).startOf('day');
    const endDateValue = moment(newEndDate).endOf('day');

    filterList[index][FILTER_POSITION.operands] = [startDateValue, endDateValue];
    dispatch(setAdvancedSearch({
      entity,
      filters: {
        [operator]: [
          ...filterList,
        ],
      },
    }));
  };

  const handleOnClose = () => {};

  const errors = error.split(',');
  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          style={{ maxWidth: '160px', marginLeft: '8px' }}
          format="MM/DD/yyyy"
          margin="dense"
          id="date-picker-inline-start"
          value={getStartDate()}
          onChange={(value) => handleDateChange(0, value as Moment)}
          onClose={handleOnClose}
          error={!!errors[0]}
          helperText={errors[0]}
        />
        <span style={{ textAlign: 'center', width: '24px', marginTop: '6px' }}>{ t(lang, 'search.and')}</span>
        <DatePicker
          style={{ maxWidth: '160px', marginLeft: '8px' }}
          format="MM/DD/yyyy"
          margin="dense"
          name="date-picker-inline-end"
          value={getEndDate()}
          onClose={handleOnClose}
          onChange={(value) => handleDateChange(1, value as Moment)}
          error={!!errors[1]}
          helperText={errors[1]}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default OperandBetweenDate;
