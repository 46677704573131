import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Subtitle from 'src/components/Elements/Subtitle';
import { makeStyles } from '@material-ui/core/styles';
import { useCallback, useState } from 'react';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { FORM } from 'src/constants/Form';
import { CreateOrganisationPayload, OrganisationPayload } from 'src/models/OrganisationModel';
import OrganisationTypeSelector from 'src/components/Control/OrganisationTypeSelector';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import { AddressModel } from 'src/models/AddressModel';
import AddressSearch from 'src/components/Control/AddressSearch';
import CountriesSelector from 'src/components/Control/CountriesSelector';
import { BankAccountPayload } from 'src/models/BankAccountModel';
import { BANK_ACCOUNT_COUNTRY, BANK_ACCOUNT_TYPE } from 'src/constants/BankAccount';
import {
  debounce,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import CurrenciesSelector from 'src/components/Control/CurrenciesSelector';
import { setError } from 'src/redux/actions/errorsActions';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import CreateOrganisationValidator from 'src/validations/CreateOrganisationValidator';
import CreateAdminValidator from 'src/validations/CreateAdminValidator';
import Button from '@material-ui/core/Button';
import { UserPayload } from 'src/models/UserModel';
import TimezoneSelector from 'src/components/Control/TimezoneSelector';
import { createOrganisationAction } from 'src/redux/actions/createOrganisationAction';
import { BankAccountPayloadValidator } from 'src/validations/BankAccountValidator';
import {
  GatewayPayload,
  GatewaySettingBambora,
  GatewaySettingElavon,
  GatewayType,
  ProcessingType,
} from 'src/models/GatewayModel';
import {
  CreateBamboraSettingValidator,
  CreateElavonSettingValidator,
} from 'src/validations/GatewayValidator';
import { postOrganisationExistsAction } from 'src/redux/actions/organisationActions';
import { postUserExistsAction } from 'src/redux/actions/userActions';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: '640px',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
    marginTop: '10px',
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectElement: {
    width: '100%',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.50)',
  },
  selectLabelError: {
    color: 'rgba(255, 0, 0, 0.50)',
  },
  spacer: {
    height: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
}));

const OnboardingForm = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const [organisationState, setOrganisationState] = useState({} as OrganisationPayload);
  const [bankAccountState, setBankAccountState] = useState({
    bank_account_type: BANK_ACCOUNT_TYPE.checking,
    currency_id: 19,
  } as BankAccountPayload);
  const [userState, setUserState] = useState({} as UserPayload);
  const [gatewayState, setGatewayState] = useState({
    gateway_type: GatewayType.bambora,
    processing_type: ProcessingType.eft,
    bank_country: BANK_ACCOUNT_COUNTRY.ca,
  } as GatewayPayload);
  const [elavonSettingState, setElavonSettingState] = useState({} as GatewaySettingElavon);
  const [bamboraSettingState, setBamboraSettingState] = useState({} as GatewaySettingBambora);

  const organisationExists = async (organisationId: string) => {
    const result = await dispatch(postOrganisationExistsAction(organisationId));
    if (result) {
      dispatch(setError({
        [FORM.createOrganisation]: {
          id: ['Organisation ID exists'],
        },
      }));
      return;
    }
    dispatch(setError({
      [FORM.createOrganisation]: {},
    }));
  };

  const searchOrganisation = useCallback(
    debounce((organisationId: string) => {
      organisationExists(organisationId);
    }, 300),
    [], // will be created only once initially
  );

  const userExists = async (email: string) => {
    const result = await dispatch(postUserExistsAction(email));
    if (result) {
      dispatch(setError({
        [FORM.add_admin]: {
          email: ['User exists'],
        },
      }));
      return;
    }
    dispatch(setError({
      [FORM.add_admin]: {},
    }));
  };

  const searchUser = useCallback(
    debounce((email: string) => {
      userExists(email);
    }, 300),
    [], // will be created only once initially
  );

  const onOrganisationChange = (value: any, field:any) => {
    if (field === 'legal_name') {
      const id = value.replace(/ /g, '-').replace(/[^a-z0-9-]/g, '');
      searchOrganisation(id);
      setOrganisationState({
        ...organisationState,
        id,
        [field]: value,
      });
      return;
    }
    if (field === 'id') {
      const id = value.replace(' ', '-').replace(/[^a-z0-9-]/g, '');
      searchOrganisation(id);
      setOrganisationState({
        ...organisationState,
        id,
      });
      return;
    }
    setOrganisationState({
      ...organisationState,
      [field]: value,
    });
  };

  const OnUserChange = (value: any, field:any) => {
    if (field === 'email') {
      searchUser(value);
    }
    setUserState({
      ...userState,
      [field]: value,
    });
  };

  const onBankAccountChange = (value: Value, field: string) => {
    if (!!value) {
      switch (field) {
        case 'account':
          if (Number.isNaN(Number(value))) return;
          if (String(value).length > 12) return;
          break;
        case 'bank':
          if (Number.isNaN(Number(value))) return;
          if (String(value).length > 3) return;
          break;
        case 'branch':
          if (Number.isNaN(Number(value))) return;
          if (String(value).length > 5) return;
          break;
        case 'bank_country':
          if (value === BANK_ACCOUNT_COUNTRY.ca) {
            setBankAccountState({
              ...bankAccountState,
              currency_id: 19,
              [field]: value,
            });
            return;
          }
          if (value === BANK_ACCOUNT_COUNTRY.us) {
            setBankAccountState({
              ...bankAccountState,
              currency_id: 96,
              [field]: value,
            });
            return;
          }
          break;
        default:
      }
    }
    setBankAccountState({
      ...bankAccountState,
      [field]: value,
    });
  };

  const onGatewaychange = (value: Value, field: string) => {
    setGatewayState({
      ...gatewayState,
      [field]: value,
    });
  };

  const onElavonSettingsChange = (value: Value, field: string) => {
    setElavonSettingState({
      ...elavonSettingState,
      [field]: value,
    });
  };

  const onBamboraSettingsChange = (value: Value, field: string) => {
    setBamboraSettingState({
      ...bamboraSettingState,
      [field]: value,
    });
  };

  const setAddressFromGoogle = (address: AddressModel) => {
    setOrganisationState({
      ...organisationState,
      address: address.address || '',
      city: address.city || '',
      state: address.state || '',
      country_id: address.country_id || 0,
      zip_code: (!!address.zip_code) ? address.zip_code.replaceAll(' ', '') : '',
    });
  };

  const handleSubmit = () => {
    dispatch(setError({ [FORM.createOrganisation]: {} }));
    dispatch(setError({ [FORM.add_organisation_bankaccount]: {} }));
    dispatch(setError({ [FORM.add_admin]: {} }));
    dispatch(setError({ [FORM.add_gateway]: {} }));
    let hasError = false;
    const payload = {} as CreateOrganisationPayload;
    // Validation Organisation
    try {
      CreateOrganisationValidator(lang).validateSync(
        organisationState,
        { abortEarly: false },
      );
    } catch (validationErrors: any) {
      hasError = true;
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: t(lang, 'forms.organisation.validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.createOrganisation]: errorBag }));
    } finally {
      payload.organisationData = organisationState;
    }
    // Validation Bank Account
    try {
      BankAccountPayloadValidator(lang).validateSync(
        bankAccountState,
        { abortEarly: false },
      );
    } catch (validationErrors: any) {
      hasError = true;
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: t(lang, 'forms.bankaccounts.validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.add_organisation_bankaccount]: errorBag }));
    } finally {
      payload.bankAccountData = bankAccountState;
    }
    // Validation User
    try {
      CreateAdminValidator(lang).validateSync(
        userState,
        { abortEarly: false },
      );
    } catch (validationErrors: any) {
      hasError = true;
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: t(lang, 'forms.organisation.user_validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.add_admin]: errorBag }));
    } finally {
      payload.userData = userState;
    }
    // Validation Organisation
    try {
      if (gatewayState.gateway_type === GatewayType.bambora) {
        CreateBamboraSettingValidator(lang).validateSync(
          {
            ...bamboraSettingState,
            gateway_name: gatewayState.gateway_name,
          },
          { abortEarly: false },
        );
      }
      if (gatewayState.gateway_type === GatewayType.elavon_converge) {
        CreateElavonSettingValidator(lang).validateSync(
          {
            ...elavonSettingState,
            gateway_name: gatewayState.gateway_name,
          },
          { abortEarly: false },
        );
      }
    } catch (validationErrors: any) {
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
    } finally {
      const gatewaySetting = gatewayState.gateway_type === GatewayType.bambora
        ? bamboraSettingState
        : elavonSettingState;
      payload.gatewayData = {
        ...gatewayState,
        gateway_settings: gatewaySetting,
      };
    }
    if (!hasError) {
      dispatch(createOrganisationAction(payload));
    }
  };

  return (
    <>
      <form className={classes.form} noValidate>
        <Subtitle>
          <strong><span style={{ fontSize: 14 }}>General Informations</span></strong>
        </Subtitle>
        <FormTextField
          form={FORM.createOrganisation}
          label="Organisation English name"
          name="name_en"
          onChange={onOrganisationChange}
          required
          value={organisationState.name_en}
        />
        <FormTextField
          form={FORM.createOrganisation}
          label="Organisation French name"
          name="name_fr"
          onChange={onOrganisationChange}
          required
          value={organisationState.name_fr}
        />
        <FormTextField
          form={FORM.createOrganisation}
          label="legalName name"
          name="legal_name"
          onChange={onOrganisationChange}
          required
          value={organisationState.legal_name}
        />
        <FormTextField
          form={FORM.createOrganisation}
          label="Organisation Id (only letters or '-' characters"
          name="id"
          onChange={onOrganisationChange}
          required
          value={organisationState.id}
        />
        <FormTextField
          form={FORM.createOrganisation}
          label="Tax Registration Number"
          name="registration_number"
          onChange={onOrganisationChange}
          required
          value={organisationState.registration_number}
        />
        <OrganisationTypeSelector
          form={FORM.createOrganisation}
          name="type"
          onChange={(id:string) => {
            onOrganisationChange(id, 'type');
          }}
          value={organisationState.type}
        />
        <div>
          <FormCheckbox
            name="is_npo"
            label="Is it a NPO?"
            checked={!!organisationState.is_npo}
            onChange={onOrganisationChange}
          />
        </div>
        <div>
          <FormCheckbox
            name="is_jewish"
            label="Is it a jewish organisation?"
            checked={!!organisationState.is_jewish}
            onChange={onOrganisationChange}
          />
        </div>
        <Subtitle>
          <strong><span style={{ fontSize: 14 }}>Locators</span></strong>
        </Subtitle>
        <TimezoneSelector
          name="timezone_id"
          onChange={onOrganisationChange}
          value={organisationState.timezone_id}
          form={FORM.createOrganisation}
        />
        <AddressSearch
          onChange={
            onOrganisationChange
          }
          onAddressChange={
            (addresse: AddressModel) => { setAddressFromGoogle(addresse); }
          }
          value={{ description: organisationState.address || '' }}
          name="address"
          form={FORM.createOrganisation}
        />
        <FormTextField
          form={FORM.createOrganisation}
          label={t(lang, 'forms.contact.suite')}
          name="suite"
          onChange={onOrganisationChange}
          value={organisationState.suite || ''}
        />
        <FormTextField
          form={FORM.createOrganisation}
          label={t(lang, 'forms.contact.city')}
          name="city"
          onChange={onOrganisationChange}
          required
          value={organisationState.city || ''}
        />
        <FormTextField
          form={FORM.createOrganisation}
          label={t(lang, 'forms.contact.state')}
          name="state"
          onChange={onOrganisationChange}
          required
          value={organisationState.state || ''}
        />
        <CountriesSelector
          form={FORM.createOrganisation}
          name="country_id"
          onChange={(id) => onOrganisationChange('country_id', id)}
          value={organisationState.country_id || 0}
        />
        <FormTextField
          form={FORM.createOrganisation}
          label={t(lang, 'forms.contact.zip_code')}
          name="zip_code"
          onChange={onOrganisationChange}
          required
          value={organisationState.zip_code || ''}
        />
        <FormTextField
          form={FORM.createOrganisation}
          label="phone"
          name="phone"
          onChange={onOrganisationChange}
          value={organisationState.phone || ''}
        />
        <FormTextField
          form={FORM.createOrganisation}
          label="extension"
          name="extension"
          onChange={onOrganisationChange}
          value={organisationState.extension || ''}
        />
        <FormTextField
          form={FORM.createOrganisation}
          label="Toll free"
          name="tollfreePhone"
          onChange={onOrganisationChange}
          value={organisationState.tollfree_phone || ''}
        />
        <FormTextField
          form={FORM.createOrganisation}
          label="Toll free extension"
          name="tollfreeExtension"
          onChange={onOrganisationChange}
          value={organisationState.tollfree_extension || ''}
        />
        <FormTextField
          form={FORM.createOrganisation}
          label="Website"
          name="website"
          onChange={onOrganisationChange}
          value={organisationState.website}
        />
        <FormTextField
          form={FORM.createOrganisation}
          label="Email"
          name="email"
          onChange={onOrganisationChange}
          required
          value={organisationState.email}
        />
        <Subtitle>
          <strong><span style={{ fontSize: 14 }}>Bank Account</span></strong>
        </Subtitle>
        <div style={{ width: '75%' }}>
          <FormTextField
            form={FORM.add_organisation_bankaccount}
            label={t(lang, 'forms.bankaccounts.bank_name')}
            name="bank_name"
            onChange={onBankAccountChange}
            required
            value={bankAccountState.bank_name || ''}
          />
        </div>
        <div style={{ width: '75%' }}>
          <FormTextField
            form={FORM.add_organisation_bankaccount}
            label={t(lang, 'forms.bankaccounts.name')}
            name="bank_holder_name"
            onChange={onBankAccountChange}
            required
            value={bankAccountState.bank_holder_name || ''}
          />
        </div>
        <div style={{ width: '75%' }}>
          <FormTextField
            form={FORM.add_organisation_bankaccount}
            label={t(lang, 'forms.bankaccounts.account')}
            name="account"
            onChange={onBankAccountChange}
            required
            value={bankAccountState.account || ''}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{
            width: '30%',
            marginRight: '16px',
          }}
          >
            <FormTextField
              form={FORM.add_organisation_bankaccount}
              label={t(lang, 'forms.bankaccounts.bank')}
              name="bank"
              onChange={onBankAccountChange}
              required
              value={bankAccountState.bank || ''}
            />
          </div>
          <div style={{ display: 'inline-block', width: '30%' }}>
            <FormTextField
              form={FORM.add_organisation_bankaccount}
              label={t(lang, 'forms.bankaccounts.branch')}
              name="branch"
              onChange={onBankAccountChange}
              required
              value={bankAccountState.branch || ''}
            />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '30%', marginRight: '16px' }}>
            <FormControl component="fieldset" style={{ marginTop: '8px' }}>
              <FormLabel component="legend">{t(lang, 'forms.organisation.account_type')}</FormLabel>
              <RadioGroup
                row
                aria-label="bank_country"
                name="bank_country"
                value={bankAccountState.bank_country || BANK_ACCOUNT_COUNTRY.ca}
                onChange={(e) => onBankAccountChange(e.target.value, 'bank_country')}
              >
                <FormControlLabel value={BANK_ACCOUNT_COUNTRY.ca} control={<Radio />} label="Canadian" />
                <FormControlLabel value={BANK_ACCOUNT_COUNTRY.us} control={<Radio />} label="US" />
              </RadioGroup>
            </FormControl>
          </div>
          <div style={{ width: '30%', marginRight: '16px' }}>
            <CurrenciesSelector
              form={FORM.add_organisation_bankaccount}
              onChange={(id: number) => onBankAccountChange(id, 'currency_id')}
              value={bankAccountState.currency_id || 0}
              name="currency_id"
            />
          </div>
        </div>
        { bankAccountState.bank_country === BANK_ACCOUNT_COUNTRY.us && (
          <div>
            <FormControl component="fieldset" style={{ marginTop: '8px' }}>
              <FormLabel component="legend">{t(lang, 'forms.bankaccounts.type')}</FormLabel>
              <RadioGroup
                row
                aria-label="bank_account_type"
                name="bank_account_type"
                value={bankAccountState.bank_account_type || BANK_ACCOUNT_TYPE.saving}
                onChange={(e) => onBankAccountChange(e.target.value, 'bank_account_type')}
              >
                <FormControlLabel
                  value={BANK_ACCOUNT_TYPE.saving}
                  control={<Radio />}
                  label="Personnal Saving"
                />
                <FormControlLabel
                  value={BANK_ACCOUNT_TYPE.checking}
                  control={<Radio />}
                  label="Personnal Chequing"
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}
        <Subtitle>
          <strong><span style={{ fontSize: 14 }}>Gateway</span></strong>
        </Subtitle>
        <FormTextField
          form={FORM.add_gateway}
          label="Gateway name"
          name="gateway_name"
          onChange={onGatewaychange}
          required
          value={gatewayState.gateway_name}
        />
        <div>
          <FormControl component="fieldset" style={{ marginTop: '8px' }}>
            <FormLabel component="legend">Processing type</FormLabel>
            <RadioGroup
              row
              aria-label="gateway_type"
              name="gateway_type"
              value={gatewayState.processing_type || ProcessingType.eft}
              onChange={(e) => onGatewaychange(e.target.value, 'processing_type')}
            >
              <FormControlLabel
                value={ProcessingType.eft}
                control={<Radio />}
                label="EFT"
              />
              <FormControlLabel
                value={ProcessingType.cc}
                control={<Radio />}
                label="Credit card"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div>
          <FormControl component="fieldset" style={{ marginTop: '8px' }}>
            <FormLabel component="legend">Gateway type</FormLabel>
            <RadioGroup
              row
              aria-label="gateway_type"
              name="gateway_type"
              value={gatewayState.gateway_type || GatewayType.bambora}
              onChange={(e) => onGatewaychange(e.target.value, 'gateway_type')}
            >
              <FormControlLabel
                value={GatewayType.bambora}
                control={<Radio />}
                label="Bambora"
              />
              <FormControlLabel
                value={GatewayType.elavon_converge}
                control={<Radio />}
                label="Elavon/Converge"
              />
            </RadioGroup>
          </FormControl>
        </div>
        { gatewayState.gateway_type === GatewayType.elavon_converge && (
          <>
            <Subtitle>
              <strong><span style={{ fontSize: 14 }}>Elavon Settings</span></strong>
            </Subtitle>
            <FormTextField
              form={FORM.add_gateway}
              label="Account ID"
              name="account_id"
              onChange={onElavonSettingsChange}
              required
              value={elavonSettingState.account_id}
            />
            <FormTextField
              form={FORM.add_gateway}
              label="User ID"
              name="user_id"
              onChange={onElavonSettingsChange}
              required
              value={elavonSettingState.user_id}
            />
            <FormTextField
              form={FORM.add_gateway}
              label="Transaction API ID"
              name="transaction_api_key"
              onChange={onElavonSettingsChange}
              required
              value={elavonSettingState.transaction_api_key}
            />
          </>
        )}
        { gatewayState.gateway_type === GatewayType.bambora && (
          <>
            <Subtitle>
              <strong><span style={{ fontSize: 14 }}>Bambora Settings</span></strong>
            </Subtitle>
            <FormTextField
              form={FORM.add_gateway}
              label="Batch API key"
              name="batch_api_key"
              onChange={onBamboraSettingsChange}
              required
              value={bamboraSettingState.batch_api_key}
            />
            <FormTextField
              form={FORM.add_gateway}
              label="Merchant ID"
              name="merchant_id"
              onChange={onBamboraSettingsChange}
              required
              value={bamboraSettingState.merchant_id}
            />
            <FormTextField
              form={FORM.add_gateway}
              label="Reporting API key"
              name="reporting_api_key"
              onChange={onBamboraSettingsChange}
              required
              value={bamboraSettingState.reporting_api_key}
            />
          </>
        )}
        <Subtitle>
          <strong><span style={{ fontSize: 14 }}>Administrator</span></strong>
        </Subtitle>
        <FormTextField
          form={FORM.add_admin}
          label="Email"
          name="email"
          onChange={OnUserChange}
          required
          value={userState.email}
        />
        <FormTextField
          form={FORM.add_admin}
          label="Password"
          name="password"
          onChange={OnUserChange}
          required
          value={userState.password}
        />
        <div className={classes.bottomRow}>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
            key="submit-button"
          >
            {t(lang, 'misc.save')}
          </Button>
        </div>
      </form>
    </>
  );
};

export default OnboardingForm;
