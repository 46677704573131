import { useSelector } from 'react-redux';
import { IMAGES_TYPE, ImageSizeType } from 'src/constants/Images';
import Button from '@material-ui/core/Button';
import Subtitle from 'src/components/Elements/Subtitle';
import { makeStyles } from '@material-ui/core/styles';
import { Store } from 'src/redux/Store';
import { OrganisationImageModel } from 'src/models/OrganisationModel';
import { SyntheticEvent, useState } from 'react';
import ImageCropper from 'src/components/Elements/ImageCropper';
import { t } from 'src/lib/language';
import { getS3URL } from 'src/lib/S3File';

const useStyles = makeStyles(() => ({
  logo: {
    width: ImageSizeType[IMAGES_TYPE.organisation_logo].width,
    height: ImageSizeType[IMAGES_TYPE.organisation_logo].height,
    backgroundColor: '#666',
    margin: '16px 0',
  },
  signature: {
    width: ImageSizeType[IMAGES_TYPE.tax_receipt_signature].width,
    height: ImageSizeType[IMAGES_TYPE.tax_receipt_signature].height,
    backgroundColor: '#666',
    margin: '16px 0',
  },
  tax_receipt_bg: {
    width: ImageSizeType[IMAGES_TYPE.tax_receipt_header].width,
    height: ImageSizeType[IMAGES_TYPE.tax_receipt_header].height,
    backgroundColor: '#666',
    margin: '16px 0',
  },
}));

enum STATE {
  form = 'form',
  cropping = 'cropping',
}

const OrganisationImagesForm = () => {
  const classes = useStyles();

  const organisation = useSelector((state: Store) => state.currentOrganisation.id);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const images = useSelector((state: Store) => state.organisation as OrganisationImageModel);
  const [state, setState] = useState(STATE.form);
  const [imageType, setImageType] = useState('' as IMAGES_TYPE);

  const handleClick = (type: IMAGES_TYPE) => {
    setState(STATE.cropping);
    setImageType(type);
  };

  const onBack = () => {
    setState(STATE.form);
  };

  const getImage = (type: IMAGES_TYPE) => {
    if (!images[type]) return undefined;
    return {
      backgroundImage: `url("${images[type]}")`,
    };
  };

  const getURL = (type: IMAGES_TYPE) => `${getS3URL()}${organisation}/images/${type}.png?${performance.now()}`;

  switch (state) {
    case STATE.form:
      return (
        <>
          <Subtitle>Logo</Subtitle>
          <div className={classes.logo} style={getImage(IMAGES_TYPE.organisation_logo)}>
            <object data={getURL(IMAGES_TYPE.organisation_logo)} type="image/png">
              <img
                alt="logo"
                src={getURL(IMAGES_TYPE.organisation_logo)}
                // eslint-disable-next-line no-param-reassign
                onError={(event:SyntheticEvent<HTMLImageElement>) => {
                  const target = event.target as HTMLImageElement;
                  target.style.display = 'none';
                }}
              />
            </object>
          </div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleClick(IMAGES_TYPE.organisation_logo)}
          >
            {t(lang, 'forms.organisation.upload_logo')}
          </Button>
          <Subtitle>Signature</Subtitle>
          <div className={classes.signature} style={getImage(IMAGES_TYPE.tax_receipt_signature)}>
            <img
              alt="signature"
              src={getURL(IMAGES_TYPE.tax_receipt_signature)}
              // eslint-disable-next-line no-param-reassign
              onError={(event:SyntheticEvent<HTMLImageElement>) => {
                const target = event.target as HTMLImageElement;
                target.style.display = 'none';
              }}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleClick(IMAGES_TYPE.tax_receipt_signature)}
          >
            {t(lang, 'forms.organisation.upload_signature')}
          </Button>
          <Subtitle>{t(lang, 'forms.organisation.tax_receipt_background')}</Subtitle>
          <div
            className={classes.tax_receipt_bg}
            style={getImage(IMAGES_TYPE.tax_receipt_header)}
          >
            <img
              alt="tax_receipt_bg"
              src={getURL(IMAGES_TYPE.tax_receipt_header)}
              // eslint-disable-next-line no-param-reassign
              onError={(event:SyntheticEvent<HTMLImageElement>) => {
                const target = event.target as HTMLImageElement;
                target.style.display = 'none';
              }}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleClick(IMAGES_TYPE.tax_receipt_header)}
          >
            {t(lang, 'forms.organisation.upload_tax_receipt_background')}
          </Button>
        </>
      );
    default:
      return (<ImageCropper type={imageType} onBack={onBack} />);
  }
};

export default OrganisationImagesForm;
