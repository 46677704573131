import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { t } from 'src/lib/language';
import FormSingleSelect from 'src/components/Control/FormControls/FormSingleSelect';
import { FORM_ID } from 'src/models/FormModel';
import { ORGANISATION_TYPES } from 'src/constants/OrganisationTypes';

interface Props {
  disabled?: boolean,
  form: FORM_ID,
  name: string,
  onChange: (id:string) => void,
  value?: string,
}

interface OrganisationTypeItem {
  id: string,
  display: string,
}

const OrganisationTypeSelector = (props: Props) => {
  const {
    disabled, form, name, onChange, value,
  } = props;

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const getItems = () => [
    { id: ORGANISATION_TYPES.school, display: ORGANISATION_TYPES.school },
    { id: ORGANISATION_TYPES.business, display: ORGANISATION_TYPES.business },
    { id: ORGANISATION_TYPES.church, display: ORGANISATION_TYPES.church },
    { id: ORGANISATION_TYPES.general, display: ORGANISATION_TYPES.general },
  ] as OrganisationTypeItem[];

  return (
    <FormSingleSelect
      disabled={disabled}
      form={form}
      items={getItems()}
      label={t(lang, 'forms.organisation.organisation_type')}
      name={name}
      onChange={onChange}
      value={value || ''}
      noMarginTop
    />
  );
};

export default OrganisationTypeSelector;
