import TextField from '@material-ui/core/TextField';
import { ErrorBag } from 'src/models/ErrorModel';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { v4 as uuid } from 'uuid';
import { FORM_ID } from 'src/models/FormModel';

interface Props {
  disabled?: boolean,
  error?: Nullable<string>,
  form: FORM_ID,
  label?: string,
  name: string,
  onChange: (value: any, fieldname: any) => void,
  required?: boolean,
  value: string | number | undefined,
  multiline?: boolean,
  minRows?: number | string,
  maxRows?: number | string,
  size?: 'medium' | 'small' | undefined,
  margin?: 'dense' | 'normal' | undefined,
  inputProps?: any,
  type?: string,
}

const FormTextField = (props: Props) => {
  const {
    disabled,
    error,
    form,
    name,
    label,
    onChange,
    required,
    value,
    multiline,
    minRows,
    maxRows,
    size,
    margin,
    inputProps,
    type,
  } = props;

  const errors: ErrorBag =
    useSelector((state:Store) => state.errors[form] || {} as ErrorBag);

  const getError = () => {
    if (!!error) return error;
    if (errors[name]) return errors[name].join(',');
    return undefined;
  };

  return (
    <TextField
      variant="outlined"
      size={size}
      margin={margin || 'normal'}
      multiline={multiline}
      required={!!required}
      disabled={!!disabled}
      fullWidth
      minRows={minRows}
      maxRows={maxRows}
      id={name.concat(uuid())}
      label={label}
      name={name}
      key={`textfield-${name}`}
      autoComplete={name}
      onChange={(e) => onChange(e.target.value as string, name)}
      error={!!getError()}
      helperText={getError()}
      value={value || ''}
      inputProps={inputProps}
      type={type}
    />
  );
};

export default FormTextField;
