import { ProductOrderList, ProductOrderModel } from 'src/models/InvoiceModel';
import OrderDisplayerHeader from 'src/components/Elements/OrdersDisplayerHeader';
import GuessOrderProductItem from 'src/components/Elements/GuessOrderProductItem';
import { ProductList } from 'src/models/ProductModel';

interface Props {
  order: ProductOrderList,
  products: ProductList,
}

export enum OrderColumn {
  quantity = '40px',
  product = '100%',
  price = '128px',
  taxes = '128px',
  discount = '128px',
  deductible = '128px',
  amount = '128px',
}

const GuessOrderDisplayer = (props: Props) => {
  const {
    order,
    products,
  } = props;

  const displayOrderProductItem = () => order.map((item, index) => (
    <GuessOrderProductItem
      key={`product-${index}`}
      index={index}
      order={item as ProductOrderModel}
      products={products}
    />
  ));

  return (
    <div>
      <OrderDisplayerHeader />
      {displayOrderProductItem()}
    </div>
  );
};

export default GuessOrderDisplayer;
//
