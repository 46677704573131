import * as Yup from 'yup';
/* eslint-disable import/no-extraneous-dependencies */
import { t } from 'src/lib/language';

type BaseSchema = {
  amount: Yup.NumberSchema,
  contact_salutation_id: Yup.NumberSchema,
  contact_first_name: ReturnType<typeof Yup.lazy>,
  contact_last_name: ReturnType<typeof Yup.lazy>,
  contact_company_name: ReturnType<typeof Yup.lazy>,
  billing_address: Yup.StringSchema,
  billing_suite: Yup.StringSchema,
  billing_city: Yup.StringSchema,
  billing_state: Yup.StringSchema,
  billing_country_id: Yup.NumberSchema,
  billing_zip_code: Yup.StringSchema,
  payment_type: Yup.StringSchema,
  first_name?: Yup.StringSchema,
  last_name?: Yup.StringSchema,
  card_number?: Yup.StringSchema,
  expiration_year?: Yup.StringSchema,
  expiration_month?: Yup.StringSchema,
  cvv?: Yup.StringSchema,
  payment_info_id?: Yup.NumberSchema,
};

const GuessPaymentValidator = (lang:string, isLogged: boolean) => {
  const baseSchema = {
    amount: Yup.number()
      .min(0.01, t(lang, 'validations.invoice.field_required'))
      .required(),
    contact_salutation_id: Yup.number()
      .min(1, t(lang, 'validations.contact.field_required')).max(999)
      .integer()
      .transform((value) => (!!value ? value : null))
      .nullable(true),
    contact_first_name: Yup.lazy(() => Yup.string()
      .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
      .when(['contact_company_name'], {
        is: (companyName: string) => !companyName,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      })),
    contact_last_name: Yup.lazy(() => Yup.string()
      .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
      .when(['contact_company_name'], {
        is: (companyName: string) => !companyName,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      })),
    contact_company_name: Yup.lazy(() => Yup.string()
      .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
      .when(['contact_first_name', 'contact_last_name'], {
        is: (firstName: string, lastName: string) => !firstName && !lastName,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      })),
    billing_address: Yup.string()
      .required(t(lang, 'validations.contact.field_required'))
      .max(250, t(lang, 'validations.contact.field_maximum', { max: 250 })),
    billing_suite: Yup.string()
      .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
      .nullable(),
    billing_city: Yup.string()
      .required(t(lang, 'validations.contact.field_required'))
      .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 })),
    billing_state: Yup.string()
      .required(t(lang, 'validations.contact.field_required'))
      .max(150, t(lang, 'validations.contact.field_maximum_', { max: 150 })),
    billing_country_id: Yup.number()
      .required(t(lang, 'validations.contact.field_required'))
      .min(1, t(lang, 'validations.contact.field_required')).max(999)
      .integer(),
    billing_zip_code: Yup.string()
      .required(t(lang, 'validations.contact.field_required'))
      .max(7, t(lang, 'validations.contact.field_maximum', { max: 7 })),
    payment_type: Yup.string().required(),
  } as BaseSchema;

  if (!isLogged) {
    baseSchema.first_name = Yup.string().max(128).required();
    baseSchema.last_name = Yup.string().max(128).required();
    baseSchema.card_number = Yup.string()
      .matches(/^[0-9]+$/, t(lang, 'validations.creditcard.only_digit'))
      .min(16, t(lang, 'validations.creditcard.exact_digit_12'))
      .max(16, t(lang, 'validations.creditcard.exact_digit_12'))
      .required();
    baseSchema.expiration_year = Yup.string()
      .matches(/^[0-9]+$/, t(lang, 'validations.creditcard.only_digit'))
      .min(2, t(lang, 'validations.creditcard.exact_digit_2'))
      .max(2, t(lang, 'validations.creditcard.exact_digit_2'))
      .test('is-greater-or-equal', t(lang, 'validations.creditcard.year_must_be_future'),
        (value) => {
          if (!!value) {
            return parseInt(value, 10) >= new Date().getFullYear() % 100;
          }
          return true;
        })
      .required();
    baseSchema.expiration_month = Yup.string()
      .matches(/^[0-9]+$/, t(lang, 'validations.creditcard.only_digit'))
      .matches(/^([0-9]|1[0-2])$/, 'Value must be between 0 and 12')
      .min(2, t(lang, 'validations.creditcard.exact_digit_2'))
      .max(2, t(lang, 'validations.creditcard.exact_digit_2'))
      .required();
    baseSchema.cvv = Yup.string()
      .matches(/^[0-9]+$/, t(lang, 'validations.creditcard.only_digit'))
      .min(3, t(lang, 'validations.creditcard.exact_digit_3'))
      .max(4, t(lang, 'validations.creditcard.exact_digit_3'))
      .required();
  }

  if (isLogged) {
    baseSchema.payment_info_id = Yup.number().integer();
  }

  return Yup.object().shape(baseSchema);
};

export default GuessPaymentValidator;
