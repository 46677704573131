import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { FundraisingTeam, Team } from 'src/models/FundraisingModel';
import { getTeamListAction } from 'src/redux/actions/fundraisingAction';
import Button from '@material-ui/core/Button';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { FORM } from 'src/constants/Form';
import { t } from 'src/lib/language';

const useStyles = makeStyles((theme) => ({
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '56px',
  },
  title: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    fontSize: '16px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
  },
  scroller: {
    overflowY: 'auto',
    height: '50 vh',
    padding: '8px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    height: '53px',
    gap: '10px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
  },
  search: {
    height: '6vh',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
    marginTop: '10px',
  },
}));

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

interface Props {
  onChange: (teams: FundraisingTeam[]) => void,
  onNextStep: (step: number) => void,
  setHasChanged: (hasChanged: boolean) => void;
}

const FundraisingTeams = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    onChange,
    onNextStep,
    setHasChanged,
  } = props;

  useEffect(() => {
    dispatch(getTeamListAction());
  }, []);

  const [currentFilter, setCurrentFilter] = useState('');
  const [teamList, setTeamList] = useState([] as FundraisingTeam[]);

  const teams = useSelector((state: Store) => state.teams);
  const lang = useSelector((state: Store) => state.language.language);

  const addTeam = (id: number) => {
    setHasChanged(true);
    setTeamList([...teamList, { id, goal: 0 }]);
  };

  const removeTeam = (id: number) => {
    setHasChanged(true);
    const index = teamList.findIndex((obj: FundraisingTeam) => obj.id === id);
    teamList.splice(index, 1);
    setTeamList([...teamList]);
  };

  const handleSubmit = () => {
    onChange(teamList);
    onNextStep(6);
  };

  const getSelected = (id: number | undefined) => {
    if (id === undefined) return false;
    return !!teamList.filter((obj) => obj.id === id).length;
  };

  const toggleSelected = (value:boolean, id: number | undefined) => {
    if (!id) return;
    if (value) {
      addTeam(id);
      return;
    }
    removeTeam(id);
  };

  const sanitizeInput = (value: string) => {
    const sanitizedString = value.replace(/[^0-9.]/g, '');
    const parts = sanitizedString.split('.');
    if (parts.length > 1) {
      const decimalPart = parts[1].substring(0, 2);
      return Number(`${parts[0]}.${decimalPart}`);
    }
    return Number(sanitizedString);
  };

  const setTeamGoal = (value: any, id: number) => {
    const index = teamList.findIndex((obj: FundraisingTeam) => obj.id === id);
    teamList[index].goal = sanitizeInput(value);
    setTeamList([...teamList]);
  };

  const getTeamGoal = (id: number) => {
    const index = teamList.findIndex((obj: FundraisingTeam) => obj.id === id);
    if (index === -1) return 0;
    return teamList[index].goal;
  };

  const getRowStyle = (index: number) => {
    const style = {} as any;
    if (!index) {
      style.borderTop = '1px solid rgba(0, 0, 0, 0.25)';
    }
    if (index % 2) {
      style.backgroundColor = '#eee';
    }
    return style;
  };

  const displayTeam = () => teams.map((team: Team, index: number) => {
    if (currentFilter) {
      if (!team.name) return null;
      if (!team.name.toLowerCase().includes(currentFilter)) return null;
    }
    return (
      <div
        className={classes.item}
        key={`team-${index}`}
        style={getRowStyle(index)}
      >
        <div style={{ width: '75%', marginTop: '5px', paddingLeft: '8px' }}>
          <FormCheckbox
            checked={getSelected(team.id)}
            label={team.name}
            name=""
            onChange={(value:boolean) => toggleSelected(value, team.id)}
          />
        </div>
        <div style={{
          width: '25%', marginTop: '-10px', marginBottom: '-4px', paddingRight: '8px',
        }}
        >
          <FormTextField
            form={FORM.fundraising_teams}
            name={`team-goal-${team.id}`}
            onChange={(value:string) => setTeamGoal(value, team.id as number)}
            value={getTeamGoal(team.id as number)}
            margin="dense"
            label={t(lang, 'forms.fundraising.goal')}
          />
        </div>
      </div>
    );
  });

  return (
    <>
      <div className={classes.title}>Choose Teams</div>
      <div className={classes.search}>
        <TextField
          margin="dense"
          variant="outlined"
          label={t(lang, 'forms.fundraising.goal')}
          onChange={(e) => setCurrentFilter(e.target.value)}
        />
      </div>
      <div className={classes.scroller}>
        {displayTeam()}
      </div>
      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          key="submit-button"
        >
          {t(lang, 'forms.fundraising.next_step')}
        </Button>
      </div>
    </>
  );
};

export default FundraisingTeams;
