import { makeStyles } from '@material-ui/core/styles';
import { FundraisingTypes } from 'src/constants/FundraisingTypes';
import { ProductType } from 'src/models/ProductModel';
import { useState } from 'react';
import {
  FormControl, FormControlLabel, Radio, RadioGroup,
} from '@material-ui/core';
import ProductsSelector from 'src/components/Control/ProductsSelector';
import { FORM } from 'src/constants/Form';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  frame: {
    width: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    flexWrap: 'wrap',
  },
}));

interface Props {
  fundraisingType: FundraisingTypes,
  onAdd: (id: number) => void,
}

const ChooseProducts = (props: Props) => {
  const classes = useStyles();
  const { fundraisingType, onAdd } = props;

  const [type, setType] = useState(ProductType.all);
  const [productId, setProductId] = useState(0);

  const onAddProduct = () => {
    onAdd(productId);
    setProductId(0);
  };

  return (
    <div className={classes.frame}>
      <div className={classes.row}>
        Choose a Product
      </div>
      <div className={classes.row}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={type}
            onChange={(e) => setType(e.target.value as ProductType)}
          >
            <FormControlLabel value={ProductType.all} control={<Radio />} label="All" />
            { (fundraisingType === FundraisingTypes.event) && (
            <FormControlLabel value={ProductType.ticket} control={<Radio />} label="Ticket" />
            )}
            <FormControlLabel value={ProductType.donation} control={<Radio />} label="Donation" />
            <FormControlLabel value={ProductType.product} control={<Radio />} label="Product" />
            <FormControlLabel value={ProductType.fee} control={<Radio />} label="Fee" />
          </RadioGroup>
        </FormControl>
      </div>
      <div className={classes.row}>
        <div style={{ width: '640px' }}>
          <ProductsSelector
            type={type}
            form={FORM.fundraising_product}
            name="products"
            onChange={setProductId}
            value={productId}
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={onAddProduct}
          key="submit-button"
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default ChooseProducts;
