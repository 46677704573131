import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ENTITIES, ORDER, QuerbuilderPayloadColumns } from 'src/models/QuerybuilderModel';
import Datagrid from 'src/components/Datagrid/Datagrid';
import { useEffect } from 'react';
import {
  getEntitiesAction,
  setColumns,
  setFilters,
  setOrders,
  setQueryBuilderResult,
} from 'src/redux/actions/querybuilderActions';
import DEFAULT_COLUMNS from 'src/constants/columns';
import { resetSelections } from 'src/redux/actions/selectionActions';
import PaymentInvoiceToolbar from 'src/components/DatagridToolbar/entities/PaymentInvoiceToolbar';
import PaymentsForInvoiceHeader from 'src/components/DatagridToolbar/entities/PaymentsforInvoiceHeader';
import { t } from 'src/lib/language';

const PaymentsForInvoiceDatagrid = () => {
  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language);
  const invoiceId = useSelector((state: Store) => state.route.id || 0);
  const invoice = useSelector((state: Store) => state.invoices[invoiceId]);

  useEffect(() => {
    dispatch(setColumns({
      entity: ENTITIES.payments,
      columns: DEFAULT_COLUMNS.paymentsForInvoice,
    } as QuerbuilderPayloadColumns));
    dispatch(setOrders({
      entity: ENTITIES.payments,
      order: [['payments.id', ORDER.desc]],
    }));
    dispatch(setFilters({
      entity: ENTITIES.payments,
      filters: {
        and: [['invoices.id', '=', invoiceId]],
      },
    }));
    dispatch(resetSelections({ entity: ENTITIES.payments }));
    dispatch(setQueryBuilderResult({
      entity: ENTITIES.payments,
      result: {
        count: 0,
        list: [],
      },
    }));
    dispatch(getEntitiesAction(ENTITIES.payments));
  }, []);

  const getTitle = () => {
    if (!invoice) return '';
    if (!invoice.title) return `#${invoice.id}`;
    return `${invoice.title} (#${invoice.id})`;
  };

  useEffect(() => {
    if (!invoice) return;
    document.title = `${t(lang, 'menus.payments_for_invoice')}: ${getTitle()}`;
  }, [invoice]);

  return (
    <>
      <PaymentsForInvoiceHeader />
      <PaymentInvoiceToolbar />
      <Datagrid entity={ENTITIES.payments} dontReloadEntities />
    </>
  );
};

export default PaymentsForInvoiceDatagrid;
