import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import TicketIdentifier from 'src/components/Control/TicketIdentifier';

const TicketIdentifiersModal = () => (
  <ModalBase
    name={MODALS.ticketIdentifiers}
    size={MODALS_SIZE.small}
    height="38vh"
  >
    <TicketIdentifier />
  </ModalBase>
);
export default TicketIdentifiersModal;
