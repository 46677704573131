import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { FORM } from 'src/constants/Form';
import { t } from 'src/lib/language';
import { MouseEvent, useState } from 'react';
import { CreditCardPayload } from 'src/models/CreditCardModel';
import CreditCardValidator from 'src/validations/CreditCardValidator';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import { setError } from 'src/redux/actions/errorsActions';
import { putContactCreditCardAction } from 'src/redux/actions/paymentInfoAction';
import { getCardType } from 'src/lib/PaymentHelper';

const useStyles = makeStyles((theme) => ({
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '48px',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
  },
  close: {
    padding: 5,
    minHeight: 0,
    minWidth: 0,
    float: 'right',
    '&> span': {
      lineHeight: 0.75,
    },
  },
  submit: {
    marginLeft: 'auto',
    marginRight: '0px',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
    marginTop: '10px',
  },
}));

const EditCreditCardForm = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const user = useSelector((state: Store) => state.user);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const { contactId, creditCard } =
  useSelector((state: Store) => state.modals[MODALS.editCreditCard].payload);

  const getExpirationMonth = () => {
    if (!creditCard.expiration_data) return '';
    return creditCard.expiration_data.split('/')[0];
  };

  const getExpirationYear = () => {
    if (!creditCard.expiration_data) return '';
    return creditCard.expiration_data.split('/')[1];
  };

  const getFirstName = () => {
    if (!creditCard.card_holder) return '';
    return creditCard.card_holder.split(' ')[0];
  };

  const getLastName = () => {
    if (!creditCard.card_holder) return '';
    return creditCard.card_holder.split(' ')[1];
  };

  const [state, setState] = useState(
    {
      contact_id: contactId,
      name: creditCard.name,
      expiration_month: getExpirationMonth(),
      expiration_year: getExpirationYear(),
      first_name: getFirstName(),
      last_name: getLastName(),
    } as CreditCardPayload,
  );

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.editCreditCard }));
  };

  const onChange = (value: string | undefined, field: string) => {
    if (!!value) {
      switch (field) {
        case 'card_number':
          if (value.length > 16) return;
          break;
        case 'expiration_month':
          if (value.length > 2) return;
          break;
        case 'expiration_year':
          if (value.length > 2) return;
          break;
        case 'cvv':
          if (value.length > 4) return;
          break;
        default:
      }
    }

    setState({
      ...state,
      [field]: value,
    });
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setError({ [FORM.edit_creditcard]: {} }));
    try {
      CreditCardValidator(lang, user.is_admin).validateSync(
        state,
        { abortEarly: false },
      );
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: t(lang, 'forms.creditcards.validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.edit_creditcard]: errorBag }));
      return false;
    }

    const payload = {
      ...state,
      card_type: getCardType(state.card_number),
    };
    dispatch(putContactCreditCardAction(payload, creditCard.id));
    return true;
  };

  return (
    <>
      <div className={classes.header}>
        <div className={classes.topRow}>
          <div className={classes.title}>
            Edit a credit card ({creditCard.masked_card_number})
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.close}
              onClick={handleClose}
            >
              X
            </Button>
          </div>
        </div>
      </div>
      <FormTextField
        form={FORM.add_creditcard}
        label={t(lang, 'forms.creditcards.card_number')}
        name="card_number"
        onChange={onChange}
        required
        value={state.card_number || ''}
        inputProps={{
          type: 'number',
        }}
      />
      <FormTextField
        form={FORM.add_creditcard}
        label={t(lang, 'forms.creditcards.name')}
        name="name"
        onChange={onChange}
        required
        value={state.name || ''}
      />
      <FormTextField
        form={FORM.add_creditcard}
        label={t(lang, 'forms.creditcards.first_name')}
        name="first_name"
        onChange={onChange}
        required
        value={state.first_name || ''}
      />
      <FormTextField
        form={FORM.add_creditcard}
        label={t(lang, 'forms.creditcards.last_name')}
        name="last_name"
        onChange={onChange}
        required
        value={state.last_name || ''}
      />
      <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
        <div>
          <FormTextField
            form={FORM.add_creditcard}
            label={t(lang, 'forms.creditcards.expiration_month')}
            name="expiration_month"
            onChange={onChange}
            required
            value={state.expiration_month || ''}
            inputProps={{
              type: 'number',
            }}
          />
        </div>
        <div>
          <FormTextField
            form={FORM.add_creditcard}
            label={t(lang, 'forms.creditcards.expiration_year')}
            name="expiration_year"
            onChange={onChange}
            required
            value={state.expiration_year || ''}
            inputProps={{
              type: 'number',
            }}
          />
        </div>
        <div>
          <FormTextField
            form={FORM.add_creditcard}
            label="cvv"
            name="cvv"
            onChange={onChange}
            value={state.cvv || ''}
            inputProps={{
              type: 'number',
            }}
          />
        </div>
      </div>
      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={(e) => handleSubmit(e)}
        >
          {t(lang, 'misc.save')}
        </Button>
      </div>
    </>
  );
};

export default EditCreditCardForm;
