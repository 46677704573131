import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import AddUserCreditCardForm from 'src/components/Forms/AddUserCreditCardForm';

const UserAddCreditCardModal = () => (
  <ModalBase
    name={MODALS.addUserCreditCard}
    size={MODALS_SIZE.small}
    height="48vh"
  >
    <AddUserCreditCardForm />
  </ModalBase>
);

export default UserAddCreditCardModal;
