import { MODALS } from 'src/models/ModalModel';
import { MODALS_SIZE } from 'src/models/modal';
import ModalBase from 'src/components/Modals/ModalBase';
import PaymentsForContactDatagrid from 'src/components/EntityDatagrids/PaymentsForContactDatagrid';

const PaymentsForContactModal = () => (
  <ModalBase
    name={MODALS.paymentsForContact}
    size={MODALS_SIZE.large}
  >
    <PaymentsForContactDatagrid />
  </ModalBase>
);

export default PaymentsForContactModal;
