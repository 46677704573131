import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { setError } from 'src/redux/actions/errorsActions';
import { postContactAction } from 'src/redux/actions/contactActions';
import ContactModel from 'src/models/ContactModel';
import { FORM } from 'src/constants/Form';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import Subtitle from 'src/components/Elements/Subtitle';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import { t } from 'src/lib/language';
import { LanguageModel, LANGUAGES } from 'src/models/LanguageModel';
import LanguageSelector from 'src/components/Control/LanguageSelector';
import SimpleContactValidator from 'src/validations/SimpleContactValidator';
import SimpleContactShippingValidator from 'src/validations/SimpleContactShippingValidator';
import { CustomfieldValidationSchema } from 'src/validations/CustomfieldValidator';
import ContactGroupSelector from 'src/components/Control/ContactGroupSelector';
import CountriesSelector from 'src/components/Control/CountriesSelector';
import SalutationSelector from 'src/components/Control/SalutationSelector';
import EmailStatusSelector from 'src/components/Control/EmailStatusSelector';
import CustomfieldsDisplayer from 'src/components/Elements/CustomfieldsDisplayer';
import AddCustomfieldButton from 'src/components/DatagridToolbar/buttons/AddCustomfieldButton';
import { AddressModel, AddressType } from 'src/models/AddressModel';
import AddressSearch from 'src/components/Control/AddressSearch';
import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import SaveIcon from '@material-ui/icons/Save';
import ManageCustomfieldButton
  from 'src/components/DatagridToolbar/buttons/ManageCustomfieldButton';
import ManageGroupsButton from 'src/components/DatagridToolbar/buttons/ManageGroupsButton';
import SubmenuLink from 'src/components/Elements/SubmenuLink';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: '640px',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectElement: {
    width: '100%',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.50)',
  },
  selectLabelError: {
    color: 'rgba(255, 0, 0, 0.50)',
  },
  spacer: {
    height: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
    marginTop: '10px',
  },
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '12vh',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
  },
  scroller: {
    overflowY: 'auto',
    height: '66vh',
    padding: '8px',
  },
}));

interface Props {
  contactId?: number;
}

export const enum TABS {
  info = 'info',
  meta = 'meta',
  payment = 'payment',
  relations = 'relations',
}

const AddContactForm = (props: Props) => {
  const classes = useStyles();

  const { contactId } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setError({ [FORM.add_contact]: {} }));
  }, []);

  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const customfields = useSelector((state: Store) => state.customfields);
  const { reload, onChange } = useSelector(
    (state: Store) => state.modals[MODALS.addContact]?.payload || undefined,
  );

  const emptyContact = {} as ContactModel;

  const [currentTab, setCurrentTab] = useState(TABS.info);
  const [contactHasError, setContactHasError] = useState(false);
  const [customfieldsHasError, setCustomfieldsHasError] = useState(false);

  const [contactState, setContactState] = useState({
    ...emptyContact,
    locale_id: 'en_CA',
    sameAsBilling: true,
    issue_tax_receipt: 1,
  });

  const [customfieldState, setCustomfieldState] = useState({});
  const [groupsState, setGroupsState] = useState([] as number[]);

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.addContact }));
  };

  const onContactFieldChange = (value: Value, field: string) => {
    switch (field) {
      case 'phone_home':
      case 'phone_office':
      case 'phone_mobile':
        if (value) {
          if (!value.toString().match(/^\d+$/)) {
            return;
          }
          if (value.toString().length >= 20) {
            return;
          }
        }
        break;
      default:
    }
    setContactState({
      ...contactState,
      [field]: value,
    });
  };

  const onCustomfieldChange = (value: Value, field: string) => {
    setCustomfieldState({
      ...customfieldState,
      [field]: value,
    });
  };

  const onGroupChange = (group:any) => {
    setGroupsState(group);
  };

  const onLanguageChange = (language: LanguageModel) => {
    setContactState({
      ...contactState,
      locale_id: language.locale,
    });
  };

  const setShippingFromBilling = () => ({
    ...contactState,
    shipping_address: contactState.billing_address,
    shipping_suite: contactState.billing_suite,
    shipping_city: contactState.billing_city,
    shipping_state: contactState.billing_state,
    shipping_country_id: contactState.billing_country_id,
    shipping_zip_code: contactState.billing_zip_code,
  });

  const setAddressFromGoogle = (address: AddressModel, type: AddressType) => {
    setContactState({
      ...contactState,
      [`${type}_address`]: address.address,
      [`${type}_city`]: address.city,
      [`${type}_state`]: address.state,
      [`${type}_country_id`]: address.country_id,
      [`${type}_zip_code`]: (!!address.zip_code) ? address.zip_code.replaceAll(' ', '') : '',
    });
  };

  const onAddressChange = (address: AddressModel) => {
    setAddressFromGoogle(address, AddressType.billing);
  };

  const validateContact = () => {
    const errorBag = {} as ErrorBag;
    try {
      let schema;
      if (!contactState.sameAsBilling) {
        schema = SimpleContactValidator(lang).concat(SimpleContactShippingValidator(lang));
      } else {
        schema = SimpleContactValidator(lang);
      }
      schema.validateSync(contactState, { abortEarly: false });
    } catch (validationErrors: any) {
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      return { contactValid: false, contactErrors: errorBag };
    }
    return { contactValid: true, contactErrors: errorBag };
  };

  const validateCustomfields = () => {
    const errorBag = {} as ErrorBag;
    try {
      const schema = CustomfieldValidationSchema(customfields);
      const data = {
        ...contactState,
        ...customfieldState,
      };
      schema.validateSync(data, { abortEarly: false });
    } catch (validationErrors: any) {
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      return { customfieldValid: false, customfieldsError: errorBag };
    }
    return { customfieldValid: true, customfieldsError: errorBag };
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>, close:boolean = false) => {
    event.preventDefault();
    dispatch(setError({ [FORM.add_contact]: {} }));
    const { contactValid, contactErrors } = validateContact();
    const { customfieldValid, customfieldsError } = validateCustomfields();
    if (!contactValid || !customfieldValid) {
      let errorBag = {} as ErrorBag;
      if (!contactValid) {
        setContactHasError(true);
        errorBag = {
          ...contactErrors,
        };
      }
      if (!customfieldValid) {
        setCustomfieldsHasError(true);
        errorBag = {
          ...errorBag,
          ...customfieldsError,
        };
      }
      dispatch(setError({ [FORM.add_contact]: errorBag }));
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.contact.validation_errors',
      }));
      return;
    }
    let contact = contactState;
    if (contactState.sameAsBilling) {
      contact = setShippingFromBilling();
    }

    const payload = {
      contact: {
        ...contact,
        issue_tax_receipt: contactState.issue_tax_receipt ? 1 : 0,
      },
      customfields: customfieldState,
      groups: groupsState,
    };

    dispatch(postContactAction(payload, close, reload, onChange));
  };

  return (
    <>
      <div className={classes.header}>
        <div className={classes.row}>
          <div className={classes.title}>Add Contact</div>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}
          >
            {t(lang, 'misc.close')}
          </Button>
        </div>
        <div className={classes.row}>
          <Tooltip
            title={t(lang, 'menus.add_contact')}
            placement="top-start"
          >
            <span>
              <ActionButton
                category={MENU_BUTTON_CATEGORY.action}
                onClick={handleSubmit}
              >
                <SaveIcon />
              </ActionButton>
            </span>
          </Tooltip>
          <ManageCustomfieldButton />
          <ManageGroupsButton />
        </div>
        <div className={classes.row}>
          <SubmenuLink
            onClick={() => setCurrentTab(TABS.info)}
            selected={currentTab === TABS.info}
            hasError={contactHasError}
          >
            {t(lang, 'forms.contact.contact_information')}
          </SubmenuLink>
          <SubmenuLink
            onClick={() => setCurrentTab(TABS.meta)}
            selected={currentTab === TABS.meta}
            hasError={customfieldsHasError}
          >
            {t(lang, 'forms.contact.meta_information')}
          </SubmenuLink>
        </div>
      </div>
      <div className={classes.scroller}>
        <form className={classes.form} noValidate>
          { currentTab === TABS.info && (
          <>
            <FormCheckbox
              label={t(lang, 'forms.contact.issue_tax_receipt')}
              name="issue_tax_receipt"
              onChange={onContactFieldChange}
              checked={Boolean(contactState.issue_tax_receipt)}
            />
            <Subtitle>Language</Subtitle>
            <div>
              Language
              <LanguageSelector
                onChange={onLanguageChange}
                current={LANGUAGES[contactState.locale_id]}
              />
            </div>
            <Subtitle>{ t(lang, 'forms.contact.header_name') }</Subtitle>
            <div>
              <SalutationSelector
                form={FORM.add_contact}
                name="salutation_id"
                value={contactState.salutation_id || 0}
                onChange={(id) => onContactFieldChange(id, 'salutation_id')}
              />
              <FormTextField
                form={FORM.add_contact}
                label={t(lang, 'forms.contact.first_name')}
                name="first_name"
                onChange={onContactFieldChange}
                value={contactState.first_name}
              />
              <FormTextField
                form={FORM.add_contact}
                label={t(lang, 'forms.contact.last_name')}
                name="last_name"
                onChange={onContactFieldChange}
                value={contactState.last_name}
              />
            </div>
            <div>
              <FormTextField
                disabled={!!contactId}
                form={FORM.add_contact}
                label={t(lang, 'forms.contact.company_name')}
                name="company_name"
                onChange={onContactFieldChange}
                value={contactState.company_name}
              />
            </div>
            <Subtitle>{ t(lang, 'forms.contact.header_emails') }</Subtitle>
            <FormTextField
              form={FORM.add_contact}
              label={t(lang, 'forms.contact.primary_email')}
              name="email"
              onChange={onContactFieldChange}
              value={contactState.email || ''}
            />
            <FormCheckbox
              name="emailNotification"
              label={t(lang, 'forms.contact.email_notification')}
              checked={contactState.email_allow_notification}
              onChange={onContactFieldChange}
            />
            <EmailStatusSelector
              form={FORM.add_contact}
              name="email_status"
              value={contactState.email_status || ''}
              onChange={(id) => onContactFieldChange(id, 'email_status')}
            />
            <FormTextField
              form={FORM.add_contact}
              label={t(lang, 'forms.contact.secondary_email')}
              name="secondary_email"
              onChange={onContactFieldChange}
              value={contactState.secondary_email || ''}
            />
            <FormCheckbox
              name="secondaryEmailNotification"
              label={t(lang, 'forms.contact.email_notification')}
              checked={contactState.secondary_email_allow_notification}
              onChange={onContactFieldChange}
            />
            <Subtitle>{ t(lang, 'forms.contact.header_billing_address') }</Subtitle>
            <AddressSearch
              onChange={onContactFieldChange}
              onAddressChange={onAddressChange}
              value={{ description: contactState.billing_address || '' }}
              name="billing_address"
              form={FORM.add_contact}
            />
            <FormTextField
              form={FORM.add_contact}
              label={t(lang, 'forms.contact.suite')}
              name="billing_suite"
              onChange={onContactFieldChange}
              value={contactState.billing_suite || ''}
            />
            <FormTextField
              form={FORM.add_contact}
              label={t(lang, 'forms.contact.city')}
              name="billing_city"
              onChange={onContactFieldChange}
              value={contactState.billing_city || ''}
            />
            <FormTextField
              form={FORM.add_contact}
              label={t(lang, 'forms.contact.state')}
              name="billing_state"
              onChange={onContactFieldChange}
              value={contactState.billing_state || ''}
            />
            <CountriesSelector
              form={FORM.add_contact}
              onChange={(id) => onContactFieldChange(id, 'billing_country_id')}
              value={contactState.billing_country_id || undefined}
              name="billing_country_id"
            />
            <FormTextField
              form={FORM.add_contact}
              label={t(lang, 'forms.contact.zip_code')}
              name="billing_zip_code"
              onChange={onContactFieldChange}
              value={contactState.billing_zip_code || ''}
            />
            <Subtitle>{ t(lang, 'forms.contact.header_shipping_address') }</Subtitle>
            <div className={classes.spacer} />
            <FormCheckbox
              checked={contactState.sameAsBilling}
              label={t(lang, 'forms.contact.same_as_billing')}
              name="sameAsBilling"
              onChange={onContactFieldChange}
            />
            { !contactState.sameAsBilling && (
            <>
              <AddressSearch
                onChange={
            onContactFieldChange
          }
                onAddressChange={
            (addresse: AddressModel) => { setAddressFromGoogle(addresse, AddressType.shipping); }
          }
                value={{ description: contactState.shipping_address || '' }}
                name="shipping_address"
                form={FORM.add_contact}
              />
              <FormTextField
                disabled={contactState.sameAsBilling}
                form={FORM.add_contact}
                label={t(lang, 'forms.contact.suite')}
                name="shipping_suite"
                onChange={onContactFieldChange}
                value={contactState.shipping_suite || ''}
              />
              <FormTextField
                disabled={contactState.sameAsBilling}
                form={FORM.add_contact}
                label={t(lang, 'forms.contact.city')}
                name="shipping_city"
                onChange={onContactFieldChange}
                value={contactState.shipping_city || ''}
              />
              <FormTextField
                disabled={contactState.sameAsBilling}
                form={FORM.add_contact}
                label={t(lang, 'forms.contact.state')}
                name="shipping_state"
                onChange={onContactFieldChange}
                value={contactState.shipping_state || ''}
              />
              <CountriesSelector
                form={FORM.add_contact}
                onChange={(id) => onContactFieldChange(id, 'shipping_country_id')}
                value={contactState.shipping_country_id || undefined}
                name="shipping_country_id"
              />
              <FormTextField
                disabled={contactState.sameAsBilling}
                form={FORM.add_contact}
                label={t(lang, 'forms.contact.zip_code')}
                name="shipping_zip_code"
                onChange={onContactFieldChange}
                required
                value={contactState.shipping_zip_code || ''}
              />
            </>
            ) }
            <Subtitle>{ t(lang, 'forms.contact.header_phones') }</Subtitle>
            <FormTextField
              form={FORM.add_contact}
              label={t(lang, 'forms.contact.phone_home')}
              name="phone_home"
              onChange={onContactFieldChange}
              value={contactState.phone_home || ''}
            />
            <FormCheckbox
              checked={contactState.phone_home_allow_notification}
              label={t(lang, 'forms.contact.phone_notification')}
              name="phone_home_allow_notification"
              onChange={onContactFieldChange}
            />
            <FormTextField
              form={FORM.add_contact}
              label={t(lang, 'forms.contact.phone_mobile')}
              name="phone_mobile"
              onChange={onContactFieldChange}
              value={contactState.phone_mobile || ''}
            />
            <FormCheckbox
              checked={contactState.phone_mobile_allow_notification}
              label={t(lang, 'forms.contact.phone_notification')}
              name="phone_mobile_allow_notification"
              onChange={onContactFieldChange}
            />
            <FormTextField
              form={FORM.add_contact}
              label={t(lang, 'forms.contact.phone_office')}
              name="phone_office"
              onChange={onContactFieldChange}
              value={contactState.phone_office || ''}
            />
            <FormCheckbox
              checked={contactState.phone_office_allow_notification}
              label={t(lang, 'forms.contact.phone_notification')}
              name="phone_office_allow_notification"
              onChange={onContactFieldChange}
            />
          </>
          )}
          { currentTab === TABS.meta && (
            <>
              <Subtitle>{t(lang, 'forms.contact.groups')}</Subtitle>
              <div>
                <ContactGroupSelector
                  onChange={onGroupChange}
                  values={groupsState}
                />
              </div>
              <Subtitle>{t(lang, 'forms.contact.custom_fields')}</Subtitle>
              <div style={{ marginTop: 8 }}><AddCustomfieldButton />{t(lang, 'forms.contact.add_custom_fields')}</div>
              <CustomfieldsDisplayer
                form={FORM.add_contact}
                onChange={onCustomfieldChange}
              />
            </>
          )}
          <div className={classes.bottomRow}>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
              key="submit-button"
            >
              {t(lang, 'misc.save')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={(e) => handleSubmit(e, true)}
              key="submit-close-button"
            >
              {t(lang, 'misc.save_and_close')}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddContactForm;
