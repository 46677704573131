import { MODALS } from 'src/models/ModalModel';
import EditContactForm from 'src/components/Forms/EditContactForm';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';

const EditContactModal = () => (
  <ModalBase
    name={MODALS.editContacts}
    size={MODALS_SIZE.small}
  >
    <EditContactForm />
  </ModalBase>
);

export default EditContactModal;
//
