import { TextField } from '@material-ui/core';
import { EmptyFilter } from 'src/models/AdvanceSearchModel';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { t } from 'src/lib/language';
import { FILTER_POSITION } from 'src/constants/FilterPosition';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { getOperatorAndFilters } from 'src/lib/QueryBuilderHelper';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FORM } from 'src/constants/Form';
import { setAdvancedSearch } from 'src/redux/actions/advancedSearchAction';

interface Props {
  entity: ENTITIES;
  index: number;
}

const OperandBetweenText = (props: Props) => {
  const {
    index, entity,
  } = props;

  const dispatch = useAppDispatch();

  const error: string =
    useSelector(
      (state: Store) => {
        if (state.errors[FORM.query_builder]) {
          if (state.errors[FORM.query_builder][index]) {
            return state.errors[FORM.query_builder][index][FILTER_POSITION.operands];
          }
          return '';
        }
        return '';
      },
    );

  const filters = useSelector(
    (state: Store) => state.advancedSearch[entity] || EmptyFilter,
  );
  const [operator, filterList] = getOperatorAndFilters(filters);

  const getInitialValue = (postion: number) => {
    if (filterList[index][FILTER_POSITION.operands]) {
      return filterList[index][FILTER_POSITION.operands][postion];
    }
    return '';
  };

  const [startText, setStartText] = useState(getInitialValue(0));
  const [endText, setEndText] = useState(getInitialValue(1));

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const handleTextChange = (position: number, value: string) => {
    if (position === 0) {
      setStartText(value);
    } else {
      setEndText(value);
    }
  };

  const handleOnBlur = () => {
    filterList[index][FILTER_POSITION.operands] = [startText, endText];
    dispatch(setAdvancedSearch({
      entity,
      filters: {
        [operator]: [
          ...filterList,
        ],
      },
    }));
  };

  const errors = error.split(',');
  return (
    <>
      <TextField
        inputProps={{ style: { height: '14px' } }}
        style={{ margin: 0 }}
        margin="dense"
        id={uuid()}
        key="between-operand-0"
        size="small"
        variant="outlined"
        onChange={(e) => handleTextChange(0, e.target.value)}
        onBlur={handleOnBlur}
        value={startText}
        error={!!errors[0]}
        helperText={errors[0]}
      />
      <span style={{ marginTop: '6px' }}>{ t(lang, 'search.and')}</span>
      <TextField
        inputProps={{ style: { height: '14px' } }}
        style={{ margin: 0 }}
        id={uuid()}
        key="between-operand-1"
        size="small"
        variant="outlined"
        onChange={(e) => handleTextChange(1, e.target.value)}
        onBlur={handleOnBlur}
        value={endText}
        error={!!errors[1]}
        helperText={errors[1]}
      />
    </>
  );
};

export default OperandBetweenText;
