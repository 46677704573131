import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField, Tooltip } from '@material-ui/core';
import { PresetItem } from 'src/models/PresetModel';
import { State } from 'src/components/DatagridToolbar/PresetToolbar';
import PeopleIcon from '@material-ui/icons/People';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '420px',
    marginTop: '-8px',
    marginBottom: '-4px',
  },
  icon: {
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  iconShared: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface Props {
  onChangeState: (state: State) => void;
  onChangePreset: (preset: PresetItem) => void;
  preset: PresetItem;
}

const PresetEdit = (props: Props) => {
  const classes = useStyles();

  const {
    onChangeState, onChangePreset, preset,
  } = props;

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const onChange = (value: string) => {
    onChangePreset({
      ...preset,
      name: value,
    });
  };

  const toggleDefault = () => {
    onChangePreset({
      ...preset,
      is_default: !preset.is_default,
    });
  };

  const toggleShared = () => {
    onChangePreset({
      ...preset,
      is_shared: !preset.is_shared,
    });
  };

  const displayStartAdornment = () => (
    <>
      <CloseIcon
        className={classes.icon}
        color="disabled"
        onClick={() => {
          onChangeState(State.load);
        }}
      />
      <Tooltip
        title={t(lang, 'menus.preset_default')}
        placement="top-start"
      >
        <DoneIcon
          className={classes.iconShared}
          color={preset?.is_default ? 'primary' : 'disabled'}
          onClick={() => toggleDefault()}
        />
      </Tooltip>
      <Tooltip
        title={t(lang, 'menus.preset_share')}
        placement="top-start"
      >
        <PeopleIcon
          className={classes.iconShared}
          style={{ marginRight: '4px' }}
          color={preset?.is_shared ? 'primary' : 'disabled'}
          onClick={() => toggleShared()}
        />
      </Tooltip>
    </>
  );

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      key="form-control-presets-edit"
    >
      <TextField
        onChange={(e) => onChange(e.target.value)}
        value={preset.name}
        variant="outlined"
        label="Edit preset"
        margin="dense"
        InputProps={{
          startAdornment: displayStartAdornment(),
        }}
      />
    </FormControl>
  );
};

export default PresetEdit;
