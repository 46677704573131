import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ButtonProps } from '@material-ui/core/Button/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
// import { t } from 'src/lib/language';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import {
  postDownloadSelectedEntitesAction,
} from 'src/redux/actions/querybuilderActions';
import { DELIVERY_TYPE, FORMAT, ORIENTATION } from 'src/models/DownloadModel';

interface OwnProps {
  taxReceipts: boolean;
}

type Props = OwnProps & ButtonProps;
const UserDownloadTaxReceiptButton = (props: Props) => {
  const dispatch = useAppDispatch();
  const { disabled, taxReceipts } = props;

  // const lang = useSelector((state: Store) => state.language.language);

  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.taxReceipts] || [],
  );

  const hasReceipts = useSelector(
    (state: Store) => {
      if (!selections.length) { return false; }
      return selections.reduce(
        (result: boolean, id: number) => result || state.payments[id].tax_receipt_issued,
      );
    },
  );

  const handleOpen = () => {
    dispatch(postDownloadSelectedEntitesAction(
      ENTITIES.taxReceipts,
      selections,
      {
        file: 'payment_receipt.pdf',
        orientation: ORIENTATION.portrait,
        format: FORMAT.pdf,
        delivery: DELIVERY_TYPE.download,
        paymentReceipt: taxReceipts ? 0 : 1,
      },
    ));
  };

  return (
    <>
      <Tooltip
        title="Download tax receipt"
        placement="top-start"
      >
        <span>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.download}
            onClick={handleOpen}
            disabled={disabled || !hasReceipts}
          >
            <GetAppIcon />
          </ActionButton>
        </span>
      </Tooltip>
    </>
  );
};

export default UserDownloadTaxReceiptButton;
