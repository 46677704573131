import { makeStyles } from '@material-ui/core/styles';
import { FundraisingStandardInfo } from 'src/models/FundraisingModel';
import { TABS } from 'src/components/Pages/AddFundraisingPage';
import { MouseEvent, useState } from 'react';
import Button from '@material-ui/core/Button';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { t } from 'src/lib/language';

const useStyles = makeStyles(() => ({
  subtitle: {
    marginTop: '8px',
    borderBottom: 'solid #888 1px',
    width: '480px',
  },
  list: {
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '0px',
  },
  item: {
    border: '1px solid #c8c8c8',
    borderRadius: '8px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px',
    width: '480px',
    backgroundColor: 'white',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
  },
}));

interface Props {
  onChange: (eventInfo: FundraisingStandardInfo) => void;
  onNextStep: (step:number) => void;
  setErrors: (tab:TABS, hasError: boolean) => void;
  initialState: FundraisingStandardInfo;
  setHasChanged: (hasChanged: boolean) => void;
}

const FundraisingStandardInfoForm = (props: Props) => {
  const classes = useStyles();
  const {
    onChange, onNextStep, initialState, setHasChanged,
  } = props;

  const [order, setOrder] = useState(initialState.order);

  const lang = useSelector((state: Store) => state.language.language);

  const onDragEnd = (dropResult: any) => {
    const { destination, source } = dropResult;
    // dropped outside the list
    if (!destination) return;
    setHasChanged(true);
    const newOrder = Array.from(order);
    const [removed] = newOrder.splice(source.index, 1);
    newOrder.splice(destination.index, 0, removed);
    setOrder(newOrder);
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const standardInfoState = {
      order,
    } as FundraisingStandardInfo;
    onNextStep(5);
    onChange(standardInfoState);
  };

  return (
    <>
      <div className={classes.subtitle}>
        {t(lang, 'forms.fundraising.order')}
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {
            (provided: any) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={classes.list}
              >
                {order.map((item, index) => (
                  <Draggable
                    key={`item-${index}`}
                    draggableId={`${index}`}
                    index={index}
                  >
                    {(providedDraggable: any) => (
                      <li
                        className={classes.item}
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                      >
                        {item}
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )
          }
        </Droppable>
      </DragDropContext>
      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          key="submit-button"
        >
          {t(lang, 'forms.fundraising.next_step')}
        </Button>
      </div>
    </>
  );
};

export default FundraisingStandardInfoForm;
