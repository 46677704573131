import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField, Tooltip } from '@material-ui/core';
import { PRESET_TYPES, PresetItem } from 'src/models/PresetModel';
import { State } from 'src/components/DatagridToolbar/PresetToolbar';
import PeopleIcon from '@material-ui/icons/People';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { useState } from 'react';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { FORM, FORM_STATUS } from 'src/constants/Form';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '420px',
    marginTop: '-8px',
    marginBottom: '-4px',
  },
  icon: {
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  iconShared: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface Props {
  onChangePreset: (preset: PresetItem) => void;
  onChangeState: (state: State) => void;
  type: PRESET_TYPES;
}

const PresetAdd = (props: Props) => {
  const classes = useStyles();

  const {
    onChangeState, onChangePreset, type,
  } = props;

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const [preset, setPreset] = useState({} as PresetItem);

  const processing = useSelector(
    (state: Store) => state.formStatus[FORM[`manage_${type}`]] === FORM_STATUS.processing,
  );

  const onChange = (value: string) => {
    const newPreset = {
      ...preset,
      name: value,
    };
    onChangePreset(newPreset);
    setPreset(newPreset);
  };

  const toggleDefault = (e: any) => {
    e.preventDefault();
    if (processing) return;
    const newPreset = {
      ...preset,
      is_default: !preset.is_default,
    };
    onChangePreset(newPreset);
    setPreset(newPreset);
  };

  const toggleShared = (e: any) => {
    e.preventDefault();
    if (processing) return;
    const newPreset = {
      ...preset,
      is_shared: !preset.is_shared,
    };
    onChangePreset(newPreset);
    setPreset(newPreset);
  };

  const displayStartAdornment = () => (
    <>
      <CloseIcon
        className={classes.icon}
        color="disabled"
        onClick={() => {
          onChangeState(State.load);
        }}
      />
      <Tooltip
        title={t(lang, 'menus.preset_default')}
        placement="top-start"
      >
        <DoneIcon
          className={classes.iconShared}
          color={preset?.is_default ? 'primary' : 'disabled'}
          onClick={(e) => toggleDefault(e)}
        />
      </Tooltip>
      <Tooltip
        title={t(lang, 'menus.preset_share')}
        placement="top-start"
      >
        <PeopleIcon
          className={classes.iconShared}
          style={{ marginRight: '4px' }}
          color={preset?.is_shared ? 'primary' : 'disabled'}
          onClick={(e) => toggleShared(e)}
        />
      </Tooltip>
    </>
  );

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      key="form-control-presets-edit"
    >
      <TextField
        onChange={(e) => onChange(e.target.value)}
        value={preset.name}
        variant="outlined"
        label={t(lang, 'misc.add_preset')}
        margin="dense"
        InputProps={{
          startAdornment: displayStartAdornment(),
        }}
      />
    </FormControl>
  );
};
//
export default PresetAdd;
