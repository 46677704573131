import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { useEffect, useState } from 'react';
import { t } from 'src/lib/language';
import { TextField, Tooltip } from '@material-ui/core';
import { Team } from 'src/models/FundraisingModel';
import {
  getTeamListAction,
  postTeamAction,
  putTeamAction,
} from 'src/redux/actions/fundraisingAction';
import EditTeamForm from 'src/components/Forms/EditTeamForm';

const useStyles = makeStyles((theme) => ({
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '56px',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
  },
  scroller: {
    overflowY: 'auto',
    height: '62vh',
    padding: '8px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
  },
  search: {
    height: '6vh',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
}));

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const ManageTeams = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTeamListAction());
  }, []);

  const [currentTeam, setCurrentTeam] = useState({} as Team);
  const [currentState, setCurrentState] = useState(STATE.list);
  const [currentFilter, setCurrentFilter] = useState('');

  const lang = useSelector((state: Store) => state.language.language);
  const teams = useSelector((state: Store) => state.teams);

  const handleDelete = (e:any, team: Team) => {
    e.preventDefault();
    dispatch(openDialog({ dialog: DIALOGS.deleteGroup, payload: team }));
  };

  const handleEdit = (team: Team) => {
    setCurrentTeam(team);
    setCurrentState(STATE.edit);
  };

  const handleAdd = () => {
    setCurrentTeam({} as Team);
    setCurrentState(STATE.add);
  };

  const getTitle = () => {
    switch (currentState) {
      case STATE.add:
        return t(lang, 'menus.add_team');
      case STATE.edit:
        return t(lang, 'menus.edit_team');
      default:
        return t(lang, 'menus.list_team');
    }
  };

  const addTeam = (team: Team) => {
    dispatch(postTeamAction(team));
  };

  const editTeam = (team: Team) => {
    if (team.id) {
      dispatch(putTeamAction(team.id, team));
    }
  };

  const getRowStyle = (index: number) => {
    const style = {
      padding: '0 8px',
    } as any;
    if (!index) {
      style.borderTop = '1px solid rgba(0, 0, 0, 0.25)';
    }
    if (index % 2) {
      style.backgroundColor = '#eee';
    }
    return style;
  };

  const displayTeam = () => teams.map((team: Team, index: number) => {
    if (currentFilter) {
      if (!team.name) return null;
      if (!team.name.toLowerCase().includes(currentFilter)) return null;
    }
    return (
      <div
        className={classes.item}
        key={`team-${index}`}
        style={getRowStyle(index)}
      >
        <span><b>{team.name}</b></span>
        <Tooltip
          title={t(lang, 'menus.edit_teams')}
          placement="top-start"
        >
          <span style={{ marginLeft: 'auto' }}>
            <ActionButton
              variant="outlined"
              category={MENU_BUTTON_CATEGORY.action}
              onClick={() => handleEdit(team)}
            >
              <EditIcon />
            </ActionButton>
          </span>
        </Tooltip>
        <Tooltip
          title={t(lang, 'menus.delete_teams')}
          placement="top-start"
        >
          <span style={{ marginLeft: '8px' }}>
            <ActionButton
              variant="outlined"
              category={MENU_BUTTON_CATEGORY.action}
              onClick={(e) => handleDelete(e, team)}
            >
              <DeleteIcon />
            </ActionButton>
          </span>
        </Tooltip>
      </div>
    );
  });

  const displayEditForm = () => (
    <EditTeamForm
      team={currentTeam}
      onClose={() => setCurrentState(STATE.list)}
      onSave={(team) => editTeam(team)}
    />
  );

  const displayAddForm = () => (
    <EditTeamForm
      team={currentTeam}
      onClose={() => setCurrentState(STATE.list)}
      onSave={(team) => addTeam(team)}
    />
  );

  return (
    <>
      <div className={classes.header}>
        <div className={classes.row}>
          <div className={classes.title}><b>{ getTitle() }</b></div>
        </div>
      </div>
      { currentState === STATE.list && (
      <div className={classes.search}>
        <Tooltip
          title={t(lang, 'menus.add_teams')}
          placement="top-start"
        >
          <span>
            <ActionButton
              category={MENU_BUTTON_CATEGORY.action}
              onClick={handleAdd}
            >
              <AddIcon />
            </ActionButton>
          </span>
        </Tooltip>
        <TextField
          margin="dense"
          variant="outlined"
          label={t(lang, 'menus.filter_teams')}
          onChange={(e) => setCurrentFilter(e.target.value)}
        />
      </div>
      )}
      <div className={classes.scroller}>
        { currentState === STATE.list && displayTeam() }
        { currentState === STATE.add && displayAddForm() }
        { currentState === STATE.edit && displayEditForm() }
      </div>
    </>
  );
};

export default ManageTeams;
