import { ENTITIES } from 'src/models/QuerybuilderModel';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Store } from 'src/redux/Store';
import AddInvoiceButton from 'src/components/DatagridToolbar/buttons/AddInvoiceButton';
import ViewInvoiceButton from 'src/components/DatagridToolbar/buttons/ViewInvoiceButton';
import EditInvoiceButton from 'src/components/DatagridToolbar/buttons/EditInvoiceButton';
import ExportInvoiceButton from 'src/components/DatagridToolbar/buttons/ExportInvoiceButton';
import DownloadInvoiceButton from 'src/components/DatagridToolbar/buttons/DownloadInvoiceButton';
import UnselectAllMenubutton from 'src/components/DatagridToolbar/buttons/UnselectAllMenuButton';
import MakePaymentButton from 'src/components/DatagridToolbar/buttons/MakePaymentButton';
import ConfigureColumnsButton from 'src/components/DatagridToolbar/buttons/ConfigureColumnsButton';
import ShowPaymentsForInvoiceButton
  from 'src/components/DatagridToolbar/buttons/ShowPaymentsForInvoiceButton';
import { keyBy } from 'lodash';
import { INVOICE_PAYMENT_STATUS, INVOICE_STATE } from 'src/constants/Invoices';
import DeleteInvoiceButton from 'src/components/DatagridToolbar/buttons/DeleteInvoiceButton';

const InvoiceToolbar = () => {
  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.invoices] || [],
  );
  const invoices = useSelector(
    (state: Store) => (
      (!!state.querybuilder.invoices && !!state.querybuilder.invoices.result)
        ? keyBy(state.querybuilder.invoices.result.list, 'invoices.id') : []
    ),
  );
  const resultCount = useSelector(
    (state: Store) => (
      (!!state.querybuilder.invoices && !!state.querybuilder.invoices.result)
        ? state.querybuilder.invoices.result.count : 0
    ),
  );

  const [disablePaymentState, setDisablePaymentState] = useState(false);

  useEffect(() => {
    let shouldDisable = false;

    if (!selections.length) {
      setDisablePaymentState(true);
      return;
    }

    selections.forEach((invoiceId: number) => {
      if (!!invoices[invoiceId] && !!invoices[invoiceId]['invoices.payment_status']) {
        if (invoices[invoiceId]['invoices.payment_status'] === INVOICE_PAYMENT_STATUS.paid) {
          shouldDisable = true;
        }
        if (invoices[invoiceId]['invoices.state'] === INVOICE_STATE.draft) {
          shouldDisable = true;
        }
      }

      setDisablePaymentState(shouldDisable);
    });
  }, [selections]);

  return (
    <>
      <DeleteInvoiceButton
        disabled={!selections.length || selections.length > 1}
        invoiceId={selections[0]}
      />
      <AddInvoiceButton />
      <EditInvoiceButton
        disabled={!selections.length}
      />
      <ViewInvoiceButton
        disabled={!selections.length}
      />
      <MakePaymentButton
        disabled={disablePaymentState}
      />
      <ConfigureColumnsButton
        entity={ENTITIES.invoices}
      />
      <UnselectAllMenubutton
        entity={ENTITIES.invoices}
        disabled={!selections.length}
      />
      <ShowPaymentsForInvoiceButton
        invoiceId={selections[0]}
        disabled={(!selections.length || selections.length > 1)}
      />
      <ExportInvoiceButton disabled={resultCount > 1000 || resultCount === 0} />
      <DownloadInvoiceButton />
    </>
  );
};
export default InvoiceToolbar;
