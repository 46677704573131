/* eslint-disable import/no-extraneous-dependencies */
import {
  MenuButtonBlockquote,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonEditLink,
  MenuButtonHighlightColor,
  MenuButtonHorizontalRule,
  MenuButtonImageUpload,
  MenuButtonIndent,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonRemoveFormatting,
  MenuButtonStrikethrough,
  MenuButtonTaskList,
  MenuButtonTextColor,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonUnindent,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectFontFamily,
  MenuSelectFontSize,
  MenuSelectHeading,
  MenuSelectTextAlign,
  isTouchDevice,
} from 'mui-tiptap';
import { useTheme } from '@mui/material';
import { uploadFileAction } from 'src/redux/actions/uploadFileAction';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { getS3URL } from 'src/lib/S3File';

const NotificationEmailToolbar = () => {
  const classes = useTheme();
  const dispatch = useAppDispatch();

  const organisation = useSelector((state: Store) => state.currentOrganisation.id);

  const getFileAsBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
    fileReader.readAsDataURL(file);
  });

  const getImageProps = (path: string) => ({
    src: path,
  });

  const handleUpload = (files: File[]) => Promise.all(
    files.map(async (file) => {
      const path = `${getS3URL()}${organisation}/images/${file.name}`;
      const base64Data = await getFileAsBase64(file);
      const data = base64Data.split(',');
      data.shift();
      const result = await dispatch(uploadFileAction(data.join(','), path, 'Success', 'Error'));
      if (result) {
        return getImageProps(path);
      }
      return null;
    }),
  ).then((results) => results.filter(Boolean));

  return (
    <MenuControlsContainer>
      <MenuSelectFontFamily
        options={[
          { label: 'Comic Sans', value: 'Comic Sans MS, Comic Sans' },
          { label: 'Cursive', value: 'cursive' },
          { label: 'Monospace', value: 'monospace' },
          { label: 'Serif', value: 'serif' },
        ]}
      />
      <MenuDivider />
      <MenuSelectHeading />
      <MenuDivider />
      <MenuSelectFontSize />
      <MenuDivider />
      <MenuButtonBold />
      <MenuButtonItalic />
      <MenuButtonUnderline />
      <MenuButtonStrikethrough />
      <MenuDivider />
      <MenuButtonTextColor
        defaultTextColor={classes.palette.text.primary}
        swatchColors={[
          { value: '#000000', label: 'Black' },
          { value: '#ffffff', label: 'White' },
          { value: '#888888', label: 'Grey' },
          { value: '#ff0000', label: 'Red' },
          { value: '#ff9900', label: 'Orange' },
          { value: '#ffff00', label: 'Yellow' },
          { value: '#00d000', label: 'Green' },
          { value: '#0000ff', label: 'Blue' },
        ]}
      />
      <MenuButtonHighlightColor
        swatchColors={[
          { value: '#595959', label: 'Dark grey' },
          { value: '#dddddd', label: 'Light grey' },
          { value: '#ffa6a6', label: 'Light red' },
          { value: '#ffd699', label: 'Light orange' },
          // Plain yellow matches the browser default `mark` like when using Cmd+Shift+H
          { value: '#ffff00', label: 'Yellow' },
          { value: '#99cc99', label: 'Light green' },
          { value: '#90c6ff', label: 'Light blue' },
          { value: '#8085e9', label: 'Light purple' },
        ]}
      />
      <MenuDivider />
      <MenuButtonEditLink />
      <MenuDivider />
      <MenuSelectTextAlign />
      <MenuDivider />
      <MenuButtonOrderedList />
      <MenuButtonBulletedList />
      <MenuButtonTaskList />
      {
        // On touch devices, we'll show indent/unindent buttons, since they're
        // unlikely to have a keyboard that will allow for using Tab/Shift+Tab. These
        // buttons probably aren't necessary for keyboard users and would add extra
        // clutter.
      }
      {isTouchDevice() && (
        <>
          <MenuButtonIndent />
          <MenuButtonUnindent />
        </>
      )}
      <MenuDivider />
      <MenuButtonBlockquote />
      <MenuDivider />
      <MenuButtonImageUpload
        multiple={false}
        onUploadFiles={handleUpload}
      />
      <MenuDivider />
      <MenuButtonHorizontalRule />
      <MenuDivider />
      <MenuButtonRemoveFormatting />
      <MenuDivider />
      <MenuButtonUndo />
      <MenuButtonRedo />
    </MenuControlsContainer>
  );
};

export default NotificationEmailToolbar;
