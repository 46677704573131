import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { FORM } from 'src/constants/Form';
import { t } from 'src/lib/language';
import { MouseEvent, useState } from 'react';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import { setError } from 'src/redux/actions/errorsActions';
import { BankAccountPayload } from 'src/models/BankAccountModel';
import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,
} from '@material-ui/core';
import BankAccountValidator from 'src/validations/BankAccountValidator';
import { updateBankAccountAction } from 'src/redux/actions/paymentInfoAction';
import { BANK_ACCOUNT_COUNTRY, BANK_ACCOUNT_TYPE } from 'src/constants/BankAccount';
import CurrenciesSelector from 'src/components/Control/CurrenciesSelector';

const useStyles = makeStyles(() => ({
  topRow: {
    display: 'flex',
    alignItems: 'center',
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
  },
  close: {
    padding: 5,
    minHeight: 0,
    minWidth: 0,
    float: 'right',
    '&> span': {
      lineHeight: 0.75,
    },
  },
  submit: {
    marginTop: '12px',
  },
  update: {
    marginTop: '12px',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '10px',
  },
}));

interface Props {
  account: BankAccountPayload;
  contactId: number,
  onClose?: () => void;
}

const EditContactBankAccountForm = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const { account, contactId, onClose } = props;

  const [state, setState] = useState({
    id: undefined,
    contact_id: contactId,
    ...account,
  } as BankAccountPayload);

  const onChange = (value: Value, field: string) => {
    if (!!value) {
      switch (field) {
        case 'account':
          if (Number.isNaN(Number(value))) return;
          if (String(value).length > 12) return;
          break;
        case 'bank':
          if (Number.isNaN(Number(value))) return;
          if (String(value).length > 3) return;
          break;
        case 'branch':
          if (Number.isNaN(Number(value))) return;
          if (String(value).length > 5) return;
          break;
        case 'bank_country':
          if (value === BANK_ACCOUNT_COUNTRY.ca) {
            setState({
              ...state,
              currency_id: 19,
              [field]: value,
            });
            return;
          }
          if (value === BANK_ACCOUNT_COUNTRY.us) {
            setState({
              ...state,
              currency_id: 96,
              [field]: value,
            });
            return;
          }
          break;
        default:
      }
    }
    setState({
      ...state,
      [field]: value,
    });
  };

  const onCurrencyChange = (id: number) => {
    setState({
      ...state,
      currency_id: id,
    });
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setError({ [FORM.add_organisation_bankaccount]: {} }));
    try {
      BankAccountValidator(lang).validateSync(
        state,
        { abortEarly: false },
      );
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: t(lang, 'forms.bankaccounts.validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.add_organisation_bankaccount]: errorBag }));
      return;
    }

    dispatch(updateBankAccountAction(account.id ?? 0, state));
  };

  const getBranchRouting = () => (
    state.bank_country === BANK_ACCOUNT_COUNTRY.ca
      ? 'forms.bankaccounts.branch'
      : 'forms.bankaccounts.routing'
  );

  return (
    <>
      <FormTextField
        form={FORM.add_bankaccount}
        label={t(lang, 'forms.bankaccounts.bank_name')}
        name="bank_name"
        onChange={onChange}
        required
        value={state.bank_name || ''}
      />
      <FormTextField
        form={FORM.add_bankaccount}
        label={t(lang, 'forms.bankaccounts.name')}
        name="bank_holder_name"
        onChange={onChange}
        required
        value={state.bank_holder_name || ''}
      />
      <div className={classes.row}>
        <div style={{ width: '30%' }}>
          <FormTextField
            form={FORM.add_bankaccount}
            label={t(lang, 'forms.bankaccounts.account')}
            name="account"
            onChange={onChange}
            value={state.account || ''}
          />
        </div>
        <div style={{ width: '30%' }}>
          <FormTextField
            form={FORM.add_bankaccount}
            label={t(lang, getBranchRouting())}
            name="branch"
            onChange={onChange}
            required
            value={state.branch || ''}
          />
        </div>
        { state.bank_country === BANK_ACCOUNT_COUNTRY.ca && (
          <div style={{ width: '15%' }}>
            <FormTextField
              form={FORM.add_bankaccount}
              label={t(lang, 'forms.bankaccounts.bank')}
              name="bank"
              onChange={onChange}
              required
              value={state.bank || ''}
            />
          </div>
        )}
      </div>
      <div className={classes.row}>
        <div style={{ width: '30%' }}>
          <div style={{ width: '50%' }}>
            <CurrenciesSelector
              form={FORM.add_bankaccount}
              onChange={(id: number) => onCurrencyChange(id)}
              value={state.currency_id || 0}
              name="currency_id"
            />
          </div>
        </div>
        { state.bank_country === BANK_ACCOUNT_COUNTRY.us && (
          <div style={{ width: '30%' }}>
            <FormControl component="fieldset" style={{ marginTop: '8px' }}>
              <FormLabel component="legend">{t(lang, 'forms.bankaccounts.type')}</FormLabel>
              <RadioGroup
                row
                aria-label="bank_account_type"
                name="bank_account_type"
                value={state.bank_account_type || BANK_ACCOUNT_TYPE.saving}
                onChange={(e) => onChange(e.target.value, 'bank_account_type')}
              >
                <FormControlLabel
                  value={BANK_ACCOUNT_TYPE.saving}
                  control={<Radio />}
                  label="Personnal Saving"
                />
                <FormControlLabel
                  value={BANK_ACCOUNT_TYPE.checking}
                  control={<Radio />}
                  label="Personnal Chequing"
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}
      </div>
      <div className={classes.row}>
        <div style={{ width: '50%' }}>
          <FormControl component="fieldset" style={{ marginTop: '8px' }}>
            <FormLabel component="legend">Account type</FormLabel>
            <RadioGroup
              row
              aria-label="bank_country"
              name="bank_country"
              value={state.bank_country || BANK_ACCOUNT_COUNTRY.ca}
              onChange={(e) => onChange(e.target.value, 'bank_country')}
            >
              <FormControlLabel value={BANK_ACCOUNT_COUNTRY.ca} control={<Radio />} label="Canadian" />
              <FormControlLabel value={BANK_ACCOUNT_COUNTRY.us} control={<Radio />} label="US" />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        >
          {t(lang, 'misc.save')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={onClose}
          key={`submit-button-group-${state.id}`}
        >
          {t(lang, 'misc.close')}
        </Button>
      </div>
    </>
  );
};

export default EditContactBankAccountForm;
