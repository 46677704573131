import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import UserEditPaymentForm from 'src/components/Forms/UserEditPaymentForm';

const UserEditPaymentModal = () => (
  <ModalBase
    name={MODALS.editUserPayement}
    size={MODALS_SIZE.small}
  >
    <UserEditPaymentForm />
  </ModalBase>
);

export default UserEditPaymentModal;
