import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ErrorBag } from 'src/models/ErrorModel';
import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'src/lib/language';
import { FORM_ID } from 'src/models/FormModel';
import { isEmpty } from 'lodash';
import { getOrganisationBankAccountAction } from 'src/redux/actions/paymentInfoAction';
import { CSSProperties, useEffect } from 'react';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '480px',
    width: '100%',
    marginTop: '16px',
    marginBottom: '8px',
  },
}));

interface Props {
  disabled?: boolean
  form: FORM_ID,
  name: string,
  onChange: (id:number) => void,
  value?: number,
  style?: CSSProperties,
}

interface BankAccountItem {
  id: number,
  label?: string,
}

const OrganisationBankAccountSelector = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    disabled, form, name, onChange, value, style,
  } = props;

  const organisationId = useSelector((state: Store) => state.currentOrganisation.id);
  const bankAccounts = useSelector((state: Store) => state.organisationBankAccounts);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const errors: ErrorBag =
    useSelector((state:Store) => state.errors[form] || {} as ErrorBag);

  useEffect(() => {
    dispatch(getOrganisationBankAccountAction());
  }, []);

  const getBankAccountItems = () => {
    const items:BankAccountItem[] = [{ id: 0, label: undefined }];
    if (isEmpty(bankAccounts)) {
      return items;
    }

    const accounts = bankAccounts[organisationId] ?? [];

    accounts.forEach((item: any) => {
      items.push({
        id: item.id,
        label: `${item.bank_holder_name} #${item.account}`,
      });
    });
    return items;
  };

  const onValueChange = (item:BankAccountItem) => {
    if (!item || !item.id) {
      onChange(0);
      return null;
    }
    onChange(item.id as number);
    return null;
  };

  const getValue = (initialValue?: number) => {
    let result = {
      id: 0,
      label: undefined,
    } as BankAccountItem;
    if (isEmpty(bankAccounts) || !initialValue) {
      return result;
    }

    const accounts = bankAccounts[organisationId] ?? [];

    accounts.forEach((item: any) => {
      if (item.id === initialValue) {
        result = {
          id: item.id,
          label: `${item.bank_holder_name} #${item.account}`,
        } as BankAccountItem;
      }
    });
    return result;
  };

  const getOptionLabel = (option: BankAccountItem) => option.label || '';

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      key={`form-control-${name}`}
      style={style}
    >
      <Autocomplete
        disabled={disabled}
        value={getValue(value)}
        onChange={(e, item) => onValueChange(item as BankAccountItem)}
        options={getBankAccountItems() || []}
        getOptionLabel={(option: BankAccountItem) => getOptionLabel(option)}
        getOptionSelected={(option, item) => option.id === item.id}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={t(lang, 'forms.payments.organisation_bank_accounts')}
            error={!!errors[name]}
            helperText={errors[name] && errors[name].join(',')}
          />
        )}
        key={`autocomplete-${name}`}
      />
    </FormControl>
  );
};

export default OrganisationBankAccountSelector;
