import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { MODALS } from 'src/models/ModalModel';
import { t } from 'src/lib/language';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { useState } from 'react';
import TicketIdentifierItem from 'src/components/Elements/TicketIdentifierItem';
import { setError } from 'src/redux/actions/errorsActions';
import { FORM } from 'src/constants/Form';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '48px',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  noMargin: {
    height: '32px',
    marginBottom: '0 !important',
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
  },
  item: {
    display: 'flex',
    gap: '8px',
  },
  scroller: {
    overflowY: 'auto',
    height: '72vh',
    padding: '8px',
  },
}));

export interface RowIdentifiers {
  name: string,
  start: string,
  end: string
}

const TicketIdentifier = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const [onChange, identifierValues] = useSelector(
    (state: Store) => state.modals[MODALS.ticketIdentifiers]?.payload ?? [null, null],
  );

  const getIdentifiers = (identifiersString: string) => {
    if (!identifiersString) return [] as RowIdentifiers[];
    const rows = identifiersString.split(',');
    return rows.reduce((result, row: string) => {
      const [name, start, end] = row.split(':');
      result.push(
        {
          name,
          start,
          end,
        },
      );
      return result;
    }, [] as RowIdentifiers[]);
  };

  const [identifiers, setIdentifiers] = useState(getIdentifiers(identifierValues));

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.ticketIdentifiers }));
  };

  const handleSave = () => {
    const errors = {};
    let hasError = false;

    identifiers.forEach((identifier, index) => {
      if (!identifier.name) {
        errors[`name_${index}`] = ['required'];
        hasError = true;
      }
      if (!identifier.start) {
        errors[`start_${index}`] = ['required'];
        hasError = true;
      }
      if (!identifier.end) {
        errors[`end_${index}`] = ['required'];
        hasError = true;
      }
    });

    if (hasError) {
      dispatch(setError({ [FORM.identifiers]: errors }));
      return;
    }

    const value = identifiers.reduce((result: string[], identifier) => {
      result.push(`${identifier.name}:${identifier.start}:${identifier.end}`);
      return result;
    }, [] as string[]).join(',');
    onChange(value);
  };

  const onDelete = (index:number) => {
    const result = [...identifiers];
    result.splice(index, 1);
    setIdentifiers(result);
  };

  const onUpdate = (index: number, field: string, value: any) => {
    dispatch(setError({ [FORM.identifiers]: null }));
    const result = [...identifiers];
    if (!result[index]) {
      result[index] = {} as RowIdentifiers;
    }
    result[index][field] = value;
    setIdentifiers(result);
  };

  const displayItems = () => {
    let index = 0;
    const items = identifiers.map(() => {
      const item = (
        <TicketIdentifierItem
          index={index}
          identifier={identifiers[index]}
          onDelete={onDelete}
          onUpdate={onUpdate}
        />
      );
      index += 1;
      return item;
    });
    items.push(
      <TicketIdentifierItem
        index={index}
        identifier={{ name: '', start: '', end: '' }}
        onUpdate={onUpdate}
      />,
    );
    return items;
  };

  return (
    <>
      <div className={classes.header}>
        <div className={`${classes.row} ${classes.noMargin}`}>
          <div className={classes.title}><b>Identifiers</b></div>
          <div style={{ marginLeft: 'auto' }}>
            <Button
              style={{ marginRight: '8px' }}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSave}
            >
              {t(lang, 'misc.save')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleClose}
            >
              {t(lang, 'misc.close')}
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.scroller}>
        <div>
          {displayItems()}
        </div>
      </div>
    </>
  );
};

export default TicketIdentifier;
