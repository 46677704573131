import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { t } from 'src/lib/language';
import { MODALS_SIZE } from 'src/models/modal';
import { MouseEvent, useEffect, useState } from 'react';
import { FORM } from 'src/constants/Form';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { getPaymentAction, refundPaymentAction } from 'src/redux/actions/paymentActions';
import { compact, keys } from 'lodash';
import PaymentModel, { CancelPaymentItems, PaymentsModel } from 'src/models/PaymentModel';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { formatDatetime, getJSLocale } from 'src/lib/FieldHelper';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import { Moment } from 'moment';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: MODALS_SIZE.large,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
    marginTop: '10px',
  },
  close: {
    padding: 5,
    minHeight: 0,
    minWidth: 0,
    float: 'right',
    '&> span': {
      lineHeight: 0.75,
    },
  },
}));

const RefundPaymentForm = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const locale = useSelector((state: Store) => getJSLocale(state.language.locale));
  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.payments] || [],
  );

  const payments = useSelector(
    (state: Store) => selections.map(
      (id: number) => state.payments[id],
    ) ?? [] as PaymentsModel,
  );

  const [paymentState, setPaymentState] = useState({} as CancelPaymentItems);

  useEffect(() => {
    selections.forEach(
      (paymentId: number) => dispatch(getPaymentAction(paymentId as number)),
    );
  }, []);

  useEffect(() => {
    const paymentItems = {} as CancelPaymentItems;

    payments.forEach((payment: PaymentModel) => {
      if (payment) {
        paymentItems[payment.id] = {
          payment_id: payment.id,
          refund_date: moment(),
          payment_date: payment.paid_date,
        };
      }
    });
    setPaymentState({
      ...paymentItems,
    });
  }, [compact(payments).length]);

  const onFieldChange = (id: number, date: MaterialUiPickersDate) => {
    if (!date) return;
    setPaymentState({
      ...paymentState,
      [id]: {
        payment_id: id,
        refund_date: date,
      },
    });
  };

  const handleClose = () => {
    dispatch(closeDialog({ dialog: DIALOGS.refundPayments }));
  };

  const getPaymentDate = (date?: Moment) => {
    if (!!date) {
      if (moment.isMoment(date)) {
        return date.toDate();
      }
      return moment(date).toDate();
    }
    return '';
  };

  const displayPayments = () => Object.values(paymentState).map(
    (currentPayment, index) => ((currentPayment)
      ? (
        <div className={classes.row} key={`item-${index}`}>
          <div style={{ minWidth: '64px', paddingTop: '24px' }}>
            <i>{currentPayment.payment_id}</i>
          </div>
          <div style={{ minWidth: '96px', paddingTop: '24px' }}>
            {formatDatetime(getPaymentDate(currentPayment.payment_date), locale)}
          </div>
          <FormDatePicker
            form={FORM.edit_invoice}
            minDate={new Date()}
            label={t(lang, 'forms.payments.refund_date')}
            name={`refund_date_${currentPayment.payment_id}`}
            onChange={
              (date: MaterialUiPickersDate) => onFieldChange(
                currentPayment.payment_id, date,
              )
            }
            value={currentPayment.refund_date || moment()}
          />
        </div>
      )
      : <></>),
  );

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    keys(paymentState).forEach(
      (paymentId: string) => dispatch(refundPaymentAction(parseInt(paymentId, 10))),
    );
    return true;
  };

  return (
    <form className={classes.form} noValidate>
      <div className={classes.row}>
        <div style={{ minWidth: '64px' }}>
          <b>Id</b>
        </div>
        <div style={{ minWidth: '96px' }}>
          <b>Payment Date</b>
        </div>
        <div style={{ width: '100%' }}>
          <b>Refund Date</b>
        </div>
      </div>
      {displayPayments()}
      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleClose}
        >
          {t(lang, 'misc.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        >
          {t(lang, 'misc.refund_payments')}
        </Button>
      </div>
    </form>
  );
};

export default RefundPaymentForm;
