import { MODALS } from 'src/models/ModalModel';
import { MODALS_SIZE } from 'src/models/modal';
import ModalBase from 'src/components/Modals/ModalBase';
import InvoiceForContactDatagrid from 'src/components/EntityDatagrids/InvoiceForContactDatagrid';

const InvoicesForContactModal = () => (
  <ModalBase
    name={MODALS.invoicesForContact}
    size={MODALS_SIZE.large}
  >
    <InvoiceForContactDatagrid />
  </ModalBase>
);

export default InvoicesForContactModal;
