import { useAppDispatch } from 'src/redux/Store';
import { socialLoginAction } from 'src/redux/actions/userActions';
import { SOCIAL_PROVIDER } from 'src/constants/Social';
import Button from '@material-ui/core/Button';
import { FcGoogle } from 'react-icons/fc';
import { useGoogleLogin } from '@react-oauth/google';

const GoogleLoginButton = () => {
  const dispatch = useAppDispatch();

  const googleLogin = useGoogleLogin({
    onSuccess: (response: any) => {
      dispatch(socialLoginAction(SOCIAL_PROVIDER.google, response.access_token));
    },
  });

  return (
    <Button
      onClick={() => googleLogin()}
    >
      <FcGoogle /> Sign with Google
    </Button>
  );
};

export default GoogleLoginButton;
