import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { t } from 'src/lib/language';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { MODALS } from 'src/models/ModalModel';
import { openModal } from 'src/redux/actions/modalActions';
import { InvoiceModel } from 'src/models/InvoiceModel';

interface OwnProps {
  invoice: InvoiceModel;
}

type Props = OwnProps & ButtonProps;

const DuplicateInvoiceButton = (props: Props) => {
  const { disabled, invoice } = props;
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language);
  const handleOnClick = () => {
    dispatch(openModal({ modal: MODALS.addInvoice, payload: invoice }));
  };

  return (
    <>
      <Tooltip
        title={t(lang, 'menus.duplicate_invoice')}
        placement="top-start"
      >
        <span>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.action}
            onClick={handleOnClick}
            disabled={disabled}
          >
            <FileCopyIcon />
          </ActionButton>
        </span>
      </Tooltip>
    </>
  );
};

export default DuplicateInvoiceButton;
