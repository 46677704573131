import { AUTOCOMPLETE_ID, AUTOCOMPLETE_TYPE } from 'src/constants/Autocomplete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce, TextField } from '@material-ui/core';
import {
  CSSProperties,
  useCallback,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { getAutoCompleteAction } from 'src/redux/actions/autoCompleteActions';
import { ContactOption, ContactOptions } from 'src/models/AutocompleteModel';
import { makeStyles } from '@material-ui/core/styles';
import { getContactByIdAction } from 'src/redux/actions/contactActions';
import { setSelections } from 'src/redux/actions/selectionActions';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { FORM_ID } from 'src/models/FormModel';
import { t } from 'src/lib/language';

interface Props {
  onChange: (id:number) => void,
  autocompleteId: AUTOCOMPLETE_ID,
  form?: FORM_ID,
  name?: string,
  error? : string;
  label?: string,
  contactId?: number,
  disabled?: boolean,
  margin?: 'dense' | 'normal' | undefined,
  hideEdit?: boolean,
  hideAdd?: boolean,
  style?: CSSProperties,
}

const useStyles = makeStyles(() => ({
  autocomplete: {
    minWidth: '600px',
  },
  root: {
    '& .MuiFilledInput-root': {
      background: 'rgb(255, 255, 255)',
    },
  },
  link: {
    color: '#0A4DF2',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    marginBottom: 0,
  },
  link_disabled: {
    color: '#454444',
    marginBottom: 0,
  },
  flexrow: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '5px',
  },
}));

const enum STATE {
  display = 'display',
  search = 'search',
}

const ContactSelector = (props: Props) => {
  const {
    autocompleteId,
    label,
    onChange,
    form,
    name,
    error,
    contactId,
    hideEdit,
    hideAdd,
    disabled,
    margin,
    style,
  } = props;
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const contactString = useSelector((state: Store) => {
    if (!contactId) return '';
    if (!!state.contacts[contactId]) {
      const { contact } = state.contacts[contactId];
      if (!contact) return '';
      const address = contact.billing_address ? `(${contact.billing_address})` : '';
      if (!!contact.company_name) {
        return `${contact.company_name} ${address}`;
      }
      return `${contact.first_name} ${contact.last_name} (${contact.billing_address})`;
    }
    dispatch(getContactByIdAction(contactId));
    return '';
  });

  const errors: string[] | null =
    useSelector((state:Store) => {
      if (form && name) {
        if (state.errors[form]) {
          return state.errors[form][name] as string[] || null;
        }
      }
      return null;
    });

  const [contactOptions, setContactOptions] = useState([] as ContactOptions);
  const [state, setState] = useState(STATE.display);

  const getContactOptions = async (search: string) => {
    const options = await dispatch(getAutoCompleteAction(
      autocompleteId,
      search,
      AUTOCOMPLETE_TYPE.contact,
    ));
    setContactOptions(options);
  };

  const searchContact = useCallback(
    debounce((search: string) => {
      getContactOptions(search);
    }, 300),
    [], // will be created only once initially
  );

  const onInputChange = (search: string) => {
    if (search.length < 3) { return; }
    searchContact(search);
  };

  const onValueChange = (value:any) => {
    if (disabled) { return; }
    setState(STATE.display);
    if (!value) {
      onChange(0);
      return;
    }
    dispatch(getContactByIdAction(value.id as number));
    onChange(value.id as number);
  };

  const onClickEdit = () => {
    if (disabled) { return; }
    dispatch(setSelections({
      entity: ENTITIES.contacts,
      selections: [contactId],
    }));
    dispatch(openModal({ modal: MODALS.editContacts }));
  };

  const onClickAdd = () => {
    if (disabled) { return; }
    dispatch(setSelections({
      entity: ENTITIES.contacts,
      selections: [contactId],
    }));
    dispatch(openModal({
      modal: MODALS.addContact,
      payload: {
        onChange,
        reload: false,
      },
    }));
  };

  const displayLabel = (option: ContactOption) => {
    if (!option?.id) return '';

    const {
      companyName, firstName, lastName, address,
    } = option;

    if (companyName && (!firstName && !lastName)) {
      return `${companyName} (${address || ''})`;
    }

    const namePart = firstName && lastName ? `${firstName} ${lastName}` : '';
    return `${namePart} (${address || ''})`;
  };

  const displaySearch = () => (
    <Autocomplete
      fullWidth
      style={style}
      forcePopupIcon={false}
      onChange={(e, value) => onValueChange(value)}
      onInputChange={(e, value) => onInputChange(value)}
      options={contactOptions || []}
      getOptionLabel={(option: ContactOption) => displayLabel(option)}
      getOptionSelected={(option, value) => option.id === value?.id}
      filterOptions={(x) => x}
      disabled={disabled}
      onBlur={() => setState(STATE.display)}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.root}
          autoFocus
          variant="outlined"
          label="Search contacts"
          placeholder="firstname, lastname or email"
          error={!!error || !!errors}
          margin={margin}
          onBlur={() => setState(STATE.display)}
        />
      )}
    />
  );

  const displayText = () => (
    <TextField
      disabled={disabled}
      variant="outlined"
      label={label}
      placeholder="firstname, lastname or email"
      error={!!error || !!errors}
      margin={margin}
      value={contactString}
      onClick={() => setState(STATE.search)}
      style={{ width: '100%' }}
    />
  );

  const displayComponent = () => (state === STATE.display ? displayText() : displaySearch());

  return (
    <div style={{ width: '100%' }}>
      <div>{displayComponent()}</div>
      <div className={classes.flexrow}>
        { !hideEdit && (
        <div
          className={disabled ? classes.link_disabled : classes.link}
          style={{ marginRight: '10px' }}
          onClick={() => onClickEdit()}
        >
          <i>{t(lang, 'menus.edit_contact')}</i>
        </div>
        )}
        { !hideAdd && (
          <div
            className={disabled ? classes.link_disabled : classes.link}
            onClick={() => onClickAdd()}
          >
            <i>{t(lang, 'menus.add_contact')}</i>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactSelector;
//
