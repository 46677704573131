import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import EditCustomfieldForm from 'src/components/Forms/EditCustomfieldForm';
import { Customfield } from 'src/models/CustomfieldModel';
import { useEffect, useState } from 'react';
import AddCustomfieldForm from 'src/components/Forms/AddCustomfieldForm';
import { t } from 'src/lib/language';
import { TextField, Tooltip } from '@material-ui/core';
import { getCustomFields } from 'src/redux/actions/customfieldActions';

const useStyles = makeStyles((theme) => ({
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '10vh',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
  },
  scroller: {
    overflowY: 'auto',
    height: '62vh',
    padding: '8px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
    padding: '0 8px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
  },
  search: {
    height: '6vh',
    display: 'flex',
    alignItems: 'center',
  },
}));

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const ManageCustomfields = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCustomFields());
  }, []);

  const customfieldsData = useSelector((state: Store) => state.customfields);
  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.manageCustomField }));
  };

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const [currentCustomField, setCurrentCustomField] = useState({} as Customfield);
  const [currentState, setCurrentState] = useState(STATE.list);
  const [currentFilter, setCurrentFilter] = useState('');

  const handleDelete = (e:any, id:number) => {
    e.preventDefault();
    dispatch(openDialog({ dialog: DIALOGS.deleteCustomfield, payload: id }));
  };

  const handleEdit = (customfield: Customfield) => {
    setCurrentCustomField(customfield);
    setCurrentState(STATE.edit);
  };

  const handleAdd = () => {
    setCurrentCustomField({} as Customfield);
    setCurrentState(STATE.add);
  };

  const getTitle = () => {
    switch (currentState) {
      case STATE.add:
        return t(lang, 'menus.add_customfield');
      case STATE.edit:
        return t(lang, 'menus.edit_customfield');
      default:
        return t(lang, 'menus.manage_customfield');
    }
  };

  const getStyle = (index: number) => {
    const style = {} as any;
    if (!index) {
      style.borderTop = '1px solid rgba(0, 0, 0, 0.25)';
    }
    if (index % 2) {
      style.backgroundColor = '#eee';
    }
    return style;
  };

  const displayCustomFields = () => Object.keys(customfieldsData)
    .map((key:string, index: number) => {
      const customfield = customfieldsData[key];
      if (currentFilter) {
        if (!customfield[`display_${lang}`].includes(currentFilter)) return null;
      }
      return (
        <div
          className={classes.item}
          key={`customfield-${key}`}
          style={getStyle(index)}
        >
          <span><b>{ customfield[`display_${lang}`] }</b></span>
          <Tooltip
            title={t(lang, 'menus.edit_customfield')}
            placement="top-start"
          >
            <span style={{ marginLeft: 'auto' }}>
              <ActionButton
                variant="outlined"
                category={MENU_BUTTON_CATEGORY.action}
                onClick={() => handleEdit(customfield)}
              >
                <EditIcon />
              </ActionButton>
            </span>
          </Tooltip>
          <Tooltip
            title={t(lang, 'menus.delete_customfield')}
            placement="top-start"
          >
            <span style={{ marginLeft: '8px' }}>
              <ActionButton
                variant="outlined"
                category={MENU_BUTTON_CATEGORY.action}
                onClick={(e) => handleDelete(e, customfield.id)}
              >
                <DeleteIcon />
              </ActionButton>
            </span>
          </Tooltip>
        </div>
      );
    });

  const displayEditForm = () => (
    <EditCustomfieldForm
      customfield={currentCustomField}
      onClose={() => setCurrentState(STATE.list)}
    />
  );

  const displayAddForm = () => (
    <AddCustomfieldForm
      onClose={() => setCurrentState(STATE.list)}
    />
  );

  return (
    <>
      <div className={classes.header}>
        <div className={classes.row}>
          <div className={classes.title}><b>{ getTitle() }</b></div>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
        { [STATE.list, STATE.edit].includes(currentState) && (
        <div className={classes.row}>
          <Tooltip
            title={t(lang, 'menus.add_customfield')}
            placement="top-start"
          >
            <span>
              <ActionButton
                category={MENU_BUTTON_CATEGORY.action}
                onClick={handleAdd}
              >
                <AddIcon />
              </ActionButton>
            </span>
          </Tooltip>
        </div>
        )}
      </div>
      { currentState === STATE.list && (
      <div className={classes.search}>
        <TextField
          margin="dense"
          variant="outlined"
          label="Filter"
          onChange={(e) => setCurrentFilter(e.target.value)}
        />
      </div>
      )}
      <div className={classes.scroller}>
        { currentState === STATE.list && displayCustomFields() }
        { currentState === STATE.add && displayAddForm() }
        { currentState === STATE.edit && displayEditForm() }
      </div>
    </>
  );
};

export default ManageCustomfields;
//
