import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { FormControl, TextField, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'src/lib/language';
import {
  ENTITIES,
  GridColumDefinition,
  GridColumns,
  QuerbuilderPayloadColumns,
} from 'src/models/QuerybuilderModel';
import { PRESET_TYPES, PresetItem } from 'src/models/PresetModel';
import { useEffect, useState } from 'react';
import { getPresetsAction, updatePresetAction } from 'src/redux/actions/presetActions';
import { createStyles } from '@material-ui/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import PeopleIcon from '@material-ui/icons/People';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { State } from 'src/components/DatagridToolbar/PresetToolbar';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import {
  getEntitiesAction,
  setColumns,
  setFilters,
  setOrders,
} from 'src/redux/actions/querybuilderActions';
import { resetSelections } from 'src/redux/actions/selectionActions';
import { setAdvancedSearch } from 'src/redux/actions/advancedSearchAction';
import { denormalizeFilters, getOperatorAndFilters } from 'src/lib/QueryBuilderHelper';
import { Filter, Filters } from 'src/models/AdvanceSearchModel';
import { FILTER_POSITION } from 'src/constants/FilterPosition';

const useStyles = makeStyles(() => ({
  option: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%',
    maxWidth: '480px',
    marginTop: '-8px',
    marginBottom: '-4px',
  },
  iconShared: {
    cursor: 'default',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const useTexfieldStyle = makeStyles((theme) => createStyles({
  root: {
    '& fieldset': {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
    '&:hover fieldset': {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
    '& label': {
      color: theme.palette.primary.main,
    },
    '&:hover label': {
      color: theme.palette.primary.main,
    },
    overflow: 'ellipsis',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.90),
      color: theme.palette.primary.main,
    },
    color: theme.palette.primary.main,
  },
  focused: {},
}));

const useLabelStyle = makeStyles((theme) => createStyles({
  root: {
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.90),
      color: theme.palette.primary.main,
    },
    color: theme.palette.primary.main,
  },
  focused: {},
}));

interface Props {
  entity: ENTITIES;
  type: PRESET_TYPES;
  onChangeState: (state: State) => void;
  onChangeCurrentID: (id:number) => void;
  currentID: number;
}

const PresetSelector = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    entity, type, onChangeState, onChangeCurrentID, currentID,
  } = props;

  const [open, setOpen] = useState(false);

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const presets = useSelector(
    (state: Store) => (state.presets[entity] && state.presets[entity][type]) || [],
  );

  const customfields = useSelector((state: Store) => state.customfields);

  useEffect(() => {
    dispatch(getPresetsAction({ entity }));
  }, [entity]);

  const preset = useSelector(
    (state: Store) => {
      if (currentID) {
        if ((state.presets[entity] && state.presets[entity][type])) {
          return state.presets[entity][type].filter((item:PresetItem) => item.id === currentID)[0];
        }
      }
      return {} as PresetItem;
    },
  );

  const purgeCustomfieldsFilter = (filters: Filter[]) => {
    const customfieldsFields = Object.keys(customfields);
    return filters.filter((filter: Filter) => {
      const [filterEntity, field] = filter[FILTER_POSITION.field].split('.');
      if (filterEntity === 'custom_fields') {
        return customfieldsFields.indexOf(field) >= 0;
      }
      return true;
    });
  };

  const purgeCustomfieldsPreset = (columns: GridColumns) => {
    const customfieldsFields = Object.keys(customfields);
    return columns.filter((column: GridColumDefinition) => {
      const [filterEntity, field] = column.field.split('.');
      if (filterEntity === 'custom_fields') {
        return customfieldsFields.indexOf(field) >= 0;
      }
      return true;
    });
    //
  };

  const getFilter = (filters: Filter[]) => {
    const newFilters = [] as Filter[];
    filters.forEach((filter) => {
      newFilters.push([...filter]);
    });
    return newFilters;
  };

  useEffect(() => {
    if (!currentID) { return; }
    if (!preset) { return; }
    if (preset.id === currentID) { return; }
    if (!preset.filter) return;
    switch (type) {
      // eslint-disable-next-line no-lone-blocks
      case PRESET_TYPES.filter: {
        const [operator, filterList] = getOperatorAndFilters(preset?.filter as Filters);
        const purgedFilters = purgeCustomfieldsFilter(filterList);
        const filtered = denormalizeFilters([...purgedFilters]);
        dispatch(setFilters({
          entity,
          filters: {
            [operator]: getFilter(filtered),
          },
        }));
        dispatch(setOrders({
          entity,
          order: preset?.order,
        }));
        dispatch(setAdvancedSearch({
          entity,
          filters: {
            [operator]: getFilter(filtered),
          },
          order: preset.order ?? [],
        }));
        dispatch(getEntitiesAction(entity));
      }
        break;
      case PRESET_TYPES.layout:
        dispatch(setColumns({
          entity,
          columns: purgeCustomfieldsPreset(preset?.filter),
        } as QuerbuilderPayloadColumns));
        dispatch(setOrders({
          entity,
          order: preset?.order,
        }));
        break;
      default:
    }
  }, [currentID]);
  //
  const processing = useSelector(
    (state: Store) => state.formStatus[FORM[`manage_${type}`]] === FORM_STATUS.processing,
  );

  const toggleDefault = (e:any) => {
    e.preventDefault();
    if (!currentID || processing) return;
    const { order, filter } = preset;
    dispatch(updatePresetAction(
      {
        ...preset,
        filter: {
          filter,
          order,
        },
        order: undefined,
        is_default: !preset.is_default,
      },
    ));
  };

  const toggleShared = (e:any) => {
    e.preventDefault();
    if (!currentID || processing) return;
    const { order, filter } = preset;
    dispatch(updatePresetAction(
      {
        ...preset,
        filter: {
          filter,
          order,
        },
        order: undefined,
        is_shared: !preset.is_shared,
      },
    ));
  };

  const getPresetItems = () => {
    const items:PresetItem[] = [];
    if (!presets.length) {
      return items;
    }
    presets.forEach((item: any) => {
      items.push(item);
    });
    return items;
  };

  const onValueChange = (e: any, item:PresetItem) => {
    if (!open) {
      onChangeState(State.add);
      return;
    }
    onChangeCurrentID(item?.id);
    switch (type) {
      case PRESET_TYPES.filter: {
        const [operator, filterList] = getOperatorAndFilters(item?.filter as Filters);
        const purgedFilters = purgeCustomfieldsFilter(filterList);
        dispatch(setFilters({
          entity,
          filters: {
            [operator]: denormalizeFilters([...purgedFilters]),
          },
        }));
        dispatch(setOrders({
          entity,
          order: preset?.order,
        }));
        dispatch(resetSelections({ entity }));
        dispatch(getEntitiesAction(entity));
        dispatch(setAdvancedSearch({
          entity,
          filters: { ...item?.filter },
          order: preset?.order,
        }));
      }
        break;
      case PRESET_TYPES.layout:
        dispatch(setColumns({
          entity,
          columns: item?.filter,
        } as QuerbuilderPayloadColumns));
        dispatch(setOrders({
          entity,
          order: preset?.order,
        }));
        break;
      default:
    }
    setOpen(false);
  };

  const displayOption = (option: PresetItem) => (
    <span className={classes.option} onClick={(e) => onValueChange(e, option as PresetItem)}>
      <DoneIcon
        color={option?.is_default ? 'primary' : 'disabled'}
      />
      <PeopleIcon
        color={option?.is_shared ? 'primary' : 'disabled'}
      />
      <span style={{ marginLeft: '4px' }}>
        { option.name || '' }
      </span>
    </span>
  );

  const getOptionLabel = (option: PresetItem) => option.name || '';

  const displayClearIcon = () => {
    if (!open) {
      return (
        <></>
      );
    }
    return (
      <CloseIcon color="action" fontSize="small" />
    );
  };

  const displayStartAdornment = () => (
    <>
      <Tooltip
        title={t(lang, 'menus.preset_default')}
        placement="top-start"
      >
        <DoneIcon
          className={classes.iconShared}
          color={preset?.is_default ? 'primary' : 'disabled'}
          onClick={(e) => toggleDefault(e)}
        />
      </Tooltip>
      <Tooltip
        title={t(lang, 'menus.preset_share')}
        placement="top-start"
      >
        <PeopleIcon
          className={classes.iconShared}
          color={preset?.is_shared ? 'primary' : 'disabled'}
          onClick={(e) => toggleShared(e)}
        />
      </Tooltip>
    </>
  );
  //
  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      key={`form-control-presets-${type}`}
    >
      <Autocomplete
        freeSolo
        value={preset}
        onChange={(e, item) => onValueChange(e, item as PresetItem)}
        options={getPresetItems()}
        open={open}
        onOpen={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
        onClose={(e) => {
          e.preventDefault();
          setOpen(false);
        }}
        closeIcon={displayClearIcon()}
        clearText={open ? 'clear' : 'Add a preset'}
        getOptionLabel={(option: PresetItem) => getOptionLabel(option)}
        renderOption={(option: PresetItem) => displayOption(option)}
        getOptionSelected={(option) => option.id === currentID}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={t(lang, `menus.load_preset_${type}`)}
            margin="dense"
            InputProps={{
              ...params.InputProps,
              classes: useTexfieldStyle(),
              startAdornment: displayStartAdornment(),
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
              classes: useLabelStyle(),
            }}
          />
        )}
        key={`autocomplete-preset-${type}`}
      />
    </FormControl>
  );
};

export default PresetSelector;
