const menus = {
  add_department: 'Add department',
  edit_department: 'Edit department',
  edit_taxe: 'Edit tax',
  delete_taxe: 'Delete tax',
  list_department: 'List departments',
  edit_account: 'Edit account',
  list_account: 'List account',
  add_program: 'Add program',
  edit_program: 'Edit program',
  edit_invoice_details: 'Edit invoice details',
  list_program: 'List program',
  unselect_all: 'Unselect all',
  select_all: 'Select all',
  add_contact: 'Add a contact',
  add_event: 'Add an event',
  edit_selected: ' Edit selected',
  export_invoice: 'Export Invoice',
  export_payment: 'Export Payment(s)',
  show_invoice: 'Show invoice',
  add_contact_to_group: 'Add contact to group',
  clear_filter: 'Clear filter',
  cancel_payment: 'Cancel payment(s)',
  refund_payment: 'Refund payment(s)',
  delete_selected_invoice: 'Delete selected invoice',
  cancel_selected_invoice: 'Cancel selected invoice',
  deleting_invoice: 'Deleting invoice',
  different_contacts: 'Invoices has different contacts',
  cant_cancel_online_payment: 'Can\'t cancel online payments',
  cant_refund_offline_payment: 'Can\'t refund offline payments',
  cant_refund_unpaid_payment: 'Can\'t refund unpaid payments',
  is_not_offline_payment: 'WARNING! Only offline payments can be cancelled',
  is_not_online_payment: 'WARNING! Only online payments can be refunded',
  is_unpaid_payment: 'WARNING! Only unpaid payments can be refunded',
  different_invoice_contact: 'WARNING! Some of the invoices selected are not from the same person ! Do you still want to proceed ?',
  deleting_invoice_confirmation: 'Are you sure you want to delete these invoices?',
  canceling_invoice_confirmation: 'Are you sure you want to cancel these invoices?',
  cancel_payment_confirmation: 'Are you sure you want to cancel these payments?',
  delete_selected_contact: 'Delete selected contact',
  delete_selected_event: 'Delete selected event',
  download_invoices: 'Download invoice(s)',
  download_payments: 'Download payment(s)',
  download_receipts: 'Download payment receipt(s)',
  download_tax_payments: 'Download tax receipt(s)',
  download_current_invoice: 'Download this invoice',
  deleting_contact: 'Deleting contact',
  deleting_contact_confirmation: 'Are you sure you want to delete these contacts?',
  deleting_creditcard: 'Deleting credit card',
  deleting_creditcard_confirmation: 'Are you sure you want to delete this credit card?',
  deleting_bankaccount: 'Deleting bank account',
  deleting_bankaccount_confirmation: 'Are you sure you want to delete this bank account?',
  duplicate_invoice: 'Duplicate invoice',
  download_result: 'Download result',
  download_selected: 'Download selected',
  view_payments: 'View payments',
  edit_selected_contact: 'Edit selected contact(s)',
  edit_selected_event: 'Edit selected event(s)',
  edit_contact: 'Edit contact',
  quick_search: 'Quick search',
  quick_search_term: 'Quick search term',
  load_preset_layout: 'Load layout preset',
  load_preset_filter: 'Load filter preset',
  manage_preset_layout: 'Manage layout presets',
  manage_preset_filter: 'Manage filter presets',
  manage_preset_reports: 'Manage reports presets',
  manage_products: 'Manage products',
  make_payment: 'Make payment',
  make_offline_payment: 'Make offline payment',
  merge_selected_contact: 'Merge selected contacts',
  share_preset: 'Share with people in your organisation',
  save_filter_preset: 'Save filter presets',
  yes_proceed: 'Yes, I want to proceed',
  deleting_customfields: 'Deleting Custom Field',
  deleting_customfields_confirmation: 'Are you sure you want to delete this custom field?',
  deleting_contact_relation: 'Deleting contact relation',
  deleting_contact_relation_confirmation: 'Are you sure you want to delete this contact relation?',
  deleting_relation: 'Deleting relation',
  deleting_relation_confirmation: 'Are you sure you want to delete this relation?',
  deleting_group: 'Deleting group',
  deleting_group_confirmation: 'Are you sure you want to delete this group?',
  deleting_account: 'Deleting account',
  deleting_account_confirmation: 'Are you sure you want to delete this account?',
  deleting_department: 'Deleting department',
  deleting_department_confirmation: 'Are you sure you want to delete this department?',
  deleting_program: 'Deleting program',
  deleting_program_confirmation: 'Are you sure you want to delete this program?',
  deleting_product: 'Deleting product',
  deleting_product_confirmation: 'Are you sure you want to delete this product?',
  deleting_preset: 'Deleting preset',
  deleting_preset_confirmation: 'Are you sure you want to delete this preset?',
  customfield_saved: 'Custom field saved successfully',
  customfield_saved_error: 'Error when saving custom field',
  customfield_edited: 'Custom field saved successfully.',
  customfield_deleted: 'Custom field deleted',
  customfield_deleted_error: 'Error when deleting custom field',
  add_customfield: 'Add a custom field',
  edit_customfield: 'Edit the custom field',
  delete_customfield: 'Delete the custom field',
  add_relation: 'Add a relation',
  edit_relation: 'Edit the relation',
  delete_relation: 'Delete the relation',
  manage_customfield: 'Manage custom fields',
  manage_relation: 'Manage relations',
  manage_relations: 'Manage contact relations',
  manage_groups: 'Manage contact groups',
  manage_accounts: 'Manage accounts',
  manage_departments: 'Manage departments',
  manage_programs: 'Manage programs',
  add_group: 'Add a group',
  edit_group: 'Edit the group',
  edit_payment: 'Edit the payment',
  delete_group: 'Delete the group',
  list_group: 'List groups',
  preset_default: 'Make the preset the default preset',
  preset_share: 'Share the preset',
  preset_save: 'Save the preset',
  preset_delete: 'Delete the preset',
  preset_edit: 'Edit the preset',
  preset_reset: 'Reset filters',
  preset_add: 'Add a preset',
  save_contact: 'Save the contact',
  delete_contact: 'Delete the contact',
  show_invoice_contact: 'Show this contact invoices',
  show_payment_contact: 'Show this contact payments',
  show_invoice_payments: 'Show invoice payments',
  show_hide_columns: 'Show/hide columns',
  show_column: 'Show columns',
  hide_column: 'Hide columns',
  add_account: 'Add account',
  delete_account: 'Delete account',
  delete_department: 'Delete department',
  delete_program: 'Delete program',
  add_product: 'Add product',
  edit_product: 'Edit product',
  list_product: 'List product',
  delete_product: 'Delete product',
  save_product: 'Save product',
  send_invoice_by_email: 'Send invoice(s) by email',
  save_as_final: 'Save as final',
  save_as_draft: 'Save as draft',
  invoice_saved: 'Invoice successfully saved.',
  deleting_tax: 'Deleting tax',
  deleting_tax_confirmation: 'Are you sure you want to delete this tax?',
  invoices: 'Invoices',
  payments: 'Payments',
  receipts: 'Receipts',
  payment_settings: 'Payments settings',
  profile: 'Profile',
  organisations: 'Organisations',
  make_a_donation: 'Make a donation',
  logout: 'Logout',
  admin_users: 'Admin users',
  settings: 'Settings',
  invoices_for_payment: 'Invoices for payment id: ',
  payments_for_invoice: 'Payments for  invoice: ',
  payments_for_contact: 'Payments for: ',
  onboarding: 'Onboarding',
  add_bank_account: 'Add bank account',
  fundraising: 'Fundraising',
  add_team: 'Add a team',
  edit_team: 'Edit the team',
  delete_team: 'Delete the team',
  list_team: 'List teams',
  filter_teams: 'Filter teams',
  leave_tab: 'You have unsaved data',
  unsaved_data: 'You have unsaved data, are you sure you want to leave this tab?',
};

export default menus;
