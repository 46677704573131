import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import FormSingleSelect from 'src/components/Control/FormControls/FormSingleSelect';
import { ErrorBag } from 'src/models/ErrorModel';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FORM_ID } from 'src/models/FormModel';
import { FundraisingLanguages } from 'src/constants/FundraisingLanguages';
import { t } from 'src/lib/language';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    marginTop: 0,
    marginBottom: '8px',
  },
}));

interface Props {
  disabled?: boolean,
  form: FORM_ID,
  name: string,
  onChange: (value:FundraisingLanguages, field: string) => void,
  value?: string,
  label?: string,
  values?: FundraisingLanguages[],
}

interface FundraisingLanguageItem {
  id: string,
  display: string,
}

const FundraisingLanguageSelector = (props: Props) => {
  const classes = useStyles();

  const lang = useSelector((state: Store) => state.language.language);

  const {
    disabled, form, name, onChange, value, label, values,
  } = props;

  const languages = [
    t(lang, 'form.fundraising.en'),
    t(lang, 'form.fundraising.fr'),
    t(lang, 'form.fundraising.es'),
    t(lang, 'form.fundraising.he'),
    t(lang, 'form.fundraising.y'),
  ];

  const errors: ErrorBag =
    useSelector((state:Store) => state.errors[form] || {} as ErrorBag);

  const getItems = () => {
    const items:FundraisingLanguageItem[] = [];
    const languageValues = values || languages;
    languageValues.map((item: any) => {
      items.push({
        id: item,
        display: item,
      } as FundraisingLanguageItem);
      return items;
    });
    return items;
  };

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      error={!!errors[name]}
      key={`form-control-${name}`}
    >
      <FormSingleSelect
        disabled={disabled}
        form={form}
        items={getItems()}
        label={label || 'Language'}
        name={name}
        onChange={onChange}
        value={value || ''}
        noMarginTop
      />
    </FormControl>
  );
};

export default FundraisingLanguageSelector;
