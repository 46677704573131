import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { DIALOGS } from 'src/models/DialogModel';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { MODALS } from 'src/models/ModalModel';
import { closeModal } from 'src/redux/actions/modalActions';

const CantRefundOfflinePayment = () => {
  const dispatch = useAppDispatch();
  const opened = useSelector(
    (state: Store) => state.dialog[DIALOGS.cantRefundOfflinePayment]?.opened
    || false,
  );

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const handleOk = () => {
    dispatch(closeModal({ modal: MODALS.refundPayments }));
    dispatch(closeDialog({ dialog: DIALOGS.cantRefundOfflinePayment }));
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="cant-refund-offline-payment"
      open={opened}
    >
      <DialogTitle id="cant-refund-offline-payment">{t(lang, 'menus.cant_refund_offline_payment')}</DialogTitle>
      <DialogContent dividers>
        {t(lang, 'menus.is_not_online_payment')}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CantRefundOfflinePayment;
