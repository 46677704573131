import { t } from 'src/lib/language';
import { makeStyles } from '@material-ui/core/styles';
import { Store } from 'src/redux/Store';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    fontSize: '1.8rem',
    fontFamily: 'Qanelas-Bold',
    height: '48px',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  row: {
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
    marginBottom: '16px',
  },
  scroller: {
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: theme.spacing(2),
    height: '66vh',
    padding: '8px',
  },
}));

const PaymentsForInvoiceHeader = () => {
  const classes = useStyles();

  const lang = useSelector((state: Store) => state.language.language);

  const invoiceId = useSelector((state: Store) => state.route.id || 0);
  const invoice = useSelector((state: Store) => state.invoices[invoiceId]);
  const [title, setTitle] = useState('');

  const getTitle = () => {
    if (!invoice) return '';
    if (!invoice.title) return `#${invoice.id}`;
    return `${invoice.title} (#${invoice.id})`;
  };

  useEffect(() => {
    setTitle(getTitle());
  }, [invoice]);

  return (
    <div className={classes.header}>
      <div className={`${classes.row}`}>
        <Typography
          variant="h5"
          color="secondary"
          gutterBottom
          className={classes.header}
        >
          {t(lang, 'menus.payments_for_invoice')} {title}
        </Typography>
      </div>
    </div>
  );
};

export default PaymentsForInvoiceHeader;
