import { MouseEvent, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { closeModal, openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { useMediaQuery } from 'react-responsive';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import ProductsSelector from 'src/components/Control/ProductsSelector';
import ProductModel, { ProductList } from 'src/models/ProductModel';
import { FORM } from 'src/constants/Form';
import { useSelector } from 'react-redux';
import { setError } from 'src/redux/actions/errorsActions';
import { t } from 'src/lib/language';
import { Store, useAppDispatch } from 'src/redux/Store';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { capitalize } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import {
  InstallmentModel,
  PAYMENT_ACTION_TYPE,
  PAYMENT_FREQUENCY,
  PAYMENT_TYPE,
} from 'src/models/PaymentModel';
import MenuItem from '@material-ui/core/MenuItem';
import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import ContactCreditCardDisplayer from 'src/components/Elements/ContactCreditCardDisplayer';
import ButtonLink from 'src/components/Elements/ButtonLink';
import PaymentAccountSelector from 'src/components/Control/PaymentAccountSelector';
import DonationModel, { DonationOrderModel } from 'src/models/DonationModel';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import AttachMoney from '@material-ui/icons/AttachMoney';
import { postPurchaseAction } from 'src/redux/actions/paymentActions';
import UserDonationValidator from 'src/validations/UserDonationValidator';
import { getProductListAction } from 'src/redux/actions/productAction';
import { formatDatetime, getJSLocale } from 'src/lib/FieldHelper';

const useStyles = (isTabletOrMobile:boolean) => makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: '640px',
  },
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '9vh',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  scroller: {
    overflowY: 'scroll',
    height: '66vh',
    padding: '8px',
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  flexItem: {
    flex: isTabletOrMobile ? '100%' : '45%',
    margin: '0 10px',
  },
  selectDate: {
    width: '100%',
    minWidth: 120,
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.50)',
  },
  selectLabelError: {
    color: 'rgba(255, 0, 0, 0.50)',
  },
  noMargin: {
    height: '32px',
    marginBottom: '0 !important',
  },
  checkbox: {
    marginBottom: '0px !important',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
    marginBottom: '32px',
  },
  col2: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const UserDonationForm = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const classes = useStyles(isTabletOrMobile)();
  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const products = useSelector((state: Store) => state.products || [] as ProductList);
  const userContact = useSelector((state: Store) => state.userContact);
  const user = useSelector((state: Store) => state.user);
  const locale = useSelector((state: Store) => getJSLocale(state.language.locale));

  const [userDonationState, setUserDonationState] = useState({
    is_installment: 0,
    payment_type: PAYMENT_TYPE.cc,
    payment_action: PAYMENT_ACTION_TYPE.credit,
  } as DonationModel);
  const [installmentState, setInstallmentState] = useState({
    number: 1,
    start_date: moment(),
    frequency: PAYMENT_FREQUENCY.monthly,
  } as InstallmentModel);
  const [currentProductState, setCurrentProductState] = useState({} as ProductModel);
  const [orderDonationState, setOrderDonationState] = useState({} as DonationOrderModel);

  useEffect(() => {
    dispatch(getProductListAction());
  }, []);

  const getProduct = (id:number) => (
    products && products.find((product) => product.id === id)
  );

  useEffect(() => {
    if (orderDonationState.product_id) {
      const product = getProduct(orderDonationState.product_id);
      if (product) {
        setCurrentProductState(product);
        setOrderDonationState({
          ...orderDonationState,
          quantity: 1,
          paid: product?.price ?? 0,
          deductible: (product?.deductible ?? 0) * 100,
          discount: product?.discount ?? 0,
        });
        setUserDonationState({
          ...userDonationState,
          is_installment: product?.allow_installment ?? 0,
          bank_account_id: product.bank_account_id,
          department_id: product.department_id,
          account_id: product.account_id,
          program_id: product.program_id,
          is_deductible: !!product.deductible,
          payment_gateway_id: 160,
        });
      }
    }
  }, [orderDonationState.product_id]);

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.userDonation }));
  };

  const onFieldChange = (value: Value, field: string) => {
    setUserDonationState({
      ...userDonationState,
      [field]: value,
    });
  };

  const onOrderChange = (value: Value, field: string) => {
    if (field === 'product_id' && typeof value === 'number') {
      const currentProduct = getProduct(value);
      if (currentProduct) {
        setUserDonationState({
          ...userDonationState,
          bank_account_id: currentProduct.bank_account_id,
          title: currentProduct[`name_${lang}`],
        });
      }
    }
    setOrderDonationState({
      ...orderDonationState,
      [field]: value,
    });
  };

  const displayInstallmentFrequencyOptions = () => (
    Object.values(PAYMENT_FREQUENCY).map((frequency) => (
      <MenuItem value={frequency}>{frequency}</MenuItem>
    ))
  );

  const onInstallmentChange = (value: Value, field: string) => {
    setInstallmentState({
      ...installmentState,
      [field]: value,
    });
  };

  const openAddCreditCardModal = () => {
    dispatch(openModal({
      modal: MODALS.addCreditCard,
      payload: {
        contact_id: userContact.id as number,
      },
    }));
  };

  const displayInstallmentDetails = () => {
    const step = (orderDonationState.paid ?? 0) / (installmentState.number ?? 1);

    if (!Number.isNaN(step) && !!installmentState.number) {
      return (
        <p>
          <strong>
            {installmentState.number} {capitalize(installmentState.frequency)} {t(lang, 'forms.payments.payments_of')} {step.toFixed(2)}$ {' '}
            {t(lang, 'forms.payments.starting')} {formatDatetime(installmentState.start_date.toDate(), locale)}
          </strong>
        </p>
      );
    }
    return <></>;
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>, close:boolean = false) => {
    event.preventDefault();
    dispatch(setError({ [FORM.post_purchase]: {} }));

    try {
      UserDonationValidator(lang).validateSync({
        product_id: orderDonationState.product_id,
        paid: orderDonationState.paid,
        payment_info_id: userDonationState.payment_info_id,
        bank_account_id: userDonationState.bank_account_id,
      }, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: t(lang, 'forms.contact.validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.post_purchase]: errorBag }));
      return false;
    }
    const payload = {
      orders: [{
        ...orderDonationState,
        deductible: (orderDonationState.deductible ?? 0) / 100,
      }],
      installment: installmentState,
      ...userDonationState,
      contact_id: userContact.id,
      user_id: user.id,
    } as any;
    dispatch<any>(postPurchaseAction(payload));
    return close;
  };

  return (
    <>
      <div className={classes.header}>
        <div className={`${classes.row} ${classes.noMargin}`}>
          <div className={classes.title}>{t(lang, 'forms.payments.make_donation')}</div>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
        <div className={`${classes.row} ${classes.noMargin}`}>
          <Tooltip
            title="Make a Donation"
            placement="top-start"
          >
            <span>
              <ActionButton
                category={MENU_BUTTON_CATEGORY.action}
                onClick={handleSubmit}
              >
                <AttachMoney />
              </ActionButton>
            </span>
          </Tooltip>
        </div>
      </div>
      <div className={classes.scroller}>
        <form className={classes.form} noValidate>
          { (!userDonationState.is_installment) && (
            <div className={classes.flexContainer} style={{ marginBottom: '16px' }}>
              <FormDatePicker
                form={FORM.make_payment}
                required
                label={t(lang, 'forms.payments.paid_date')}
                name="issued_date"
                onChange={
                  (value: MaterialUiPickersDate) => onFieldChange(
                    value,
                    'issued_date',
                  )
                }
                value={userDonationState.issued_date || moment()}
              />
            </div>
          )}
          <div className={classes.flexContainer}>
            <ProductsSelector
              onlyPublic
              onlyDeductible
              form={FORM.post_purchase}
              name="product_id"
              onChange={(product_id: number) => onOrderChange(product_id, 'product_id')}
              value={orderDonationState.product_id ?? 0}
            />
          </div>
          <div className={classes.row}>
            <FormTextField
              form={FORM.post_purchase}
              label={t(lang, 'forms.invoices.amount')}
              name="paid"
              onChange={onOrderChange}
              value={orderDonationState.paid}
              disabled={!currentProductState.is_modifiable}
            />
            <FormTextField
              form={FORM.post_purchase}
              label={`${t(lang, 'forms.invoices.deductible')} (amount)`}
              name="deductible"
              onChange={onOrderChange}
              value={orderDonationState.deductible}
              disabled
            />
          </div>
          <>
            <div className={classes.flexContainer}>
              <FormControl
                component="fieldset"
                style={{ marginTop: '8px' }}
              >
                <RadioGroup
                  defaultValue={0}
                  row
                  aria-label={t(lang, 'forms.payments.is_installment')}
                  name="is_installment"
                  value={userDonationState.is_installment}
                  onChange={(e) => onFieldChange((e.target.value === '0') ? 0 : 1, 'is_installment')}
                >
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label={t(lang, 'forms.payments.single_payment')}
                  />
                  <FormControlLabel
                    disabled={!currentProductState.allow_installment}
                    value={1}
                    control={<Radio />}
                    label={t(lang, 'forms.payments.multiple_payment')}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            { (!!userDonationState.is_installment) && (
              <>
                <div className={`${classes.row} ${classes.checkbox}`}>
                  <div className={classes.col2}>
                    <FormTextField
                      form={FORM.make_payment}
                      label={t(lang, 'forms.payments.number')}
                      name="number"
                      onChange={onInstallmentChange}
                      required
                      value={installmentState.number || ''}
                    />
                  </div>
                  <div className={classes.col2}>
                    <FormControl className={classes.selectDate}>
                      <InputLabel id="frequency">Frequency</InputLabel>
                      <Select
                        labelId="frequency-label-id-"
                        id="demo-simple-select"
                        value={installmentState.frequency || ''}
                        onChange={(e) => onInstallmentChange(e.target.value as string, 'frequency')}
                      >
                        {displayInstallmentFrequencyOptions()}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={`${classes.row} ${classes.checkbox}`}>
                  <div className={classes.col2}>
                    <FormDatePicker
                      form={FORM.make_donation}
                      required
                      label={t(lang, 'forms.payments.start_date')}
                      name="start_date"
                      onChange={
                        (value: MaterialUiPickersDate) => onInstallmentChange(
                          moment(value).format('YYYY-MM-DD'),
                          'start_date',
                        )
                      }
                      value={installmentState.start_date || moment()}
                    />
                  </div>
                </div>
                <div className={classes.row} style={{ marginBottom: '8px' }}>
                  {displayInstallmentDetails()}
                </div>
              </>
            )}
          </>
          <PaymentAccountSelector
            value={userDonationState.payment_info_id}
            type={PAYMENT_TYPE.cc}
            onChange={(id:number) => onFieldChange(id, 'payment_info_id')}
            form={FORM.post_purchase}
            name="payment_info_id"
            contactId={userContact.id ?? 0}
          />
          <>
            <ContactCreditCardDisplayer contactId={userContact.id ?? 0} />
            <ButtonLink onClick={() => openAddCreditCardModal()}>{t(lang, 'forms.payments.add_credit_card')}</ButtonLink>
          </>
          <div className={classes.flexContainer}>
            <FormTextField
              form={FORM.post_purchase}
              label={t(lang, 'forms.invoices.note')}
              name="note"
              onChange={onFieldChange}
              value={userDonationState.note}
              multiline
              minRows="4"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default UserDonationForm;
