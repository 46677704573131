import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { DIALOGS } from 'src/models/DialogModel';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { deleteProductAction } from 'src/redux/actions/productAction';

const DeleteProductDialog = () => {
  const dispatch = useAppDispatch();

  const { opened, payload } =
    useSelector((state: Store) => {
      if (state.dialog[DIALOGS.deleteProduct]) {
        return state.dialog[DIALOGS.deleteProduct];
      }
      return { opened: false, payload: null };
    });

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const handleCancel = () => {
    dispatch(closeDialog({ dialog: DIALOGS.deleteProduct }));
  };

  const handleOk = () => {
    dispatch(closeDialog({ dialog: DIALOGS.deleteProduct }));
    dispatch(deleteProductAction(payload.id));
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="deleting-product"
      open={opened}
    >
      <DialogTitle id="deleting-product">{t(lang, 'menus.deleting_product')}</DialogTitle>
      <DialogContent dividers>
        {t(lang, 'menus.deleting_product_confirmation')}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {t(lang, 'misc.no')}
        </Button>
        <Button onClick={handleOk} color="primary">
          {t(lang, 'misc.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteProductDialog;
