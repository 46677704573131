import { makeStyles } from '@material-ui/core/styles';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { Button } from '@material-ui/core';
import { forwardRef } from 'react';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { useMediaQuery } from 'react-responsive';

const useStyles = (color: string) => makeStyles(() => ({
  actionButtonDefault: {
    minWidth: '42px',
    padding: 0,
    backgroundColor: lighten(color, 0.1),
  },
  actionButtonCategory: {
    minWidth: '42px',
    padding: 0,
    color: '#fff',
    backgroundColor: lighten(color, 0.1),
    borderColor: color,
    '&:hover': {
      borderColor: color,
      backgroundColor: lighten(color, 0.5),
      color,
    },
  },
}));

const useMobileStyles = (color: string) => makeStyles(() => ({
  actionButtonDefault: {
    minWidth: '30px',
    padding: 0,
    backgroundColor: lighten(color, 0.1),
  },
  actionButtonCategory: {
    minWidth: '30px',
    padding: 0,
    color: '#fff',
    backgroundColor: lighten(color, 0.1),
    borderColor: color,
    '&:focus': {
      color: '#fff',
      backgroundColor: lighten(color, 0.1),
      borderColor: color,
    },
  },
}));

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum MENU_BUTTON_CATEGORY {
  presets = 'presets',
  action = 'action',
  download = 'download',
  selection = 'selection',
  customfield = 'customfield',
  error = 'error',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum MENU_BUTTON_CATEGORY_COLOR {
  presets = '#0A4DF2',
  action = '#A51760',
  download = '#333333',
  selection = '#18a90f',
  customfield = '#e84004',
  error = '#F00',
}

export type CustomProps = {
  category?: MENU_BUTTON_CATEGORY,
};

type Props = CustomProps & ButtonProps;

const ActionButton = (props: Props, ref: any) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const { category, children, disabled } = props;
  const color = category ? MENU_BUTTON_CATEGORY_COLOR[category] : '#FFF';
  const classes = isTabletOrMobile ? useMobileStyles(color)() : useStyles(color)();

  const { style } = props;

  const getStyle = () => {
    const localStyle = { ...style } as any;
    localStyle.opacity = disabled ? 0.4 : 1;
    if (disabled) {
      localStyle.color = '#fff';
      localStyle.backgroundColor = color;
    }
    return localStyle;
  };

  return (
    <Button
      ref={ref}
      className={category ? classes.actionButtonCategory : classes.actionButtonDefault}
      style={getStyle()}
      {...props as ButtonProps}
    >
      {children}
    </Button>
  );
};

export default forwardRef(ActionButton);
