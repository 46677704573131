import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { t } from 'src/lib/language';
import { BASE_URL } from 'src/environments/environment';
import { getInvoiceAction } from 'src/redux/actions/invoiceActions';

interface OwnProps {
  invoiceId: number;
}

type Props = OwnProps & ButtonProps;

const ShowPaymentsForInvoiceButton = (props: Props) => {
  const { disabled, invoiceId } = props;
  const lang = useSelector((state: Store) => state.language.language);
  const dispatch = useAppDispatch();

  const onClick = () => {
    if (!invoiceId) return;
    dispatch(getInvoiceAction(invoiceId));
    window.open(`${BASE_URL}/payments/invoice/${invoiceId}`);
  };

  return (
    <Tooltip
      title={t(lang, 'menus.show_invoice_payments')}
      placement="top-start"
    >
      <span>
        <ActionButton
          category={MENU_BUTTON_CATEGORY.presets}
          onClick={onClick}
          disabled={disabled}
        >
          <DescriptionOutlinedIcon />
        </ActionButton>
      </span>
    </Tooltip>
  );
};

export default ShowPaymentsForInvoiceButton;
