import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { DIALOGS } from 'src/models/DialogModel';
import { MODALS } from 'src/models/ModalModel';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { openModal } from 'src/redux/actions/modalActions';

const ConfirmPaymentDialog = () => {
  const dispatch = useAppDispatch();
  const opened =
    useSelector((state: Store) => state.dialog[DIALOGS.confirmPaymentModalDialog]?.opened || false);

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const handleCancel = () => {
    dispatch(closeDialog({ dialog: DIALOGS.confirmPaymentModalDialog }));
  };

  const handleOk = () => {
    dispatch(openModal({ modal: MODALS.makePayment }));
    dispatch(closeDialog({ dialog: DIALOGS.confirmPaymentModalDialog }));
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirm-payment"
      open={opened}
    >
      <DialogTitle id="confirm-payment">{t(lang, 'menus.different_contacts')}</DialogTitle>
      <DialogContent dividers>
        {t(lang, 'menus.different_invoice_contact')}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {t(lang, 'misc.no')}
        </Button>
        <Button onClick={handleOk} color="primary">
          {t(lang, 'menus.yes_proceed')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmPaymentDialog;
