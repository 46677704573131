import { ENTITIES, GridColumns, QuerybuilderOrders } from 'src/models/QuerybuilderModel';
import { PRESET_TYPES, PresetItem } from 'src/models/PresetModel';
import { Theme, Tooltip } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ActionButton from 'src/components/Elements/MenuButton';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { t } from 'src/lib/language';
import PresetSelector from 'src/components/Control/PresetSelector';
import { useEffect, useState } from 'react';
import PresetEdit from 'src/components/Control/PresetEdit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import PresetAdd from 'src/components/Control/PresetAdd';
import { createFilterAction, updatePresetAction } from 'src/redux/actions/presetActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { setSelectedPresets } from 'src/redux/actions/selectedPresetActions';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { getOperatorAndFilters } from 'src/lib/QueryBuilderHelper';
import { validateFilters } from 'src/validations/FiltersValidation';
import { setError } from 'src/redux/actions/errorsActions';
import { Filter } from 'src/models/AdvanceSearchModel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  buttons: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
}));

interface Props {
  entity: ENTITIES,
  type: PRESET_TYPES;
  onReset: () => void;
}

export enum State {
  edit = 'edit',
  add = 'add',
  load = 'load',
}

const PresetToolbar = (props: Props) => {
  const classes = useStyles();
  const { entity, type, onReset } = props;
  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language);

  const columns = useSelector(
    (state: Store) => (state.querybuilder[entity] && state.querybuilder[entity].columns)
      || [] as GridColumns,
  );

  const order = useSelector(
    (state: Store) => (
      state.querybuilder[entity] && state.querybuilder[entity].order as QuerybuilderOrders
    ) || [] as QuerybuilderOrders,
  );

  const filterList = useSelector((state: Store) => state.advancedSearch[entity]);

  const processing = useSelector(
    (state: Store) => state.formStatus[FORM[`manage_${type}`]] === FORM_STATUS.processing,
  );

  const selectedId = useSelector(
    (state: Store) => {
      if (!state.selectedPreset[entity]) {
        return 0;
      }
      if (!state.selectedPreset[entity][type]) {
        return 0;
      }
      return state.selectedPreset[entity][type].selected || 0;
    },
  );

  const [currentState, setCurrentState] = useState(State.load);

  const preset = useSelector(
    (state: Store) => {
      if (selectedId) {
        if ((state.presets[entity] && state.presets[entity][type])) {
          const selectedPreset = state.presets[entity][type].filter(
            (item: PresetItem) => item.id === selectedId,
          );
          if (selectedPreset[0]) {
            return selectedPreset[0];
          }
        }
      }
      return {
        is_shared: false,
        is_default: false,
        name: '',
      } as PresetItem;
    },
  );

  const [currentPreset, setCurrentPreset] = useState(preset as PresetItem);

  useEffect(() => {
    setCurrentPreset(preset);
  }, [selectedId]);

  const setSelectedId = (id: number) => {
    dispatch(setSelectedPresets({
      entity,
      type,
      id,
    }));
  };

  const onChangePreset = (presetItem: PresetItem) => {
    setCurrentPreset(presetItem);
  };

  const validateFilter = (filters: Filter[]) => {
    dispatch(setError({ [FORM.query_builder]: {} }));
    const validationErrors = validateFilters(filters, lang);
    if (validationErrors.length) {
      dispatch(setError({ [FORM.query_builder]: validationErrors }));
    }
    return validationErrors.length < 1;
  };

  const createPreset = () => {
    let data: any;
    switch (type) {
      case PRESET_TYPES.filter: {
        const filters = getOperatorAndFilters(filterList)[1];
        if (!validateFilter(filters)) {
          return;
        }
        data = filterList;
        break;
      }
      case PRESET_TYPES.layout:
        data = columns;
        break;
      default:
    }
    dispatch(createFilterAction({
      entity,
      type,
      filter: {
        filter: data,
        order,
      },
      name: currentPreset.name || '',
      is_shared: currentPreset.is_shared || false,
      is_default: currentPreset.is_default || false,
    }));
  };

  const savePreset = () => {
    let data: any;
    switch (type) {
      case PRESET_TYPES.filter: {
        const filters = getOperatorAndFilters(filterList)[1];
        if (!validateFilter(filters)) {
          return;
        }
        data = filterList;
        break;
      }
      case PRESET_TYPES.layout:
        data = columns;
        break;
      default:
    }
    dispatch(updatePresetAction({
      entity,
      type,
      id: selectedId,
      filter: {
        filter: data,
        order,
      },
      name: currentPreset.name || '',
      is_shared: currentPreset.is_shared || false,
      is_default: currentPreset.is_default || false,
    }));
  };

  const onSave = () => {
    switch (currentState) {
      case State.add:
        createPreset();
        break;
      default:
        savePreset();
    }
    setCurrentState(State.load);
  };

  const onDelete = () => {
    if (!selectedId) return;
    dispatch(openDialog({
      dialog: DIALOGS.deletePreset,
      payload: {
        id: selectedId,
        entity,
        type,
      },
    }));
  };

  const displayComponent = () => {
    switch (currentState) {
      case State.load:
        return (
          <PresetSelector
            entity={entity}
            type={type}
            onChangeState={(state: State) => setCurrentState(state)}
            onChangeCurrentID={(id:number) => setSelectedId(id)}
            currentID={selectedId}
          />
        );
      case State.edit:
        return (
          <PresetEdit
            onChangeState={(state: State) => setCurrentState(state)}
            onChangePreset={(presetItem:PresetItem) => onChangePreset(presetItem)}
            preset={currentPreset}
          />
        );
      default:
        return (
          <PresetAdd
            onChangeState={(state: State) => setCurrentState(state)}
            onChangePreset={(presetItem:PresetItem) => onChangePreset(presetItem)}
            type={type}
          />
        );
    }
  };

  return (
    <div className={classes.buttons}>
      { displayComponent() }
      { !!currentPreset.name && (
      <Tooltip
        title={t(lang, 'menus.preset_save')}
        placement="top-start"
      >
        <span>
          <ActionButton
            variant="outlined"
            onClick={onSave}
            color="primary"
            disabled={processing}
          >
            <SaveIcon />
          </ActionButton>
        </span>
      </Tooltip>
      )}
      { currentState === State.load && (
      <Tooltip
        title={t(lang, 'menus.preset_add')}
        placement="top-start"
      >
        <span>
          <ActionButton
            variant="outlined"
            onClick={() => setCurrentState(State.add)}
            color="primary"
            disabled={processing}
          >
            <AddIcon />
          </ActionButton>
        </span>
      </Tooltip>
      )}
      { (!!currentPreset.id && currentState === State.load) && (
      <Tooltip
        title={t(lang, 'menus.preset_edit')}
        placement="top-start"
      >
        <span>
          <ActionButton
            variant="outlined"
            onClick={() => setCurrentState(State.edit)}
            color="primary"
          >
            <EditIcon />
          </ActionButton>
        </span>
      </Tooltip>
      )}
      { (!!selectedId && currentState === State.load) && (
      <Tooltip
        title={t(lang, 'menus.preset_delete')}
        placement="top-start"
      >
        <span>
          <ActionButton
            variant="outlined"
            onClick={onDelete}
            color="primary"
            disabled={processing}
          >
            <DeleteIcon />
          </ActionButton>
        </span>
      </Tooltip>
      )}
      { (!!selectedId && currentState === State.load) && (
      <Tooltip
        title={t(lang, 'menus.preset_reset')}
        placement="top-start"
      >
        <ActionButton
          variant="outlined"
          onClick={onReset}
          color="primary"
          disabled={processing}
        >
          <RefreshIcon />
        </ActionButton>
      </Tooltip>
      )}
    </div>
  );
};

export default PresetToolbar;
