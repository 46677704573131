import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { setError } from 'src/redux/actions/errorsActions';
import { FORM } from 'src/constants/Form';
import { t } from 'src/lib/language';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import { putDepartmentsAction } from 'src/redux/actions/dataActions';
import { DepartmentItemModel } from 'src/models/DepartmentListModel';
import DepartmentCreationValidator from 'src/validations/DepartmentCreationValidator';

interface Props {
  department: DepartmentItemModel;
  onClose?: () => void;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: '640px',
  },
  submit: {
    marginTop: '12px',
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectElement: {
    width: '100%',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.50)',
  },
  selectLabelError: {
    color: 'rgba(255, 0, 0, 0.50)',
  },
  spacer: {
    height: theme.spacing(3),
  },
  hide: {
    display: 'none',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '10px',
  },
}));

const EditAccountForm = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const { department, onClose } = props;

  const [state, setState] = useState(department);
  const form = FORM.manage_department;

  useEffect(() => {
    dispatch(setError({ [form]: {} }));
  }, []);

  const onChange = (value: any, field: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setError({ [form]: {} }));
    try {
      DepartmentCreationValidator(lang).validateSync(state, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.group.validation_errors',
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [form]: errorBag }));
      return false;
    }

    dispatch(putDepartmentsAction(state));
    return true;
  };

  return (
    <form className={classes.form} noValidate>
      <FormTextField
        form={form}
        label={t(lang, 'forms.groups.name_en')}
        name="name_en"
        onChange={onChange}
        required
        value={state.name_en}
      />
      <FormTextField
        form={form}
        label={t(lang, 'forms.groups.name_fr')}
        name="name_fr"
        onChange={onChange}
        required
        value={state.name_fr}
      />
      <FormTextField
        form={form}
        label={t(lang, 'forms.groups.description_en')}
        name="description_en"
        onChange={onChange}
        required
        value={state.description_en}
      />
      <FormTextField
        form={form}
        label={t(lang, 'forms.groups.description_fr')}
        name="description_fr"
        onChange={onChange}
        required
        value={state.description_fr}
      />
      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
          key="submit-button"
        >
          {t(lang, 'misc.save')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleClose}
          key={`submit-button-group-${state.id}`}
        >
          {t(lang, 'misc.back_to_list')}
        </Button>
      </div>
    </form>
  );
};

export default EditAccountForm;
