import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { TimeZoneListModel } from 'src/models/TimeZoneListModel';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { getTimeZoneAction } from 'src/redux/actions/dataActions';

const useStyles = makeStyles(() => ({
  row: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    width: '640px',
  },
}));

const OrganisationInfo = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const organisation = useSelector((state: Store) => state.organisation);
  const timezones = useSelector((store: Store) => store.data.timezones || [] as TimeZoneListModel);

  useEffect(() => {
    dispatch(getTimeZoneAction());
  }, []);

  const getTimeZone = () => {
    const result = timezones[Number(organisation.timezoneId)];
    return result ?? 'Invalid Timezone';
  };

  return (
    <div className={classes.row}>
      <div style={{ fontSize: '16px', marginBottom: '8px' }}><b>{organisation.legalName}</b></div>
      <div><b>ID: </b>{organisation.id}</div>
      <div><b>Name: </b><i>{organisation.nameEn} / {organisation.nameFr}</i></div>
      <div><b>Legal Name: </b>{organisation.legalName}</div>
      <div><b>Registration #: </b>{organisation.registrationNumber}</div>
      <div><b>Type: </b>{organisation.type || 'Not set'}</div>
      <div><b>Timezone: </b>{getTimeZone()}</div>
    </div>
  );
};

export default OrganisationInfo;
