import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import {
  DELIVERY_TYPE,
  DownloadOptionsModel,
  EXTENSION,
  FORMAT,
  ORIENTATION,
} from 'src/models/DownloadModel';
import { FORM } from 'src/constants/Form';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { makeStyles } from '@material-ui/core/styles';
import { DIALOGS } from 'src/models/DialogModel';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { setError } from 'src/redux/actions/errorsActions';
import { useState } from 'react';
import { closeDialog } from 'src/redux/actions/dialogActions';
import {
  postDownloadEntitesAction,
  postDownloadSelectedEntitesAction,
  postEmailEntitesAction,
  postEmailSelectedEntitesAction,
} from 'src/redux/actions/querybuilderActions';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'baseline',
    gap: '10px',
  },
}));

enum PaymentType {
  all = 'all',
  selected = 'selected',
}

const ExportPaymentDialog = () => {
  const classes = useStyles();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const opened =
    useSelector((state: Store) => state.dialog[DIALOGS.paymentExport]?.opened || false);
  const count =
    useSelector(
      (state: Store) => (state.querybuilder[ENTITIES.payments]
        && (state.querybuilder[ENTITIES.payments].result?.count || 0)),
    );
  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.payments] || [],
  );
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState({
    file: 'payments',
    orientation: ORIENTATION.portrait,
    format: FORMAT.pdf,
    delivery: DELIVERY_TYPE.download,
    export_to_grid: true,
  } as DownloadOptionsModel);

  const [type, setType] = useState(PaymentType.all as PaymentType);
  const extension = options.format === FORMAT.csv ? EXTENSION.csv : EXTENSION.pdf;
  const handleCancel = () => {
    dispatch(closeDialog({ dialog: DIALOGS.paymentExport }));
  };

  const handleExport = () => {
    dispatch(setError({ [FORM.export_payment]: {} }));
    if (!options.file) {
      dispatch(setError({
        [FORM.export_payment]: {
          file: ['Required'],
        },
      }));
      return 1;
    }

    if (!options.file) {
      options.file = 'payments';
    }
    options.file = `${options.file.split('.')[0]}.${extension}`;

    if (type === PaymentType.all) {
      if (options.delivery === DELIVERY_TYPE.download) {
        dispatch(postDownloadEntitesAction(ENTITIES.payments, options));
      } else {
        dispatch(postEmailEntitesAction(ENTITIES.payments, options));
      }
    }

    if (type === PaymentType.selected) {
      if (options.delivery === DELIVERY_TYPE.download) {
        dispatch(postDownloadSelectedEntitesAction(ENTITIES.payments, selections, options));
      } else {
        dispatch(postEmailSelectedEntitesAction(ENTITIES.payments, selections, options));
      }
    }

    dispatch(closeDialog({ dialog: DIALOGS.paymentExport }));
    return 1;
  };

  const onChange = (value: DownloadOptionsModel) => {
    setOptions(value);
  };

  const onContactFieldChange = (value: Value, field: string) => {
    onChange({
      ...options,
      [field]: value,
    });
  };

  return (
    <Dialog
      aria-labelledby="confirmation-dialog-title"
      open={opened}
    >
      <DialogTitle id="confirmation-dialog-title">Export payment(s)</DialogTitle>
      <DialogContent dividers>
        <div style={{ width: '480px' }}>
          <FormControl component="fieldset" style={{ marginTop: '8px' }}>
            <FormLabel component="legend">Payment selection</FormLabel>
            <RadioGroup
              row
              aria-label="payments"
              name="payments"
              value={type}
              onChange={(e) => setType(e.target.value as PaymentType)}
            >
              <FormControlLabel value={PaymentType.all} control={<Radio />} label={`All payments (${count})`} />
              <FormControlLabel disabled={(selections.length < 0)} value={PaymentType.selected} control={<Radio />} label={`Selected payments (${selections.length})`} />
            </RadioGroup>
          </FormControl>
          <div className={classes.row}>
            <FormTextField
              form={FORM.export_payment}
              label="File Name"
              name="file"
              onChange={onContactFieldChange}
              required
              value={options.file.split('.')[0]}
            />
            { `.${extension}` }
          </div>
          <FormControl component="fieldset" style={{ marginTop: '8px' }}>
            <FormLabel component="legend">Format</FormLabel>
            <RadioGroup
              row
              aria-label="format"
              name="format"
              value={options.format || FORMAT.pdf}
              onChange={(e) => onContactFieldChange(e.target.value, 'format')}
            >
              <FormControlLabel value={FORMAT.pdf} control={<Radio />} label={FORMAT.pdf} />
              <FormControlLabel value={FORMAT.csv} control={<Radio />} label={FORMAT.csv} />
            </RadioGroup>
          </FormControl>
          <div />
          <FormControl
            component="fieldset"
            style={{ marginTop: '8px' }}
          >
            <FormLabel component="legend">Orientation</FormLabel>
            <RadioGroup
              row
              aria-label="orientation"
              name="orientation"
              value={options.orientation || ORIENTATION.portrait}
              onChange={(e) => onContactFieldChange(e.target.value, 'orientation')}
            >
              <FormControlLabel
                value={ORIENTATION.portrait}
                control={<Radio />}
                label={ORIENTATION.portrait}
              />
              <FormControlLabel
                value={ORIENTATION.landscape}
                control={<Radio />}
                label={ORIENTATION.landscape}
              />
            </RadioGroup>
          </FormControl>
          <div />
          <FormControl
            component="fieldset"
            style={{ marginTop: '8px' }}
          >
            <FormLabel component="legend">Delivery Type</FormLabel>
            <RadioGroup
              row
              aria-label="Delivery Type"
              name="download"
              value={options.delivery || DELIVERY_TYPE.email}
              onChange={(e) => onContactFieldChange(e.target.value, 'delivery')}
            >
              <FormControlLabel
                value={DELIVERY_TYPE.email}
                control={<Radio />}
                label="By Email"
              />
              { ((count < 1000 && (type === PaymentType.all))
              || (selections.length > 0 && (type === PaymentType.selected))) && (
              <FormControlLabel
                value={DELIVERY_TYPE.download}
                control={<Radio />}
                label="Download"
              />
              )}
            </RadioGroup>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          { t(lang, 'misc.cancel') }
        </Button>
        <Button onClick={handleExport} color="primary">
          { t(lang, 'misc.export') }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportPaymentDialog;
