import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { DIALOGS } from 'src/models/DialogModel';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { deleteContactCreditCardAction } from 'src/redux/actions/paymentInfoAction';

const DeleteCreditCardDialog = () => {
  const dispatch = useAppDispatch();
  const opened =
    useSelector((state: Store) => state.dialog[DIALOGS.deleteCreditCard]?.opened || false);

  const cardId = useSelector(
    (state: Store) => state.currentEntity[ENTITIES.creditCards] || 0,
  );

  const contactId = useSelector(
    (state: Store) => state.currentEntity[ENTITIES.contacts] || 0,
  );
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const handleCancel = () => {
    dispatch(closeDialog({ dialog: DIALOGS.deleteCreditCard }));
  };

  const handleOk = () => {
    dispatch(deleteContactCreditCardAction(contactId, cardId));
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="deleting-credit-card"
      open={opened}
    >
      <DialogTitle id="deleting-credit-card">{t(lang, 'menus.deleting_creditcard')}</DialogTitle>
      <DialogContent dividers>
        {t(lang, 'menus.deleting_creditcard_confirmation')}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {t(lang, 'misc.no')}
        </Button>
        <Button onClick={handleOk} color="primary">
          {t(lang, 'misc.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCreditCardDialog;
