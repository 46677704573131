import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ErrorBag } from 'src/models/ErrorModel';
import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'src/lib/language';
import { useEffect } from 'react';
import { getProductListAction } from 'src/redux/actions/productAction';
import { FORM_ID } from 'src/models/FormModel';
import Tooltip from '@material-ui/core/Tooltip';
import { ProductModel, ProductType } from 'src/models/ProductModel';
import {
  FaBoxOpen, FaDollarSign, FaHeart, FaTicketAlt,
} from 'react-icons/fa';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '200px',
    marginTop: '4px',
    width: '100%',
  },
}));

interface Props {
  disabled?: boolean
  form: FORM_ID,
  name: string,
  onChange: (id: number) => void,
  value?: number,
  onlyPublic?: boolean,
  onlyDeductible?: boolean,
  size?: 'small' | 'medium';
  type?: ProductType;
}

interface ProductItem {
  id: number,
  label?: string,
  type: ProductType,
}

const ProductsSelector = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    disabled,
    form,
    name,
    onChange,
    value,
    onlyPublic,
    size,
    onlyDeductible,
    type,
  } = props;

  useEffect(() => {
    dispatch(getProductListAction(type));
  }, []);

  const products = useSelector((state: Store) => {
    let currentProducts = state.products;
    if (onlyPublic) {
      currentProducts = currentProducts.filter((product: ProductModel) => product.is_public);
    }
    if (onlyDeductible) {
      currentProducts = currentProducts.filter(
        (product: ProductModel) => !!product.deductible && (Number(product.deductible) > 0),
      );
    }
    return currentProducts;
  });

  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const errors: ErrorBag =
        useSelector((state: Store) => state.errors[form] || {} as ErrorBag);

  const getProductItems = () => {
    const items: ProductItem[] = [] as ProductItem[];
    if (!products.length) {
      return [{
        id: 0,
        label: undefined,
        type: ProductType.all,
      }] as ProductItem[];
    }
    const orderedProducts = products.sort((a, b) => a[`name_${lang}`].localeCompare(b[`name_${lang}`]));
    orderedProducts.forEach((item: any) => {
      items.push({
        id: item.id,
        label: item[`name_${lang}`],
        type: item.type,
      });
    });
    return items;
  };
  //
  const onValueChange = (item: ProductItem) => {
    if (!item || !item.id) {
      return onChange(0);
    }
    return onChange(item.id as number);
  };

  const getValue = (initialValue?: number) => {
    let result = {
      id: 0,
      label: undefined,
      type: ProductType.all,
    } as ProductItem;
    if (!products.length || !initialValue) {
      return result;
    }
    products.forEach((item: any) => {
      if (item.id === initialValue) {
        result = {
          id: item.id,
          label: item[`name_${lang}`],
          type: item.type,
        } as ProductItem;
      }
    });
    return result;
  };

  const getIcon = (productType: ProductType = ProductType.product) => {
    switch (productType) {
      case ProductType.ticket:
        return (<FaTicketAlt />);
      case ProductType.donation:
        return (<FaHeart />);
      case ProductType.fee:
        return (<FaDollarSign />);
      default:
        return (<FaBoxOpen />);
    }
  };

  const getOptionLabel = (option: ProductItem) => {
    if (option.label) {
      return `${option.label} (${option.type})`;
    }
    return '';
  };

  const renderOption = (option: ProductItem) => <span>{getIcon()} {option.label || ''}</span>;

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      key={`form-control-${name}`}
    >
      <Autocomplete
        size={size}
        disabled={disabled}
        value={getValue(value)}
        onChange={(e, item) => onValueChange(item as ProductItem)}
        options={getProductItems() || []}
        renderOption={renderOption}
        getOptionLabel={getOptionLabel}
        getOptionSelected={(option, item) => option.id === item.id}
        renderInput={(params) => (
          <Tooltip title="">
            <TextField
              {...params}
              variant="outlined"
              label={t(lang, 'forms.invoices.products')}
              error={!!errors[name]}
            />
          </Tooltip>
        )}
        key={`autocomplete-${name}`}
      />
    </FormControl>
  );
};

export default ProductsSelector;
