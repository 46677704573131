import AddCustomfieldForm from 'src/components/Forms/AddCustomfieldForm';
import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';

const AddCustomfieldModal = () => (
  <ModalBase
    name={MODALS.addCustomfields}
    size={MODALS_SIZE.small}
  >
    <AddCustomfieldForm />
  </ModalBase>
);

export default AddCustomfieldModal;
