import axios, { AxiosRequestConfig } from 'axios';
import { store } from 'src/redux/Store';
import fileDownload from 'js-file-download';

import { headers } from 'src/constants/Endpoints';
import { API_URL } from '../environments/environment';

// import Debug from 'src/lib/Debug';

export enum DownloadOption {
  download = 'download',
  display = 'display',
}

export interface RequestOptions {
  url: string,
  method: string
  data?: object | string
  headers?: object
  organisation: string
  download?: boolean;
}

export default function makeApiRequest(
  options: RequestOptions,
  downloadOption: DownloadOption = DownloadOption.download,
  filename?: string,
): Promise<APIResponse> {
  const headersAIC = {
    'x-aic-organisation': options.organisation || 'aic',
    Authorization: '',
    'X-AIC-LOCALE': store.getState().language.locale,
  };
  const { accessToken } = store.getState().auth;
  if (accessToken) {
    headersAIC.Authorization = `Bearer ${accessToken}`;
  }
  const url = options.url ? API_URL + options.url : API_URL;

  const config = {
    data: options.data,
    headers: {
      ...headers.DEFAULT,
      ...options.headers,
      ...headersAIC,
    },
    method: options.method,
    url,
  } as AxiosRequestConfig;

  if ((downloadOption === DownloadOption.download) && filename) {
    config.responseType = 'blob';
  }

  return axios(config)
    .then(async (response) => {
      if ((downloadOption === DownloadOption.download) && filename) {
        if (response.status !== 200) {
          const text = await response.data.text();
          const json = JSON.parse(text);
          return {
            data: json.data ?? undefined,
            success: json.success,
            status: response.status,
          };
        }
        if (response.status === 200) {
          fileDownload(response.data, filename);
        }
      }
      return {
        data: (!!response.data.data) ? response.data.data : response.data,
        success: response.data.success,
        status: response.status,
      };
    })
    .catch((response) => {
      if (!response.response || !response.response.data) {
        if (response.message.stack) {
          return {
            data: undefined,
            success: false,
            errors: true,
            status: response.status,
            message: response.message.stack,
          };
        }
        return {
          data: undefined,
          success: false,
          errors: true,
          status: response.status,
          message: response,
        };
      }
      const {
        code, errors, message, data, success,
      } = response.response.data;
      return {
        data,
        success,
        errors,
        status: code,
        message,
      };
    });
}
