import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { DIALOGS } from 'src/models/DialogModel';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { deleteRelationAction } from 'src/redux/actions/contactActions';

const DeleteRelationDialog = () => {
  const dispatch = useAppDispatch();

  const { opened, payload } =
    useSelector((state: Store) => {
      if (state.dialog[DIALOGS.deleteRelation]) {
        return state.dialog[DIALOGS.deleteRelation];
      }
      return { opened: false, payload: null };
    });

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const handleCancel = () => {
    dispatch(closeDialog({ dialog: DIALOGS.deleteRelation }));
  };

  const handleOk = () => {
    dispatch(closeDialog({ dialog: DIALOGS.deleteRelation }));
    dispatch(deleteRelationAction(payload));
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="deleting-relationship"
      open={opened}
    >
      <DialogTitle id="deleting-relationship">{t(lang, 'menus.deleting_relation')}</DialogTitle>
      <DialogContent dividers>
        {t(lang, 'menus.deleting_relation')}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {t(lang, 'misc.no')}
        </Button>
        <Button onClick={handleOk} color="primary">
          {t(lang, 'misc.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRelationDialog;
