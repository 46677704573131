import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import UserEditCreditCardForm from 'src/components/Forms/UserEditCreditCardForm';

const UserEditCreditCardModal = () => (
  <ModalBase
    name={MODALS.editUserCreditCard}
    size={MODALS_SIZE.small}
    height="48vh"
  >
    <UserEditCreditCardForm />
  </ModalBase>
);

export default UserEditCreditCardModal;
