import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { t } from 'src/lib/language';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  DELIVERY_TYPE,
  DownloadOptionsModel,
  FORMAT,
  ORIENTATION,
} from 'src/models/DownloadModel';
import { postDownloadSelectedEntitesAction } from 'src/redux/actions/querybuilderActions';

interface OwnProps {
  invoiceId: number;
}

type Props = OwnProps & ButtonProps;

const DownloadSelectedInvoiceButton = (props: Props) => {
  const { disabled, invoiceId } = props;
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language);
  const handleOnClick = () => {
    dispatch(postDownloadSelectedEntitesAction(ENTITIES.invoices, [invoiceId], {
      file: `invoices_${invoiceId}.pdf`,
      orientation: ORIENTATION.portrait,
      format: FORMAT.pdf,
      delivery: DELIVERY_TYPE.download,
      export_to_grid: false,
    } as DownloadOptionsModel));
  };

  return (
    <>
      <Tooltip
        title={t(lang, 'menus.download_current_invoice')}
        placement="top-start"
      >
        <span>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.download}
            onClick={handleOnClick}
            disabled={disabled}
          >
            <GetAppIcon />
          </ActionButton>
        </span>
      </Tooltip>
    </>
  );
};

export default DownloadSelectedInvoiceButton;
