import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ErrorBag } from 'src/models/ErrorModel';
import { TextField, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'src/lib/language';
import { useEffect } from 'react';
import {
  getAccountsAction,
  getDepartmentsAction,
  getProgramsAction,
} from 'src/redux/actions/dataActions';
import { FORM_ID } from 'src/models/FormModel';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '200px',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));

export enum MetadataType {
  department = 'departments',
  program = 'programs',
  account = 'accounts',
}

interface Props {
  form: FORM_ID,
  name: string,
  onChange: (id:number) => void,
  value?: number,
  type: MetadataType,
  margin?: 'dense' | 'normal' | undefined,
  noMarginTop?: boolean
  disabled?: boolean,
}

interface DataItem {
  id: number,
  label?: string,
  description?: string,
}

const MetadataSelector = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    form, name, onChange, value, type, margin, noMarginTop, disabled,
  } = props;

  const data = useSelector((state: Store) => state.data[type]);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const errors: ErrorBag =
    useSelector((state:Store) => state.errors[form] || {} as ErrorBag);

  useEffect(() => {
    switch (type) {
      case MetadataType.program:
        dispatch(getProgramsAction());
        break;
      case MetadataType.department:
        dispatch(getDepartmentsAction());
        break;
      default:
        dispatch(getAccountsAction());
    }
  }, []);

  const getItems = () => {
    const items:DataItem[] = [{ id: 0 }];
    if (!data || !data.length) {
      return items;
    }
    data.forEach((item: any) => {
      items.push({
        id: item.id,
        label: item[`name_${lang}`],
        description: item[`description_${lang}`],
      } as DataItem);
    });
    return items;
  };

  const onValueChange = (item: DataItem) => {
    if (!item) {
      onChange(0);
      return null;
    }
    onChange(item.id as number);
    return null;
  };

  const getValue = (initialValue?: number) => {
    let result = {
      id: 0,
      label: undefined,
    } as DataItem;
    if (!data || !data.length) {
      return result;
    }
    data.forEach((item: any) => {
      if (item.id === initialValue) {
        result = {
          id: item.id,
          label: item[`name_${lang}`],
        } as DataItem;
      }
    });
    return result;
  };

  const displayLabel = (option:DataItem) => {
    if (option.label) {
      return (
        <Tooltip
          title={option.description as string}
          placement="top-start"
        >
          <span>{ option.label }</span>
        </Tooltip>
      );
    }
    return (<></>);
  };

  const getOptionLabel = (option: DataItem) => option.label || '';

  return (
    <Autocomplete
      disabled={disabled}
      className={`${noMarginTop ? '' : classes.marginTop} ${classes.formControl}`}
      value={getValue(value)}
      onChange={(e, item) => onValueChange(item as DataItem)}
      options={getItems() || []}
      getOptionLabel={(option: DataItem) => getOptionLabel(option)}
      getOptionSelected={(option, item) => option.id === item.id}
      renderOption={(option: DataItem) => displayLabel(option)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={t(lang, `forms.invoices.${type}`)}
          placeholder={t(lang, `forms.invoices.${type}`)}
          error={!!errors[name]}
          helperText={errors[name] && errors[name].join(',')}
          margin={margin || 'normal'}
        />
      )}
    />
  );
};

export default MetadataSelector;
