import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { SEARCH_LOGICAL_OPERATORS } from 'src/models/AdvanceSearchModel';
import { getEntitiesAction, setFilters } from 'src/redux/actions/querybuilderActions';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { useAppDispatch } from 'src/redux/Store';
import { useCallback, useEffect, useState } from 'react';
import { getReceiptFilter, getReceiptRangeDate } from 'src/lib/PaymentHelper';
import { ReceiptSections } from 'src/components/Pages/UserReceiptsPage';
import { debounce } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
// eslint-disable-next-line import/no-extraneous-dependencies
import MomentUtils from '@date-io/moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';

interface Props {
  section: ReceiptSections,
}

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    gap: '8px',
    alignItems: 'center',
  },
  col2: {
    flex: 1,
    flexDirection: 'column',
    width: '50%',
    alignItems: 'center',
  },
}));

const UserReceiptSearch = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { section } = props;

  const [start, end] = getReceiptRangeDate();

  const [startDate, setStartDate] = useState(start);

  const [endDate, setEndDate] = useState(end);

  useEffect(() => {
    const [newStartDate, newEndDate] = getReceiptRangeDate();
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }, [section]);

  const handleSearch = () => {
    const filters = getReceiptFilter(
      startDate,
      endDate,
    );
    dispatch(setFilters({
      entity: ENTITIES.taxReceipts,
      filters: { [SEARCH_LOGICAL_OPERATORS.and]: filters },
    }));
    dispatch(getEntitiesAction(ENTITIES.taxReceipts));
  };

  const searchInvoice = useCallback(
    debounce(async () => {
      handleSearch();
    }, 300),
    [], // will be created only once initially
  );

  const handleDateChange = (position: number, value: MaterialUiPickersDate) => {
    if (!value) { return; }

    let newStartDate;
    let newEndDate;

    if (position === 0) {
      newStartDate = value;
      newEndDate = endDate;
    } else {
      newStartDate = startDate;
      newEndDate = value;
    }

    const now = moment();

    if (newEndDate > now) {
      newEndDate = now;
    }

    if (newEndDate < newStartDate) {
      setStartDate(newStartDate);
      setEndDate(newStartDate);
      return;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    searchInvoice();
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div
        className={classes.row}
      >
        <div className={classes.col2}>
          <span style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
          >
            <b>Start:</b>
            <DatePicker
              disableToolbar
              format="MM/DD/yyyy"
              margin="dense"
              id="date-picker-inline-start"
              value={startDate}
              disableFuture
              onChange={(value) => handleDateChange(0, value)}
            />
          </span>
        </div>
        <div className={classes.col2}>
          <span style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
          >
            <b>End:</b>
            <DatePicker
              disableToolbar
              format="MM/DD/yyyy"
              margin="dense"
              id="date-picker-inline-end"
              value={endDate}
              disableFuture
              onChange={(value) => handleDateChange(1, value)}
            />
          </span>
        </div>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default UserReceiptSearch;
