import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import EditRelationForm from 'src/components/Forms/EditRelationForm';
import { RelationModel } from 'src/models/ContactModel';
import { useEffect, useState } from 'react';
import AddRelationForm from 'src/components/Forms/AddRelationForm';
import { t } from 'src/lib/language';
import { TextField, Tooltip } from '@material-ui/core';
import { getRelationsAction } from 'src/redux/actions/dataActions';

const useStyles = makeStyles((theme) => ({
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '10vh',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
  },
  scroller: {
    overflowY: 'auto',
    height: '62vh',
    padding: '8px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
  },
  search: {
    height: '6vh',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
  },
}));

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const ManageRelations = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getRelationsAction());
  }, []);

  const relationsData = useSelector((state: Store) => state.data.relations);
  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.manageRelations }));
  };

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const [currentRelation, setCurrentRelationField] = useState({} as RelationModel);
  const [currentState, setCurrentState] = useState(STATE.list);
  const [currentFilter, setCurrentFilter] = useState('');

  const handleDelete = (e:any, id:number) => {
    e.preventDefault();
    dispatch(openDialog({ dialog: DIALOGS.deleteRelation, payload: id }));
  };

  const handleEdit = (relation: RelationModel) => {
    setCurrentRelationField(relation);
    setCurrentState(STATE.edit);
  };

  const handleAdd = () => {
    setCurrentRelationField({} as RelationModel);
    setCurrentState(STATE.add);
  };

  const getTitle = () => {
    switch (currentState) {
      case STATE.add:
        return t(lang, 'menus.add_relation');
      case STATE.edit:
        return t(lang, 'menus.edit_relation');
      default:
        return t(lang, 'menus.manage_relation');
    }
  };

  const displayRelations = () => Object.keys(relationsData).map((key:string) => {
    const relation = relationsData[key];
    if (currentFilter) {
      if (
        !relation[`name_${lang}`].toLocaleLowerCase().includes(currentFilter.toLocaleLowerCase())
      ) return null;
    }
    return (
      <div className={classes.item} key={`relation-${key}`}>
        <span><b>{ relation[`name_${lang}`] }</b></span>
        <Tooltip
          title={t(lang, 'menus.edit_relation')}
          placement="top-start"
        >
          <span style={{ marginLeft: 'auto' }}>
            <ActionButton
              variant="outlined"
              category={MENU_BUTTON_CATEGORY.action}
              onClick={() => handleEdit(relation)}
            >
              <EditIcon />
            </ActionButton>
          </span>
        </Tooltip>
        <Tooltip
          title={t(lang, 'menus.delete_relation')}
          placement="top-start"
        >
          <span style={{ marginLeft: '8px' }}>
            <ActionButton
              variant="outlined"
              category={MENU_BUTTON_CATEGORY.action}
              onClick={(e) => handleDelete(e, relation.id)}
            >
              <DeleteIcon />
            </ActionButton>
          </span>
        </Tooltip>
      </div>
    );
  });

  const displayEditForm = () => (
    <EditRelationForm
      relation={currentRelation}
      onClose={() => setCurrentState(STATE.list)}
    />
  );

  const displayAddForm = () => (
    <AddRelationForm
      onClose={() => setCurrentState(STATE.list)}
    />
  );

  return (
    <>
      <div className={classes.header}>
        <div className={classes.row}>
          <div className={classes.title}><b>{ getTitle() }</b></div>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
        <div className={classes.row}>
          <Tooltip
            title={t(lang, 'menus.add_relation')}
            placement="top-start"
          >
            <span>
              <ActionButton
                category={MENU_BUTTON_CATEGORY.action}
                onClick={handleAdd}
              >
                <AddIcon />
              </ActionButton>
            </span>
          </Tooltip>
        </div>
      </div>
      { currentState === STATE.list && (
      <div className={classes.search}>
        <TextField
          margin="dense"
          variant="outlined"
          label="Filter"
          onChange={(e) => setCurrentFilter(e.target.value)}
        />
      </div>
      )}
      <div className={classes.scroller}>
        { currentState === STATE.list && displayRelations() }
        { currentState === STATE.add && displayAddForm() }
        { currentState === STATE.edit && displayEditForm() }
      </div>
    </>
  );
};

export default ManageRelations;
//
