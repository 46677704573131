import ContactModel from 'src/models/ContactModel';
import { ProductList } from 'src/models/ProductModel';
import { INVOICE_PAYMENT_STATUS, INVOICE_STATE } from 'src/constants/Invoices';
import { Moment } from 'moment';
import { OrganisationModel } from 'src/models/OrganisationModel';
import { TaxListModel } from 'src/models/TaxListModel';

export interface Country {
  id: number,
  ISO2: string,
  ISO3: string,
  name_en: string,
  name_fr: string,
}

export interface OrderTaxModel {
  [id:number] :number
}

export interface InvoiceInstallmentNumberModel {
  number: number,
}

export interface InvoiceModel {
  billing_address?: string,
  billing_suite?: string,
  billing_city?: string,
  billing_state?: string,
  billing_country_id?: number,
  billing_zip_code?: string,
  shipping_address?: string,
  shipping_suite?: string,
  shipping_city?: string,
  shipping_state?: string,
  shipping_country_id?: number,
  bank_account_id?: number,
  shipping_zip_code?: string,
  contact_company_name?: string,
  contact_salutation_id?: number,
  sameAsBilling?: boolean,
  hasInstallments?:boolean,
  installments?: number,
  id?: number,
  invoice_number?: string,
  contact_first_name?: string,
  contact_last_name?: string,
  account_id?:number,
  program_id?:number,
  department_id?:number,
  state?: INVOICE_STATE,
  due_date: Moment,
  issued_date?:Moment,
  title?:string,
  issue_tax_receipt?: boolean,
  payment_status?:INVOICE_PAYMENT_STATUS,
  contact_id?:number,
  seller_id?: number,
  contact_payer_id?: number,
  note?:string,
  order?:ProductOrderList,
  products?: ProductList,
  contact?: ContactModel,
  seller?:any,
  payer?:any,
  total?:number,
  subtotal?:number,
  taxes?:OrderTaxModel,
  paid?:number,
  balance?:number,
  deductible?:number,
  scheduled?: number,
  installment?: InvoiceInstallmentNumberModel,
  payment_gateway_id?: number,
}

export interface ProductOrderModel {
  product_id: number,
  quantity: number,
  paid: number,
  taxes?: OrderTaxModel,
  discount?: number,
  deductible?: number,
  note?: string,
}
export type ProductOrderList = Array<ProductOrderModel>;

export interface InvoicePayload {
  invoice: InvoiceModel,
  order?: OrderList,
}
export interface OrderModel {
  product_id: number,
  quantity: number,
  paid: number,
  taxes?: string,
  discount?: number,
  deductible?: number,
  note?: string,
}
export type OrderList = Array<OrderModel>;

export type InvoicesModel = {
  [id:number] :InvoiceModel
};

export type GuessInvoiceModel = {
  invoice: InvoiceModel,
  contact: ContactModel,
  seller?: ContactModel,
  payer?: ContactModel,
  products: ProductList,
  taxes: TaxListModel,
  organisation: OrganisationModel,
  billing_country: Country,
};

export type GuessInvoicesModel = {
  [id:number] :GuessInvoiceModel
};

export enum InvoiceOperations {
  manage_accounts = 'manage_accounts',
  manage_departments = 'manage_departments',
  manage_programs = 'manage_programs',
  manage_products = 'manage_products',
  manage_orders = 'manage_orders',
  show_invoice_payments = 'show_invoice_payments',
  download_selected = 'download_selected',
  send_by_email = 'send_by_email',
  make_payment = 'make_payment',
  duplicate_invoice = 'duplicate_invoice',
  make_final = 'make_final',
  delete_invoice = 'delete_invoice',
}
