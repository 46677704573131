import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { MouseEvent } from 'react';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { DIALOGS } from 'src/models/DialogModel';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { INVOICE_STATE } from 'src/constants/Invoices';

interface Props {
  handleSubmit: (
    e: MouseEvent<HTMLElement>,
    state: INVOICE_STATE,
    needConfirmation: Boolean
  ) => boolean;
}

const ReplaceInvoiceDialog = (props:Props) => {
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const { handleSubmit } = props;
  const opened =
    useSelector((state: Store) => state.dialog[DIALOGS.replaceInvoice]?.opened || false);
  const dispatch = useAppDispatch();
  const handleCancel = () => {
    dispatch(closeDialog({ dialog: DIALOGS.replaceInvoice }));
  };

  return (
    <Dialog
      aria-labelledby="replace-invoice"
      open={opened}
    >
      <DialogTitle id="replace-invoice">{t(lang, 'messages.replace_invoice')}</DialogTitle>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          { t(lang, 'misc.cancel') }
        </Button>
        <Button onClick={(e) => handleSubmit(e, INVOICE_STATE.final, false)} color="primary">
          { t(lang, 'misc.save') }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReplaceInvoiceDialog;
