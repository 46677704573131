import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { getContactBankAccountAction } from 'src/redux/actions/paymentInfoAction';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { BankAccountModel } from 'src/models/BankAccountModel';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { setCurrentEntity } from 'src/redux/actions/currentEntityActions';
import EditIcon from '@material-ui/icons/Edit';
import { openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';

interface Props {
  contactId: number;
}

const useStyles = makeStyles((theme) => ({
  paymentInfo: {
    fontSize: '0.875rem',
    fontStyle: 'italic',
    display: 'flex',
    alignItems: 'center',
  },
  deletePaymentInfo: {
    color: theme.palette.action.active,
    margin: '0 4px',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}));

const ContactBankAccountDisplayer = (props: Props) => {
  const { contactId } = props;
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const bankAccounts = useSelector(
    (state: Store) => {
      if (!!state.contactBankAccounts[contactId]) {
        return state.contactBankAccounts[contactId] as BankAccountModel[];
      }
      return [] as BankAccountModel[];
    },
  );

  useEffect(() => {
    dispatch(
      getContactBankAccountAction(contactId),
    );
  }, []);

  const handleDeleteBankAccount = (id: number) => {
    dispatch(setCurrentEntity({ entity: ENTITIES.bankAccounts, id }));
    dispatch(openDialog(
      { dialog: DIALOGS.deleteBankAccount, payload: { accountId: id, contactId } },
    ));
  };

  const handleEditCreditCard = (account: BankAccountModel) => {
    dispatch(openModal({
      modal: MODALS.editBankAccount,
      payload: {
        account,
        contactId,
      },
    }));
  };

  const displayBankAccount = () => bankAccounts.map((account: BankAccountModel, index: number) => (
    <div
      className={classes.paymentInfo}
      key={`bank-account-${index}`}
    >
      <span>{`${account.bank}-${account.branch}-${account.account} (${account.bank_holder_name})`}</span>
      <DeleteIcon
        className={classes.deletePaymentInfo}
        fontSize="small"
        onClick={() => handleDeleteBankAccount(account.id)}
      />
      <EditIcon
        className={classes.deletePaymentInfo}
        fontSize="small"
        onClick={() => handleEditCreditCard(account)}
      />
    </div>
  ));

  return (
    <>{displayBankAccount()}</>
  );
};

export default ContactBankAccountDisplayer;
