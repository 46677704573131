import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { Box, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RoundIcon from 'src/components/Elements/RoundIcon';
import { v4 as uuid } from 'uuid';
import { postOrganisationsUserAction } from 'src/redux/actions/organisationActions';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import Button from '@material-ui/core/Button';
import { useState } from 'react';
import { t } from 'src/lib/language';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  userOrganisationsBox: {
    margin: 0,
    padding: 0,
    fontSize: '1.0rem',
  },
  spacer: {
    marginRight: theme.spacing(2),
  },
  card: {
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.75)',
    color: 'rgba(0, 0, 0, 0.75)',
    borderRadius: '5px',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 2),
    width: '100%',
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.5)',
      borderColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
  deleteIcon: {
    marginRight: theme.spacing(0.5),
  },
  icon: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  text: {
    paddingLeft: '8px',
  },
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '8vh',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: '6vh',
    gap: '10px',
  },
  scroller: {
    overflowY: 'auto',
    height: '86vh',
    padding: '8px',
  },
  search: {
    height: '6vh',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
  },
}));

const ChooseOrganisationForm = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [currentFilter, setCurrentFilter] = useState('');

  const lang = useSelector((state: Store) => state.language.language);

  const organisationsUserList = useSelector((state: Store) => state.organisationsUser);

  const organisations = useSelector((state: Store) => state.organisationList);

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.chooseOrganisation }));
  };

  const handleCardClick = (id:string) => {
    dispatch(postOrganisationsUserAction(id));
    handleClose();
  };

  const displayAvailableOrganisations = () => {
    const elements = [] as Array<JSX.Element>;
    Object.keys(organisations).forEach((id: string) => {
      if (!organisationsUserList[id] || !organisationsUserList[id].hide) {
        if (currentFilter) {
          const [first, last] = lang.split('');
          if (
            !organisations[id][`name${first.toUpperCase()}${last}`]
              .toUpperCase()
              .includes(currentFilter.toUpperCase())
          ) {
            return;
          }
        }
        elements.push(
          <Box
            className={classes.card}
            key={uuid()}
            onClick={() => handleCardClick(id)}
          >
            <div className={classes.icon}>
              <RoundIcon>
                { organisations[id].nameEn.substr(0, 1).toUpperCase() }
              </RoundIcon>
            </div>
            <div className={classes.text}>
              <Typography>
                <b>{ organisations[id].nameEn }</b>
              </Typography>
              <Typography>
                Short description of the mission of the organisation.
              </Typography>
            </div>
          </Box>,
        );
      }
    });
    return elements;
  };

  return (
    <>
      <div className={classes.header}>
        <div className={classes.row}>
          <div className={classes.title}>{t(lang, 'misc.connect_to_organisation')}</div>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}
          >
            {t(lang, 'misc.close')}
          </Button>
        </div>
      </div>
      <div className={classes.search}>
        <TextField
          margin="dense"
          variant="outlined"
          label="Filter"
          onChange={(e) => setCurrentFilter(e.target.value)}
        />
      </div>
      {displayAvailableOrganisations()}
    </>
  );
};

export default ChooseOrganisationForm;
