import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { getContactActionForMerge, mergeContactsAction } from 'src/redux/actions/contactActions';
import ContactModel, {
  APIContactPayload,
  ContactPayload,
  CustomfieldModel,
} from 'src/models/ContactModel';
import Button from '@material-ui/core/Button';
import Subheader, { SUBHEADER_COLOR, SUBHEADER_SIZE } from 'src/components/Elements/Subheader';
import { makeStyles } from '@material-ui/core/styles';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import MergeContactFieldChooser from 'src/components/Elements/MergeContactFieldChooser';
import { f, t } from 'src/lib/language';
import columns from 'src/constants/columns';
import { isEmpty } from 'lodash';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import SaveIcon from '@material-ui/icons/Save';
import { FIELD_TYPES } from 'src/constants/Fields';

const useStyles = makeStyles((theme) => ({
  boolean: {
    textAlign: 'center',
    display: 'inline-block',
    height: '20px',
    position: 'relative',
    top: '4px',
  },
  close: {
    padding: 5,
    minHeight: 0,
    minWidth: 0,
    marginTop: 12,
    float: 'right',
    '&> span': {
      lineHeight: 0.75,
    },
  },
  container: {
    width: '100%',
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  first: {
    width: '50%',
    borderRight: '1px solid rgba(0,0,0, 0.25)',
    paddingRight: theme.spacing(2),
    float: 'left',
  },
  second: {
    width: '50%',
    float: 'right',
    paddingLeft: theme.spacing(2),
  },
  buttonContainer: {
    width: '100%',
    overflow: 'hidden',
  },
  submit: {
    float: 'right',
  },
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '10vh',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
  },
  scroller: {
    overflowY: 'auto',
    height: '66vh',
    padding: '8px',
  },
  search: {
    height: '6vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
  },
}));

type SelectedFields = {
  [field: string]: SELECTED_CONTACT;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SELECTED_CONTACT {
  first = 0,
  second = 1,
  none = 2,
}

interface Fields {
  name: string,
  type: FIELD_TYPES,
}

const MergeContactForm = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  // *****************************************************88
  // Selector

  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.contacts] || [],
  );

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const firstContact = useSelector(
    (state: Store) => {
      if (!!state.contacts[selections[SELECTED_CONTACT.first] as number]) {
        return state.contacts[selections[SELECTED_CONTACT.first] as number] as ContactPayload;
      }
      return {} as ContactPayload;
    },
  );

  const secondContact = useSelector(
    (state: Store) => {
      if (!!state.contacts[selections[SELECTED_CONTACT.second] as number]) {
        return state.contacts[selections[SELECTED_CONTACT.second] as number] as ContactPayload;
      }
      return {} as ContactPayload;
    },
  );

  const localeData = useSelector((state: Store) => state.language.locale || 'en_CA');
  const countriesData = useSelector((state: Store) => state.data.countries);
  const salutationsData = useSelector((state: Store) => state.data.salutations);
  const customfieldsData = useSelector((state: Store) => state.customfields);
  const groupsData = useSelector((state: Store) => state.contactGroup);

  const [contactState, setContactState] = useState({} as ContactModel);
  const [customfieldState, setCustomfieldState] = useState({} as any);
  const [groupsState, setGroupsState] = useState([] as number[]);

  const [selectedContact, setSelectedContact] = useState({} as SelectedFields);
  const [selectedCustomfield, setSelectedCustomfield] = useState({} as SelectedFields);
  const [selectedGroup, setSelectedGroup] = useState(SELECTED_CONTACT.first);

  const [disabled, setDisabled] = useState(isEmpty(firstContact) || isEmpty(secondContact));

  useEffect(() => {
    setDisabled(isEmpty(firstContact) || isEmpty(secondContact));
  }, [secondContact, firstContact]);

  const excludedFields = [
    'id',
    'deleted_at',
    'updated_at',
    'created_at',
    'user_id',
    'email_status',
    'contact_relations',
  ];

  const isExcludedField = (fieldName: string): boolean => excludedFields.includes(fieldName);

  const removeExcludedFields = (contact: ContactModel) => {
    const newContact = { ...contact };
    excludedFields.forEach((field) => {
      if (field in newContact) {
        delete newContact[field as keyof ContactModel];
      }
    });
    return newContact;
  };

  const excludedTypes = [
    'single-list',
    'multiple-list',
  ];

  const isExcludedType = (type: string): boolean => excludedTypes.includes(type);

  const removeExcludedTypes = (customFields?:CustomfieldModel) => {
    if (!customFields) return undefined;
    const newCustomFields = {} as CustomfieldModel;
    const values = Object.values(customfieldsData);
    values.forEach((customField) => {
      if (!isExcludedType(customField.type_id) && !!customField.name) {
        newCustomFields[customField.name] = customFields[customField.name];
      }
    });
    return newCustomFields;
  };

  useEffect(() => {
    dispatch(getContactActionForMerge(
      selections[SELECTED_CONTACT.first] as number,
      selections[SELECTED_CONTACT.second] as number,
    ));
  }, []);

  const getInitialSelectedContact = (contact: ContactModel | undefined): SelectedFields => {
    const initialSelectedContact = {} as SelectedFields;
    if (!contact) return initialSelectedContact;
    const initialContact = removeExcludedFields(contact);

    Object.keys(initialContact).forEach((field: string) => {
      initialSelectedContact[field] = SELECTED_CONTACT.first;
    });

    return initialSelectedContact;
  };

  const getInitialSelectedCustomfields = () => {
    const initialSelectedCustomfields = {} as SelectedFields;
    Object.keys(customfieldsData).forEach((field:string) => {
      initialSelectedCustomfields[field] = SELECTED_CONTACT.first;
    });
    return initialSelectedCustomfields;
  };

  useEffect(() => {
    const { contact, customfields, groups } = firstContact;

    setContactState(contact);
    setCustomfieldState(removeExcludedTypes(customfields));
    setGroupsState(groups as number[]);
    setSelectedContact(getInitialSelectedContact(contact));
    setSelectedCustomfield(getInitialSelectedCustomfields());
  }, [firstContact]);

  // ***********************************************************
  // State

  const getSelectedContact =
    (property: string) => selectedContact[property] || SELECTED_CONTACT.first;

  const getSelectedCustomfield =
    (property: string) => selectedCustomfield[property] || SELECTED_CONTACT.first;

  const getSelectedGroup =
    () => selectedGroup || SELECTED_CONTACT.first;

  const getCountry = (id: number): string => {
    const country = countriesData.find((element) => element.id === id);
    return country ? country[`name_${lang}`] || '' : '';
  };

  const getSalutation = (id: number) => {
    const salutation = salutationsData.find((element) => element.id === id);
    return salutation ? salutation.name || '' : '';
  };

  // ***********************************************************
  // Format
  // const formatPhoneNumber = (phone: string) => new AsYouType('US').input(phone);

  const formatDate = (dateValue: string) => {
    if (!dateValue) return '';
    const date = new Date(dateValue);
    return date.toLocaleDateString(localeData.replace('_', '-'), {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const formatBoolean = (booleanValue: number) => (
    booleanValue ? t(lang, 'misc.y') : t(lang, 'misc.n')
  );

  const formatList = (type: string, value: string | string[]) => {
    if (type === 'single-list') {
      return value;
    }
    const valueArray = value as string[];
    return valueArray.join(', ');
  };

  const formatField = (property: string, fieldValue: any) => {
    switch (property) {
      case 'billing_country_id':
      case 'shipping_country_id':
      case 'country_id':
        return getCountry(fieldValue as number);
      case 'salutation_id':
        return getSalutation(fieldValue as number);
      case 'created_at':
      case 'updated_at':
      case 'deleted_at':
        return fieldValue ? formatDate(fieldValue as string) : '';
      case 'email_allow_notification':
      case 'secondary_email_allow_notification':
      case 'phone_home_allow_notification':
      case 'phone_mobile_allow_notification':
      case 'phone_office_allow_notification':
        return formatBoolean(fieldValue as number);
      default:
        return fieldValue;
    }
  };

  const formatCustomField = (property: string, customFieldValue: any) => {
    if (!customfieldsData[property]) {
      return '';
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { type_id } = customfieldsData[property];
    switch (type_id) {
      case 'single-list':
      case 'multiple-list':
        return formatList(type_id, customFieldValue);
      case 'date':
        return (
          formatDate(customFieldValue as string)
        );
      case 'boolean':
        return formatBoolean(customFieldValue as number);
      default:
        return customFieldValue;
    }
  };

  const formatGroups = (groupsToFormat: number[]) => {
    const result = [] as string[];
    if (!groupsToFormat) { return ''; }
    groupsToFormat.forEach((groupId) => {
      if (!!groupsData[groupId]) {
        result.push(groupsData[groupId][`name_${lang}`]);
      }
    });
    return result.join(', ');
  };

  // *******************************************88
  // Handlers

  const handleContactChange = (selected: SELECTED_CONTACT, property: string) => {
    if (
      selectedContact[property] === selected
      && selectedContact[property] !== SELECTED_CONTACT.none
    ) {
      setContactState({
        ...contactState,
        [property]: undefined,
      });
      setSelectedContact({
        ...selectedContact,
        [property]: SELECTED_CONTACT.none,
      });
      return;
    }

    const value =
      selected === SELECTED_CONTACT.first
        ? firstContact.contact[property]
        : secondContact.contact[property];

    setContactState({
      ...contactState,
      [property]: value,
    });
    setSelectedContact({
      ...selectedContact,
      [property]: selected,
    });
  };

  const handleCustomfieldsChange = (selected: SELECTED_CONTACT, property: string) => {
    if (
      selectedCustomfield[property] === selected
      && selectedContact[property] !== SELECTED_CONTACT.none
    ) {
      setCustomfieldState({
        ...customfieldState,
        [property]: undefined,
      });
      setSelectedCustomfield({
        ...selectedCustomfield,
        [property]: SELECTED_CONTACT.none,
      });
      return;
    }
    const value =
      selected === SELECTED_CONTACT.first
        ? (firstContact.customfields && firstContact.customfields[property]) || undefined
        : (secondContact.customfields && secondContact.customfields[property]) || undefined;
    setCustomfieldState({
      ...customfieldState,
      [property]: value,
    });
    setSelectedCustomfield({
      ...selectedCustomfield,
      [property]: selected,
    });
  };

  const handleGroupsChange = (selected: SELECTED_CONTACT) => {
    const value =
      selected === SELECTED_CONTACT.first
        ? firstContact.groups
        : secondContact.groups;
    setGroupsState(value as number[]);
    setSelectedGroup(selected);
  };

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.mergeContacts }));
  };

  const handleSubmit = () => {
    setDisabled(true);
    const payload = {
      contact: contactState,
      custom_fields: customfieldState,
      groups: groupsState,
    } as APIContactPayload;
    dispatch(mergeContactsAction(
      selections[0] as number,
      selections[1] as number,
      setDisabled,
      payload,
    ));
  };

  // ***********************************************************
  // Display

  const displayContactValue = (contactOrder: SELECTED_CONTACT) => {
    const currentContact = contactOrder === SELECTED_CONTACT.first ? firstContact : secondContact;
    const fields = [] as Fields[];
    columns.contacts.forEach((column, index) => {
      const name = column.field.split('.')[1];
      if (isExcludedField(name)) return;
      const { type } = column;
      fields[index] = {
        name,
        type,
      };
    });
    const result = [] as any[];

    if (Object.entries(currentContact).length > 0) {
      if (!currentContact.contact) return (<></>);
      fields.forEach((field) => {
        const { name } = field;
        const value = formatField(name, currentContact.contact[name]);
        result.push(
          <MergeContactFieldChooser
            disabled={disabled}
            order={contactOrder}
            onChoose={() => handleContactChange(contactOrder, name)}
            property={name}
            selected={getSelectedContact(name) === contactOrder}
            label={f(lang, `contacts.${name}`)}
            value={value || ''}
            key={`contact-field-${contactOrder}-${name}`}
          />,
        );
      });
    }
    return (<>{result}</>);
  };

  const displayCustomfieldValue = (contactOrder: SELECTED_CONTACT) => {
    const currentContact = contactOrder === SELECTED_CONTACT.first ? firstContact : secondContact;
    const result = [] as any[];
    if (Object.entries(currentContact).length > 0) {
      if (!currentContact.customfields) return (<></>);

      const { customfields } = currentContact;
      Object.keys(customfieldsData).forEach((key: string) => {
        if (isExcludedType(customfieldsData[key].type_id)) return;
        const property = (customfields && customfields[key]) || undefined;
        const value = formatCustomField(key, property);
        const label = customfieldsData[key][`display_${lang}`];
        result.push(
          <MergeContactFieldChooser
            disabled={disabled}
            order={contactOrder}
            onChoose={() => handleCustomfieldsChange(contactOrder, key)}
            property={key}
            selected={getSelectedCustomfield(key) === contactOrder}
            label={label}
            value={value || ''}
            key={`customfield-field-${contactOrder}-${key}`}
          />,
        );
      });
    }
    return (<>{result}</>);
  };

  const displayGroupsValue = (contactOrder: SELECTED_CONTACT) => {
    const currentContact = contactOrder === SELECTED_CONTACT.first ? firstContact : secondContact;
    if (!currentContact.groups) return (<></>);
    const value = formatGroups(currentContact.groups as number[]);
    const label = 'Groups';
    return (
      <MergeContactFieldChooser
        disabled={disabled}
        order={contactOrder}
        onChoose={() => handleGroupsChange(contactOrder)}
        property=""
        selected={getSelectedGroup() === contactOrder}
        label={label}
        value={value || ''}
        key={`group-field-${contactOrder}`}
      />
    );
  };

  // ****************************************
  // Render

  return (
    <>
      <div className={classes.header}>
        <div className={classes.row}>
          <div className={classes.title}>
            <b>{ t(lang, 'forms.contact.merge_contact') }</b>
          </div>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
        <div className={classes.row}>
          <Tooltip
            title={t(lang, 'forms.contact.merge_contact')}
            placement="top-start"
          >
            <span>
              <ActionButton
                category={MENU_BUTTON_CATEGORY.action}
                onClick={handleSubmit}
                disabled={disabled}
              >
                <SaveIcon />
              </ActionButton>
            </span>
          </Tooltip>
        </div>
      </div>
      <div className={classes.scroller}>
        <div className={classes.container}>
          <div className={classes.first}>
            <Subheader size={SUBHEADER_SIZE.small} color={SUBHEADER_COLOR.textPrimary} noMargin>
              { t(lang, 'forms.contact.contact') }
            </Subheader>
            { displayContactValue(SELECTED_CONTACT.first) }
            { displayCustomfieldValue(SELECTED_CONTACT.first) }
            { displayGroupsValue(SELECTED_CONTACT.first) }
          </div>
          <div className={classes.second}>
            <Subheader size={SUBHEADER_SIZE.small} color={SUBHEADER_COLOR.textPrimary} noMargin>
              { t(lang, 'forms.contact.contact_to_merge') }
            </Subheader>
            { displayContactValue(SELECTED_CONTACT.second)}
            { displayCustomfieldValue(SELECTED_CONTACT.second)}
            { displayGroupsValue(SELECTED_CONTACT.second) }
          </div>
        </div>
      </div>
    </>

  );
};

export default MergeContactForm;
//
