import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ButtonProps } from '@material-ui/core/Button/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import { t } from 'src/lib/language';
import { DIALOGS } from 'src/models/DialogModel';
import { openDialog } from 'src/redux/actions/dialogActions';
import { ENTITIES } from 'src/models/QuerybuilderModel';

const DownloadPaymentButton = (props: ButtonProps) => {
  const dispatch = useAppDispatch();
  const { disabled } = props;

  const lang = useSelector((state: Store) => state.language.language);

  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.payments] || [],
  );

  const hasReceipts = useSelector(
    (state: Store) => {
      if (!selections.length) { return false; }
      return selections.reduce(
        (result: boolean, id: number) => result || state.payments[id].tax_receipt_issued,
      );
    },
  );

  const handleOpen = () => {
    dispatch(openDialog({ dialog: DIALOGS.paymentDownload }));
  };

  return (
    <>
      <Tooltip
        title={t(lang, 'menus.download_payments')}
        placement="top-start"
      >
        <span>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.download}
            onClick={handleOpen}
            disabled={disabled || !hasReceipts}
          >
            <GetAppIcon />
          </ActionButton>
        </span>
      </Tooltip>
    </>
  );
};

export default DownloadPaymentButton;
