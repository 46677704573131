import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { f } from 'src/lib/language';
import { makeStyles } from '@material-ui/core/styles';
import { GridColumnHeaderParams } from '@material-ui/x-grid';
import {
  ENTITIES, GridColumns, ORDER, QuerybuilderOrders,
} from 'src/models/QuerybuilderModel';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getEntitiesAction, setOrders } from 'src/redux/actions/querybuilderActions';
import { findIndex } from 'lodash';

interface Props {
  entity: ENTITIES,
  params: GridColumnHeaderParams
  order: QuerybuilderOrders;
}

const useStyles = makeStyles(() => ({
  datagridHeaderCellText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    fontWeight: 'bold',
    display: 'inline-block',
  },
  datagridHeaderCellIcon: {
    display: 'inline-block',
    marginLeft: 'auto',
    marginRight: '0px',
    paddingTop: '15px',
  },
  datagridHeaderCell: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 4px',
  },
}));

const DatagridHeaderCell = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    entity,
    order,
    params,
  } = props;

  const lang = useSelector((state: Store) => state.language.language || 'en');
  const customfields = useSelector((state: Store) => state.customfields);
  const columns = useSelector(
    (state: Store) => (state.querybuilder[entity] && state.querybuilder[entity].columns)
      || [] as GridColumns,
  );

  const [table, property] = params.field.split('.');

  const caption = (table === 'custom_fields')
    ? customfields[property][`display_${lang}`]
    : f(lang, params.field);

  const getCaption = () => {
    const index = findIndex(columns, (column) => column.field === params.field);
    if (columns[index]?.display) {
      return columns[index].display;
    }
    return (table === 'custom_fields')
      ? customfields[property][`display_${lang}`]
      : f(lang, params.field);
  };

  const getOrder = () => {
    if (!order) return '';

    const current = order.filter(
      (item:any) => item[0] === params.field,
    );
    if (current.length) {
      return current[0][1];
    }
    return '';
  };

  const displayOrderIcon = () => {
    const currentOrder = getOrder();
    if (!currentOrder) return '';
    return getOrder() === ORDER.asc
      ? <ExpandLessIcon />
      : <ExpandMoreIcon />;
  };

  const handleHeaderClick = () => {
    const currentOrder = getOrder();

    let direction = '';
    switch (currentOrder) {
      case ORDER.asc:
        direction = '';
        break;
      case ORDER.desc:
        direction = ORDER.asc;
        break;
      default:
        direction = ORDER.desc;
    }

    if (direction) {
      dispatch(setOrders({
        entity,
        order: [[
          params.field, direction,
        ]],
      }));
      dispatch(getEntitiesAction(entity));
    } else {
      dispatch(setOrders({
        entity,
        order: [],
      }));
      dispatch(getEntitiesAction(entity));
    }
  };

  return (
    <div
      className={classes.datagridHeaderCell}
      onClick={() => handleHeaderClick()}
    >
      <div
        className={classes.datagridHeaderCellText}
        title={caption}
      >
        { getCaption() }
      </div>
      <div className={classes.datagridHeaderCellIcon}>{displayOrderIcon()}</div>
    </div>
  );
};

export default DatagridHeaderCell;
