import { ChangeEvent, FormEvent, useState } from 'react';
import { Store, useAppDispatch } from 'src/redux/Store';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Copyright from 'src/components/Elements/Copyright';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import LanguageSelector from 'src/components/Control/LanguageSelectorLogin';
import { FORM } from 'src/constants/Form';
import { ErrorBag } from 'src/models/ErrorModel';
import { setError } from 'src/redux/actions/errorsActions';
import { resetPasswordAction } from 'src/redux/actions/userActions';
import { t } from 'src/lib/language';
import { ResetPasswordData } from 'src/apis/UserAPI';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  pointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  policy: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.775rem',
  },
  copyright: {
    position: 'absolute',
    bottom: theme.spacing(2),
  },
  inlineText: {
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'inline-block',
  },
  forgetPassword: {
    width: '100%',
    padding: theme.spacing(2),
  },
  forgetPasswordText: {
    marginBottom: theme.spacing(2),
    color: 'rgba(0, 0, 0, 0.54)',
  },
  language: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(4),
  },
}));

type OnChangeEvent = ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

const ResetPasswordPage = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const resetPasswordErrors: ErrorBag =
    useSelector((state: Store) => state.errors[FORM.reset_password] || {} as ErrorBag);

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const location = useLocation();
  const queryStringToSend = location.search;
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const init = searchParams.get('init');

  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
    submitted: false,
  });

  const resetPasswordRules: any = Yup.object().shape({
    password: Yup.string()
      .required(t(lang, 'validations.login.password_required'))
      .min(6, t(lang, 'validations.login.password_min')),
    password_confirmation: Yup.string()
      .test('passwords-match', t(lang, 'validations.login.password_must_match'), function (value) {
        /* eslint-disable react/no-this-in-sfc */
        return this.parent.password === value;
      })
      .required(t(lang, 'validations.login.confirm_password_required')),
  });

  const onPasswordChange = (event: OnChangeEvent) => {
    setState({
      ...state,
      password: event.target.value,
    });
  };

  const onConfirmPasswordChange = (event: OnChangeEvent) => {
    setState({
      ...state,
      confirmPassword: event.target.value,
    });
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(setError({ [FORM.reset_password]: {} as ErrorBag }));
    const { confirmPassword, password } = state;
    const passwordData = {
      email,
      token,
      password,
      password_confirmation: confirmPassword,
    } as ResetPasswordData;
    try {
      resetPasswordRules.validateSync(passwordData, { abortEarly: false });
    } catch (validationErrors: any) {
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.reset_password]: errorBag }));
      return false;
    }
    dispatch(resetPasswordAction(passwordData, queryStringToSend));
    setState({
      ...state,
      submitted: true,
    });
    return false;
  };

  const getTitle = () => {
    if (init) {
      return t(lang, 'pages.reset_password.init_password');
    }
    return t(lang, 'pages.reset_password.reset_password');
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        {getTitle()}
      </Typography>
      <div className={classes.language}>
        <span style={{ marginRight: '-5px' }}>{t(lang, 'pages.reset_password.language')}:</span><LanguageSelector />
      </div>
      <form className={classes.form} noValidate onSubmit={(event) => onSubmit(event)}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label={t(lang, 'pages.reset_password.password')}
          type="password"
          id="password"
          autoComplete="current-password"
          autoFocus
          onChange={(event) => onPasswordChange(event)}
          error={!!resetPasswordErrors.password}
          helperText={resetPasswordErrors.password && resetPasswordErrors.password.join(',')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="confirmPassword"
          label={t(lang, 'pages.reset_password.confirm_password')}
          type="password"
          name="confirmPassword"
          autoComplete="confirmPassword"
          onChange={(event) => onConfirmPasswordChange(event)}
          error={!!resetPasswordErrors.password_confirmation}
          helperText={
            resetPasswordErrors.password_confirmation &&
            resetPasswordErrors.password_confirmation.join(',')
          }
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {getTitle()}
        </Button>
      </form>
      <div className={classes.policy}>
        {t(lang, 'pages.reset_password.policy')}
        <Link href="/privacy" variant="body2">{t(lang, 'pages.reset_password.privacy_policy')}</Link>
        &nbsp;&&nbsp;
        <Link href="/service" variant="body2">{t(lang, 'pages.reset_password.tos')}</Link>.
      </div>
      <Box mt={5} className={classes.copyright}>
        <Copyright />
      </Box>
    </>
  );
};

export default ResetPasswordPage;
