import CssBaseline from '@material-ui/core/CssBaseline';
import { Route } from 'src/constants/routes';
import contentPageStyle from 'src/theme/ContentPageTheme';
import Content from 'src/components/Layout/Content';
import Main from 'src/components/Layout/Main';
import GuessInvoicePage from 'src/components/Pages/GuessInvoicePage';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  content: string
}

const useStyles = makeStyles((theme) => ({
  content: {
    flex: 1,
    background: '#FFF',
    width: '100%',
    marginTop: '38px',
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function GuessLayout(props: Props) {
  const classes = contentPageStyle();
  const styles = useStyles();
  const { content } = props;

  const displayContent = () => {
    switch (content) {
      case Route.GUESS_INVOICE:
        return <GuessInvoicePage />;
      default:
        return (
          <></>
        );
    }
  };

  return (
    <div>
      <div className={classes.root}>
        <CssBaseline />
        <Main>
          <Content className={styles.content}>
            { displayContent() }
          </Content>
        </Main>
      </div>
    </div>
  );
}
//
