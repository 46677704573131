import { SyntheticEvent } from 'react';
import SubmenuLink from 'src/components/Elements/SubmenuLink';
import { openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { useAppDispatch } from 'src/redux/Store';
import { getS3URL } from 'src/lib/S3File';

interface Props {
  content?:any;
  errors?: string;
  height: number
  hidePreview?: boolean;
  name: string
  path: string
  width: number
}

const FormUploadImage = (props: Props) => {
  const dispatch = useAppDispatch();
  const {
    content,
    errors,
    height,
    hidePreview,
    name,
    path,
    width,
  } = props;

  const getURL = () => `${getS3URL()}${path}?${performance.now()}`;

  const onUploadImage = () => {
    dispatch(openModal({
      modal: MODALS.uploadImage,
      payload: {
        path,
        size: {
          width,
          height,
          ratio: width / height,
        },
        name,
      },
    }));
  };

  const imgStyle = {
    // backgroundImage: `url(${getURL()})`,
    // backgroundSize: 'contain',
    // backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center',
    backgroundColor: '#f8f8f8',
    display: hidePreview ? 'none' : '',
    width: '100%',
  };

  const getColor = () => (!!errors ? '#f00' : '#0A4DF2');

  return (
    <div style={{ marginBottom: hidePreview ? '0px' : '16px' }}>
      { content && (
        { content }
      )}
      { !content && (
        <>
          <div style={imgStyle}>
            { path && (
              <img
                style={{ maxWidth: '100%' }}
                alt="url"
                src={getURL()}
              // eslint-disable-next-line no-param-reassign
                onError={(event:SyntheticEvent<HTMLImageElement>) => {
                  const target = event.target as HTMLImageElement;
                  target.style.display = 'none';
                }}
              />
            )}
          </div>
          <SubmenuLink
            onClick={onUploadImage}
            selected
            color={getColor()}
          >
            Upload Image
          </SubmenuLink>
        </>
      )}
    </div>
  );
};

export default FormUploadImage;
