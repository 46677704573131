import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import UserPaymentForm from 'src/components/Forms/UserPaymentForm';

const MakeUserPaymentModal = () => (
  <ModalBase
    name={MODALS.makeUserPayment}
    size={MODALS_SIZE.small}
  >
    <UserPaymentForm />
  </ModalBase>
);

export default MakeUserPaymentModal;
