import { MouseEvent, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import {
  OrganisationContactModel,
  OrganisationAddressPayload,
} from 'src/models/OrganisationModel';
import Button from '@material-ui/core/Button';
import { setError } from 'src/redux/actions/errorsActions';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import { putOrganisationAddressAction } from 'src/redux/actions/organisationActions';
import CountriesSelector from 'src/components/Control/CountriesSelector';
import OrganisationContactValidator from 'src/validations/OrganisationContactValidator';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: '640px',
  },
  checkbox: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginTop: '10px',
  },
}));

const OrganisationAddressForm = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const organisation = useSelector((state: Store) => state.organisation);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const processing = useSelector(
    (state: Store) => (state.formStatus[FORM.edit_organisation_address] === FORM_STATUS.processing),
  );

  const [formState, setFormState] = useState(organisation as OrganisationContactModel);

  const onFieldChange = (value: Value, field: string) => {
    setFormState({
      ...formState,
      [field]: value,
    });
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setError({ [FORM.edit_organisation_address]: {} }));
    try {
      OrganisationContactValidator(lang).validateSync(formState, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.organisation.validation_errors',
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.edit_organisation_address]: errorBag }));
      return false;
    }
    dispatch(putOrganisationAddressAction({
      name_en: organisation.nameEn,
      name_fr: organisation.nameFr,
      legal_name: organisation.legalName,
      registration_number: organisation.registrationNumber,
      timezone_id: organisation.timezoneId,
      type: organisation.type,
      website: formState.website,
      email: formState.email,
      address: formState.address,
      city: formState.city,
      state: formState.state,
      suite: formState.suite,
      zip_code: formState.zipCode,
      country_id: formState.countryId,
      phone: formState.phone,
      extension: formState.extension,
      tollfree_phone: formState.tollfreePhone,
      tollfree_extension: formState.tollfreeExtension,
    } as OrganisationAddressPayload));
    return true;
  };

  return (
    <div style={{ width: '640px' }}>
      <FormTextField
        form={FORM.edit_organisation_address}
        label={t(lang, 'forms.organisation.website')}
        name="website"
        onChange={onFieldChange}
        value={formState.website}
        disabled={processing}
      />
      <FormTextField
        form={FORM.edit_organisation_address}
        label={t(lang, 'forms.organisation.email')}
        name="email"
        onChange={onFieldChange}
        value={formState.email}
        disabled={processing}
      />
      <FormTextField
        form={FORM.edit_organisation_address}
        label={t(lang, 'forms.organisation.address')}
        name="address"
        onChange={onFieldChange}
        value={formState.address}
        disabled={processing}
      />
      <FormTextField
        form={FORM.edit_organisation_address}
        label={t(lang, 'forms.organisation.city')}
        name="city"
        onChange={onFieldChange}
        value={formState.city}
        disabled={processing}
      />
      <FormTextField
        form={FORM.edit_organisation_address}
        label={t(lang, 'forms.organisation.suite')}
        name="suite"
        onChange={onFieldChange}
        value={formState.suite}
        disabled={processing}
      />
      <FormTextField
        form={FORM.edit_organisation_address}
        label={t(lang, 'forms.organisation.state')}
        name="state"
        onChange={onFieldChange}
        value={formState.state}
        disabled={processing}
      />
      <CountriesSelector
        form={FORM.edit_contact}
        onChange={(id) => onFieldChange(id, 'countryId')}
        value={formState.countryId || undefined}
        name="countryId"
        disabled={processing}
      />
      <FormTextField
        form={FORM.edit_organisation_address}
        label={t(lang, 'forms.organisation.zipCode')}
        name="zipCode"
        onChange={onFieldChange}
        value={formState.zipCode}
        disabled={processing}
      />
      <FormTextField
        form={FORM.edit_organisation_address}
        label={t(lang, 'forms.organisation.phone')}
        name="phone"
        onChange={onFieldChange}
        value={formState.phone}
        disabled={processing}
      />
      <FormTextField
        form={FORM.edit_organisation_address}
        label={t(lang, 'forms.organisation.extension')}
        name="extension"
        onChange={onFieldChange}
        value={formState.extension}
        disabled={processing}
      />
      <FormTextField
        form={FORM.edit_organisation_address}
        label={t(lang, 'forms.organisation.tollfreePhone')}
        name="tollfreePhone"
        onChange={onFieldChange}
        value={formState.tollfreePhone}
        disabled={processing}
      />
      <FormTextField
        form={FORM.edit_organisation_address}
        label={t(lang, 'forms.organisation.tollfreeExtension')}
        name="tollfreeExtension"
        onChange={onFieldChange}
        value={formState.tollfreeExtension}
        disabled={processing}
      />
      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={(e) => handleSubmit(e)}
          disabled={processing}
        >
          {t(lang, 'misc.save')}
        </Button>
      </div>
    </div>
  );
};

export default OrganisationAddressForm;
