import { VideoPlatform } from 'src/models/FundraisingModel';
import { FORM } from 'src/constants/Form';
import { makeStyles } from '@material-ui/core/styles';
import { Store } from 'src/redux/Store';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ErrorBag } from 'src/models/ErrorModel';
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  selectEmpty: {
  },
  selectElement: {
    width: '100%',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.50)',
  },
}));

interface Props {
  disabled?: boolean;
  error?: string;
  name: string;
  form: FORM;
  onChange: (platform: VideoPlatform) => void;
  value?: VideoPlatform;
}

const VideoPlatformSelector = (props: Props) => {
  const classes = useStyles();
  const {
    disabled,
    error,
    form,
    name,
    onChange,
    value,
  } = props;

  const [valueState, setValueState] = useState(value || VideoPlatform.youtube);
  const errors: ErrorBag =
    useSelector((state: Store) => state.errors[form] || {} as ErrorBag);

  const onValueChange = (newValue: VideoPlatform) => {
    setValueState(newValue);
    onChange(newValue);
  };

  const displayClass = !valueState ? classes.selectLabel : '';

  return (
    <FormControl
      variant="outlined"
      error={!!error || !!errors[name]}
      key={`form-control-${name}`}
      className={classes.selectElement}
    >
      <InputLabel id="tax-select-label">Platform</InputLabel>
      <Select
        value={valueState}
        onChange={(e) => onValueChange(e.target.value as VideoPlatform)}
        displayEmpty
        className={`${classes.selectEmpty} ${displayClass} ${classes.selectElement}`}
        disabled={disabled}
        label="Platform"
        labelId="video-platform-select-label"
      >
        <MenuItem
          key={`${name}-${VideoPlatform.youtube}`}
          value={VideoPlatform.youtube}
          selected={valueState === VideoPlatform.youtube}
        >
          {VideoPlatform.youtube}
        </MenuItem>
        <MenuItem
          key={`${name}-${VideoPlatform.vimeo}`}
          value={VideoPlatform.vimeo}
          selected={valueState === VideoPlatform.vimeo}
        >
          {VideoPlatform.vimeo}
        </MenuItem>
        <MenuItem
          key={`${name}-${VideoPlatform.other}`}
          value={VideoPlatform.other}
          selected={valueState === VideoPlatform.other}
        >
          {VideoPlatform.other}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default VideoPlatformSelector;
