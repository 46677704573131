import { Store, useAppDispatch } from 'src/redux/Store';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import InfiniteScroll from 'react-infinite-scroll-component';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import {
  getReceiptFilter,
  getReceiptRangeDate,
  normalizeReceiptForUser,
} from 'src/lib/PaymentHelper';
import { useEffect, useState } from 'react';
import { SEARCH_LOGICAL_OPERATORS } from 'src/models/AdvanceSearchModel';
import { getEntitiesAction, setFilters, setOptions } from 'src/redux/actions/querybuilderActions';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { ReceiptSections } from 'src/components/Pages/UserReceiptsPage';
import { setSelections } from 'src/redux/actions/selectionActions';
import Typography from '@material-ui/core/Typography';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = (isTabletOrMobile:boolean) => makeStyles((theme) => ({
  card: {
    border: '1px solid rgba(0, 0, 0, 0.25)',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      cursor: 'pointer',
    },
    flex: 1,
    width: '100%',
    maxWidth: '680px',
  },
  label: {
    fontWeight: 'bold',
    marginRight: '8px',
  },
  selected: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
  list: {
    marginTop: '0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mobile: {
    marginTop: '48px',
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '18px',
  },
  submit: {
    marginLeft: 'auto',
    display: 'flex',
    marginBottom: '8px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    gap: '8px',
    alignItems: 'center',
    minWidth: '640px',
  },
  tabs: {
    width: '100%',
    height: '26px',
    display: 'flex',
    maxWidth: '680px',
    backgroundColor: 'white',
  },
  tab: {
    border: '1px solid #eee',
    borderTop: 'none',
    height: '26px',
    flex: 1,
    padding: '0 8px',
    '&:hover': {
      opacity: 0.5,
      cursor: 'pointer',
    },
  },
  tabSelected: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
  },
  col2: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '50%',
    width: '100%',
    alignItems: 'center',
  },
  col3: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '33%',
    width: '100%',
    alignItems: 'center',
  },
  col4: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '25%',
    width: '100%',
    alignItems: 'center',
  },
}));

interface Props {
  section: ReceiptSections,
}

const ManageUserReceipts = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const classes = useStyles(isTabletOrMobile)();
  const dispatch = useAppDispatch();

  const { section } = props;

  const MAX_ITEMS = 7;

  useEffect(() => {
    const [startDate, endDate] = getReceiptRangeDate();
    const filters = getReceiptFilter(
      startDate,
      endDate,
    );
    dispatch(setOptions({
      entity: ENTITIES.taxReceipts,
      options: {
        payment_receipt: section === ReceiptSections.purchase,
      },
    }));
    dispatch(setFilters({
      entity: ENTITIES.taxReceipts,
      filters: { [SEARCH_LOGICAL_OPERATORS.and]: filters },
    }));
    dispatch(getEntitiesAction(ENTITIES.taxReceipts));
  }, [section]);

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'CAD',
  });

  const loading = useSelector(
    (state:Store) => state.formStatus[FORM.query_builder] === FORM_STATUS.processing,
  );

  const selections = useSelector((state: Store) => {
    if (!!state.selected[ENTITIES.taxReceipts]) {
      return [...state.selected[ENTITIES.taxReceipts]];
    }
    return [];
  });

  const receipts = useSelector(
    (state: Store) => state.querybuilder[ENTITIES.taxReceipts]?.result?.list || [],
  );

  const [page, setPage] = useState(1);
  const [items, setItems] = useState(Object.values(receipts).slice(0, MAX_ITEMS));
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (receipts.length) {
      setItems(Object.values(receipts).slice(0, MAX_ITEMS));
    }
  }, [receipts]);

  const handleOnClick = (id?: number) => {
    if (!id) return;
    const index = selections.indexOf(id);

    if (index >= 0) {
      selections.splice(index, 1);
    } else {
      selections.push(id);
    }

    dispatch(setSelections({
      entity: ENTITIES.taxReceipts,
      selections,
    }));
  };

  const getClasses = (id?: number) => {
    if (selections.indexOf(id) < 0) {
      return classes.card;
    }
    return `${classes.card} ${classes.selected}`;
  };

  const handleNext = () => {
    const newItems = receipts.slice(page * MAX_ITEMS, (page + 1) * MAX_ITEMS);
    setItems((prevItems) => [...prevItems, ...newItems]);
    setPage((prevPage) => prevPage + 1);
    setHasMore(receipts.length > (page + 1) * MAX_ITEMS);
  };

  const showItems = () => items.map((item) => {
    const receipt = normalizeReceiptForUser(item);
    const date = moment(receipt.issued_date).format('YYYY-MM-DD');
    return (
      <div className={classes.row} key={receipt.id}>
        <div
          onClick={() => handleOnClick(receipt.id)}
          className={getClasses(receipt.id)}
        >
          { section === ReceiptSections.tax && (
          <div><span className={classes.label}>#</span>{receipt.sequence}</div>
          )}
          <div><span className={classes.label}>Issued:</span>{date}</div>
          <div>
            <span className={classes.label}>Amount:</span>
            {formatter.format(receipt.amount ?? 0)}
          </div>
          <div>
            <span className={classes.label}>Deductible:</span>
            {formatter.format(receipt.deductible ?? 0)}
          </div>
        </div>
      </div>
    );
  });
  //
  const displayItems = () => (
    <InfiniteScroll
      dataLength={receipts.length}
      next={handleNext}
      hasMore={hasMore}
      loader={<Typography>Loading...</Typography>}
      endMessage={<Typography>All items displayed.</Typography>}
      height={590}
    >
      {showItems()}
    </InfiniteScroll>
  );

  if (loading) {
    return (
      <CircularProgress
        size={40}
        style={{
          margin: '0 auto',
          marginTop: '32px',
        }}
      />
    );
  }
  return (
    <>
      <div className={`${isTabletOrMobile ? classes.mobile : classes.list}`}>
        {displayItems()}
      </div>
    </>
  );
};

export default ManageUserReceipts;
