import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Store, useAppDispatch } from 'src/redux/Store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { validateFilters } from 'src/validations/FiltersValidation';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import DatagridAdvancedSearchRow from 'src/components/DatagridSearch/DatagridAdvancedSearchRow';
import { AdvancedSearchErrors, EmptyFilter, Filter } from 'src/models/AdvanceSearchModel';
import LogicalOperatorSelector
  from 'src/components/Control/AdvancedSearch/Selectors/LogicalOperatorSelector';
import { SEARCH_OPERATORS_PRIMITIVE } from 'src/constants/SearchOperators';
import { denormalizeFilters, getOperatorAndFilters } from 'src/lib/QueryBuilderHelper';
import {
  getEntitiesAction,
  resetFilters,
  setFilters,
} from 'src/redux/actions/querybuilderActions';
import { v4 as uuid } from 'uuid';
import { setError } from 'src/redux/actions/errorsActions';
import { FORM } from 'src/constants/Form';
import { t } from 'src/lib/language';
import { PRESET_TYPES } from 'src/models/PresetModel';
import { resetSelections } from 'src/redux/actions/selectionActions';
import { resetAdvancedSearch, setAdvancedSearch } from 'src/redux/actions/advancedSearchAction';
import PresetToolbar from 'src/components/DatagridToolbar/PresetToolbar';
import { setSelectedPresets } from 'src/redux/actions/selectedPresetActions';

const useStyles = makeStyles((theme) => ({
  datagridAdvancedSearch: {
    border: '1px solid #eeeeee',
    borderTop: 'none',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '8px',
    backgroundColor: '#f8f8f8',
    padding: '4px',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
  },
  datagridSearchClose: {
    lineHeight: '20px',
    height: '20px',
    textAlign: 'center',
    margin: 'auto auto',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}));

interface Props {
  closeTab: () => void,
  entity: ENTITIES;
}

const DatagridAdvancedSearch = (props: Props) => {
  const classes = useStyles();
  const { closeTab, entity } = props;

  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language);

  const errors: AdvancedSearchErrors =
    useSelector(
      (state: Store) => {
        if (state.errors[FORM.query_builder]) {
          return state.errors[FORM.query_builder] as AdvancedSearchErrors;
        }
        return [] as AdvancedSearchErrors;
      },
    );

  useEffect(() => {
    dispatch(setError({ [FORM.query_builder]: [] }));
  }, []);

  const [operator, filterList] = useSelector(
    (state: Store) => {
      const filters = state.advancedSearch[entity];
      if (filters) {
        return getOperatorAndFilters(filters);
      }
      return getOperatorAndFilters(EmptyFilter);
    },
  );

  const setOperator = (value:string) => {
    dispatch(setAdvancedSearch({
      entity,
      filters: {
        [value]: [
          ...filterList,
        ],
      },
    }));
  };

  const resetFilter = () => {
    dispatch(resetFilters({
      entity,
    }));
    dispatch(resetAdvancedSearch({
      entity,
    }));
    dispatch(setSelectedPresets({
      entity,
      type: PRESET_TYPES.filter,
      id: null,
    }));
    dispatch(getEntitiesAction(entity));
  };

  const addFilter = () => {
    const newFilters = [
      ...filterList,
      ['', SEARCH_OPERATORS_PRIMITIVE.empty, ''] as Filter,
    ];
    dispatch(setAdvancedSearch({
      entity,
      filters: {
        [operator]: [
          ...newFilters,
        ],
      },
    }));
  };

  const deleteFilter = (index:number) => {
    if (filterList.length <= 1) {
      dispatch(setAdvancedSearch({
        entity,
        filters: {
          [operator]: [['', '', '']],
        },
      }));
      return;
    }
    const newFilters = [
      ...filterList,
    ];
    newFilters.splice(index, 1);
    dispatch(setAdvancedSearch({
      entity,
      filters: {
        [operator]: [
          ...newFilters,
        ],
      },
    }));
    errors.splice(index, 1);
    dispatch(setError({ [FORM.query_builder]: errors }));
  };

  const search = () => {
    dispatch(setError({ [FORM.query_builder]: [] }));
    dispatch(setFilters({
      entity,
      filters: {
        [operator]: denormalizeFilters([...filterList]),
      },
    }));
    const validationErrors = validateFilters(filterList, lang);
    if (validationErrors.length) {
      dispatch(setError({ [FORM.query_builder]: validationErrors }));
      return;
    }
    dispatch(resetSelections({ entity }));
    dispatch(getEntitiesAction(entity));
  };

  const displayFilters = () => {
    const elements = [] as any[];
    let index = 0;
    filterList.forEach(() => {
      elements.push(
        <DatagridAdvancedSearchRow
          key={uuid()}
          entity={entity}
          index={index}
          isLast={filterList.length - 1 === index}
          addFilter={addFilter}
          deleteFilter={deleteFilter}
        />,
      );
      index += 1;
    });
    return elements;
  };

  return (
    <div className={classes.datagridAdvancedSearch}>
      <div className={classes.topRow}>
        <PresetToolbar entity={entity} type={PRESET_TYPES.filter} onReset={resetFilter} />
      </div>
      <LogicalOperatorSelector setOperator={setOperator} operator={operator} />
      {displayFilters()}
      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          onClick={search}
          style={{ padding: '0 8px' }}
        >
          {t(lang, 'search.search')}
        </Button>
      </div>
      <div
        className={classes.datagridSearchClose}
        onClick={() => closeTab()}
      >
        {t(lang, 'misc.close_search')}
      </div>
    </div>
  );
};

export default DatagridAdvancedSearch;
