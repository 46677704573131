import { useEffect, useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';

import LogoLarge from 'src/components/Elements/LogoLarge';
import LoginPage from 'src/components/Pages/LoginPage';
import RegisterPage from 'src/components/Pages/RegisterPage';
import NotVerifiedPage from 'src/components/Pages/NotVerifiedPage';
import ResetPasswordPage from 'src/components/Pages/ResetPasswordPage';
import VerifyPage from 'src/components/Pages/VerifyPage';
import { Route } from 'src/constants/routes';
import { AuthModel } from 'src/models/AuthModel';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(/images/perfectdeed.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(6, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
  pointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface Props {
  content: string
}

const LoginLayout = (props: Props) => {
  const classes = useStyles();

  const { content } = props;
  const auth: AuthModel = useSelector((store: Store) => store.auth);
  const verified: boolean = useSelector((store: Store) => store.user.verified);

  const [page, setPage] = useState(content);

  useEffect(() => {
    if (auth.accessToken && !verified) {
      setPage(content);
    }
  }, [content]);

  const changePage = (gotoPage:string) => {
    setPage(gotoPage);
  };

  const displayPage = () => {
    switch (page) {
      case Route.RESET_PASSWORD:
        return (<ResetPasswordPage />);
      case Route.VERIFY:
        return (<VerifyPage />);
      case Route.LOGIN:
        return (<LoginPage changePage={changePage} />);
      case Route.REGISTER:
        return (<RegisterPage changePage={changePage} />);
      case Route.NOT_VERIFIED:
        return (<NotVerifiedPage changePage={changePage} />);
      default:
        return (<LoginPage changePage={changePage} />);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={10} md={8} className={classes.image} />
      <Grid item xs={12} sm={2} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <LogoLarge />
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          { displayPage() }
        </div>
      </Grid>
    </Grid>
  );
};

export default LoginLayout;
