export enum ORIENTATION {
  landscape = 'landscape',
  portrait = 'portrait',
}

export enum EXTENSION {
  pdf = 'pdf',
  csv = 'csv',
}

export enum FORMAT {
  pdf = 'pdf',
  csv = 'csv',
  statement = 'statement',
  tax = 'tax',
  payment = 'payment',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum DELIVERY_TYPE {
  download = 'download',
  email = 'email',
  contact = 'contact',
  user = 'user',
}

export interface DownloadOptionsModel {
  file: string,
  orientation: ORIENTATION,
  format: FORMAT,
  delivery: DELIVERY_TYPE,
  paymentReceipt?: number | null
}
