import { FORM_ID } from 'src/models/FormModel';
import { ErrorBag } from 'src/models/ErrorModel';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { useRef } from 'react';
import NotificationEmailToolbar from 'src/components/Control/NotificationEmailToolbar';
import Debug from 'src/lib/Debug';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RichTextEditor, RichTextEditorRef } from 'mui-tiptap';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Editor } from '@tiptap/core';
import useExtensions from 'src/lib/useExtention';

interface Props {
  disabled?: boolean,
  form: FORM_ID,
  label?: string,
  name: string,
  onChange: (value: any, field: any) => void,
  value: string,
}

interface Param {
  editor: Editor,
}
const FormRichTextEditor = (props: Props) => {
  const {
    disabled, form, label, name, onChange, value,
  } = props;

  const extensions = useExtensions({
    placeholder: label,
  });

  type RichTextEditorRef = typeof RichTextEditorRef;

  const errors: ErrorBag =
    useSelector((state:Store) => state.errors[form] || {} as ErrorBag);

  Debug.log([errors], true);

  const rteRef = useRef<RichTextEditorRef>(null);

  return (
    <RichTextEditor
      ref={rteRef}
      extensions={extensions} // Or any Tiptap extensions you wish!
      content="<p>Hello world</p>" // Initial content for the editor
      onUpdate={({ editor }:Param) => { onChange(editor.getHTML(), name); }}
      // Optionally include `renderControls` for a menu-bar atop the editor:
      renderControls={() => <NotificationEmailToolbar />}
      disabled={disabled}
      disableFocusTrap
      value={value}
    />
  );
};

export default FormRichTextEditor;
