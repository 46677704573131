import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { FORM } from 'src/constants/Form';
import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { RowIdentifiers } from 'src/components/Control/TicketIdentifier';

const useStyles = makeStyles(() => ({
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '8px',
  },
  item: {
    display: 'flex',
    gap: '8px',
  },
}));

interface Props {
  index: number;
  identifier: RowIdentifiers;
  onDelete?: (index: number) => void;
  onUpdate: (index: number, field: string, value: string) => void;
}

const TicketIdentifierItem = (props: Props) => {
  const classes = useStyles();
  const {
    index, identifier, onDelete, onUpdate,
  } = props;

  const displayIcon = () => {
    if (onDelete) {
      return (
        <div className={classes.item}>
          <Tooltip
            title="Delete row"
            placement="top-start"
          >
            <span>
              <ActionButton
                variant="outlined"
                category={MENU_BUTTON_CATEGORY.action}
                onClick={() => onDelete(index)}
                name="product-delete"
              >
                <DeleteIcon />
              </ActionButton>
            </span>
          </Tooltip>
        </div>
      );
    }
    return (<div style={{ width: '56px' }} />);
  };

  return (
    <div className={classes.row}>
      <div className={classes.item}>
        <FormTextField
          size="small"
          margin="dense"
          label="Row name"
          form={FORM.identifiers}
          name={`name_${index}`}
          onChange={(value) => onUpdate(index, 'name', value)}
          value={identifier.name}
        />
      </div>
      <div className={classes.item}>
        <FormTextField
          size="small"
          margin="dense"
          label="Seat start"
          form={FORM.identifiers}
          name={`start_${index}`}
          onChange={(value) => onUpdate(index, 'start', value)}
          value={identifier.start}
        />
      </div>
      <div className={classes.item}>
        <FormTextField
          size="small"
          margin="dense"
          label="Seat End"
          form={FORM.identifiers}
          name={`start_${index}`}
          onChange={(value) => onUpdate(index, 'end', value)}
          value={identifier.end}
        />
      </div>
      {displayIcon()}
    </div>
  );
};

export default TicketIdentifierItem;
