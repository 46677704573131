import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { t } from 'src/lib/language';
import FormSingleSelect from 'src/components/Control/FormControls/FormSingleSelect';
import { FORM_ID } from 'src/models/FormModel';
import { EMAIL_STATUS_LIST } from 'src/models/ContactModel';

interface Props {
  disabled?: boolean,
  form: FORM_ID,
  name: string,
  onChange: (string:Nullable<string>) => void,
  value?: string | '',
}

const EmailStatusSelector = (props: Props) => {
  const {
    disabled, form, name, onChange, value,
  } = props;

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const normalizeValue = (id:string) => {
    let normalValue: Nullable<string> = id;
    if (!normalValue) {
      normalValue = null;
    }
    onChange(normalValue);
  };

  const getItems = () => EMAIL_STATUS_LIST.map((emailStatus:string) => ({
    id: emailStatus,
    display: emailStatus,
  }));

  return (
    <FormSingleSelect
      disabled={disabled}
      form={form}
      items={getItems()}
      label={t(lang, 'fields.contacts.email_status')}
      name={name}
      onChange={normalizeValue}
      value={value || ''}
      noMarginTop
    />
  );
};

export default EmailStatusSelector;
