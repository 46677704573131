import { makeStyles } from '@material-ui/core/styles';
import { FundraisingMatchingInfo } from 'src/models/FundraisingModel';
import { TABS } from 'src/components/Pages/AddFundraisingPage';
import { MouseEvent, useState } from 'react';
import { FORM } from 'src/constants/Form';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { ErrorBag } from 'src/models/ErrorModel';
import { setError } from 'src/redux/actions/errorsActions';
import FundraisingEventValidator from 'src/validations/FundraisingEventInfoValidator';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import { useSelector } from 'react-redux';
import { t } from 'src/lib/language';

const useStyles = makeStyles(() => ({
  subtitle: {
    marginTop: '8px',
    borderBottom: 'solid #888 1px',
    width: '480px',
  },
  list: {
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '0px',
  },
  item: {
    border: '1px solid #c8c8c8',
    borderRadius: '8px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px',
    width: '480px',
    backgroundColor: 'white',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
  },
  row: {
    display: 'flex',
    alignItems: 'start',
    gap: '10px',
    marginTop: '16px',
  },
  col2: {
    width: '50%',
  },
}));

interface Props {
  onChange: (eventInfo: FundraisingMatchingInfo) => void;
  onNextStep: (step:number) => void;
  setErrors: (tab:TABS, hasError: boolean) => void;
  initialState: FundraisingMatchingInfo;
  setHasChanged: (hasChanged: boolean) => void;
}

const FundraisingMatchingInfoForm = (props: Props) => {
  const classes = useStyles();
  const {
    onChange,
    onNextStep,
    setErrors,
    initialState,
    setHasChanged,
  } = props;

  const dispatch = useAppDispatch();

  const [matchingInfoState, setMatchingInfoState] = useState(initialState);
  const [order, setOrder] = useState(initialState.order);

  const lang = useSelector((state: Store) => state.language.language);

  const onValueChange = (value: any, field: string) => {
    setHasChanged(true);
    setMatchingInfoState({
      ...matchingInfoState,
      [field]: value,
    });
  };

  const onDragEnd = (dropResult: any) => {
    const {
      destination,
      source,
    } = dropResult;
    // dropped outside the list
    if (!destination) return;

    const newOrder = Array.from(order);
    const [removed] = newOrder.splice(source.index, 1);
    newOrder.splice(destination.index, 0, removed);
    setOrder(newOrder);
    onValueChange(order, 'order');
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setErrors(TABS.event_info, false);
    const errorBag = {} as ErrorBag;
    dispatch(setError({ [FORM.fundraising_info]: {} }));
    try {
      FundraisingEventValidator()
        .validateSync(matchingInfoState, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.group.validation_errors',
      }));
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      // eslint-disable-next-line prefer-destructuring
      dispatch(setError({ [FORM.fundraising_notification]: errorBag }));
      setErrors(TABS.info, true);
      onNextStep(5);
      onChange(matchingInfoState);
    }
    onNextStep(5);
    onChange(matchingInfoState);
  };

  return (
    <>
      <div className={classes.subtitle}>
        Matching Information
      </div>
      <div style={{ width: '240px' }}>
        <FormTextField
          form={FORM.fundraising_info}
          label={t(lang, 'forms.fundraising.match_multiplier')}
          name="match_multiplier"
          onChange={onValueChange}
          value={matchingInfoState.match_multiplier}
        />
      </div>
      <div style={{ width: '240px' }}>
        <FormTextField
          form={FORM.fundraising_info}
          label={t(lang, 'forms.fundraising.match_goal')}
          name="match_goal"
          onChange={onValueChange}
          value={matchingInfoState.match_goal}
        />
      </div>
      <div style={{ width: '240px' }}>
        <FormTextField
          form={FORM.fundraising_info}
          label={t(lang, 'forms.fundraising.match_reached_multiplier')}
          name="match_reached_multiplier"
          onChange={onValueChange}
          value={matchingInfoState.match_reached_multiplier}
        />
      </div>
      <FormCheckbox
        label={t(lang, 'forms.fundraising.match_reached_multiplier')}
        name="bonus_round"
        onChange={onValueChange}
        checked={!!matchingInfoState.bonus_round}
      /><br />
      <div style={{
        width: '240px',
        marginTop: '-16px',
      }}
      >
        <FormTextField
          form={FORM.fundraising_info}
          label={t(lang, 'forms.fundraising.bonus_round_goal')}
          name="bonus_round_goal"
          onChange={onValueChange}
          value={matchingInfoState.bonus_round_goal}
        />
      </div>
      <div className={classes.row}>
        <div className={classes.col2}>
          <FormCheckbox
            label={t(lang, 'forms.fundraising.slider_main_all_language')}
            name="slider_main_all_language"
            onChange={onValueChange}
            checked={!!matchingInfoState.slider_main_all_language}
          /><br />
          <FormCheckbox
            label={t(lang, 'forms.fundraising.slider_secondary_all_language')}
            name="slider_secondary_all_language"
            onChange={onValueChange}
            checked={!!matchingInfoState.slider_secondary_all_language}
          /><br />
          <FormCheckbox
            label={t(lang, 'forms.fundraising.slider_matcher_all_language')}
            name="slider_matcher_all_language"
            onChange={onValueChange}
            checked={!!matchingInfoState.slider_matcher_all_language}
          /><br />
          <FormCheckbox
            label={t(lang, 'forms.fundraising.slider_matcher_all_language')}
            name="slider_sponsor_all_language"
            onChange={onValueChange}
            checked={!!matchingInfoState.slider_sponsor_all_language}
          /><br />
          <FormCheckbox
            label={t(lang, 'forms.fundraising.video_main_all_language')}
            name="video_main_all_language"
            onChange={onValueChange}
            checked={!!matchingInfoState.video_main_all_language}
          /><br />
          <FormCheckbox
            label={t(lang, 'forms.fundraising.video_secondary_all_language')}
            name="video_secondary_all_language"
            onChange={onValueChange}
            checked={!!matchingInfoState.video_secondary_all_language}
          /><br />
          <FormCheckbox
            label={t(lang, 'forms.fundraising.campaign_message_all_language')}
            name="campaign_message_all_language"
            onChange={onValueChange}
            checked={!!matchingInfoState.campaign_message_all_language}
          /><br />
        </div>
        <div className={classes.col2}>
          <FormCheckbox
            label={t(lang, 'forms.fundraising.allow_comments')}
            name="allow_comments"
            onChange={onValueChange}
            checked={!!matchingInfoState.allow_comments}
          /><br />
          <FormCheckbox
            label={t(lang, 'forms.fundraising.show_team_donor_amount')}
            name="show_team_donor_amount"
            onChange={onValueChange}
            checked={!!matchingInfoState.show_team_donor_amount}
          /><br />
          <FormCheckbox
            label={t(lang, 'forms.fundraising.show_donors')}
            name="show_donors"
            onChange={onValueChange}
            checked={!!matchingInfoState.show_donors}
          /><br />
          <FormCheckbox
            label={t(lang, 'forms.fundraising.show_teams')}
            name="show_teams"
            onChange={onValueChange}
            checked={!!matchingInfoState.show_teams}
          /><br />
        </div>
      </div>
      <div className={classes.subtitle}>
        {t(lang, 'forms.fundraising.order')}
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {
            (provided: any) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={classes.list}
              >
                {order.map((item, index) => (
                  <Draggable
                    key={`item-${index}`}
                    draggableId={`${index}`}
                    index={index}
                  >
                    {(providedDraggable: any) => (
                      <li
                        className={classes.item}
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                      >
                        {item}
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )
        }
        </Droppable>
      </DragDropContext>
      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          key="submit-button"
        >
          {t(lang, 'forms.fundraising.next_Step')}
        </Button>
      </div>
    </>
  );
};

export default FundraisingMatchingInfoForm;
