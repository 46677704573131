import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { t } from 'src/lib/language';
import { DIALOGS } from 'src/models/DialogModel';
import { openDialog } from 'src/redux/actions/dialogActions';

const DeleteContactButton = (props: ButtonProps) => {
  const { disabled } = props;
  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const handleOpen = () => {
    dispatch(openDialog({ dialog: DIALOGS.deleteContact }));
  };

  return (
    <>
      <Tooltip
        title={t(lang, 'menus.delete_selected_contact')}
        placement="top-start"
      >
        <span>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.action}
            onClick={handleOpen}
            disabled={disabled}
          >
            <DeleteIcon />
          </ActionButton>
        </span>
      </Tooltip>
    </>
  );
};

export default DeleteContactButton;
