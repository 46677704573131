import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { setError } from 'src/redux/actions/errorsActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { t } from 'src/lib/language';
import { setFormStatus } from 'src/redux/actions/formActions';
import ContactSelector from 'src/components/Control/ContactSelector';
import ContactRelationSelector from 'src/components/Control/ContactRelationSelector';
import { AUTOCOMPLETE_ID } from 'src/constants/Autocomplete';
import ContactModel, { ContactRelation } from 'src/models/ContactModel';
import { putContactAction } from 'src/redux/actions/contactActions';
import { keyBy } from 'lodash';

interface Props {
  onClose?: () => void;
  contact: ContactModel,
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: '640px',
    paddingTop: '15px',
  },
  submit: {
    marginTop: '12px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectElement: {
    width: '100%',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.50)',
  },
  selectLabelError: {
    color: 'rgba(255, 0, 0, 0.50)',
  },
  spacer: {
    height: theme.spacing(3),
  },
  hide: {
    display: 'none',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '10px',
  },
  formControl: {
    width: '100%',
    minWidth: '120px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

const AddContactRelationForm = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const formStatus = useSelector(
    (state: Store) => state.formStatus[FORM.add_contact_relation] ?? null,
  );
  const relations = useSelector((state: Store) => keyBy(state.data.relations, 'id'));
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const contacts = useSelector((state: Store) => state.contacts);
  const { onClose, contact } = props;

  const [state, setState] = useState({} as ContactRelation);
  const form = FORM.edit_contact;

  useEffect(() => {
    dispatch(setError({ [form]: {} }));
  }, []);

  useEffect(() => {
    if (formStatus === FORM_STATUS.success) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onClose && onClose();
      dispatch(setFormStatus({ [FORM.add_contact_relation]: null }));
    }
  }, [formStatus]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const getName = (obj: ContactModel) => {
    if (obj.company_name) {
      return obj.company_name;
    }
    return `${obj.last_name} ${obj.first_name}`;
  };

  const displayRelationString = () => {
    if (state.type_id && !!contacts[state.related_contact_id]) {
      const relationName = relations[state.type_id][`name_${lang}`];
      const { contact: related } = contacts[state.related_contact_id];
      return (
        <span>
          {getName(contact)}&nbsp;<i>is the</i>
          &nbsp;<b>{relationName}</b>
          &nbsp;<i>of </i>&nbps;{getName(related)}
        </span>
      );
    }
    return '';
  };

  const displayReverseRelationString = () => {
    if (state.reciprocity_id && !!contacts[state.related_contact_id]) {
      const relationName = relations[state.reciprocity_id][`name_${lang}`];
      const { contact: related } = contacts[state.related_contact_id];
      return (
        <span>
          {getName(related)}&nbsp;<i>is the</i>
          &nbsp;<b>{relationName}</b>
          &nbsp;<i>of </i>&nbsp;{getName(contact)}
        </span>
      );
    }
    return '';
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(putContactAction(contact.id ?? 0, {
      contact,
      contact_relations: [
        ...contact.contact_relations ?? [],
        state,
      ],
    }));
    return true;
  };

  return (
    <form className={classes.form} noValidate>
      <ContactSelector
        autocompleteId={AUTOCOMPLETE_ID.edit_contact_relation}
        onChange={(id:number) => setState({
          ...state,
          related_contact_id: id,
        })}
        label="Contact"
        form={FORM.edit_contact}
        name="related_contact_id"
        contactId={state.related_contact_id}
        hideEdit
        hideAdd
      />

      <ContactRelationSelector
        onChange={(id:number) => setState({
          ...state,
          type_id: id,
        })}
        label="Relation"
        form={FORM.edit_contact}
        name="type_id"
        value={state.type_id}
      />
      {displayRelationString()}
      <ContactRelationSelector
        onChange={(id:number) => setState({
          ...state,
          reciprocity_id: id,
        })}
        label="Relation Inverse"
        form={FORM.edit_contact}
        name="reciprocity_id"
        value={state.reciprocity_id}
      />
      {displayReverseRelationString()}
      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
          key="submit-button"
          disabled={!state.related_contact_id || !state.type_id}
        >
          {t(lang, 'misc.save')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleClose}
          key={`submit-button-group-${state.type_id}`}
        >
          {t(lang, 'misc.back_to_list')}
        </Button>
      </div>
    </form>
  );
};

export default AddContactRelationForm;
