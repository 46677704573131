import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { useState } from 'react';
import { createQuickSearchFilter } from 'src/lib/QueryBuilderHelper';
import {
  getEntitiesAction,
  setFilters,
  setQuickSearch,
} from 'src/redux/actions/querybuilderActions';
import {
  ENTITIES,
  QuerbuilderPayloadFilters,
  QuerbuilderPayloadQuicksearch,
} from 'src/models/QuerybuilderModel';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { resetSelections } from 'src/redux/actions/selectionActions';
import { t } from 'src/lib/language';
import { Moment } from 'moment/moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DateType, getDateValue } from 'src/lib/DateHelper';

const useStyles = makeStyles((theme) => ({
  datagridQuickSearch: {
    width: '100%',
    border: '1px solid #eeeeee',
    borderTop: 'none',
    padding: theme.spacing(1),
  },
  datagridQuickSearchContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  datagridQuickSearchClear: {
    color: theme.palette.primary.main,
    '&:Hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.light,
      cursor: 'pointer',
    },
  },
  datagridSearchClose: {
    lineHeight: '20px',
    height: '20px',
    textAlign: 'center',
    margin: 'auto auto',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  text: {
    padding: theme.spacing(0, 1),
  },
}));

interface Props {
  closeTab: () => void,
  entity: ENTITIES,
}

const DatagridQuickSearchWithDate = (props: Props) => {
  const classes = useStyles();
  const { closeTab, entity } = props;

  const dispatch = useAppDispatch();

  const loading = useSelector(
    (state: Store) => (state.formStatus[FORM.query_builder] === FORM_STATUS.processing),
  );
  const lang = useSelector((state: Store) => state.language.language);

  const getDefaultStartDate = () => moment().subtract(18, 'months').startOf('day');

  const getDefaultEndDate = () => moment().add(6, 'months').endOf('day');

  const { startDate, endDate, term } =
    useSelector(
      (state: Store) => (state.querybuilder[entity] && state.querybuilder[entity].quickSearch)
        || {
          startDate: getDefaultStartDate(),
          endDate: getDefaultEndDate(),
          term: '',
        },
    );
  const [value, setValue] = useState(term);
  const [startDateValue, setStartDateValue] =
    useState<Moment>(
      startDate,
    );
  const [endDateValue, setEndDateValue] =
    useState<Moment>(
      endDate,
    );

  const quickSearch = () => {
    const filters = createQuickSearchFilter(
      entity,
      value,
      startDateValue,
      endDateValue,
    );
    dispatch(resetSelections({ entity }));
    dispatch(setFilters({
      entity,
      filters,
    } as QuerbuilderPayloadFilters));
    dispatch(setQuickSearch({
      entity,
      quickSearch: {
        term: value,
        startDate: startDateValue,
        endDate: endDateValue,
      },
    } as QuerbuilderPayloadQuicksearch));
    dispatch(getEntitiesAction(entity));
  };

  const handleKeyDown = (e: { key: string; }) => {
    if (e.key === 'Enter') {
      quickSearch();
    }
  };

  const clearFilter = () => {
    dispatch(setFilters({
      entity,
      filters: null,
    } as QuerbuilderPayloadFilters));
    dispatch(setQuickSearch({
      entity,
      quickSearch: {
        term: '',
        startDate: getDefaultStartDate(),
        endDate: moment().add(6, 'months'),
      },
    } as QuerbuilderPayloadQuicksearch));
    setValue('');
    setStartDateValue(getDefaultStartDate());
    setEndDateValue(getDefaultEndDate());
    dispatch(getEntitiesAction(entity));
  };

  return (
    <div className={classes.datagridQuickSearch}>
      <div className={classes.datagridQuickSearchContainer}>
        <TextField
          size="small"
          variant="outlined"
          margin="normal"
          label={t(lang, 'menus.quick_search_term')}
          onChange={(e) => setValue(e.target.value as string)}
          onKeyDown={handleKeyDown}
          value={value || ''}
        />
        <span className={classes.text}> Between </span>
        <FormDatePicker
          form={FORM.invoice_contact}
          label={t(lang, 'forms.invoices.start_date')}
          name="start_date"
          onChange={
            (date: MaterialUiPickersDate) => setStartDateValue(getDateValue(date, DateType.start))
          }
          value={startDateValue}
        />
        <span className={classes.text}> And </span>
        <FormDatePicker
          form={FORM.invoice_contact}
          label={t(lang, 'forms.invoices.end_date')}
          name="end_date"
          onChange={
            (date: MaterialUiPickersDate) => setEndDateValue(getDateValue(date, DateType.start))
          }
          value={endDateValue}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: '8px', marginTop: '8px' }}
          onClick={quickSearch}
          disabled={loading}
        >
          {t(lang, 'menus.quick_search')}
        </Button>
      </div>
      <div
        className={classes.datagridQuickSearchClear}
        onClick={() => clearFilter()}
      >
        {t(lang, 'menus.clear_filter')}
      </div>
      <div
        className={classes.datagridSearchClose}
        onClick={() => closeTab()}
      >
        {t(lang, 'misc.close_search')}
      </div>
    </div>
  );
};

export default DatagridQuickSearchWithDate;
