import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { EmptyFilter } from 'src/models/AdvanceSearchModel';
import { FILTER_POSITION } from 'src/constants/FilterPosition';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { getOperatorAndFilters } from 'src/lib/QueryBuilderHelper';
import { setAdvancedSearch } from 'src/redux/actions/advancedSearchAction';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
// eslint-disable-next-line import/no-extraneous-dependencies
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { SEARCH_OPERATORS } from 'src/constants/SearchOperators';

interface Props {
  entity: ENTITIES;
  index: number;
}

const OperandDate = (props: Props) => {
  const {
    index, entity,
  } = props;

  const dispatch = useAppDispatch();

  const filters = useSelector(
    (state: Store) => state.advancedSearch[entity] || EmptyFilter,
  );
  const [logicalOperator, filterList] = getOperatorAndFilters(filters);

  // useEffect(() => {
  //   filterList[index][FILTER_POSITION.operands] = moment();
  // }, []);

  const handleDateChange = (operand: MaterialUiPickersDate) => {
    if (!operand) return;
    const operator = filterList[index][FILTER_POSITION.operator];
    if (!operator) return;

    if (operator === SEARCH_OPERATORS.is_equal) {
      filterList[index][FILTER_POSITION.operands] = moment(operand).startOf('day');
    }
    if (operator === SEARCH_OPERATORS.is_not_equal) {
      filterList[index][FILTER_POSITION.operands] = moment(operand).startOf('day');
    }
    if (operator === SEARCH_OPERATORS.is_before) {
      filterList[index][FILTER_POSITION.operands] = moment(operand).startOf('day');
    }
    if (operator === SEARCH_OPERATORS.is_after) {
      filterList[index][FILTER_POSITION.operands] = moment(operand).endOf('day');
    }

    dispatch(setAdvancedSearch({
      entity,
      filters: {
        [logicalOperator]: [
          ...filterList,
        ],
      },
    }));
  };

  const getDate = () => {
    if (!filterList[index][FILTER_POSITION.operands]) {
      return null;
    }
    return moment(filterList[index][FILTER_POSITION.operands]).startOf('day');
  };
  //
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        style={{ maxWidth: '160px', marginLeft: '8px' }}
        format="MM/DD/yyyy"
        margin="dense"
        name={`operand-date-${index}`}
        value={getDate()}
        onChange={(date) => handleDateChange(date)}
      />
    </MuiPickersUtilsProvider>
  );
};

export default OperandDate;
