import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { Store, useAppDispatch } from 'src/redux/Store';
import { useSelector } from 'react-redux';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { PAYMENT_STATUS, PAYMENT_TYPE, PaymentsModel } from 'src/models/PaymentModel';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { t } from 'src/lib/language';

const RefundPaymentButton = (props: ButtonProps) => {
  const dispatch = useAppDispatch();
  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.payments] || [],
  );
  const lang = useSelector((state: Store) => state.language.language);

  const payments = useSelector(
    (state: Store) => selections.map(
      (id: number) => state.payments[id],
    ) ?? [] as PaymentsModel,
  );

  const { disabled } = props;

  const containOfflinePayment = () => {
    let index = 0;
    while (index < payments.length) {
      if (![PAYMENT_TYPE.cc, PAYMENT_TYPE.eft].includes(payments[index].payment_type)) {
        return true;
      }
      index += 1;
    }
    return false;
  };

  const containUnpaidPayment = () => {
    let index = 0;
    while (index < payments.length) {
      if (payments[index].payment_status !== PAYMENT_STATUS.paid) {
        return true;
      }
      index += 1;
    }
    return false;
  };

  const handleOpen = () => {
    dispatch(openDialog({ dialog: DIALOGS.refundPayments }));
  };

  return (
    <>
      <Tooltip
        title={t(lang, 'menus.refund_payment')}
        placement="top-start"
      >
        <span>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.action}
            onClick={handleOpen}
            disabled={disabled || containOfflinePayment() || containUnpaidPayment()}
          >
            <UndoOutlinedIcon />
          </ActionButton>
        </span>
      </Tooltip>
    </>
  );
};

export default RefundPaymentButton;
