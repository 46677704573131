import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Spacer from 'src/components/Elements/Spacer';
import WarningIcon from '@material-ui/icons/Warning';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { t } from 'src/lib/language';

const useStyles = makeStyles((theme) => ({
  content: {
    flex: 'none',
    textAlign: 'center',
  },
  title: {
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0),
  },
  header: {
    marginTop: theme.spacing(1),
    fontSize: '1.2rem',
    fontFamily: 'Qanelas-Bold',
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
  },
  buttons: {
    width: 'fit-content',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  icon: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '16px',
  },
}));

interface Props {
  onDelete: () => void;
  open: boolean,
  toggleOpen: any,
  children: any;
}
const DeleteOrganisationDialog = (props:Props) => {
  const classes = useStyles();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const {
    onDelete,
    open,
    toggleOpen,
    children,
  } = props;

  const handleClose = () => {
    toggleOpen();
  };

  const hadleDelete = () => {
    onDelete();
    toggleOpen();
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogContent className={classes.content}>
        <WarningIcon color="error" className={classes.icon} fontSize="large" /><br />
        {children}
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button
            color="primary"
            onClick={handleClose}
          >
            {t(lang, 'misc.close')}
          </Button>
          <Spacer space={2} />
          <Button
            color="primary"
            onClick={hadleDelete}
          >
            {t(lang, 'misc.delete')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOrganisationDialog;
