import { Texts } from 'src/models/FundraisingModel';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import { FundraisingLanguages } from 'src/constants/FundraisingLanguages';
import { FORM } from 'src/constants/Form';
import FormRichTextEditor from 'src/components/Control/FormControls/FormRichTextEditor';
import { TextsErrors } from 'src/validations/FundraisingAssetsValidation';

interface Props {
  errors?: TextsErrors;
  language: FundraisingLanguages,
  name: string;
  onChange: (language: FundraisingLanguages, texts: Texts) => void;
  title: string;
  value: Texts,
}

const useStyles = makeStyles(() => ({
  error: {
    color: '#F00',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
  },
  frame: {
    border: 'solid 1px #eee',
  },
  errorBox: {
    border: 'solid 1px #f00',
  },
  header: {
    backgroundColor: 'rgba(174, 46, 111, 0.1)',
    padding: '8px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
  },
  sliders: {
    padding: '4px 8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  buttons: {
    padding: '0px 8px',
  },
}));

interface PanelState {
  [panel: string]: boolean,
}

const TextSliderManager = (props: Props) => {
  const classes = useStyles();
  const {
    errors,
    language,
    name,
    onChange,
    title,
    value,
  } = props;

  const [texts, setTexts] = useState(value ?? [] as Texts);
  const [panelState, setPanelState] = useState({} as PanelState);

  const getTitle = () => {
    if (errors && errors.length) {
      if (errors[0] === 1) {
        return (
          <span className={classes.error}>{title}
            (<span style={{ fontStyle: 'italic' }}>Can not be empty</span>)
          </span>
        );
      }
      return (
        <span className={classes.error}>{title}</span>
      );
    }
    return (<span>{title}</span>);
  };

  const onDeleteText = (e: any, index: number) => {
    e.stopPropagation();
    const newTexts = [...texts];
    newTexts.splice(index, 1);
    setTexts(newTexts);
  };

  const onTextChange = (index: number, text: string) => {
    const newTexts = [...texts];
    newTexts[index] = text;
    setTexts(newTexts);
    onChange(language, newTexts);
  };

  const onAddText = () => {
    const panelName = `panel-text-${texts.length}`;
    const newPanelState = {
      ...panelState,
      [panelName]: true,
    };
    setPanelState(newPanelState);
    const newTexts = [...texts];
    newTexts.push('');
    setTexts(newTexts);
  };

  const onPanelClick = (panelName: string, expanded: boolean) => {
    const newPanelState = {
      ...panelState,
      [panelName]: expanded,
    };
    setPanelState(newPanelState);
  };

  const getClasses = (index: number) => {
    if (!errors) return '';
    if (!errors || !errors[index]) {
      return '';
    }
    return classes.errorBox;
  };

  const getErrorText = (index: number) => {
    if (!errors || !errors[index]) return (<></>);
    return (
      <span className={classes.error}>{errors[index]}&nbsp;</span>
    );
  };

  const displaySliders = () => texts.map((text: string, index: number) => {
    const panelName = `${title}-${index}`;
    return (
      <Accordion
        expanded={!!panelState[panelName]}
        onChange={(e, expanded) => onPanelClick(panelName, expanded)}
        key={`${panelName}-${index}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls={panelName}
          id={panelName}
        >
          <div className={classes.row} style={{ width: '100%' }}>
            <span>{title} {index + 1}</span>
            <span style={{ marginLeft: 'auto', marginRight: '0px' }}>
              <span style={{ width: '8px' }}>&nbsp;</span>
              <ActionButton
                variant="outlined"
                category={MENU_BUTTON_CATEGORY.action}
                onClick={(e) => onDeleteText(e, index)}
                onFocus={(e) => e.stopPropagation()}
              >
                <span className={classes.buttons}>Delete Text</span>
                <DeleteIcon />
              </ActionButton>
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ marginTop: '-16px', width: '100%' }} className={getClasses(index)}>
            {getErrorText(index)}
            <FormRichTextEditor
              label=""
              form={FORM.fundraising_info}
              name={name}
              controls={[
                'title', 'bold', 'italic', 'underline', 'strikethrough', 'undo', 'redo',
                'link', 'numberList', 'bulletList', 'quote', 'clear', 'font-face',
              ]}
              onChange={(textValue: string) => onTextChange(index, textValue)}
              value={texts[index] ?? ''}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <div className={classes.frame}>
      <div className={classes.header}>
        {getTitle()}
        <span style={{ marginLeft: 'auto', marginRight: '0px' }}>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.action}
            onClick={() => onAddText()}
          >
            <span className={classes.buttons}>Add a Text</span><AddIcon />
          </ActionButton>
        </span>
      </div>
      <div className={classes.sliders}>
        {displaySliders()}
      </div>
    </div>
  );
};

export default TextSliderManager;
