import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import ManageGroups from 'src/components/Elements/ManageGroups';

const GroupManagementModal = () => (
  <ModalBase
    name={MODALS.manageGroup}
    size={MODALS_SIZE.small}
  >
    <ManageGroups />
  </ModalBase>
);

export default GroupManagementModal;
