import Datagrid from 'src/components/Datagrid/Datagrid';
import {
  ENTITIES,
  QuerbuilderPayloadColumns,
  QuerbuilderPayloadOrder,
} from 'src/models/QuerybuilderModel';
import {
  resetQueryBuilderResult,
  setColumns,
  setOrders,
} from 'src/redux/actions/querybuilderActions';
import DatagridSearch from 'src/components/DatagridSearch/DatagridSearch';
import DatagridToolbar from 'src/components/DatagridToolbar/DatagridToolbar';
import { useEffect } from 'react';
import DEFAULT_COLUMNS from 'src/constants/columns';
import { Store, useAppDispatch } from 'src/redux/Store';
import ButtonLink from 'src/components/Elements/ButtonLink';
import { openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { useSelector } from 'react-redux';
import { Preset, PRESET_TYPES } from 'src/models/PresetModel';
import { setSelectedPresets } from 'src/redux/actions/selectedPresetActions';
import { t } from 'src/lib/language';
import { useNavigate } from 'react-router-dom';
import routes, { Route } from 'src/constants/routes';

interface DefaultPreset {
  defaultPreset: Nullable<Preset>,
  defaultPresetId: number,
}

const entity = ENTITIES.payments;
const PaymentsPage = () => {
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const user = useSelector((state: Store) => state.user);
  const navigate = useNavigate();
  if (!user.is_admin) {
    navigate(routes[Route.USER_PAYMENTS].path);
  }

  // PRESET
  const { defaultPreset, defaultPresetId } = useSelector(
    (state: Store): DefaultPreset => {
      const empty = {
        defaultPreset: null,
        defaultPresetId: 0,
      } as DefaultPreset;
      const type = PRESET_TYPES.layout;
      if (!state.presets[entity]) return empty;
      if (!state.presets[entity][type]) return empty;
      const storedPreset = state.presets[entity][type].find((preset) => preset.is_default);
      if (storedPreset) {
        return {
          defaultPreset: storedPreset,
          defaultPresetId: storedPreset.id,
        } as DefaultPreset;
      }
      return empty;
    },
  );

  useEffect(() => {
    dispatch(setSelectedPresets({
      entity,
      type: PRESET_TYPES.layout,
      id: defaultPresetId,
    }));
    dispatch(setColumns({
      entity,
      columns: defaultPreset?.filter || DEFAULT_COLUMNS[entity],
    } as QuerbuilderPayloadColumns));
    dispatch(setOrders({
      entity,
      order: defaultPreset?.order,
    } as QuerbuilderPayloadOrder));
    dispatch(resetQueryBuilderResult({ entity: ENTITIES.taxReceipts }));
  }, []);

  return (
    <>
      <DatagridSearch entity={ENTITIES.payments} />
      <DatagridToolbar entity={ENTITIES.payments} />
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
      }}
      >
        <ButtonLink onClick={() => dispatch(openModal({ modal: MODALS.manageAccount }))}>
          {t(lang, 'forms.invoices.accounts')}
        </ButtonLink>
        <ButtonLink onClick={() => dispatch(openModal({ modal: MODALS.manageDepartment }))}>
          {t(lang, 'forms.invoices.departments')}
        </ButtonLink>
        <ButtonLink onClick={() => dispatch(openModal({ modal: MODALS.managePrograms }))}>
          {t(lang, 'forms.invoices.programs')}
        </ButtonLink>
        <ButtonLink onClick={() => dispatch(openModal({ modal: MODALS.manageProducts }))}>
          {t(lang, 'forms.invoices.products')}
        </ButtonLink>
      </div>
      <Datagrid entity={ENTITIES.payments} />
    </>
  );
};

export default PaymentsPage;
