import { makeStyles } from '@material-ui/core/styles';
import { OrderColumn } from 'src/components/Elements/GuessOrderDisplayer';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { useMediaQuery } from 'react-responsive';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.1)',
    height: '40px',
  },
  quantity: {
    minWidth: OrderColumn.quantity,
    width: OrderColumn.quantity,
  },
  product: {
    width: OrderColumn.product,
  },
  price: {
    minWidth: OrderColumn.price,
    width: OrderColumn.price,
  },
  taxes: {
    minWidth: OrderColumn.taxes,
    width: OrderColumn.taxes,
  },
  discount: {
    minWidth: OrderColumn.discount,
    width: OrderColumn.discount,
  },
  deductible: {
    minWidth: OrderColumn.deductible,
    width: OrderColumn.deductible,
  },
  amount: {
    minWidth: OrderColumn.amount,
    width: OrderColumn.amount,
  },
  divider: {
    width: '1px',
    height: '20px',
    backgroundColor: 'rgba(0, 0, 0, .75)',
  },
  header: {
    fontWeight: 'bold',
    padding: '0 8px',
  },
}));

const OrderDisplayerHeader = () => {
  const classes = useStyles();
  const lang = useSelector((state: Store) => state.language.language);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });

  const showDesktop = () => (
    <div className={classes.container}>
      <div className={`${classes.header} ${classes.product}`}>
        {t(lang, 'forms.invoices.product')}
      </div>
      <div className={classes.divider} />
      <div className={`${classes.header} ${classes.deductible}`}>
        {t(lang, 'forms.invoices.deductible')}
      </div>
      <div className={classes.divider} />
      <div className={`${classes.header} ${classes.price}`}>
        {t(lang, 'forms.invoices.price')}
      </div>
      <div className={classes.divider} />
      <div
        className={`${classes.header} ${classes.amount}`}
        style={{ textAlign: 'right' }}
      >
        {t(lang, 'forms.invoices.amount')}
      </div>
    </div>
  );

  const showTablet = () => (
    <div className={classes.container}>
      <div className={`${classes.header} ${classes.product}`}>
        {t(lang, 'forms.invoices.product')}
      </div>
      <div
        className={`${classes.header} ${classes.amount}`}
        style={{ textAlign: 'right' }}
      >
        {t(lang, 'forms.invoices.amount')}
      </div>
    </div>
  );

  return isTabletOrMobile ? showTablet() : showDesktop();
};

export default OrderDisplayerHeader;
