import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import AddContactForm from 'src/components/Forms/AddContactForm';

const AddContactModal = () => (
  <ModalBase
    name={MODALS.addContact}
    size={MODALS_SIZE.small}
  >
    <AddContactForm />
  </ModalBase>
);
//
export default AddContactModal;
