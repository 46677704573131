import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { openDialog } from 'src/redux/actions/dialogActions';
import { getTaxesAction } from 'src/redux/actions/taxActions';
import { DIALOGS } from 'src/models/DialogModel';
import { useEffect, useState } from 'react';
import { t } from 'src/lib/language';
import { TextField, Tooltip } from '@material-ui/core';
import { TaxItemModel } from 'src/models/TaxListModel';
import EditTaxForm from 'src/components/Forms/EditTaxForm';
import AddTaxForm from 'src/components/Forms/AddTaxForm';

const useStyles = makeStyles((theme) => ({
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '10vh',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
  },
  scroller: {
    padding: '8px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
  },
  search: {
    height: '6vh',
    display: 'flex',
    width: '100%',
    padding: '8px 0px',
    justifyContent: 'left',
    gap: '8px',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
  },
}));

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const OrganisationTaxesForm = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const taxes = useSelector((state: Store) => state.taxes);

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const [currentTax, setcurrentTax] = useState({} as TaxItemModel);
  const [currentState, setCurrentState] = useState(STATE.list);
  const [currentFilter, setCurrentFilter] = useState('');

  useEffect(() => {
    dispatch(getTaxesAction());
  }, []);

  const handleDelete = (e:any, tax: TaxItemModel) => {
    e.preventDefault();
    dispatch(openDialog({ dialog: DIALOGS.deleteTax, payload: tax }));
  };

  const handleEdit = (tax: TaxItemModel) => {
    setcurrentTax(tax);
    setCurrentState(STATE.edit);
  };

  const handleAdd = () => {
    setcurrentTax({} as TaxItemModel);
    setCurrentState(STATE.add);
  };

  const displayItems = () => taxes.map((tax: TaxItemModel) => {
    if (currentFilter) {
      if (!tax[`name_${lang}`].includes(currentFilter)) return null;
    }
    return (
      <div className={classes.item} key={`tax-${tax.id}`}>
        <Tooltip
          title={t(lang, 'menus.edit_taxe')}
          placement="top-start"
        >
          <span>
            <ActionButton
              variant="outlined"
              category={MENU_BUTTON_CATEGORY.action}
              onClick={() => handleEdit(tax)}
            >
              <EditIcon />
            </ActionButton>
          </span>
        </Tooltip>
        <Tooltip
          title={t(lang, 'menus.delete_taxe')}
          placement="top-start"
        >
          <span>
            <ActionButton
              variant="outlined"
              category={MENU_BUTTON_CATEGORY.action}
              onClick={(e) => handleDelete(e, tax)}
            >
              <DeleteIcon />
            </ActionButton>
          </span>
        </Tooltip>
        <span><b>{ tax[`name_${lang}`] }</b></span>
      </div>
    );
  });

  const displayEditForm = () => (
    <EditTaxForm
      tax={currentTax}
      onClose={() => setCurrentState(STATE.list)}
    />
  );

  const displayAddForm = () => (
    <AddTaxForm
      onClose={() => setCurrentState(STATE.list)}
    />
  );

  return (
    <>
      { currentState === STATE.list && (
      <div className={classes.search}>
        <TextField
          margin="dense"
          variant="outlined"
          label={t(lang, 'forms.organisation.filter')}
          onChange={(e) => setCurrentFilter(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAdd}
        >
          <AddIcon /> {t(lang, 'forms.organisation.add_a_tax')}
        </Button>
      </div>
      )}
      <div className={classes.scroller}>
        { currentState === STATE.list && displayItems() }
        { currentState === STATE.add && displayAddForm() }
        { currentState === STATE.edit && displayEditForm() }
      </div>
    </>
  );
};

export default OrganisationTaxesForm;
