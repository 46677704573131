import { FundraisingNotification } from 'src/models/FundraisingModel';
import { TABS } from 'src/components/Pages/AddFundraisingPage';
import { makeStyles } from '@material-ui/core/styles';
import { Store, useAppDispatch } from 'src/redux/Store';
import { MouseEvent, useState } from 'react';
import { setError } from 'src/redux/actions/errorsActions';
import { FORM } from 'src/constants/Form';
import Button from '@material-ui/core/Button';
import { AUTOCOMPLETE_ID } from 'src/constants/Autocomplete';
import ContactSelector from 'src/components/Control/ContactSelector';
import { useSelector } from 'react-redux';
import { getContactByIdAction } from 'src/redux/actions/contactActions';
import { indexOf } from 'lodash';
import ContactGroupSelector from 'src/components/Control/ContactGroupSelector';
import { Chip } from '@material-ui/core';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import FormRichTextEditor from 'src/components/Control/FormControls/FormRichTextEditor';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import FormUploadImage from 'src/components/Control/FormControls/FormUploadImage';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import FundraisingNotificationValidator from 'src/validations/FundraisingNotificationValidator';
import { ErrorBag } from 'src/models/ErrorModel';
import { subscribeEvent } from 'src/models/EventHelper';
import { EventType } from 'src/models/EventType';
import Debug from 'src/lib/Debug';
import { t } from 'src/lib/language';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  title: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    fontSize: '16px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    flexWrap: 'wrap',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
  },
  contact: {
    borderRadius: '8px',
    border: '2px #ccc solid',
    padding: '8px',
    '&:hover': {
      border: '2px #0A4DF2 solid',
    },
    display: 'flex',
    gap: '16px',
  },
  media_image_preview: {
    width: '576px',
    height: '110px',
    backgroundColor: '#666',
    margin: '16px 0',
  },
  deleteIcon: {
    marginRight: theme.spacing(0.5),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  subtitle: {
    marginTop: '8px',
    borderBottom: 'solid #888 1px',
  },
}));

interface Props {
  onChange: (notifications: FundraisingNotification) => void;
  onNextStep: (step:number) => void;
  setErrors: (tab:TABS, hasError: boolean) => void;
  slug: string,
  initialState: FundraisingNotification,
  setHasChanged: (hasChanged: boolean) => void;
}

const FundraisingNotificationForm = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {
    slug,
    onChange,
    onNextStep,
    setErrors,
    initialState,
    setHasChanged,
  } = props;

  const organisation = useSelector((state: Store) => state.currentOrganisation.id);
  const [validationError, setValidationError] = useState({} as ErrorBag);
  const [notificationState, setNotificationState] = useState(initialState);
  const [contactIds, setContactIds] = useState([] as number[]);
  const [contactId, setContactId] = useState<number | undefined>(0);
  const [groupdIds, setGroupdIds] = useState([] as number[]);
  const [emailBody, setEmailBody] = useState('');
  const [upload, setUpload] = useState(0);

  const contacts = useSelector((state: Store) => state.contacts);
  const lang = useSelector((state: Store) => state.language.language);

  const forceReload = () => setUpload(upload + 1);
  subscribeEvent(EventType.uploadComplete, () => forceReload());

  const onValueChange = (value: any, field: any) => {
    setHasChanged(true);
    setNotificationState({
      ...notificationState,
      [field]: value,
    });
  };

  const onGroupChange = (group:any) => {
    setGroupdIds(group);
    onValueChange(group, 'group_id_list');
  };

  const onContactChange = (id: number) => {
    setContactId(id);
    if (!contacts[id]) {
      dispatch(getContactByIdAction(id));
    }
  };

  const onAddContact = () => {
    if (!contactId) return;
    setContactIds([
      ...contactIds,
      contactId,
    ]);
    setContactId(undefined);
    onValueChange('contact_id_list', contactIds);
  };

  const removeContact = (id:number) => {
    const index = indexOf(contactIds, id);
    contactIds.splice(index, 1);
    setContactIds([...contactIds]);
    onValueChange('contact_id_list', contactIds);
  };

  const displayContacts = () => contactIds.map((id:number) => {
    if (!contacts[id]) return undefined;
    const { contact } = contacts[id];
    let label = `${contact.first_name} ${contact.last_name}`;
    if (contact.company_name && contact.company_name !== '') {
      label = contact.company_name;
    }
    label = `${label}(${contact.email})`;
    return (
      <Chip
        variant="outlined"
        label={label}
        onDelete={() => removeContact(id)}
      />
    );
  });

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setErrors(TABS.notifications, false);
    const errorBag = {} as ErrorBag;
    dispatch(setError({ [FORM.fundraising_notification]: {} }));
    notificationState.thanks_email_body = emailBody;
    notificationState.contacts = contactIds;
    notificationState.groups = groupdIds;
    try {
      FundraisingNotificationValidator().validateSync(notificationState, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.group.validation_errors',
      }));
      if (!validationErrors.inner) {
        Debug.log(validationErrors);
        return;
      }
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      // eslint-disable-next-line prefer-destructuring
      dispatch(setError({ [FORM.fundraising_notification]: errorBag }));
      setValidationError(errorBag);
      setErrors(TABS.info, true);
      return;
    }
    onNextStep(3);
    onChange(notificationState);
  };

  const displayError = (label: string, field: string) => {
    if (validationError[field]?.length) {
      return (
        <div style={{ color: 'red' }}>
          {label} <i>{validationError[field].join(',')}</i>
        </div>
      );
    }
    return (<>{label}</>);
  };

  // @ts-ignore
  return (
    <div>
      <form className={classes.form} noValidate>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <div className={classes.row}>
            <div className={classes.title}>Notifications/Social</div>
          </div>
          <div className={classes.subtitle}>
            { displayError(t(lang, 'forms.fundraising.contacts_to_notify'), 'contacts') }
          </div>
          <div className={classes.row}>
            <ContactSelector
              autocompleteId={AUTOCOMPLETE_ID.add_invoice_contact}
              onChange={(id: number) => onContactChange(id)}
              label={t(lang, 'forms.fundraising.add_contact')}
              form={FORM.fundraising_notification}
              name="add_contact"
              contactId={contactId}
              hideEdit
            />
            <Button
              style={{ marginBottom: '24px' }}
              variant="contained"
              color="primary"
              onClick={onAddContact}
              key="add-contact-button"
              disabled={!contactId}
            >
              {t(lang, 'forms.fundraising.add_contact_btn')}
            </Button>
          </div>
          <div className={classes.row}>
            {displayContacts()}
          </div>
          <div className={classes.subtitle}>
            { displayError(t(lang, 'forms.fundraising.groups_to_notify'), 'groups') }
          </div>
          <div>
            <ContactGroupSelector
              onChange={onGroupChange}
              values={groupdIds}
            />
          </div>
          <div className={classes.subtitle}>
            {t(lang, 'forms.fundraising.notifications')}
          </div>
          <FormCheckbox
            label={t(lang, 'forms.fundraising.send_thanks_email')}
            name="send_thanks_email"
            onChange={onValueChange}
            checked={notificationState.send_thanks_email}
          />
          { notificationState.send_thanks_email && (
            <FormRichTextEditor
              label={t(lang, 'forms.fundraising.thanks_email_body')}
              form={FORM.fundraising_notification}
              name="thanks_email_body"
              controls={[
                'title', 'bold', 'italic', 'underline', 'strikethrough', 'undo', 'redo',
                'link', 'numberList', 'bulletList', 'quote', 'clear', 'save-check', 'font-face',
              ]}
              onChange={setEmailBody}
              value={notificationState.thanks_email_body ?? ''}
            />
          )}
          <FormCheckbox
            label={t(lang, 'forms.fundraising.send_thanks_sms')}
            name="send_thanks_sms"
            onChange={onValueChange}
            checked={notificationState.send_thanks_sms}
          />
          { notificationState.send_thanks_sms && (
            <FormTextField
              form={FORM.fundraising_notification}
              name="thanks_sms_text"
              onChange={onValueChange}
              value={notificationState.thanks_sms_text}
              minRows={3}
              maxRows={3}
              multiline
              label={t(lang, 'forms.fundraising.thanks_sms_text')}
            />
          )}
          <div className={classes.subtitle}>
            {t(lang, 'forms.fundraising.facebook_information')}
          </div>
          <div className={classes.row} style={{ width: '50%' }}>
            <FormTextField
              form={FORM.fundraising_notification}
              name="fb_description"
              onChange={onValueChange}
              value={notificationState.fb_description}
              minRows={3}
              maxRows={3}
              multiline
              label={t(lang, 'forms.fundraising.fb_description')}
            />
            <FormTextField
              form={FORM.fundraising_notification}
              label={t(lang, 'forms.fundraising.fb_keyword')}
              name="fb_keyword"
              onChange={onValueChange}
              value={notificationState.fb_keyword}
            />
            <FormTextField
              form={FORM.fundraising_notification}
              label={t(lang, 'forms.fundraising.media_title')}
              name="media_title"
              onChange={onValueChange}
              value={notificationState.media_title}
            />
            <FormTextField
              form={FORM.fundraising_notification}
              label={t(lang, 'forms.fundraising.media_text')}
              name="media_text"
              onChange={onValueChange}
              value={notificationState.media_text}
            />
          </div>
          <FormUploadImage
            name="media_image_preview.png"
            height={110}
            width={576}
            path={`${organisation}/${slug}/images/media_image_preview.png`}
          />
        </div>
        <div className={classes.bottomRow}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            key="submit-button"
          >
            {t(lang, 'forms.fundraising.next_step')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FundraisingNotificationForm;
//
