import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { setSelections } from 'src/redux/actions/selectionActions';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { t } from 'src/lib/language';

interface CustomProps {
  entity: ENTITIES,
}

type Props = CustomProps & ButtonProps;

const SelectAllMenubutton = (props: Props) => {
  const { disabled, entity } = props;
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language);

  const ids = useSelector((state: Store) => {
    if (!!state.querybuilder[entity] && !!state.querybuilder[entity].result) {
      // @ts-ignore
      return state.querybuilder[entity].result.ids as number[];
    }
    return [] as number[];
  });

  const pagination = useSelector(
    (state: Store) => (state.querybuilder[entity] && state.querybuilder[entity].pagination)
      || { size: 50, page: 1 },
  );

  const handleOnClick = () => {
    const { size, page } = pagination;
    const startingIndex = (page - 1) * size;
    const endingIndex = startingIndex + size;
    dispatch(setSelections({
      entity,
      selections: ids.slice(startingIndex, endingIndex),
    }));
  };

  return (
    <Tooltip
      title={t(lang, 'menus.select_all')}
      placement="top-start"
    >
      <span>
        <ActionButton
          variant="outlined"
          category={MENU_BUTTON_CATEGORY.selection}
          onClick={handleOnClick}
          disabled={disabled}
        >
          <PlaylistAddCheckIcon />
        </ActionButton>
      </span>
    </Tooltip>
  );
};

export default SelectAllMenubutton;
