import MakePaymentForm from 'src/components/Forms/MakePaymentForm';
import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';

const MakePaymentModal = () => (
  <ModalBase
    name={MODALS.makePayment}
    size={MODALS_SIZE.small}
  >
    <MakePaymentForm />
  </ModalBase>
);

export default MakePaymentModal;
