import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { AdminModel } from 'src/models/UserModel';
import { useEffect, useState } from 'react';
import {
  getAdminsAction,
  getCreateAdminAction,
  putAdminsAction,
} from 'src/redux/actions/userActions';
import UserSelector from 'src/components/Control/UserSelector';
import { UserOption } from 'src/models/AutocompleteModel';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { FORM } from 'src/constants/Form';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import Subtitle from 'src/components/Elements/Subtitle';
import { t } from 'src/lib/language';
import { Pagination } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '6vh',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  scroller: {
    overflowY: 'auto',
    height: '500px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
  },
  search: {
    paddingTop: '8px',
    paddingBottom: '14px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    '&:hover': {
      cursor: 'pointer',
      color: 'rgba(0, 0, 0, 0.33)',
    },
  },
}));

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

export enum RIGHT {
  none = 0,
  admin = 1,
  superadmin = 2,
}

const ManageUserAdmins = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(1);

  const admins = useSelector((state: Store) => state.admins);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const locale = useSelector((state: Store) => state.language.locale);

  const [option, setOption] = useState({} as UserOption);

  const [email, setEmail] = useState('' as string);

  const displayEmailItem = (admin: AdminModel) => ((!!admin.last_name || !!admin.first_name)
    ? (`${admin.email} (${admin.last_name} ${admin.first_name})`)
    : admin.email);

  useEffect(() => {
    dispatch(getAdminsAction());
  }, []);

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.manageUserAdmins }));
  };

  const handleUpdateRight = (userId: number, right: RIGHT) => {
    setOption({} as UserOption);
    dispatch(putAdminsAction(userId, right));
  };

  const handleInvite = (value: string) => {
    dispatch(getCreateAdminAction(value, locale));
  };

  const handleChange = (e:any, newPage:number) => {
    setPage(newPage);
  };

  const getRowStyle = (index: number) => {
    const style = {} as any;
    if (index % 2) {
      style.backgroundColor = '#eee';
    }
    return style;
  };

  const getItems =
    (adminList: AdminModel[]) => adminList.map((admin: AdminModel, index: number) => (
      <div
        className={classes.item}
        key={`admin-${admin.id}-${index}`}
        style={getRowStyle(index)}
      >
        <span>
          <b>
            {displayEmailItem(admin)}
          </b>
        </span>
        <span style={{ marginLeft: 'auto' }}>
          <SupervisorAccountIcon
            color={admin.is_super_admin ? 'primary' : 'disabled'}
            className={classes.icon}
            onClick={
              () => handleUpdateRight(
                admin.id,
                admin.is_super_admin ? RIGHT.admin : RIGHT.superadmin,
              )
            }
          />
        </span>
        <span>
          <HighlightOffIcon
            className={classes.icon}
            onClick={() => handleUpdateRight(admin.id, RIGHT.none)}
          />
        </span>
      </div>
    ));

  const displayItems = () => {
    const MAX_ITEM = 13;
    if (admins.length <= MAX_ITEM) {
      return (
        <>
          <div className={classes.scroller}>
            {getItems(admins)}
          </div>
        </>
      );
    }
    const count = Math.ceil(admins.length / MAX_ITEM);
    const start = (page - 1) * MAX_ITEM;
    const adminList = admins.slice(start, start + MAX_ITEM);
    return (
      <>
        <Pagination page={page} count={count} onChange={handleChange} size="small" />
        <div style={{ marginBottom: '8px', marginTop: '8px' }}>
          <div className={classes.scroller}>
            {getItems(adminList)}
          </div>
        </div>
        <Pagination page={page} count={count} onChange={handleChange} size="small" />
      </>
    );
  };

  return (
    <>
      <div className={classes.header}>
        <div className={classes.row}>
          <div className={classes.title}><b>{t(lang, 'misc.manage_admins')}</b></div>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}
          >
            {t(lang, 'misc.close')}
          </Button>
        </div>
      </div>
      <div className={classes.search}>
        <UserSelector onChange={setOption} currentValue={option} />
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={!option.id}
          onClick={() => handleUpdateRight(option.id, RIGHT.admin)}
        >
          {t(lang, 'misc.add')}
        </Button>
      </div>
      { displayItems() }
      <Subtitle>{t(lang, 'misc.send_invite_to_become_admin')}r</Subtitle>
      <div className={classes.row}>
        <FormTextField
          form={FORM.invite_admin}
          label="Email"
          onChange={(value:string) => setEmail(value)}
          required
          value={email}
          name=""
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleInvite(email)}
        >
          {t(lang, 'misc.invite')}
        </Button>
      </div>
    </>
  );
};

export default ManageUserAdmins;
