import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { setError } from 'src/redux/actions/errorsActions';
import { FORM } from 'src/constants/Form';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import Subtitle from 'src/components/Elements/Subtitle';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import { t } from 'src/lib/language';
import InvoiceValidator from 'src/validations/InvoiceValidator';
import InvoiceOrderValidator from 'src/validations/InvoiceOrderValidator';
import SimpleContactShippingValidator from 'src/validations/SimpleContactShippingValidator';
import {
  InvoiceModel,
  InvoicePayload, OrderList, OrderModel, OrderTaxModel,
  ProductOrderList,
  ProductOrderModel,
} from 'src/models/InvoiceModel';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import { postInvoiceAction } from 'src/redux/actions/invoiceActions';
import {
  INVOICE_FIELDS,
  INVOICE_ORDER_FIELDS,
  INVOICE_PAYMENT_STATUS,
  INVOICE_STATE,
} from 'src/constants/Invoices';
import {
  getAccountsAction,
  getDepartmentsAction,
  getProgramsAction,
} from 'src/redux/actions/dataActions';
import { getProductListAction } from 'src/redux/actions/productAction';
import { ProductModel } from 'src/models/ProductModel';
import ContactSelector from 'src/components/Control/ContactSelector';
import { AUTOCOMPLETE_ID } from 'src/constants/Autocomplete';
import { getContactAction } from 'src/redux/actions/contactActions';
import { ContactPayload } from 'src/models/ContactModel';
import { getContactInfoForInvoice } from 'src/lib/ContactHelper';
import MetadataSelector, { MetadataType } from 'src/components/Control/MetadataSelector';
import { MODALS_SIZE } from 'src/models/modal';
import OrdersDisplayer from 'src/components/Elements/OrderDisplayer';
import {
  ceil,
  isEmpty,
  keyBy,
} from 'lodash';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import SaveIcon from '@material-ui/icons/Save';
import SaveIconOutlined from '@material-ui/icons/SaveOutlined';
import ManageProgramsButton from 'src/components/DatagridToolbar/buttons/ManageProgramsButton';
import ManageAccountsButton from 'src/components/DatagridToolbar/buttons/ManageAccountsButton';
import ManageProductsButton from 'src/components/DatagridToolbar/buttons/ManageProductsButton';
import ManageDepartmentsButton
  from 'src/components/DatagridToolbar/buttons/ManageDepartmentsButton';
import OrganisationBankAccountSelector
  from 'src/components/Control/OrganisationBankAccountSelector';
import TextField from '@material-ui/core/TextField';
import GatewaySelector from 'src/components/Control/GatewaySelector';
import AddInvoiceContactButton
  from 'src/components/DatagridToolbar/buttons/AddInvoiceContactButton';

type Fields = INVOICE_ORDER_FIELDS | INVOICE_FIELDS;

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: MODALS_SIZE.large,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
  },
  column: {
    maxWidth: '640px',
  },
  col2: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  control: {
    minHeight: '56px',
  },
  amount: {
    width: '105px',
    display: 'inline-block',
    textAlign: 'right',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: 120,
    margin: theme.spacing(3, 2, 0),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectElement: {
    width: '100%',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.50)',
  },
  selectLabelError: {
    color: 'rgba(255, 0, 0, 0.50)',
  },
  spacer: {
    height: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
    marginTop: '10px',
  },
  clickable: {
    cursor: 'pointer',
  },
  listNoStyle: {
    listStyle: 'none',
    padding: '0px',
    margin: '0px',
  },
  totals: {
    float: 'right',
  },
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '10vh',
  },
  headerRow: {
    height: '4vh',
  },
  scroller: {
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: theme.spacing(2),
    height: '66vh',
    padding: '8px',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
}));

const AddInvoiceForm = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const modalPayload =
    useSelector((state: Store) => {
      if (state.modals[MODALS.addInvoice]) {
        return state.modals[MODALS.addInvoice].payload as InvoiceModel;
      }
      return null;
    });

  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const taxes = useSelector((state: Store) => {
    if (!state.taxes) return {};
    const result = {};
    state.taxes.forEach((tax) => {
      if (tax.id) result[tax.id] = tax;
    });
    return result;
  });
  const countries = useSelector(
    (state: Store) => keyBy(state.data.countries ?? [], 'id'),
  );
  const currentContactId = useSelector(
    (state: Store) => state.currentEntity[ENTITIES.contacts] || 0,
  );
  const [contactId, setContactId] = useState(currentContactId);
  const { contact: contactInfo } = useSelector(
    (state: Store) => {
      if (!!state.contacts[contactId]) {
        return state.contacts[contactId] as ContactPayload;
      }
      return {} as ContactPayload;
    },
  );

  const [invoiceState, setInvoiceState] = useState({
    sameAsBilling: true,
    issue_tax_receipt: false,
    issued_date: moment(),
    due_date: moment(),
    bank_account_id: 0,
    payment_status: INVOICE_PAYMENT_STATUS.not_paid,
    state: INVOICE_STATE.draft,
  } as InvoiceModel);

  const [orderState, setOrderState] = useState([{
    product_id: 0,
    paid: 0,
    quantity: 1,
    deductible: 0,
    discount: 0,
    taxes: {} as OrderTaxModel,
  }] as ProductOrderList);

  const [showInstallment, onToggleInstallment] = useState(false);

  useEffect(() => {
    const modalData = { ...modalPayload };
    if (modalData?.id) {
      delete modalData.id;
    }
    dispatch(setError({ [FORM.add_invoice]: {} }));
    dispatch(getDepartmentsAction());
    dispatch(getAccountsAction());
    dispatch(getProgramsAction());
    dispatch(getProductListAction());
    setInvoiceState({
      ...invoiceState,
      ...modalData,
    });
    if (modalData.order) {
      setOrderState(modalData.order);
    }
  }, []);

  useEffect(() => {
    if (!contactInfo) return;
    const contactInfoForInvoice = getContactInfoForInvoice(contactInfo);
    setInvoiceState({
      ...invoiceState,
      ...contactInfoForInvoice,
      issue_tax_receipt: !!contactInfoForInvoice.issue_tax_receipt,
      contact_salutation_id: (contactInfo.salutation_id === 0)
        ? null
        : contactInfo.salutation_id,
    } as InvoiceModel);
  }, [contactInfo]);

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'CAD',
  });

  const processing = () => false;

  const setShippingFromBilling = () => ({
    ...invoiceState,
    shipping_address: invoiceState.billing_address,
    shipping_suite: invoiceState.billing_suite,
    shipping_city: invoiceState.billing_city,
    shipping_state: invoiceState.billing_state,
    shipping_country_id: invoiceState.billing_country_id,
    shipping_zip_code: invoiceState.billing_zip_code,
  });

  const onFieldChange = (value: Value, field: string) => {
    setInvoiceState({
      ...invoiceState,
      [field]: value,
    });
  };

  const onInstallmentChange = (installmentNumber: string) => {
    onFieldChange(parseInt(installmentNumber, 10), 'installments');
  };

  const onContactChange = (id: number, field: Fields) => {
    onFieldChange(id, field);
    setContactId(id);
    dispatch(getContactAction(id, FORM.add_invoice));
  };

  const formatOrdersForValidation = (orders: any) => {
    const result = {};
    orders.forEach((order: any, index: number) => {
      result[`deductible_${index}`] = order.deductible;
      result[`discount_${index}`] = order.discount;
      result[`note_${index}`] = order.note;
      result[`paid_${index}`] = order.paid;
      result[`product_id_${index}`] = order.product_id;
      result[`quantity_${index}`] = order.quantity;
      result[`taxes_${index}`] = order.taxes;
    });
    return result;
  };

  const onAddProductOrder = () => {
    setOrderState([
      ...orderState,
      {
        product_id: 0,
        paid: 0,
        quantity: 1,
        deductible: 0,
      }]);
  };

  const getTaxesFromProduct = (product: ProductModel) => {
    const result = {} as OrderTaxModel;
    if (!product.taxes) return result;
    product.taxes.forEach((taxId: number) => {
      result[taxId] = ((taxes[taxId]) ? taxes[taxId].rate : 0) * (product.price ?? 0);
    });
    return result;
  };

  const onDeleteProduct = (orderIndex: number) => {
    const productOrderList = [...orderState];
    productOrderList.splice(orderIndex, 1);
    setOrderState(productOrderList);
  };

  const getTaxItem = (id: number) => taxes[id];

  const calculateSubtotal = () => {
    let subtotal = 0;
    orderState.forEach((order) => {
      subtotal += ((order.quantity ?? 0) * ((order.paid ?? 0) - (order.discount ?? 0)));
    });
    return ceil(subtotal, 2);
  };

  const calculateTaxes = () => {
    const taxesObject = invoiceState.taxes || {};
    let totalTaxes = 0;
    Object.keys(taxesObject).forEach((taxId) => {
      totalTaxes += taxesObject[taxId];
    });
    return totalTaxes;
  };

  const calculateTotal = () => ceil((calculateSubtotal() + calculateTaxes()), 2);

  const updateTotals = () => {
    setInvoiceState({
      ...invoiceState,
      total: calculateTotal(),
      subtotal: calculateSubtotal(),
    });
  };

  const updateInvoiceTaxes = (orderList: ProductOrderList) => {
    const invoicesTaxes: OrderTaxModel = {};
    orderList.forEach((order:ProductOrderModel) => {
      Object.keys(order.taxes ?? {}).forEach((taxId) => {
        if (order.taxes && order.taxes[taxId]) {
          if (!invoicesTaxes[taxId]) {
            invoicesTaxes[taxId] = 0;
          }
          invoicesTaxes[taxId] += order.taxes[taxId];
        }
      });
    });

    setInvoiceState({
      ...invoiceState,
      taxes: invoicesTaxes,
    });
  };

  const getInvoiceTaxes = (orderList: ProductOrderList) => {
    const invoicesTaxes: OrderTaxModel = {};
    orderList.forEach((order:ProductOrderModel) => {
      Object.keys(order.taxes ?? {}).forEach((taxId) => {
        if (order.taxes && order.taxes[taxId]) {
          if (!invoicesTaxes[taxId]) {
            invoicesTaxes[taxId] = 0;
          }
          invoicesTaxes[taxId] += order.taxes[taxId];
        }
      });
    });

    return invoicesTaxes;
  };

  const onUpdateProduct = (value: Value, field: string, index: number) => {
    const orderList = [...orderState];
    orderList[index][field] = value;
    setInvoiceState({
      ...invoiceState,
    });
    setOrderState(orderList);
    updateInvoiceTaxes(orderList);
    updateTotals();
  };

  const onProductChange = (product?: ProductModel, index?: number) => {
    const productOrderList = [...orderState] as ProductOrderList;

    if (index !== undefined) {
      if (product === undefined) {
        productOrderList[index][INVOICE_ORDER_FIELDS.product_id] = 0;
        productOrderList[index][INVOICE_ORDER_FIELDS.paid] = 0;
        productOrderList[index][INVOICE_ORDER_FIELDS.discount] = 0;
        productOrderList[index][INVOICE_ORDER_FIELDS.deductible] = 0;
        productOrderList[index][INVOICE_ORDER_FIELDS.taxes] = {} as OrderTaxModel;
        setOrderState(productOrderList);
        updateInvoiceTaxes(productOrderList);
        updateTotals();
        return;
      }

      productOrderList[index][INVOICE_ORDER_FIELDS.product_id] = product?.id || 0;
      productOrderList[index][INVOICE_ORDER_FIELDS.paid] = product?.price || 0;
      productOrderList[index][INVOICE_ORDER_FIELDS.discount] = product?.discount || 0;
      productOrderList[index][INVOICE_ORDER_FIELDS.deductible] = (product?.deductible || 0) * 100;
      productOrderList[index][INVOICE_ORDER_FIELDS.taxes] = getTaxesFromProduct(product);
      setOrderState(productOrderList);
      setInvoiceState({
        ...invoiceState,
        title: product[`name_${lang}`],
        program_id: product.program_id,
        account_id: product.account_id,
        department_id: product.department_id,
        bank_account_id: product.bank_account_id,
        payment_gateway_id: product.payment_gateway_id,
        taxes: getInvoiceTaxes(productOrderList),
      });
    }
  };

  const getTaxesForOrder = (order: ProductOrderModel):string => JSON.stringify(order.taxes);

  const denormalizeOrders = ():OrderList => ({
    ...orderState.map((order: ProductOrderModel) => ({
      ...order,
      deductible: (order.deductible ?? 0) / 100,
      taxes: getTaxesForOrder(order),
    } as OrderModel)),
  });

  const handleSubmit = (
    event: MouseEvent<HTMLElement>,
    state: INVOICE_STATE,
    close:boolean = false,
  ) => {
    event.preventDefault();
    dispatch(setError({ [FORM.add_invoice]: {} }));
    try {
      let schema;
      if (!invoiceState.sameAsBilling) {
        schema = InvoiceValidator(lang).concat(SimpleContactShippingValidator(lang));
      } else {
        schema = InvoiceValidator(lang);
      }
      schema.validateSync({ ...invoiceState }, { abortEarly: false });
      InvoiceOrderValidator(orderState, lang).validateSync(
        formatOrdersForValidation(orderState),
        { abortEarly: false },
      );
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: t(lang, 'forms.contact.validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.add_invoice]: errorBag }));
      return false;
    }

    let invoice = invoiceState;

    if (invoiceState.sameAsBilling) {
      invoice = setShippingFromBilling();
    }

    const payload = {
      invoice: {
        ...invoice,
        state,
        installment: {
          number: invoice.installments ?? 0,
        },
      },
      order: denormalizeOrders(),
    } as InvoicePayload;

    dispatch(postInvoiceAction(payload, close));
    return true;
  };

  const displayTaxes = () => {
    const taxesObject = invoiceState.taxes || {};
    return (
      <>
        { Object.keys(taxesObject).map((taxId: string, index) => {
          const tax = getTaxItem(Number(taxId));
          return (
            <div key={`tax-item-${index}`} style={{ textAlign: 'right' }}>
              {tax[`name_${lang}`]} ({tax.rate * 100}%):&nbsp;
              <div className={classes.amount}>{formatter.format(taxesObject[taxId])}</div>
            </div>
          );
        }) }
      </>
    );
  };

  const displayContactDetails = () => {
    if (isEmpty(contactInfo)) return '';
    return (
      <div style={{ marginTop: '16px' }}>
        <div className={classes.row} style={{ marginBottom: '8px' }}>
          <div className={classes.col2}>
            <b>Billing Address:</b>
            <div> { contactInfo?.billing_address || '' } </div>
            <div> { contactInfo?.billing_suite || '' }</div>
            <div> { contactInfo?.billing_city || '' }</div>
            <div> { contactInfo?.billing_state || '' }</div>
            <div> { contactInfo?.billing_zip_code || '' }</div>
            <div>{ countries[contactInfo.billing_country_id ?? 0]?.name_en || '' }</div>
          </div>
          <div className={classes.col2}>
            <b>Shipping Address:</b>
            <div> { contactInfo?.shipping_address || '' } </div>
            <div> { contactInfo?.shipping_suite || '' } </div>
            <div> { contactInfo?.shipping_city || '' } </div>
            <div> { contactInfo?.shipping_state || '' } </div>
            <div> { contactInfo?.shipping_zip_code || '' }</div>
            <div> { countries[contactInfo?.shipping_country_id ?? 0]?.name_en || '' }</div>
          </div>
        </div>
        <div className={classes.row}>
          <div> <b>Tel:</b> { contactInfo?.phone_mobile || '' } (Mobile) </div>
          <div> <b>Email:</b> { contactInfo?.email || '' } </div>
        </div>
      </div>
    );
  };

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.addInvoice }));
  };

  return (
    <>
      <div className={classes.header}>
        <div className={`${classes.row} ${classes.headerRow}`}>
          <div className={classes.title}>{t(lang, 'forms.invoices.create_invoice')}</div>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}
          >
            {t(lang, 'misc.close')}
          </Button>
        </div>
        <div className={`${classes.row} ${classes.headerRow}`}>
          <Tooltip
            title={t(lang, 'menus.save_as_draft')}
            placement="top-start"
          >
            <span>
              <ActionButton
                disabled={processing()}
                category={MENU_BUTTON_CATEGORY.action}
                onClick={(e) => handleSubmit(e, INVOICE_STATE.draft, true)}
              >
                <SaveIconOutlined />
              </ActionButton>
            </span>
          </Tooltip>
          <Tooltip
            title={t(lang, 'menus.save_as_final')}
            placement="top-start"
          >
            <span>
              <ActionButton
                disabled={processing()}
                category={MENU_BUTTON_CATEGORY.action}
                onClick={(e) => handleSubmit(e, INVOICE_STATE.final, true)}
              >
                <SaveIcon />
              </ActionButton>
            </span>
          </Tooltip>
          <ManageAccountsButton />
          <ManageDepartmentsButton />
          <ManageProgramsButton />
          <ManageProductsButton />
          <AddInvoiceContactButton />
        </div>
      </div>
      <div className={classes.scroller}>
        <form className={classes.form} noValidate>
          <div className={classes.column}>
            <Subtitle noMargin style={{ marginBottom: '8px' }}>
              {t(lang, 'forms.invoices.invoice_informations')}
            </Subtitle>
            <div className={classes.row}>
              <FormTextField
                form={FORM.add_invoice}
                label={t(lang, 'forms.invoices.title')}
                name={INVOICE_FIELDS.title}
                onChange={onFieldChange}
                required
                value={invoiceState.title || ''}
              />
            </div>
            <div className={classes.row}>
              <div className={`${classes.col2} ${classes.control}`}>
                <FormDatePicker
                  form={FORM.add_invoice}
                  required
                  label={t(lang, 'forms.invoices.due_date')}
                  name={INVOICE_FIELDS.due_date}
                  onChange={
                  (value: MaterialUiPickersDate) => onFieldChange(
                    moment(value).format('YYYY-MM-DD'),
                    INVOICE_FIELDS.due_date,
                  )
                }
                  value={moment(invoiceState.due_date) || moment()}
                />
              </div>
              <div className={`${classes.col2} ${classes.control}`}>
                <FormDatePicker
                  form={FORM.add_invoice}
                  required
                  label={t(lang, 'forms.invoices.issued_date')}
                  name={INVOICE_FIELDS.issued_date}
                  onChange={
                  (value: MaterialUiPickersDate) => onFieldChange(
                    moment(value).format('YYYY-MM-DD'),
                    INVOICE_FIELDS.issued_date,
                  )
                }
                  value={moment(invoiceState.issued_date) || moment()}
                />
              </div>
            </div>
          </div>
          <Subtitle style={{ marginBottom: '24px' }}>
            {t(lang, 'forms.invoices.contacts')}
          </Subtitle>
          <div className={classes.row} style={{ marginTop: '16px' }}>
            <div className={`${classes.col2} ${classes.control}`}>
              <ContactSelector
                autocompleteId={AUTOCOMPLETE_ID.add_invoice_payer}
                onChange={(id:number) => onFieldChange(id, INVOICE_FIELDS.contact_payer_id)}
                label="Payer"
                form={FORM.add_invoice}
                name={INVOICE_FIELDS.contact_payer_id}
                contactId={invoiceState.contact_payer_id}
                hideEdit={!invoiceState.contact_payer_id}
              />
            </div>
            <div className={`${classes.col2} ${classes.control}`}>
              <ContactSelector
                autocompleteId={AUTOCOMPLETE_ID.add_invoice_seller}
                onChange={(id:number) => onFieldChange(id, INVOICE_FIELDS.seller_id)}
                label="Seller"
                form={FORM.add_invoice}
                name={INVOICE_FIELDS.seller_id}
                contactId={invoiceState.seller_id}
                hideEdit={!invoiceState.seller_id}
              />
            </div>
          </div>
          <div className={classes.row} style={{ marginTop: '16px' }}>
            <div className={`${classes.col2} ${classes.control}`}>
              <ContactSelector
                autocompleteId={AUTOCOMPLETE_ID.add_invoice_contact}
                onChange={(id:number) => onContactChange(id, INVOICE_FIELDS.contact_id)}
                label="Contact"
                form={FORM.add_invoice}
                name={INVOICE_FIELDS.contact_id}
                contactId={invoiceState.contact_id}
                hideEdit={!invoiceState.contact_id}
              />
            </div>
            <div
              className={`${classes.col2} ${classes.control}`}
              style={{ marginTop: '-26px' }}
            >
              <GatewaySelector
                form={FORM.add_invoice}
                name="gateway_id"
                onChange={(value) => onFieldChange(value, 'payment_gateway_id')}
                value={invoiceState.payment_gateway_id}
                noMarginTop
              />
            </div>
          </div>
          {displayContactDetails()}
          <Subtitle style={{ marginBottom: '16px' }}>{t(lang, 'forms.invoices.orders')}</Subtitle>
          <div>
            <FormCheckbox
              name={INVOICE_FIELDS.issue_tax_receipt}
              label={t(lang, 'forms.invoices.issue_tax_receipt')}
              checked={invoiceState.issue_tax_receipt}
              onChange={onFieldChange}
            />
          </div>
          <div className={classes.row} style={{ marginTop: '16px' }}>
            <FormCheckbox
              name="allow-installment"
              label={t(lang, 'forms.invoices.allow_installments')}
              checked={showInstallment}
              onChange={onToggleInstallment}
            />
          </div>
          { showInstallment && (
          <div className={classes.row}>
            <Typography
              variant="body1"
              style={{ color: 'rgb(0, 0, 0, .75)' }}
            >
              {t(lang, 'forms.invoices.number_of_installments')}
            </Typography>
            <TextField
              style={{ width: '35px' }}
              name="installments"
              type="number"
              onChange={(e) => onInstallmentChange(e.target.value as string)}
              value={invoiceState.installments}
            />
          </div>
          )}
          <OrdersDisplayer
            form={FORM.add_invoice}
            onUpdateProduct={onUpdateProduct}
            orders={orderState}
            onDeleteProduct={onDeleteProduct}
            onAddProduct={onAddProductOrder}
            onProductChange={onProductChange}
          />
          <div className={classes.row}>
            <span style={{ marginRight: '0px', marginLeft: 'auto' }}>
              {t(lang, 'forms.invoices.subtotal')}:&nbsp;
              <span className={classes.amount}>{formatter.format(calculateSubtotal())}</span>
            </span>
          </div>
          <div className={classes.row}>
            <span style={{ marginRight: '0px', marginLeft: 'auto' }}>
              <i>{displayTaxes()}</i>
            </span>
          </div>
          <div className={classes.row}>
            <span style={{ marginRight: '0px', marginLeft: 'auto' }}>
              <b>
                Total:&nbsp;
                <span className={classes.amount}>{formatter.format(calculateTotal())}</span>
              </b>
            </span>
          </div>
          <Subtitle style={{ marginBottom: '8px' }}>Meta Data</Subtitle>
          <div className={classes.row}>
            <div className={`${classes.col2} ${classes.control}`}>
              <MetadataSelector
                type={MetadataType.program}
                onChange={(id) => onFieldChange(id, INVOICE_FIELDS.program_id)}
                form={FORM.add_invoice}
                name={INVOICE_FIELDS.program_id}
                value={invoiceState.program_id || undefined}
                noMarginTop
              />
            </div>
            <div className={`${classes.col2} ${classes.control}`}>
              <MetadataSelector
                type={MetadataType.department}
                onChange={(id) => onFieldChange(id, INVOICE_FIELDS.department_id)}
                form={FORM.add_invoice}
                name={INVOICE_FIELDS.department_id}
                value={invoiceState.department_id || undefined}
                noMarginTop
              />
            </div>
          </div>
          <div className={classes.row} style={{ marginTop: '16px' }}>
            <div className={`${classes.col2} ${classes.control}`}>
              <MetadataSelector
                type={MetadataType.account}
                onChange={(id) => onFieldChange(id, INVOICE_FIELDS.account_id)}
                form={FORM.add_invoice}
                name={INVOICE_FIELDS.account_id}
                value={invoiceState.account_id || undefined}
                noMarginTop
              />
            </div>
            <div className={`${classes.col2} ${classes.control}`}>
              <OrganisationBankAccountSelector
                value={invoiceState.bank_account_id || undefined}
                onChange={(id:number) => onFieldChange(id, INVOICE_FIELDS.bank_account_id)}
                form={FORM.add_invoice}
                name="bank_account_id"
              />
            </div>
          </div>
          <div className={classes.row} style={{ marginTop: '16px' }}>
            <FormTextField
              form={FORM.add_invoice}
              label={t(lang, 'forms.invoices.note')}
              multiline
              minRows="4"
              name={INVOICE_FIELDS.note}
              onChange={onFieldChange}
              value={invoiceState.note || ''}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default AddInvoiceForm;
//
