import { Store, useAppDispatch } from 'src/redux/Store';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import { lighten } from '@material-ui/core/styles/colorManipulator';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import {
  getPaymentFilter,
  getPaymentRangeDate,
  normalizePaymentForUser,
} from 'src/lib/PaymentHelper';
import { useEffect, useState } from 'react';
import { SEARCH_LOGICAL_OPERATORS } from 'src/models/AdvanceSearchModel';
import { getEntitiesAction, setFilters } from 'src/redux/actions/querybuilderActions';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { PaymentSections } from 'src/components/Pages/UserPaymentsPage';
import PaymentModel, { PAYMENT_STATUS, PAYMENT_TYPE } from 'src/models/PaymentModel';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import InfiniteScroll from 'react-infinite-scroll-component';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  getUserBankAccountAction,
  getUserCreditCardAction,
} from 'src/redux/actions/paymentInfoAction';
import { CreditCardModel } from 'src/models/CreditCardModel';
import { BankAccountModel } from 'src/models/BankAccountModel';
import { openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { setSelections } from 'src/redux/actions/selectionActions';

const useStyles = (isTabletOrMobile:boolean) => makeStyles((theme) => ({
  card: {
    border: '1px solid rgba(0, 0, 0, 0.25)',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      cursor: 'pointer',
    },
    flex: 1,
    width: '100%',
    maxWidth: '680px',
  },
  label: {
    fontWeight: 'bold',
    marginRight: '8px',
  },
  selected: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
  list: {
    marginTop: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  mobile: {
    marginTop: '48px',
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '18px',
  },
  submit: {
    marginLeft: 'auto',
    display: 'flex',
    marginBottom: '8px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    gap: '8px',
    alignItems: 'center',
    minWidth: '640px',
  },
  tabs: {
    width: '100%',
    height: '26px',
    display: 'flex',
    maxWidth: '680px',
    backgroundColor: 'white',
  },
  tab: {
    border: '1px solid #eee',
    borderTop: 'none',
    height: '26px',
    flex: 1,
    padding: '0 8px',
    '&:hover': {
      opacity: 0.5,
      cursor: 'pointer',
    },
  },
  tabSelected: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
  },
  col2: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '50%',
    width: '100%',
    alignItems: 'center',
  },
  col3: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '33%',
    width: '100%',
    alignItems: 'center',
  },
  col4: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '25%',
    width: '100%',
    alignItems: 'center',
  },
}));

interface Props {
  section: PaymentSections,
}

const ManageUserPayments = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const classes = useStyles(isTabletOrMobile)();
  const dispatch = useAppDispatch();

  const { section } = props;

  const MAX_ITEMS = 7;

  const selections = useSelector((state: Store) => {
    if (!!state.selected[ENTITIES.payments]) {
      return [...state.selected[ENTITIES.payments]];
    }
    return [];
  });

  const loading = useSelector(
    (state: Store) => state.formStatus[FORM.query_builder] === FORM_STATUS.processing
      || state.formStatus[FORM.get_user_credit_cards] === FORM_STATUS.processing
      || state.formStatus[FORM.get_user_bank_accounts] === FORM_STATUS.processing,
  );

  const payments = useSelector(
    (state: Store) => state.querybuilder.payments?.result?.list || [],
  );

  const creditCards = useSelector(
    (state: Store) => {
      if (!!state.userCreditCards) {
        return state.userCreditCards as CreditCardModel[];
      }
      return [] as CreditCardModel[];
    },
  );

  const bankAccounts = useSelector(
    (state: Store) => {
      if (!!state.userBankAccounts) {
        return state.userBankAccounts as BankAccountModel[];
      }
      return [] as BankAccountModel[];
    },
  );

  const getCreditCard = (id: number) => {
    if (!id) return 'N/A';
    const result = creditCards.find((creditCard) => creditCard.id === id);
    if (!result) return 'N/A';
    return result.masked_card_number;
  };

  const getBankAccount = (id: number) => {
    if (!id) return 'N/A';
    const result = bankAccounts.find((bankAccount) => bankAccount.id === id);
    if (!result) return 'N/A';
    return result.account.slice(-4);
  };

  const getFiltered = ():PaymentModel[] => payments.map((item) => {
    const payment = normalizePaymentForUser(item);
    if (section === PaymentSections.all) {
      return payment;
    }
    if (section === PaymentSections.scheduled) {
      if (![
        PAYMENT_STATUS.scheduled,
        PAYMENT_STATUS.pending,
        PAYMENT_STATUS.holding,
      ].includes(payment.payment_status as PAYMENT_STATUS)) {
        return undefined;
      }
    }
    if (section === PaymentSections.error) {
      if (![
        PAYMENT_STATUS.error,
        PAYMENT_STATUS.declined,
      ].includes(payment.payment_status as PAYMENT_STATUS)) {
        return undefined;
      }
    }
    return payment;
  }).filter((item): item is PaymentModel => item !== undefined);

  const [page, setPage] = useState(1);
  const [items, setItems] = useState(getFiltered().slice(0, MAX_ITEMS));
  const [hasMore, setHasMore] = useState(true);
  const [filteredItems, setFilteredItems] = useState(getFiltered());
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'CAD',
  });

  useEffect(() => {
    if (payments.length && !!filteredItems) {
      setItems(getFiltered().slice(0, MAX_ITEMS));
    }
  }, [section]);

  useEffect(() => {
    if (payments.length && !!filteredItems) {
      const newFilteredItems = getFiltered();
      setFilteredItems(newFilteredItems);
      setItems(newFilteredItems.slice(0, MAX_ITEMS));
    }
  }, [payments]);

  useEffect(() => {
    const [startDate, endDate] = getPaymentRangeDate(section);
    const filters = getPaymentFilter(
      startDate,
      endDate,
    );
    dispatch(setFilters({
      entity: ENTITIES.payments,
      filters: { [SEARCH_LOGICAL_OPERATORS.and]: filters },
    }));
    dispatch(getEntitiesAction(ENTITIES.payments));
    dispatch(getUserCreditCardAction());
    dispatch(getUserBankAccountAction());
  }, []);

  const handleClick = (payment: PaymentModel) => {
    dispatch(setSelections({
      entity: ENTITIES.payments,
      selections: [payment.id],
    }));
    dispatch(openModal({
      modal: MODALS.editUserPayement,
      payload: payment,
    }));
  };

  const handleNext = () => {
    const newItems = filteredItems.slice(page * MAX_ITEMS, (page + 1) * MAX_ITEMS);
    setItems((prevItems) => [...prevItems, ...newItems]);
    setPage((prevPage) => prevPage + 1);
    setHasMore(filteredItems.length > (page + 1) * MAX_ITEMS);
  };

  const getClasses = (id?: number) => {
    if (selections.indexOf(id) < 0) {
      return classes.card;
    }
    return `${classes.card} ${classes.selected}`;
  };

  const displayPaymentInfo = (payment: PaymentModel) => {
    if (payment.payment_type === PAYMENT_TYPE.cc) {
      return (
        <div>
          <span className={classes.label}>Credit Card Used:</span>
          {getCreditCard(payment.payment_info_id as number)}
        </div>
      );
    }
    if (payment.payment_type === PAYMENT_TYPE.eft) {
      return (
        <div>
          <span className={classes.label}>Credit Card Used:</span>
          ********{getBankAccount(payment.payment_info_id as number)}
        </div>
      );
    }
    return (<></>);
  };

  const displayScheduledDate = (payment: PaymentModel) => {
    if (payment.payment_status !== PAYMENT_STATUS.scheduled) return (<></>);
    if (!payment.scheduled_date) return (<></>);
    const date = moment(payment.scheduled_date).format('YYYY-MM-DD');
    return (
      <div><span className={classes.label}>Scheduled:</span>{date}</div>
    );
  };

  const displayPaidDate = (payment: PaymentModel) => {
    if (!payment.paid_date) return (<></>);
    const date = moment(payment.paid_date).format('YYYY-MM-DD');
    return (
      <div><span className={classes.label}>Date:</span>{date}</div>
    );
  };

  const getItem = (payment: PaymentModel) => (
    <div className={classes.row} key={payment.id}>
      <div
        className={getClasses(payment.id)}
        onClick={() => handleClick(payment)}
        key={payment.id}
      >
        <div><span className={classes.label}>ID:</span>{payment.id}</div>
        {displayPaidDate(payment)}
        {displayScheduledDate(payment)}
        <div><span className={classes.label}>Title:</span>{payment.title}</div>
        <div>
          <span className={classes.label}>Amount:</span>
          {formatter.format(payment.amount ?? 0)}
        </div>
        <div>
          <span className={classes.label}>Deductible:</span>
          {formatter.format(payment.deductible ?? 0)}
        </div>
        <div><span className={classes.label}>Method:</span>{payment.payment_type}</div>
        {displayPaymentInfo(payment)}
        <div><span className={classes.label}>Status:</span>{payment.payment_status}</div>
      </div>
    </div>
  );

  const displayItems = () => items.map((item) => getItem(item as PaymentModel));

  const displayScroller = () => (
    <InfiniteScroll
      dataLength={filteredItems.length}
      next={handleNext}
      hasMore={hasMore}
      loader={<Typography />}
      endMessage={<Typography>All items displayed</Typography>}
      height={621}
    >
      {displayItems()}
    </InfiniteScroll>
  );

  const displayContent = () => {
    if (loading) {
      return (
        <CircularProgress
          size={40}
          style={{
            margin: '0 auto',
            marginTop: '32px',
          }}
        />
      );
    }
    return (
      <div className={`${isTabletOrMobile ? classes.mobile : classes.list}`}>
        {displayScroller()}
      </div>
    );
  };

  return (
    <>
      {displayContent()}
    </>
  );
};

export default ManageUserPayments;
