import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { DIALOGS } from 'src/models/DialogModel';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { deletePresetAction } from 'src/redux/actions/presetActions';

const DeletePresetDialog = () => {
  const dispatch = useAppDispatch();
  const { opened, payload } =
    useSelector((state: Store) => {
      if (state.dialog[DIALOGS.deletePreset]) {
        return state.dialog[DIALOGS.deletePreset];
      }
      return { opened: false, payload: null };
    });

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const handleCancel = () => {
    dispatch(closeDialog({ dialog: DIALOGS.deletePreset }));
  };

  const handleOk = () => {
    dispatch(deletePresetAction(payload));
    dispatch(closeDialog({ dialog: DIALOGS.deletePreset }));
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="deleting-contact"
      open={opened}
    >
      <DialogTitle id="deleting-contact">{t(lang, 'menus.deleting_preset')}</DialogTitle>
      <DialogContent dividers>
        {t(lang, 'menus.deleting_preset_confirmation')}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {t(lang, 'misc.no')}
        </Button>
        <Button onClick={handleOk} color="primary">
          {t(lang, 'misc.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePresetDialog;
