import { GridColDef } from '@material-ui/x-grid';
import {
  Filter,
  Filters,
  FiltersPayload,
  SEARCH_LOGICAL_OPERATORS,
} from 'src/models/AdvanceSearchModel';
import { Moment } from 'moment';
import { DownloadOptionsModel } from 'src/models/DownloadModel';

export enum ENTITIES {
  bankAccounts = 'bankAccounts',
  contacts = 'contacts',
  invoices = 'invoices',
  products = 'products',
  creditCards = 'creditCards',
  customfields = 'customfields',
  payments = 'payments',
  taxReceipts = 'tax-receipts',
  events = 'events',
}

export type QuerybuilderFields = Array<string>;

export enum ORDER {
  asc = 'asc',
  desc = 'desc',
}

export type QuerybuilderOrder = [ string, string];

export type QuerybuilderOrders = Array<QuerybuilderOrder>;

export type QuerybuilderPagination = { page:number, size:number };

export interface GridColumDefinition extends GridColDef {
  display?:string,
  hide_in_search?: boolean,
}

export type GridColumns = GridColumDefinition[];

export interface QuerybuilderResultValue {
  [field:string]:any;
}

export interface QuerybuilderResult {
  count: number,
  ids: number[],
  list: Array<QuerybuilderResultValue>
}

// ONLY BECAUSE OF THE BACK END
export interface QuerybuilderOptions {
  payment_receipt?: boolean,
}

export type QuerybuilderModel = {
  columns?: GridColumns,
  fields?: QuerybuilderFields,
  filters?: FiltersPayload,
  order?: QuerybuilderOrders,
  pagination?: QuerybuilderPagination,
  result?: QuerybuilderResult,
  quickSearch?: QuicksearchPayload,
  options?: QuerybuilderOptions | DownloadOptionsModel,
};

export type QuerybuilderModels = {
  [entity in ENTITIES]: QuerybuilderModel;
};

export interface QuerbuilderPayloadBase {
  entity:ENTITIES,
}

export interface QuerbuilderPayloadColumns extends QuerbuilderPayloadBase {
  columns: GridColumns
}

export type QuerbuilderPayloadFilters = QuerbuilderPayloadBase & {
  filters: Filters | null;
};

export type QuerbuilderPayloadOperator = {
  operator: SEARCH_LOGICAL_OPERATORS | null,
};

export interface QuerbuilderPayloadFilter extends QuerbuilderPayloadBase {
  filter: Filter
}

export interface QuerbuilderPayloadFields extends QuerbuilderPayloadBase {
  fields: QuerybuilderFields
}

export interface QuerbuilderPayloadOrder extends QuerbuilderPayloadBase {
  order: QuerybuilderOrders
}

export interface QuerbuilderPayloadPagination extends QuerbuilderPayloadBase {
  pagination: QuerybuilderPagination
}

export interface QuerbuilderPayloadResult extends QuerbuilderPayloadBase {
  result: QuerybuilderResult
}

export interface QuerybuilderPayloadOptions {
  options: QuerybuilderOptions
}

export interface QuicksearchPayload {
  term: string,
  startDate: Moment,
  endDate: Moment,
}

export interface QuerbuilderPayloadQuicksearch extends QuerbuilderPayloadBase {
  quickSearch: QuicksearchPayload,
}

export type QuerbuilderPayload =
  QuerbuilderPayloadColumns |
  QuerbuilderPayloadFilters |
  QuerbuilderPayloadFilter |
  QuerbuilderPayloadFields |
  QuerbuilderPayloadOrder |
  QuerbuilderPayloadPagination |
  QuerbuilderPayloadQuicksearch |
  QuerbuilderPayloadResult;
