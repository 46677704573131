import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { DIALOGS } from 'src/models/DialogModel';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { cancelInvoiceAction } from 'src/redux/actions/invoiceActions';

const CancelInvoiceDialog = () => {
  const dispatch = useAppDispatch();

  const { opened, payload } =
    useSelector((state: Store) => {
      if (state.dialog[DIALOGS.cancelInvoice]) {
        return state.dialog[DIALOGS.cancelInvoice];
      }
      return { opened: false, payload: null };
    });
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const handleCancel = () => {
    dispatch(closeDialog({ dialog: DIALOGS.cancelInvoice }));
  };

  const handleOk = () => {
    const { invoiceId } = payload;
    dispatch(cancelInvoiceAction(invoiceId));
    dispatch(closeDialog({ dialog: DIALOGS.cancelInvoice }));
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="deleting-contact"
      open={opened}
    >
      <DialogTitle id="deleting-contact">{t(lang, 'menus.cancel_selected_invoice')}</DialogTitle>
      <DialogContent dividers>
        {t(lang, 'menus.canceling_invoice_confirmation')}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {t(lang, 'misc.no')}
        </Button>
        <Button onClick={handleOk} color="primary">
          {t(lang, 'misc.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelInvoiceDialog;
