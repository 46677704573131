import {
  FormControl, InputLabel, MenuItem, Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorBag } from 'src/models/ErrorModel';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { v4 as uuid } from 'uuid';
import { FORM_ID } from 'src/models/FormModel';

export interface SelectItem {
  id: number | string,
  label: string,
}
//
interface Props {
  disabled?: boolean;
  form: FORM_ID;
  items: Array<SelectItem>;
  label: string;
  name: string;
  onChange:any;
  value: any[];
  noMarginTop?: boolean,
  margin?: 'dense' | 'normal' | undefined,
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 220,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectElement: {
    width: '100%',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.50)',
  },
  selectLabelError: {
    color: theme.palette.error.main,
  },
}));

const FormMultipleSelect = (props:Props) => {
  const {
    disabled, form, items, label, name, onChange, value, noMarginTop, margin,
  } = props;
  const classes = useStyles();
  const errors: ErrorBag =
    useSelector((state:Store) => state.errors[form] || {} as ErrorBag);

  const displayItems = () => {
    const elements = [
      <MenuItem key={uuid()} value="" selected={!value.length} disabled>{label}</MenuItem>];
    if (!items) {
      return items;
    }
    items.map((item) => {
      elements.push(
        <MenuItem
          key={uuid()}
          value={item.id}
          selected={value.indexOf(item.id) >= 0}
        >
          { item.label }
        </MenuItem>,
      );
      return elements;
    });
    return elements;
  };

  let displayClass = '';
  if (!value) {
    displayClass = classes.selectLabel;
  }
  if (errors[name]) {
    displayClass = classes.selectLabelError;
  }

  const getIdToValue = () => {
    const idsMap = {} as any;
    items.forEach((item) => {
      idsMap[item.id] = item.label;
    });
    return idsMap;
  };

  const renderValue = () => {
    if (value.length > 0) {
      const IdToLabel = getIdToValue();
      const result = [] as string[];
      value.forEach((id) => {
        result.push(IdToLabel[id]);
      });
      return result.join(', ');
    }
    return '';
  };

  return (
    <FormControl
      variant="outlined"
      margin={margin || 'dense'}
      className={classes.formControl}
      error={!!errors[name]}
      key={`form-control-${name}`}
    >
      <InputLabel id={`select-${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`select-${name}-label`}
        id={`select-${name}-select`}
        value={value}
        onChange={(e) => onChange(e.target.value as number, name)}
        displayEmpty
        className={`${noMarginTop ? '' : classes.selectEmpty} ${displayClass} ${classes.selectElement}`}
        inputProps={{ 'aria-label': 'Without label' }}
        renderValue={renderValue}
        disabled={disabled}
        multiple
        key={`select-${name}`}
        label={label}
      >
        { displayItems() }
      </Select>
    </FormControl>
  );
};

export default FormMultipleSelect;
//
