import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import {
  FormControl, FormHelperText, MenuItem, Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EmptyFilter, SingleOperand } from 'src/models/AdvanceSearchModel';
import { FILTER_POSITION } from 'src/constants/FilterPosition';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { getOperatorAndFilters } from 'src/lib/QueryBuilderHelper';
import { v4 as uuid } from 'uuid';
import { FORM } from 'src/constants/Form';
import { setAdvancedSearch } from 'src/redux/actions/advancedSearchAction';
import { BankAccountModel } from 'src/models/BankAccountModel';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 240,
  },
  minimalSelect: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

interface Props {
  entity: ENTITIES;
  index: number;
}

const OperandBankAccount = (props: Props) => {
  const classes = useStyles();

  const {
    index, entity,
  } = props;

  const dispatch = useAppDispatch();

  const organisationId = useSelector((state: Store) => state.currentOrganisation.id);
  const organisationBankAccounts = useSelector(
    (state: Store) => state.organisationBankAccounts[organisationId],
  );
  const error: string =
    useSelector(
      (state: Store) => {
        if (state.errors[FORM.query_builder]) {
          if (state.errors[FORM.query_builder][index]) {
            return state.errors[FORM.query_builder][index][FILTER_POSITION.operands];
          }
          return '';
        }
        return '';
      },
    );

  const filters = useSelector(
    (state: Store) => state.advancedSearch[entity] || EmptyFilter,
  );
  const [operator, filterList] = getOperatorAndFilters(filters);

  const handleChange = (operand: SingleOperand) => {
    filterList[index][FILTER_POSITION.operands] = operand;
    dispatch(setAdvancedSearch({
      entity,
      filters: {
        [operator]: [
          ...filterList,
        ],
      },
    }));
  };

  const getItems = () => {
    const elements = [] as any[];
    elements.push(
      <MenuItem
        key={uuid()}
        value=""
        selected={filterList[index][FILTER_POSITION.operands] === ''}
      >
        Select a value
      </MenuItem>,
    );

    // eslint-disable-next-line no-restricted-syntax
    organisationBankAccounts.forEach((account: BankAccountModel) => {
      elements.push(
        <MenuItem
          key={uuid()}
          value={account.id}
          selected={account.id === filterList[index][FILTER_POSITION.operands]}
        >
          { account.account}
        </MenuItem>,
      );
    });
    return elements;
  };
  //
  return (
    <div style={{ display: 'inline-block' }}>
      <FormControl variant="outlined" className={classes.formControl} error={!!error}>
        <Select
          value={filterList[index][FILTER_POSITION.operands] || ''}
          onChange={(e) => handleChange(e.target.value as string)}
          displayEmpty
          classes={{ root: classes.minimalSelect }}
          inputProps={{ 'aria-label': 'Without label' }}
          name={`operand-bankaccount-${index}`}
        >
          { getItems() }
        </Select>
        <FormHelperText>{ error }</FormHelperText>
      </FormControl>
    </div>
  );
};

export default OperandBankAccount;
