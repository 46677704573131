import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { t } from 'src/lib/language';
import { MODALS_SIZE } from 'src/models/modal';
import { MouseEvent, useEffect, useState } from 'react';
import { FORM } from 'src/constants/Form';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import { cancelPaymentAction, getPaymentAction } from 'src/redux/actions/paymentActions';
import { compact } from 'lodash';
import { setError } from 'src/redux/actions/errorsActions';
import PaymentModel, { CancelPaymentItems, PaymentsModel } from 'src/models/PaymentModel';
import { Moment } from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import CancelPaymentValidator from 'src/validations/CancelPaymentValidator';
import { formatDatetime, getJSLocale } from 'src/lib/FieldHelper';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: MODALS_SIZE.large,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
    marginTop: '10px',
  },
  close: {
    padding: 5,
    minHeight: 0,
    minWidth: 0,
    float: 'right',
    '&> span': {
      lineHeight: 0.75,
    },
  },
}));

const CancelPaymentForm = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const locale = useSelector((state: Store) => getJSLocale(state.language.locale));
  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.payments] || [],
  );

  const payments = useSelector(
    (state: Store) => selections.map(
      (id: number) => state.payments[id],
    ) ?? [] as PaymentsModel,
  );

  const [paymentState, setPaymentState] = useState({} as CancelPaymentItems);

  useEffect(() => {
    selections.forEach(
      (paymentId: number) => dispatch(getPaymentAction(paymentId as number)),
    );
  }, []);

  useEffect(() => {
    const paymentItems = {} as CancelPaymentItems;
    payments.forEach((payment: PaymentModel) => {
      if (payment) {
        paymentItems[Number(payment.id)] = {
          payment_id: Number(payment.id),
          refund_date: moment(),
          payment_date: payment.paid_date || undefined,
        };
      }
    });

    setPaymentState({
      ...paymentItems,
    });
  }, [compact(payments).length]);

  const onFieldChange = (id: number, date: Moment) => {
    setPaymentState({
      ...paymentState,
      [id]: {
        payment_id: id,
        refund_date: date,
      },
    });
  };

  const handleClose = () => {
    dispatch(closeDialog({ dialog: DIALOGS.cancelPayments }));
  };

  const formatDate = (date: string | undefined) => formatDatetime(date, locale);

  const displayPayments = () => Object.values(paymentState).map(
    (currentPayment, index) => ((currentPayment)
      ? (
        <div className={classes.row} key={`item-${index}`}>
          <div style={{ minWidth: '64px', paddingTop: '24px' }}>
            <i>{currentPayment.payment_id}</i>
          </div>
          <div style={{ minWidth: '96px', paddingTop: '24px' }}>
            {formatDate(currentPayment.payment_date?.toString())}
          </div>
          <FormDatePicker
            form={FORM.cancel_payment}
            label={t(lang, 'forms.payments.cancel_date')}
            name="refund_date"
            onChange={
              (date: MaterialUiPickersDate) => onFieldChange(
                currentPayment.payment_id, date ?? moment(),
              )
            }
            value={moment(currentPayment.refund_date) ?? moment()}
          />
        </div>
      )
      : <></>),
  );

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    try {
      CancelPaymentValidator().validateSync(
        Object.values(paymentState)[0],
        { abortEarly: false },
      );
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: t(lang, 'forms.payments.cancel_date_validation'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.cancel_payment]: errorBag }));
      return 0;
    }

    dispatch(cancelPaymentAction(Object.values(paymentState)));
    return true;
  };

  return (
    <form className={classes.form} noValidate>
      <div className={classes.row}>
        <div style={{ minWidth: '64px' }}>
          <b>Id</b>
        </div>
        <div style={{ minWidth: '96px' }}>
          <b>Payment Date</b>
        </div>
        <div style={{ width: '100%' }}>
          <b>Cancel Date</b>
        </div>
      </div>
      {displayPayments()}
      <div className={classes.bottomRow}>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleClose}
        >
          {t(lang, 'misc.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        >
          {t(lang, 'forms.payments.cancel_payment')}
        </Button>
      </div>
    </form>
  );
};

export default CancelPaymentForm;
