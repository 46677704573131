import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import ManageUserAdmins from 'src/components/Elements/ManageUserAdmins';

const AdminUserManagementModal = () => (
  <ModalBase
    name={MODALS.manageUserAdmins}
    size={MODALS_SIZE.small}
  >
    <ManageUserAdmins />
  </ModalBase>
);

export default AdminUserManagementModal;
