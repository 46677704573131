import { Store, useAppDispatch } from 'src/redux/Store';
import { useSelector } from 'react-redux';
import { normalizeInvoiceForUser } from 'src/lib/InvoiceHelper';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'src/lib/language';
import { useMediaQuery } from 'react-responsive';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { setSelections } from 'src/redux/actions/selectionActions';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { useEffect, useState } from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { INVOICE_PAYMENT_STATUS, INVOICE_PAYMENT_STATUS_COLOR } from 'src/constants/Invoices';
import { SEARCH_LOGICAL_OPERATORS } from 'src/models/AdvanceSearchModel';
import { getEntitiesAction, setFilters } from 'src/redux/actions/querybuilderActions';
import { InvoiceSections } from 'src/components/Pages/UserInvoicesPage';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import { getInvoiceFilter } from 'src/lib/PaymentHelper';
import { InvoiceModel } from 'src/models/InvoiceModel';
import InfiniteScroll from 'react-infinite-scroll-component';
import Typography from '@material-ui/core/Typography';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = (isTabletOrMobile:boolean) => makeStyles((theme) => ({
  card: {
    border: '1px solid rgba(0, 0, 0, 0.25)',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      cursor: 'pointer',
    },
    flex: 1,
    width: '100%',
    maxWidth: '680px',
  },
  label: {
    fontWeight: 'bold',
    marginRight: '8px',
  },
  selected: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
  list: {
    marginTop: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  mobile: {
    marginTop: '48px',
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '18px',
  },
  submit: {
    marginLeft: 'auto',
    display: 'flex',
    marginBottom: '8px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    gap: '8px',
    alignItems: 'center',
    minWidth: '640px',
  },
  tabs: {
    width: '100%',
    height: '26px',
    display: 'flex',
    maxWidth: '680px',
    backgroundColor: 'white',
  },
  tab: {
    border: '1px solid #eee',
    borderTop: 'none',
    height: '26px',
    flex: 1,
    padding: '0 8px',
    '&:hover': {
      opacity: 0.5,
      cursor: 'pointer',
    },
  },
  tabSelected: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
  },
  col2: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '50%',
    width: '100%',
    alignItems: 'center',
  },
  col3: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '33%',
    width: '100%',
    alignItems: 'center',
  },
  col4: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '25%',
    width: '100%',
    alignItems: 'center',
  },
}));

interface Props {
  section: InvoiceSections,
}

const ManageUserInvoices = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const classes = useStyles(isTabletOrMobile)();
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const { section } = props;

  const MAX_ITEMS = 7;

  useEffect(() => {
    const start = moment().subtract(2, 'year');
    const end = moment();

    const filters = getInvoiceFilter(
      start,
      end,
    );
    dispatch(setFilters({
      entity: ENTITIES.invoices,
      filters: { [SEARCH_LOGICAL_OPERATORS.and]: filters },
    }));
    dispatch(getEntitiesAction(ENTITIES.invoices));
  }, []);

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'CAD',
  });

  const selections = useSelector((state: Store) => {
    if (!!state.selected[ENTITIES.invoices]) {
      return [...state.selected[ENTITIES.invoices]];
    }
    return [];
  });

  const loading = useSelector(
    (state: Store) => state.formStatus[FORM.query_builder] === FORM_STATUS.processing,
  );

  const invoices = useSelector((state: Store) => state.querybuilder.invoices?.result?.list || []);

  const getFiltered = (): InvoiceModel[] => invoices.map((item) => {
    const invoice = normalizeInvoiceForUser(item);
    const now = moment();

    if (section === InvoiceSections.overdue) {
      if (invoice.due_date.isAfter(now) ||
        invoice.payment_status === INVOICE_PAYMENT_STATUS.paid ||
        invoice.payment_status === INVOICE_PAYMENT_STATUS.scheduled) {
        return undefined;
      }
    } else if (section === InvoiceSections.unpaid) {
      if (invoice.payment_status === INVOICE_PAYMENT_STATUS.paid ||
        invoice.payment_status === INVOICE_PAYMENT_STATUS.scheduled) {
        return undefined;
      }
    } else if (section === InvoiceSections.paid) {
      if (invoice.payment_status === INVOICE_PAYMENT_STATUS.not_paid ||
        invoice.payment_status === INVOICE_PAYMENT_STATUS.scheduled_due ||
        invoice.payment_status === INVOICE_PAYMENT_STATUS.partially_paid) {
        return undefined;
      }
    }

    return invoice;
  }).filter((item): item is InvoiceModel => item !== undefined); // Type guard

  const [page, setPage] = useState(1);
  const [items, setItems] = useState(getFiltered().slice(0, MAX_ITEMS));
  const [hasMore, setHasMore] = useState(true);
  const [filteredItems, setFilteredItems] = useState(getFiltered());

  useEffect(() => {
    if (invoices.length && !!filteredItems) {
      setItems(getFiltered().slice(0, MAX_ITEMS));
    }
  }, [section]);

  useEffect(() => {
    if (invoices.length && !!filteredItems) {
      const newFilteredItems = getFiltered();
      setFilteredItems(newFilteredItems);
      setItems(newFilteredItems.slice(0, MAX_ITEMS));
    }
  }, [invoices]);

  const handleOnClick = (id?: number) => {
    if (!id) return;
    const index = selections.indexOf(id);

    if (index >= 0) {
      selections.splice(index, 1);
    } else {
      selections.push(id);
    }

    dispatch(setSelections({
      entity: ENTITIES.invoices,
      selections,
    }));
  };

  const handleNext = () => {
    const newItems = filteredItems.slice(page * MAX_ITEMS, (page + 1) * MAX_ITEMS);
    setItems((prevItems) => [...prevItems, ...newItems]);
    setPage((prevPage) => prevPage + 1);
    setHasMore(filteredItems.length > (page + 1) * MAX_ITEMS);
  };

  const getClasses = (id?: number) => {
    if (selections.indexOf(id) < 0) {
      return classes.card;
    }
    return `${classes.card} ${classes.selected}`;
  };

  const getItem = (invoice: InvoiceModel) => {
    const isDue = () => {
      switch (invoice.payment_status) {
        case INVOICE_PAYMENT_STATUS.paid:
        case INVOICE_PAYMENT_STATUS.scheduled:
          return false;
        default:
          if (!moment.isMoment(invoice.due_date)) return false;
          return invoice.due_date.isBefore(moment());
      }
    };
    const hasWarning = () => {
      switch (invoice.payment_status) {
        case INVOICE_PAYMENT_STATUS.scheduled_due:
          return true;
        default:
          return false;
      }
    };
    const getStatusColor = () => {
      const color = INVOICE_PAYMENT_STATUS_COLOR[
        invoice.payment_status ?? INVOICE_PAYMENT_STATUS_COLOR.not_paid
      ];
      return hasWarning() ? '#d41d1d' : color;
    };
    const getDate = (value: any) => {
      if (!moment.isMoment(value)) return '';
      return value.format('YYYY-MM-DD');
    };

    return (
      <div className={classes.row} key={invoice.id}>
        <div
          className={getClasses(invoice.id)}
          onClick={() => handleOnClick(invoice.id)}
          key={invoice.invoice_number}
        >
          <div><b>{invoice.invoice_number}</b></div>
          <div className={classes.row}>
            <span><b>{invoice.title}</b></span>
            {(hasWarning() || isDue()) && (
            <WarningIcon style={{
              color: '#d41d1d',
              marginRight: 0,
              marginLeft: 'auto',
            }}
            />
            )}
          </div>

          <div style={{ color: isDue() ? '#d41d1d' : undefined }}>
            Due date: <span>{getDate(invoice.due_date)}</span>
          </div>
          <div>
            Amount due: <span>{formatter.format(invoice.total ?? 0)}</span>
          </div>
          <div>
            Amount paid: <span>{formatter.format(invoice.paid ?? 0)}</span>
          </div>
          <div>
            Balance: <span>{formatter.format(invoice.balance ?? 0)}</span>
          </div>
          <div>
            Scheduled: <span>{formatter.format(invoice.balance ?? 0)}</span>
          </div>
          <div>
            <i>Deductible: <span>{formatter.format(invoice.balance ?? 0)}</span></i>
          </div>
          <div className={classes.row}>
            <span
              style={{
                color: getStatusColor(),
              }}
            >
              {t(lang, `forms.invoices.${invoice.payment_status}`)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const showItems = () => items.map((item) => getItem(item as InvoiceModel));

  const displayItems = () => (
    <InfiniteScroll
      dataLength={filteredItems.length}
      next={handleNext}
      hasMore={hasMore}
      loader={<Typography />}
      endMessage={<Typography>All items displayed</Typography>}
      height={630}
    >
      {showItems()}
    </InfiniteScroll>
  );

  const showContent = () => {
    if (loading) {
      return (
        <CircularProgress
          size={40}
          style={{
            margin: '0 auto',
            marginTop: '32px',
          }}
        />
      );
    }
    return (
      <div className={`${isTabletOrMobile ? classes.mobile : classes.list}`}>
        {displayItems()}
      </div>
    );
  };

  return (
    <>
      {showContent()}
    </>
  );
};

export default ManageUserInvoices;
