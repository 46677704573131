import { makeStyles } from '@material-ui/core/styles';

interface Props {
  space: number;
}

const useStyles = (space: number) => makeStyles((theme) => ({
  spacer: {
    width: theme.spacing(space),
    display: 'inline-block',
  },
}));

const Spacer = (props: Props) => {
  const { space } = props;
  const classes = useStyles(space)();
  return (
    <div className={classes.spacer} />
  );
};

export default Spacer;
