import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { t } from 'src/lib/language';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { MouseEvent, useEffect, useState } from 'react';
import {
  InstallmentModel,
  PAYMENT_FREQUENCY,
  PAYMENT_FREQUENCY_LIST,
  PAYMENT_TYPE,
  PaymentModel, PurchasePayload,
} from 'src/models/PaymentModel';
import { FORM } from 'src/constants/Form';
import { setError } from 'src/redux/actions/errorsActions';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import GuessPaymentValidator from 'src/validations/GuessPaymentValidator';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { capitalize, ceil } from 'lodash';
import { InvoiceModel, ProductOrderModel } from 'src/models/InvoiceModel';
import Subtitle from 'src/components/Elements/Subtitle';
import SalutationSelector from 'src/components/Control/SalutationSelector';
import AddressSearch from 'src/components/Control/AddressSearch';
import { AddressModel, AddressType } from 'src/models/AddressModel';
import CountriesSelector from 'src/components/Control/CountriesSelector';
import { useMediaQuery } from 'react-responsive';
import { postPurchaseAction } from 'src/redux/actions/paymentActions';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { getInvoiceAction } from 'src/redux/actions/invoiceActions';
import { INVOICE_PAYMENT_STATUS } from 'src/constants/Invoices';
import UserPaymentAccountSelector from '../Control/UserPaymentAccountSelector';
// import ButtonLink from 'src/components/Elements/ButtonLink';

const useStyles = (isTabletOrMobile:boolean) => makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
  formControl: {
    width: '100%',
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  spacer: {
    height: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
    marginTop: '10px',
  },
  clickable: {
    cursor: 'pointer',
  },
  close: {
    padding: 5,
    minHeight: 0,
    minWidth: 0,
    float: 'right',
    '&> span': {
      lineHeight: 0.75,
    },
  },
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '50px',
  },
  scroller: {
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: theme.spacing(2),
    height: '66vh',
    padding: '8px',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  row: {
    display: 'flex',
    alignItems: 'start',
    gap: '10px',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  col2: {
    flex: isTabletOrMobile ? '100%' : '45%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const UserPaymentForm = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const classes = useStyles(isTabletOrMobile)();

  const dispatch = useAppDispatch();

  const isLogged = useSelector((store: Store) => !!store.auth.accessToken);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const userId = useSelector((state: Store) => state.user.id);

  const selections = useSelector((state: Store) => {
    if (!!state.selected[ENTITIES.invoices]) {
      return [...state.selected[ENTITIES.invoices]];
    }
    return [];
  });

  const invoices = useSelector(
    (state: Store) => Object.values(state.invoices)
      .filter((invoice: InvoiceModel) => selections.indexOf(invoice.id) >= 0),
  );

  const getInitialProperties = (property: string) => {
    const invoice = invoices.find(
      (currentInvoice) => !!currentInvoice[property],
    );
    return invoice ? invoice[property] : undefined;
  };

  const getInitialAmount = () => {
    const unpaid = invoices.filter((invoice) => [
      INVOICE_PAYMENT_STATUS.not_paid,
      INVOICE_PAYMENT_STATUS.scheduled_due,
      INVOICE_PAYMENT_STATUS.partially_paid,
    ].includes(invoice.payment_status ?? INVOICE_PAYMENT_STATUS.not_paid));
    return unpaid.reduce((sum, invoice) => sum + (Number(invoice.balance) ?? 0), 0);
  };

  const getDeductible = () => invoices.reduce((sum, invoice) => {
    if (invoice.deductible !== undefined) {
      return sum + invoice.deductible;
    }
    return sum;
  }, 0);

  const initializePaymentState = () => ({
    user_id: userId,
    payment_type: PAYMENT_TYPE.cc,
    scheduled_date: moment().startOf('day'),
    is_installment: false,
    amount: getInitialAmount(),
    issue_tax_receipt: getInitialProperties('issue_tax_receipt'),
    payment_gateway_id: getInitialProperties('payment_gateway_id'),
    bank_account_id: getInitialProperties('bank_account_id'),
    contact_first_name: getInitialProperties('contact_first_name'),
    contact_last_name: getInitialProperties('contact_last_name'),
    contact_salutation_id: getInitialProperties('contact_salutation_id'),
    contact_company_name: getInitialProperties('contact_company_name'),
    billing_address: getInitialProperties('billing_address'),
    billing_state: getInitialProperties('billing_state'),
    billing_city: getInitialProperties('billing_city'),
    billing_suite: getInitialProperties('billing_suite'),
    billing_country_id: getInitialProperties('billing_country_id'),
    billing_zip_code: getInitialProperties('billing_zip_code'),
    program_id: getInitialProperties('program_id') || 1,
    account_id: getInitialProperties('account_id') || 1,
    department_id: getInitialProperties('department_id') || 1,
    is_deductible: !!getDeductible(),
    deductible: getDeductible(),
  } as PaymentModel);

  const [paymentState, setPaymentState] = useState({} as PaymentModel);

  const [installmentState, setInstallmentState] = useState({
    number: 1,
    start_date: moment(),
    frequency: PAYMENT_FREQUENCY.monthly,
  } as InstallmentModel);

  const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.cc);

  useEffect(() => {
    Promise.all(
      selections.map((id) => dispatch(getInvoiceAction(id))),
    )
      .then(() => {
        setPaymentState(
          initializePaymentState(),
        );
      });
  }, []);

  const onFieldChange = (value: Value, field: string) => {
    setPaymentState({
      ...paymentState,
      [field]: value,
    });
  };

  const displayInstallmentDetails = () => {
    const step = (paymentState.amount ?? 0) / (installmentState.number ?? 1);

    if (!Number.isNaN(step) && !!installmentState.number) {
      return (
        <p>
          <strong>{installmentState.number} {capitalize(installmentState.frequency)} {t(lang, 'forms.payments.payments_of')} {step.toFixed(2)}$</strong>
        </p>
      );
    }
    return <></>;
  };

  const onInstallmentChange = (value: Value, field: string) => {
    setInstallmentState({
      ...installmentState,
      [field]: value,
    });
  };

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.makeUserPayment }));
  };

  const displayInstallmentFrequencyOptions = () => (
    PAYMENT_FREQUENCY_LIST.map((frequency: PAYMENT_FREQUENCY, index: number) => (
      <MenuItem value={frequency} key={`frequency-${index}`}>{frequency}</MenuItem>
    ))
  );

  const getData = () => ({
    ...paymentState,
  });

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const data = getData();
    if ([PAYMENT_TYPE.cc, PAYMENT_TYPE.eft].includes(paymentState.payment_type)) {
      data.scheduled_date = data.paid_date;
    }
    dispatch(setError({ [FORM.make_payment]: {} }));
    try {
      GuessPaymentValidator(lang, isLogged).validateSync(data, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: t(lang, 'forms.contact.validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.make_payment]: errorBag }));
      return false;
    }

    const payload = {
      ...paymentState,
      orders: [] as ProductOrderModel[],
      installment: paymentState.is_installment ? installmentState : undefined,
      is_installment: paymentState.is_installment || 0,
    } as PurchasePayload;

    dispatch<any>(postPurchaseAction(payload, MODALS.guessPayment));
    return true;
  };

  const setAddressFromGoogle = (address: AddressModel, type: AddressType) => {
    setPaymentState({
      ...paymentState,
      [`${type}_address`]: address.address,
      [`${type}_city`]: address.city,
      [`${type}_state`]: address.state,
      [`${type}_country_id`]: address.country_id,
      [`${type}_zip_code`]: address.zip_code,
    });
  };

  const onAddressChange = (address: AddressModel) => {
    setAddressFromGoogle(address, AddressType.billing);
  };

  return (
    <>
      <div className={classes.header}>
        <div className={classes.row}>
          <div className={classes.title}>{t(lang, 'forms.invoices.make_payment')}</div>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </div>
      <form className={classes.scroller} noValidate>
        <div className={classes.row}>
          <div className={classes.col2}>
            <FormTextField
              form={FORM.make_payment}
              label={t(lang, 'forms.payments.amount')}
              name="amount"
              onChange={onFieldChange}
              required
              value={ceil(paymentState.amount ?? 0, 2)}
            />
          </div>
          <div className={classes.col2}>
            {!!paymentState.is_installment &&
              (
                <FormControl
                  component="fieldset"
                  style={{ marginTop: '8px' }}
                >
                  <FormLabel component="legend">{t(lang, 'forms.payments.installments')}</FormLabel>
                  <RadioGroup
                    defaultValue={0}
                    row
                    aria-label={t(lang, 'forms.payments.installments')}
                    name="is_installment"
                    value={paymentState.is_installment ? 1 : 0}
                    onChange={(e) => {
                      onFieldChange(e.target.value !== '0', 'is_installment');
                    }}
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label={t(lang, 'forms.payments.single_payment')}
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label={t(lang, 'forms.payments.multiple_payment')}
                    />
                  </RadioGroup>
                </FormControl>
              )}
          </div>
        </div>
        {!!paymentState.is_installment && (
          <>
            <div className={classes.row}>
              <div className={classes.col2}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="frequency">Frequence</InputLabel>
                  <Select
                    labelId="frequency-label-id-"
                    id="demo-simple-select"
                    value={installmentState.frequency || ''}
                    onChange={(e) => onInstallmentChange(e.target.value as string, 'frequency')}
                  >
                    {displayInstallmentFrequencyOptions()}
                  </Select>
                </FormControl>
              </div>
              <div className={classes.col2}>
                <FormTextField
                  form={FORM.make_payment}
                  label={t(lang, 'forms.payments.number')}
                  name="number"
                  onChange={onInstallmentChange}
                  required
                  value={installmentState.number || ''}
                />
              </div>
            </div>
            {displayInstallmentDetails()}
            <div className={classes.row}>
              <div className={classes.col2}>
                <FormDatePicker
                  form={FORM.make_payment}
                  required
                  label={t(lang, 'forms.payments.start_date')}
                  name="start_date"
                  onChange={
                    (value: MaterialUiPickersDate) => onInstallmentChange(
                      moment(value)
                        .format('YYYY-MM-DD'),
                      'start_date',
                    )
                  }
                  value={installmentState.start_date || moment()}
                />
              </div>
            </div>
          </>
        )}
        <Subtitle>{t(lang, 'forms.contact.payer_name')}</Subtitle>
        <div className={classes.row}>
          <div className={classes.col2}>
            <SalutationSelector
              form={FORM.make_payment}
              name="contact_salutation_id"
              value={paymentState.contact_salutation_id || 0}
              onChange={(id) => onFieldChange(id, 'contact_salutation_id')}
            />
          </div>
          <div className={classes.col2}>
            <FormTextField
              form={FORM.make_payment}
              label={t(lang, 'forms.contact.first_name')}
              name="contact_first_name"
              onChange={onFieldChange}
              value={paymentState.contact_first_name}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col2}>
            <FormTextField
              form={FORM.make_payment}
              label={t(lang, 'forms.contact.last_name')}
              name="contact_last_name"
              onChange={onFieldChange}
              value={paymentState.contact_last_name}
            />
          </div>
          <div className={classes.col2}>
            <FormTextField
              form={FORM.make_payment}
              label={t(lang, 'forms.contact.company_name')}
              name="contact_company_name"
              onChange={onFieldChange}
              value={paymentState.contact_company_name}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col2}>
            <AddressSearch
              onChange={onFieldChange}
              onAddressChange={onAddressChange}
              value={{ description: paymentState.billing_address || '' }}
              name="billing_address"
              form={FORM.make_payment}
            />
          </div>
          <div className={classes.col2}>
            <FormTextField
              form={FORM.make_payment}
              label={t(lang, 'forms.contact.suite')}
              name="billing_suite"
              onChange={onFieldChange}
              value={paymentState.billing_suite || ''}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col2}>
            <FormTextField
              form={FORM.make_payment}
              label={t(lang, 'forms.contact.city')}
              name="billing_city"
              onChange={onFieldChange}
              value={paymentState.billing_city || ''}
            />
          </div>
          <div className={classes.col2}>
            <FormTextField
              form={FORM.make_payment}
              label={t(lang, 'forms.contact.state')}
              name="billing_state"
              onChange={onFieldChange}
              value={paymentState.billing_state || ''}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col2}>
            <CountriesSelector
              form={FORM.make_payment}
              onChange={(id) => onFieldChange(id, 'billing_country_id')}
              value={paymentState.billing_country_id || undefined}
              name="billing_country_id"
            />
          </div>
          <div className={classes.col2}>
            <FormTextField
              form={FORM.make_payment}
              label={t(lang, 'forms.contact.zip_code')}
              name="billing_zip_code"
              onChange={onFieldChange}
              value={paymentState.billing_zip_code || ''}
            />
          </div>
        </div>
        <Subtitle>{t(lang, 'forms.contact.payment_information')}</Subtitle>
        <div className={classes.row}>
          <FormControl component="fieldset" style={{ marginTop: '8px' }}>
            <RadioGroup
              row
              aria-label="bank_country"
              name="bank_country"
              value={paymentType}
              onChange={(e) => setPaymentType(e.target.value as PAYMENT_TYPE)}
            >
              <FormControlLabel
                value={PAYMENT_TYPE.cc}
                control={<Radio />}
                label="Credit Card"
              />
              <FormControlLabel
                value={PAYMENT_TYPE.eft}
                control={<Radio />}
                label="Bank Account"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <UserPaymentAccountSelector
          type={paymentType}
          form={FORM.make_payment}
          name="payment_info_id"
          onChange={(id: number) => onFieldChange(id, 'payment_info_id')}
          value={paymentState.payment_info_id}
        />
        <div className={classes.row}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            key="submit-close-button"
          >
            {t(lang, 'misc.pay')}
          </Button>
        </div>
      </form>
    </>
  );
};

export default UserPaymentForm;
