import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Link from '@material-ui/core/Link';

import Copyright from 'src/components/Elements/Copyright';
import { resendVerificationAction, setAuthData } from 'src/redux/actions/userActions';
import LanguageSelector from 'src/components/Control/LanguageSelectorLogin';
import { t } from 'src/lib/language';

const useStyles = makeStyles((theme) => ({
  text: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  pointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  policy: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.775rem',
  },
  copyright: {
    position: 'absolute',
    bottom: theme.spacing(2),
  },
  inlineText: {
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'inline-block',
  },
  language: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(1),
  },
}));

interface Props {
  changePage: (page: string) => void,
}

const NotVerifiedPage = (props: Props) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const { changePage } = props;

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const handleSendEmailVerification = () => {
    dispatch(resendVerificationAction());
  };

  const handleLogout = () => {
    dispatch(setAuthData({}));
    changePage('login');
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        {t(lang, 'pages.not_verified.activate')}
      </Typography>
      <div className={classes.language}>
        <span style={{ marginRight: '-5px' }}>Language:</span><LanguageSelector />
      </div>
      <div className={classes.text}>
        <Typography variant="subtitle2">
          {t(lang, 'pages.not_verified.sent_email')}
        </Typography>
        <Typography variant="body2">
          {t(lang, 'pages.not_verified.sent_email')}
          <br />
          <br />
        </Typography>
        <Typography variant="subtitle2">
          {t(lang, 'pages.not_verified.received_email')}
        </Typography>
        <Typography variant="body2">
          {t(lang, 'pages.not_verified.check_spam')}
        </Typography>
      </div>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={() => handleSendEmailVerification()}
      >
        {t(lang, 'pages.not_verified.resend_email')}
      </Button>
      <div>
        <Link
          component="button"
          variant="body2"
          onClick={() => handleLogout()}
          className={classes.pointer}
        >
          <div className={classes.inlineText}>
            {t(lang, 'pages.not_verified.different_account')}
          </div>
          &nbsp;{t(lang, 'pages.not_verified.login')}
        </Link>
      </div>
      <Box mt={5} className={classes.copyright}>
        <Copyright />
      </Box>
    </>
  );
};

export default NotVerifiedPage;
