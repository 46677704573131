import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import WarningOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ApartmentIcon from '@material-ui/icons/Apartment';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { t } from 'src/lib/language';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import routes, { Route } from 'src/constants/routes';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { setAuthData } from 'src/redux/actions/userActions';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/core/styles';
import { closeMenu } from 'src/redux/actions/menuActions';
import { openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';

const Spacer = styled.div`
  height: 25px
`;

const useStyles = makeStyles((theme) => ({
  icon: {
    color: '#ff9800',
    display: 'inline-block',
    marginRight: '2px',
    fontSize: '1rem',
    verticalAlign: 'middle',
  },
  donation: {
    color: theme.palette.primary.main,
  },
}));

interface Props {
  content: string,
}

export const UserMenu = (props: Props) => {
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const hasContact = useSelector((state: Store) => !!state.userContact?.id);
  const organisationsUserList = useSelector((state: Store) => state.organisationsUser);

  const hasChooseOrganization = !!organisationsUserList;

  const { content } = props;

  const hideItem = !hasContact || !hasChooseOrganization;

  const handleLogout = () => {
    dispatch(setAuthData({}));
    dispatch(closeMenu());
  };

  const onClick = () => {
    dispatch(closeMenu());
  };

  const openDonationModal = () => {
    dispatch(openModal({ modal: MODALS.userDonation }));
  };

  return (
    <div>
      <ListItem
        button
        component={RouterLink}
        to={routes[Route.USER_INVOICES].path}
        selected={content === Route.USER_INVOICES}
        disabled={hideItem}
        onClick={onClick}
      >
        <ListItemIcon>
          <DescriptionOutlinedIcon />
        </ListItemIcon>
        {t(lang, 'menus.invoices')}
      </ListItem>

      <ListItem
        button
        component={RouterLink}
        to={routes[Route.USER_PAYMENTS].path}
        selected={content === Route.USER_PAYMENTS}
        disabled={hideItem}
        onClick={onClick}
      >
        <ListItemIcon>
          <AttachMoneyOutlinedIcon />
        </ListItemIcon>
        {t(lang, 'menus.payments')}
      </ListItem>

      <ListItem
        button
        component={RouterLink}
        to={routes[Route.USER_RECEIPTS].path}
        selected={content === Route.USER_RECEIPTS}
        disabled={hideItem}
        onClick={onClick}
      >
        <ListItemIcon>
          <AttachMoneyOutlinedIcon />
        </ListItemIcon>
        {t(lang, 'menus.receipts')}
      </ListItem>
      <ListItem
        button
        component={RouterLink}
        to={routes[Route.USER_PAYMENT_SETTINGS].path}
        selected={content === Route.USER_PAYMENT_SETTINGS}
        onClick={onClick}
      >
        <ListItemIcon>
          <SettingsOutlinedIcon />
        </ListItemIcon>
        {t(lang, 'menus.payment_settings')}
      </ListItem>
      <Spacer />
      <ListItem
        button
        component={RouterLink}
        to={routes[Route.PROFILE].path}
        selected={content === Route.PROFILE}
        onClick={onClick}
      >
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        { hideItem && <WarningOutlinedIcon className={styles.icon} /> } {t(lang, 'menus.profile')}
      </ListItem>
      <ListItem
        button
        component={RouterLink}
        to={routes[Route.ORGANISATIONS].path}
        selected={content === Route.ORGANISATIONS}
        onClick={onClick}
      >
        <ListItemIcon>
          <ApartmentIcon />
        </ListItemIcon>
        { hideItem && <WarningOutlinedIcon className={styles.icon} /> } {t(lang, 'menus.organisations')}
      </ListItem>
      <Spacer />
      <ListItem
        button
        onClick={openDonationModal}
        className={styles.donation}
      >
        <ListItemIcon>
          <FavoriteIcon color="primary" />
        </ListItemIcon>
        {t(lang, 'menus.make_a_donation')}
      </ListItem>
      <ListItem button onClick={() => handleLogout()}>
        <ListItemIcon>
          <ExitToAppOutlinedIcon />
        </ListItemIcon>
        {t(lang, 'menus.logout')}
      </ListItem>
    </div>
  );
};
