import { Autocomplete } from '@material-ui/lab';
import { debounce, FormControl, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AddressModel } from 'src/models/AddressModel';
import { t } from 'src/lib/language';
import { ErrorBag } from 'src/models/ErrorModel';
import {
  getGooglePlaceAction,
  getGooglePredictionsAction,
} from 'src/redux/actions/googleAPIAction';
import { FORM_ID } from 'src/models/FormModel';

const useStyles = makeStyles((theme) => ({
  search: {
  },
  apply: {
    marginLeft: theme.spacing(2),
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

interface Props {
  disabled?: boolean;
  autocomplete?: boolean;
  onChange: (value: Value, field: string) => void,
  onAddressChange: (addresse: AddressModel) => void,
  value: { description: string };
  name: string;
  form: FORM_ID;
}

const AddressSearch = (props: Props) => {
  const classes = useStyles();
  const {
    autocomplete, disabled, value, onChange, name, form, onAddressChange,
  } = props;

  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const errors: ErrorBag =
    useSelector((state:Store) => state.errors[form] || {} as ErrorBag);

  useEffect(() => () => {});

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([] as any[]);

  const onKeyPress = () => {
    onChange(inputValue, name);
  };

  const handleSetAddress = async (result: any) => {
    if (!result || !result.place_id) {
      return;
    }
    const address = await dispatch(getGooglePlaceAction(result.place_id));
    onAddressChange(address);
  };

  const searchAddress = useCallback(
    debounce(async (input: string) => {
      if (!input) return;
      const result = await dispatch(getGooglePredictionsAction(input));
      setOptions(result);
    }, 300),
    [], // will be created only once initially
  );

  const onInputChange = async (input: string) => {
    setInputValue(input);
    if (inputValue.length > 3) {
      searchAddress(input);
    }
  };

  const displayLabel = (option: any) => option.description;

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      error={!!errors[name]}
      key={`form-control-${name}`}
    >
      <Autocomplete
        open={open}
        freeSolo
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        disabled={disabled}
        value={value}
        className={classes.search}
        onChange={(e, option) => handleSetAddress(option)}
        onInputChange={(e, input) => onInputChange(input)}
        renderInput={(params) => {
          // MUI mess fix
          const saneParams = autocomplete
            ? {
              ...params,
              inputProps: {
                ...params.inputProps,
                autoComplete: 'nope',
              },
            }
            : params;

          return (
            <TextField
              {...saneParams}
              variant="outlined"
              label={t(lang, 'forms.contact.address')}
              placeholder={t(lang, 'forms.contact.address')}
              onKeyPress={onKeyPress}
              error={!!errors[name]}
              helperText={errors[name] && errors[name].join(',')}
            />
          );
        }}
        options={options}
        getOptionLabel={(option) => displayLabel(option)}
        getOptionSelected={(option: any, selected: any) => option.place_id === selected.place_id}
        filterOptions={(x) => x}
      />
    </FormControl>
  );
};

export default AddressSearch;
