import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { TimeZoneListModel } from 'src/models/TimeZoneListModel';
import { getTimeZoneAction } from 'src/redux/actions/dataActions';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { t } from 'src/lib/language';
import { ErrorBag } from 'src/models/ErrorModel';
import { FORM_ID } from 'src/models/FormModel';

interface Props {
  disabled?: boolean;
  onChange: (value: Value, field: string) => void;
  name: string;
  form: FORM_ID;
  value?: number;
}

interface TimezoneItem {
  id: number,
  label?: string,
  text?: string,
}

const TimezoneSelector = (props: Props) => {
  const dispatch = useAppDispatch();

  const {
    disabled, onChange, name, value, form,
  } = props;

  const timezones = useSelector((store: Store) => store.data.timezones || {} as TimeZoneListModel);
  const errors: ErrorBag = useSelector((state:Store) => state.errors[form] || {} as ErrorBag);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  useEffect(() => {
    dispatch(getTimeZoneAction());
  }, []);

  const onOptionChange = (item: TimezoneItem) => {
    onChange(item.id as number, name);
  };

  const getTimezoneItems = () => {
    const items:TimezoneItem[] = [];
    const tzIdList = Object.keys(timezones);
    if (!tzIdList.length) return items;

    tzIdList.forEach((tzId: any) => {
      items.push({
        id: tzId,
        label: timezones[tzId],
      });
    });
    return items;
  };

  const getLabel = (option:any) => {
    if (option.label || option.text) {
      return option.label;
    }
    return 'Choose a Timezone';
  };

  const getValue = (initialValue?: number) => {
    let result = {
      id: 0,
      label: undefined,
    } as TimezoneItem;
    const tzIdList = Object.keys(timezones);
    if (!tzIdList.length || !initialValue) {
      return result;
    }
    tzIdList.forEach((tzId: any) => {
      if (tzId === initialValue) {
        result = {
          id: tzId,
          label: timezones[tzId],
        } as TimezoneItem;
      }
    });
    return result;
  };

  return (
    <Autocomplete
      id="timezone"
      options={getTimezoneItems() || []}
      value={getValue(value)}
      getOptionLabel={getLabel}
      getOptionSelected={(option, item) => option.id === item.id}
      onChange={
        (event: any, newValue: Nullable<TimezoneItem>) => onOptionChange(newValue as TimezoneItem)
      }
      style={{ marginTop: '16px' }}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t(lang, 'forms.organisation.timezone')}
          variant="outlined"
          error={!!errors[name]}
          helperText={errors[name] && errors[name].join(',')}
        />
      )}
    />
  );
};

export default TimezoneSelector;
