import { SEARCH_OPERATORS, SEARCH_TYPES } from 'src/constants/SearchOperators';
import { FIELD_TYPES } from 'src/constants/Fields';
import {
  FormControl, FormHelperText, MenuItem, Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { FILTER_POSITION } from 'src/constants/FilterPosition';
import { ENTITIES, GridColumDefinition, GridColumns } from 'src/models/QuerybuilderModel';
import { EmptyFilter } from 'src/models/AdvanceSearchModel';
import { getExtraValue, getOperatorAndFilters } from 'src/lib/QueryBuilderHelper';
import { v4 as uuid } from 'uuid';
import { FORM } from 'src/constants/Form';
import { CustomFieldType } from 'src/models/CustomfieldModel';
import { setAdvancedSearch } from 'src/redux/actions/advancedSearchAction';

interface Props {
  entity: ENTITIES;
  index: number;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 160,
    marginLeft: theme.spacing(1),
  },
  minimalSelect: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
    width: '100%',
  },
  item: {
    textAlign: 'center',
    width: '100%',
  },
}));

const OperatorSelector = (props: Props) => {
  const classes = useStyles();

  const {
    index, entity,
  } = props;

  const dispatch = useAppDispatch();

  const filters = useSelector(
    (state: Store) => state.advancedSearch[entity] || EmptyFilter,
  );

  const columns = useSelector(
    (state: Store) => (state.querybuilder[entity] && state.querybuilder[entity].columns)
      || [] as GridColumns,
  );

  const customfields = useSelector(
    (state: Store) => state.customfields,
  );

  const error: string =
    useSelector(
      (state: Store) => {
        if (state.errors[FORM.query_builder]) {
          if (state.errors[FORM.query_builder][index]) {
            return state.errors[FORM.query_builder][index][FILTER_POSITION.operator];
          }
          return '';
        }
        return '';
      },
    );

  const [operator, filterList] = getOperatorAndFilters(filters);

  const getColumType = () => {
    const field = filterList[index][FILTER_POSITION.field];
    if (!field) {
      return '';
    }
    let column = {} as GridColumDefinition;
    const [table, property] = field.split('.');
    if (table === 'custom_fields') {
      if (!customfields[property]) return null;
      return customfields[property].type_id as CustomFieldType;
    }
    columns.some((item) => {
      if (item.field === field) {
        column = item;
        return true;
      }
      return false;
    });
    return column.type as FIELD_TYPES;
  };

  const columnType = getColumType();
  const operators = !!columnType ? SEARCH_TYPES[columnType] : null;

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const handleItemClick = (value: SEARCH_OPERATORS) => {
    filterList[index][FILTER_POSITION.operator] = value;
    filterList[index][FILTER_POSITION.operands] = '';
    filterList[index][FILTER_POSITION.extra] = getExtraValue(value);
    dispatch(setAdvancedSearch({
      entity,
      filters: {
        [operator]: [
          ...filterList,
        ],
      },
    }));
  };

  const getItems = () => {
    const items = [] as any[];
    const caption = !!operators ? t(lang, 'search.selectOperator') : t(lang, 'search.selectField');
    items.push(
      <MenuItem
        key={uuid()}
        value=""
        selected={
          filterList[index][FILTER_POSITION.operator] === '' ||
          !filterList[index][FILTER_POSITION.operator].length
        }
      >
        {caption}
      </MenuItem>,
    );
    if (!!operators) {
      operators.forEach((value: any) => {
        items.push(
          <MenuItem
            key={uuid()}
            value={value}
            selected={filterList[index][FILTER_POSITION.operator] === value}
            className={classes.item}
          >
            { t(lang, `search.operators.${value}`) }
          </MenuItem>,
        );
      });
    }
    return items;
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <FormControl variant="outlined" className={classes.formControl} error={!!error}>
        <Select
          value={filterList[index][FILTER_POSITION.operator]}
          onChange={(e) => handleItemClick(e.target.value as SEARCH_OPERATORS)}
          displayEmpty
          classes={{ root: classes.minimalSelect }}
          inputProps={{ 'aria-label': 'Without label' }}
          disabled={!operators}
          name={`operator-selector-${index}`}
        >
          { getItems() }
        </Select>
        <FormHelperText>{ error }</FormHelperText>
      </FormControl>
    </div>
  );
};

export default OperatorSelector;
