import { ENTITIES } from 'src/models/QuerybuilderModel';
import Datagrid from 'src/components/Datagrid/Datagrid';
import PaymentContactToolbar from 'src/components/DatagridToolbar/entities/PaymentContactToolbar';
import PaymentForContactSearchbar from 'src/components/DatagridSearch/PaymentForContactSearchbar';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { useEffect } from 'react';
import { t } from 'src/lib/language';
import { getContactByIdAction } from 'src/redux/actions/contactActions';

const PaymentsForContactDatagrid = () => {
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language);
  const contactId = useSelector((state: Store) => state.route.id || 0);
  const contact = useSelector((state: Store) => state.contacts[contactId]?.contact);

  const getName = () => {
    if (!contact) return '';
    if (contact.company_name) {
      return contact.company_name;
    }
    return `${contact.first_name} ${contact.last_name}`;
  };

  useEffect(() => {
    dispatch(getContactByIdAction(contactId));
  }, []);

  useEffect(() => {
    document.title = `${t(lang, 'forms.contact.payments_for')}: ${getName()}`;
  }, [contact]);

  return (
    <>
      <PaymentForContactSearchbar contactId={contactId} />
      <PaymentContactToolbar />
      <Datagrid entity={ENTITIES.payments} dontReloadEntities />
    </>
  );
};

export default PaymentsForContactDatagrid;
