// eslint-disable-next-line @typescript-eslint/naming-convention
export enum INVOICE_PAYMENT_STATUS {
  not_paid = 'not_paid',
  paid = 'paid',
  scheduled = 'scheduled',
  scheduled_due = 'scheduled_due',
  partially_paid = 'partially_paid',
}

export const INVOICE_PAYMENT_STATUS_COLOR = {
  not_paid: '#383838',
  paid: '#18a90f',
  scheduled: '#4e7ff8',
  scheduled_due: '#d41d1d',
  partially_paid: '#4e7ff8',
};

export const INVOICE_PAYMENT_STATUS_LIST = [
  INVOICE_PAYMENT_STATUS.not_paid,
  INVOICE_PAYMENT_STATUS.paid,
  INVOICE_PAYMENT_STATUS.scheduled,
  INVOICE_PAYMENT_STATUS.scheduled_due,
  INVOICE_PAYMENT_STATUS.partially_paid,
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum INVOICE_STATE {
  draft = 'draft',
  final = 'final',
  legacy = 'legacy',
  error = 'error',
  canceled = 'canceled',
  abandoned = 'abandoned',
}

export const INVOICE_STATE_LIST = [
  INVOICE_STATE.draft,
  INVOICE_STATE.final,
  INVOICE_STATE.legacy,
  INVOICE_STATE.error,
  INVOICE_STATE.canceled,
  INVOICE_STATE.abandoned,
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum INVOICE_STATUS {
  draft = 'draft',
  final = 'final',
  legacy = 'legacy',
  error = 'error',
  canceled = 'canceled',
  abandoned = 'abandoned',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const STATUS_COLOR = {
  draft: '#5a5a5a',
  final: '#383838',
  legacy: '#4e7ff8',
  error: '#d41d1d',
  canceled: '#D4631DFF',
  abandoned: '#D4631DFF',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum INVOICE_ORDER_FIELDS {
  note = 'note',
  quantity = 'quantity',
  paid = 'paid',
  taxes = 'taxes',
  discount = 'discount',
  deductible = 'deductible',
  product_id = 'product_id',
  price = 'price',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum INVOICE_FIELDS {
  allow_installment = 'allow_installment',
  account_id = 'account_id',
  bank_account_id = 'bank_account_id',
  billing_address = 'billing_address',
  billing_suite = 'billing_suite',
  billing_city = 'billing_city',
  billing_state = 'billing_state',
  billing_country_id = 'billing_country_id',
  billing_zip_code = 'billing_zip_code',
  contact_id = 'contact_id',
  contact_payer_id = 'contact_payer_id',
  contact_salutation_id = 'contact_salutation_id',
  contact_company_name = 'contact_company_name',
  contact_last_name = 'contact_last_name',
  contact_first_name = 'contact_first_name',
  department_id = 'department_id',
  due_date = 'due_date',
  issued_date = 'issued_date',
  issue_tax_receipt = 'issue_tax_receipt',
  note = 'note',
  payment_status = 'payment_status',
  program_id = 'program_id',
  payment_gateway_id = 'payment_gateway_id',
  seller_id = 'seller_id',
  shipping_address = 'shipping_address',
  shipping_suite = 'shipping_suite',
  shipping_city = 'shipping_city',
  shipping_state = 'shipping_state',
  shipping_country_id = 'shipping_country_id',
  shipping_zip_code = 'shipping_zip_code',
  state = 'state',
  title = 'title',
}
