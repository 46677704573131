import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import EditCreditCardForm from 'src/components/Forms/EditCreditCardForm';

const EditCreditCardModal = () => (
  <ModalBase
    name={MODALS.editCreditCard}
    size={MODALS_SIZE.small}
  >
    <EditCreditCardForm />
  </ModalBase>
);

export default EditCreditCardModal;
