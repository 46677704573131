import FieldSelector from 'src/components/Control/AdvancedSearch/Selectors/FieldSelector';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import OperatorSelector from 'src/components/Control/AdvancedSearch/Selectors/OperatorSelector';
import OperandSelector from 'src/components/Control/AdvancedSearch/Selectors/OperandSelector';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ActionButton from 'src/components/Elements/MenuButton';
import AddIcon from '@material-ui/icons/Add';

interface Props {
  entity: ENTITIES;
  index: number;
  deleteFilter: (index:number) => void;
  addFilter: () => void;
  isLast: boolean;
}

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
    marginBottom: theme.spacing(2),
  },
}));
const DatagridAdvancedSearchRow = (props: Props) => {
  const classes = useStyles();

  const {
    entity, index, deleteFilter, addFilter, isLast,
  } = props;

  return (
    <div className={classes.row}>
      <FieldSelector
        index={index}
        entity={entity}
      />
      <OperatorSelector
        index={index}
        entity={entity}
      />
      <OperandSelector
        index={index}
        entity={entity}
      />
      <div>
        <IconButton
          aria-label="delete filter"
          color="primary"
          onClick={() => deleteFilter(index)}
          size="small"
          style={{ height: '32px' }}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <div style={{ display: isLast ? '' : 'none', paddingTop: '4px' }}>
        <Tooltip
          title="Add filter"
          placement="top-start"
        >
          <ActionButton
            variant="outlined"
            onClick={addFilter}
            color="primary"
          >
            <AddIcon />
          </ActionButton>
        </Tooltip>
      </div>
    </div>
  );
};
//

export default DatagridAdvancedSearchRow;
